import styled from "styled-components";
import { breakpoints } from "../../../themes";

export const ActionsContainer = styled.div`
  min-width: ${props => (props.minWidth ? props.minWidth : "235px")};
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: ${props => (props.top ? props.top : "auto")};
  right: ${props => (props.right ? props.right : "auto")};
  bottom: ${props => (props.bottom ? props.bottom : "auto")};
  left: ${props => (props.left ? props.left : "auto")};
  border-radius: 4px;
  background: #3f2faa;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  padding: 6px;
  display: ${props => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  gap: 4px;
  user-select: none;
  z-index: 1;

  ${breakpoints.down(breakpoints.laptop)} {
    left: ${props => (props.calendarContainerRightCol ? "-190px !important" : "unset")};
  }
  ${breakpoints.down(breakpoints.mobileL)} {
    padding: ${props => (props.calendarContainer ? "20px 6px !important" : "unset")};
  }
`;
