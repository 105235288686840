import React, { useEffect, useRef, useState } from "react";
import * as S from "../../styles";
import ErrorTooltip from "../../../../common/ErrorTooltip";
import { useSession } from "../../../../../providers/SessionProvider";
import ImagePicker from "../ImagePicker";
import Flex from "../../../../common/Flex";

const FirstStep = ({
  register,
  errors = {},
  setValue,
  currentInputValues = {},
  clearErrors,
  watch,
  assetTypeBeingUploaded,
  assetUploadMutation,
  getValues,
  isAssetUploadLoading,
}) => {
  const { session } = useSession();
  const logoRef = useRef(null);
  const sidebarIconRef = useRef(null);

  // useEffect(() => {
  //   if (!assets || !assets.data || assets.error) return;
  //   if (assets.data?.logo) {
  //     setValue("logo", assets.data?.logo);
  //   }

  //   if (assets.data?.sidebarIcon) {
  //     setValue("sidebarIcon", assets.data?.sidebarIcon);
  //   }
  // }, [assets]);

  const nameError = errors?.name?.message;
  const accPlaceholderError = errors?.accPlaceholder?.message;
  const platformIdentifierError = errors?.platformIdentifier?.message;
  const logoError = errors?.logo?.message;
  const sidebarIconError = errors?.sidebarIcon?.message;

  const [linkPlatformIdentifierToName, setLinkPlatformIdentifierToName] = useState(true);

  function updatePlatformIdentifierByName(gameName) {
    if (!gameName) {
      setValue("platformIdentifier", "");
      return;
    }
    setValue(
      "platformIdentifier",
      gameName?.toLowerCase()?.replace(/ /g, "_"),
    );
  }

  const nameVal = watch("name");

  useEffect(() => {
    if (linkPlatformIdentifierToName) {
      updatePlatformIdentifierByName(nameVal);
    }
  }, [nameVal]);

  if (!register || !setValue) return <div />;
  return (
    <S.MainContainer>
      <S.RowContainer>
        <label>Name</label>
        <S.TextInput {...register("name")} hasError={!!nameError} onInput={() => {
          clearErrors("name");
          if(linkPlatformIdentifierToName) {
            clearErrors("platformIdentifier")
          }
        }} />
        {nameError && <ErrorTooltip message={nameError} top="55%" />}
      </S.RowContainer>
      <S.RowContainer>
        <label>Account Placeholder</label>
        <S.TextInput
          {...register("accPlaceholder")}
          hasError={!!accPlaceholderError}
          onInput={() => clearErrors("accPlaceholder")}
        />
        {accPlaceholderError && <ErrorTooltip message={accPlaceholderError} top="55%" />}
      </S.RowContainer>
      <S.RowContainer>
        <label>Platform Identifier</label>
        <S.TextInput
          {...register("platformIdentifier")}
          hasError={!!platformIdentifierError}
          onInput={() => clearErrors("platformIdentifier")}
          // If user focus on the Platform Identifier input, it means user wants to edit platform identifer themselves so we stop updating the platfor imdentifer based on the name property
          onFocus={() => setLinkPlatformIdentifierToName(false)}
        />
        {platformIdentifierError && <ErrorTooltip message={platformIdentifierError} top="55%" />}
      </S.RowContainer>

      {/* Logo Input */}
      <S.RowContainer>
        <Flex columnGap="12px">
          <label>Logo</label>
          {logoError && <ErrorTooltip message={logoError} isRelative />}
        </Flex>
        <S.ImageContainer>
          <div>
            <input
              type="file"
              className="hidden"
              ref={logoRef}
              onChange={e => {
                if (e.target?.files[0]) {
                  clearErrors("logo")
                  assetUploadMutation({ file: e.target.files[0], assetType: "logo" });
                }
                console.log(e);
              }}
              disabled={isAssetUploadLoading && assetTypeBeingUploaded === "logo"}
            />
            <ImagePicker
              handleClick={() => logoRef?.current?.click()}
              loading={isAssetUploadLoading && assetTypeBeingUploaded === "logo"}
            />
          </div>
          <S.ImageBlock isError={!!logoError}>
            <img src={getValues("logo")} style={{maxHeight: "91px"}} />
          </S.ImageBlock>
        </S.ImageContainer>
      </S.RowContainer>

      {/* Sidebar icon */}
      <S.RowContainer>
        <label>
          <Flex columnGap="12px">
            <div>Sidebar icon</div>
            {sidebarIconError && <ErrorTooltip message={sidebarIconError} isRelative />}
          </Flex>
        </label>
        <S.ImageContainer>
          <div>
            <input
              type="file"
              className="hidden"
              ref={sidebarIconRef}
              onChange={e => {
                clearErrors("sidebarIcon")
                if (e.target?.files[0]) {
                  assetUploadMutation({ file: e.target.files[0], assetType: "sidebarIcon" });
                }
              }}
              disabled={isAssetUploadLoading && assetTypeBeingUploaded === "sidebarIcon"}
            />
            <ImagePicker
              handleClick={() => sidebarIconRef?.current?.click()}
              loading={isAssetUploadLoading && assetTypeBeingUploaded === "sidebarIcon"}
            />
          </div>
          <S.ImageBlock isError={!!sidebarIconError}>
            <img src={getValues("sidebarIcon")} style={{maxHeight: "91px"}} />
          </S.ImageBlock>
        </S.ImageContainer>
      </S.RowContainer>
    </S.MainContainer>
  );
};

export default FirstStep;