import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Actions } from "../../../common/Actions/Actions";
import { Add, Copy, Substract, ThreeDots, Trash } from "../../../../assets/svg";
import * as S from "./styles";
import Flex from "../../../common/Flex";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useLogin } from "../../../../providers/Loginprovider";
import { generateInvitationalCodes, removeInvitationalCode } from "../../../../api/invitationalCodes";
import { notifyError, notifySuccess } from "../../../common/ToastComponent";
import { DeleteCodesConfirmationModal } from "../DeleteCodesConfirmationModal";

export const GenerateInvitationalCode = ({ invitationalCodes = [] }) => {
  const [toggleAction, setToggleAction] = useState(false);
  const [showDeleteCodeModal, setShowDeleteCodeModal] = useState(false);
  const [amountOfCodes, setAmountOfCodes] = useState(1);
  const codesArray = invitationalCodes.map(codeObj => codeObj.code);

  const headers = useGetSecHeaders();
  const { poolId } = useParams();
  const { orgIdState } = useLogin();
  const queryClient = useQueryClient();

  const handleIncrease = () => {
    // Increase the amount
    setAmountOfCodes(amountOfCodes + 1);
  };

  const handleDecrease = () => {
    // Check if the amount is greater than 0 before decreasing
    if (amountOfCodes > 1) {
      setAmountOfCodes(amountOfCodes - 1);
    }
  };

  const toggleActions = () => {
    setToggleAction(!toggleAction);
  };

  const toggleDeleteCodeModal = () => {
    setShowDeleteCodeModal(!showDeleteCodeModal);
  };

  const { mutate: mutateGenerateNewInvitationalCode, isLoading: mutateGenerateInvCodeLoading } = useMutation({
    mutationFn: () => {
      return generateInvitationalCodes(
        {
          organizationId: orgIdState,
          poolId: poolId,
          invitationalCodeAmount: amountOfCodes,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: e => {
      notifySuccess(e?.message || e?.data);
      queryClient.invalidateQueries({
        queryKey: ["pool", poolId],
      });
    },
  });

  const { mutate: mutateDeleteAllTheInvitationalCodes, isLoading: mutateDeleteAllInvCodeLoading } = useMutation({
    mutationFn: variables => {
      return removeInvitationalCode(
        {
          organizationId: variables.organizationId,
          poolId: variables.poolId,
          invitacionalCodesArray: variables.invitationalCodes,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: e => {
      toggleDeleteCodeModal();
      notifySuccess(e?.message || e?.data);
      queryClient.invalidateQueries({
        queryKey: ["pool", poolId],
      });
    },
  });

  const actionsMenu = [
    {
      label: "Copy all the codes",
      icon: <Copy />,
      disabled: true,
    },
    {
      label: "Delete all the codes",
      icon: <Trash />,
      disabled: mutateDeleteAllInvCodeLoading,
      onClick: () => toggleDeleteCodeModal(),
    },
  ];

  const ActionsMenu = () => (
    <S.ThreeDotsContainer
      onClick={e => {
        e.preventDefault();
      }}
    >
      <S.ThreeDotsButtonContainer onClick={toggleActions} actionIsToggled={toggleAction}>
        <ThreeDots />
      </S.ThreeDotsButtonContainer>
      <Actions showActions={toggleAction} right="0px" top="40px" closeActionsMenu={() => setToggleAction(false)}>
        {actionsMenu.map((action, index) => (
          <S.ActionButton key={index} onClick={action.onClick} disabled={action.disabled}>
            {action.icon}
            <S.ActionLabel>{action.label}</S.ActionLabel>
          </S.ActionButton>
        ))}
      </Actions>
    </S.ThreeDotsContainer>
  );

  return (
    <S.InvitationalCodeGeneratorWrapper>
      <Flex columnGap="8px">
        <S.CodeAmountWrapper>
          <S.DecreaseValueButton onClick={handleDecrease}>
            <Substract />
          </S.DecreaseValueButton>
          <S.CodeAmountInput value={amountOfCodes} onChange={e => setAmountOfCodes(Number(e.target.value))} />
          <S.IncreaseValueButton onClick={handleIncrease}>
            <Add />
          </S.IncreaseValueButton>
        </S.CodeAmountWrapper>
        <S.GenerateButton
          onClick={() => {
            mutateGenerateNewInvitationalCode();
          }}
          disabled={mutateGenerateInvCodeLoading}
        >
          Generate code
        </S.GenerateButton>
      </Flex>
      <ActionsMenu />
      <DeleteCodesConfirmationModal
        showModal={showDeleteCodeModal}
        toggleModal={toggleDeleteCodeModal}
        mutateDeleteAllTheInvitationalCodes={mutateDeleteAllTheInvitationalCodes}
        mutateDeleteInvAllCodeLoading={mutateDeleteAllInvCodeLoading}
        codesArray={codesArray}
        organizationId={orgIdState}
        poolId={poolId}
      />
    </S.InvitationalCodeGeneratorWrapper>
  );
};
