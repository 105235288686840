import React, { useEffect, useState } from "react";
import { z } from "zod";
import moment from "moment/moment";

import * as S from "../../styles";
import ErrorTooltip from "../../../../common/ErrorTooltip";
import CustomDateTimePicker from "../../../../common/CustomDateTimePicker";
import { Tooltip } from "antd";
import { POOL_MODES } from "../../../../../constants";

import Container from "../../../../common/Container/Container";
import Text from "../../../../common/Text/Text";

import { isNonEmptyObject } from "../../../../../utilities";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { AccordionArrow } from "../../../../../assets/svg";

const ThirdStep = ({
  register,
  errors = {},
  currentInputValues = {},
  setValue,
  setError,
  clearErrors,
  watch,
  stageCount,
}) => {
  const { dates = [] } = currentInputValues;

  useEffect(() => {
    clearErrors(["dates"]);
    dates.forEach((date, i) => {
      const { registrationStartDate, registrationEndDate, tournamentStartDate, tournamentEndDate } = date;
      let formattedRegStartDate = registrationStartDate ? moment(registrationStartDate) : null;
      let formattedRegEndDate = registrationEndDate ? moment(registrationEndDate) : null;
      let formattedTournamentStartDate = tournamentStartDate ? moment(tournamentStartDate) : null;
      let formattedTournamentEndDate = tournamentEndDate ? moment(tournamentEndDate) : null;

      if (formattedRegStartDate && !formattedRegEndDate) {
        setError(`dates.${i}.registrationEndDate`, {
          type: "custom",
          message: "Registration end date is required if registration start date has been provided",
        });
      }
      if (formattedRegEndDate && !formattedRegStartDate) {
        setError(`dates.${i}.registrationStartDate`, {
          type: "custom",
          message: "Registration start date is required if registration end date has been provided",
        });
      }

      if (formattedRegStartDate && formattedRegEndDate && formattedRegStartDate.isAfter(formattedRegEndDate)) {
        setError(`dates.${i}.registrationStartDate`, {
          type: "custom",
          message: "Registration start date cannot be after registration end date",
        });
      }

      if (formattedTournamentStartDate) {
        if (formattedRegEndDate && formattedRegEndDate.isAfter(formattedTournamentStartDate)) {
          setError(`dates.${i}.registrationEndDate`, {
            type: "custom",
            message: "Registration end date cannot be after tournament start date",
          });
        }

        if (formattedTournamentEndDate && formattedTournamentStartDate.isAfter(formattedTournamentEndDate)) {
          setError(`dates.${i}.tournamentStartDate`, {
            type: "custom",
            message: "Tournament start date cannot be tournament start date",
          });
        }
      }
    });
  }, [JSON.stringify(dates)]); // using JSON.stringify because using dates directly was causing the useEffect to run repeatedly

  const isPoolModeLeaderboard = (stageId = 1) =>
    currentInputValues?.stages?.[stageId]?.stageMode === POOL_MODES.LEADERBOARD;

  if (!setValue) return <div />;
  if (!register || !setValue) return <div />;

  return (
    <Accordion>
      <Container flex flexDirection="column" rowGap="27px">
        {[...Array(stageCount).keys()].map((_, i) => (
          <AccordionItem>
            <AccordionItemHeading
              style={{
                borderRadius: "6px",
                border: errors?.dates?.[i] || isNonEmptyObject(errors?.dates) ? "1px solid #ed6140" : "",
              }}
            >
              <AccordionItemButton>
                <span>Stage {i + 1}</span>{" "}
                <span>
                  <AccordionArrow />
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Container flex flexDirection="column" rowGap="27px" marginLeft="35px" marginRight="35px">
                <Container flex alignItems="flex-start" justifyContent="space-between">
                  <Text fontSize="16px">Registration</Text>

                  <Container flex flexDirection="column" columnGap="23px" justifyContent="space-between">
                    <Container flex position="relative">
                      {/* Registration Start Date */}
                      <Tooltip
                        title={
                          isPoolModeLeaderboard(i)
                            ? "Register dates are not available for leaderboard type tournaments"
                            : ""
                        }
                        color="#7265C8"
                      >
                        <S.MultistageCalendarPickerContainer hasError={!!errors?.dates?.[i]?.registrationStartDate}>
                          <Text color="#A498ED" fontSize="13px">
                            Start
                          </Text>
                          <CustomDateTimePicker
                            value={currentInputValues?.dates?.[i]?.registrationStartDate}
                            onChange={v => {
                              clearErrors(`dates.${i}.registrationStartDate`);
                              if (isNaN(v)) {
                                setValue(`dates.${i}.registrationStartDate`, 0);
                              } else {
                                setValue(`dates.${i}.registrationStartDate`, v);
                              }
                            }}
                            disabled={isPoolModeLeaderboard(i)}
                            format="dd/MM/y          hh:mm a"
                            calendarIconStyles={{
                              position: "absolute",
                              left: currentInputValues?.dates?.[i]?.registrationStartDate ? "120px" : "100px",
                              top: "0px",
                            }}
                            allowClear
                          />
                        </S.MultistageCalendarPickerContainer>
                      </Tooltip>

                      {errors?.dates?.[i]?.registrationStartDate && (
                        <ErrorTooltip
                          top="15px"
                          right="5px"
                          message={errors?.dates?.[i]?.registrationStartDate?.message}
                        />
                      )}
                    </Container>

                    {/* Registration End Date */}
                    <Container flex position="relative" marginTop="6px">
                      <Tooltip
                        title={
                          isPoolModeLeaderboard(i)
                            ? "Register dates are not available for leaderboard type tournaments"
                            : ""
                        }
                        color="#7265C8"
                      >
                        <S.MultistageCalendarPickerContainer hasError={!!errors?.dates?.[i]?.registrationEndDate}>
                          <Text color="#A498ED" fontSize="13px">
                            End
                          </Text>
                          <CustomDateTimePicker
                            value={currentInputValues?.dates?.[i]?.registrationEndDate}
                            onChange={v => {
                              clearErrors(`dates.${i}.registrationEndDate`);
                              if (isNaN(v)) {
                                setValue(`dates.${i}.registrationEndDate`, 0);
                              } else {
                                setValue(`dates.${i}.registrationEndDate`, v);
                              }
                            }}
                            allowClear
                            disabled={isPoolModeLeaderboard(i)}
                            format="dd/MM/y          hh:mm a"
                            calendarIconStyles={{
                              position: "absolute",
                              left: currentInputValues?.dates?.[i]?.registrationEndDate ? "120px" : "100px",
                              top: "0px",
                            }}
                          />
                        </S.MultistageCalendarPickerContainer>
                      </Tooltip>

                      {errors?.dates?.[i]?.registrationEndDate && (
                        <ErrorTooltip
                          top="15px"
                          right="5px"
                          message={errors?.dates?.[i]?.registrationEndDate?.message}
                        />
                      )}
                    </Container>
                  </Container>
                </Container>

                <Container marginTop="64px" flex alignItems="flex-start" justifyContent="space-between">
                  <Text fontSize="16px">Tournament</Text>

                  <Container flex flexDirection="column" columnGap="23px" justifyContent="space-between">
                    <Container flex position="relative">
                      {/* Tournament Start Date */}
                      <S.MultistageCalendarPickerContainer hasError={!!errors?.dates?.[i]?.tournamentStartDate}>
                        <Text color="#A498ED" fontSize="13px">
                          Start
                        </Text>
                        <CustomDateTimePicker
                          value={currentInputValues?.dates?.[i]?.tournamentStartDate}
                          onChange={v => {
                            clearErrors(`dates.${i}.tournamentStartDate`);
                            setValue(`dates.${i}.tournamentStartDate`, v);
                          }}
                          format="dd/MM/y          hh:mm a"
                          calendarIconStyles={{
                            position: "absolute",
                            left: currentInputValues?.dates?.[i]?.tournamentStartDate ? "120px" : "100px",
                            top: "0px",
                          }}
                        />
                      </S.MultistageCalendarPickerContainer>
                      {errors?.dates?.[i]?.tournamentStartDate && (
                        <ErrorTooltip
                          top="15px"
                          right="5px"
                          message={errors?.dates?.[i]?.tournamentStartDate?.message}
                        />
                      )}
                    </Container>

                    {/* Tournament End Date */}
                    <Container flex position="relative" marginTop="6px">
                      <S.MultistageCalendarPickerContainer hasError={!!errors?.dates?.[i]?.tournamentEndDate}>
                        <Text color="#A498ED" fontSize="13px">
                          End
                        </Text>
                        <CustomDateTimePicker
                          value={currentInputValues?.dates?.[i]?.tournamentEndDate}
                          onChange={v => {
                            clearErrors(`dates.${i}.tournamentEndDate`);
                            setValue(`dates.${i}.tournamentEndDate`, v);
                          }}
                          format="dd/MM/y          hh:mm a"
                          calendarIconStyles={{
                            position: "absolute",
                            left: currentInputValues?.dates?.[i]?.tournamentEndDate ? "120px" : "100px",
                            top: "0px",
                          }}
                        />
                      </S.MultistageCalendarPickerContainer>
                      {errors?.dates?.[i]?.tournamentStartDate && (
                        <ErrorTooltip top="15px" right="5px" message={errors?.dates?.[i]?.tournamentEndDate?.message} />
                      )}
                    </Container>
                  </Container>
                </Container>
              </Container>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Container>
    </Accordion>
  );
};

export default ThirdStep;
