import styled from "styled-components";
import { breakpoints } from "../../../themes";
import { Button } from "../Button";

export const ThreeDotsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ThreeDotsButtonContainer = styled.div`
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.openMenu ? "#4e3cca" : "transparent")};
  border-radius: 6px;
`;

export const MenuOptionBtn = styled.button`
  width: 100%;
  height: 34px;
  border-radius: 4px;
  background: ${props => (props.selected ? "#4E3CCA" : "#3f2faa")};
  justify-content: flex-start;
  position: ${props => props.position || "static"};
  outline: none;
  border: 0;
  gap: 10px;
  cursor: pointer;
  &:disabled {
    cursor: default;
    opacity: 1;
    span {
      color: #ffffff80 !important;
    }
  }
`;

// export const SubMenuItemContainer = styled.div`

//   position: relative;
//   & > div {
//     position: absolute;
//     right: 165px;
//     top: 0%;
//   }
// `;

export const MenuOptionLabel = styled.span`
  color: #fff;
  font-size: 14px;
  font-weight: 325;
  white-space: nowrap;
`;

export const StyleMenuContainer = styled.div`
  min-width: ${props => (props.minWidth ? props.minWidth : "181px")};
  height: fit-content;
  position: absolute;
  top: ${props => (props.top ? props.top : "auto")};
  right: ${props => (props.right ? props.right : "auto")};
  bottom: ${props => (props.bottom ? props.bottom : "auto")};
  left: ${props => (props.left ? props.left : "auto")};
  border-radius: 4px;
  background: #3f2faa;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  padding: 6px;
  display: ${props => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  gap: 4px;
  user-select: none;
  z-index: 1;

  ${breakpoints.down(breakpoints.laptop)} {
    left: ${props => (props.calendarContainerRightCol ? "-190px !important" : "unset")};
  }
  ${breakpoints.down(breakpoints.mobileL)} {
    padding: ${props => (props.calendarContainer ? "20px 6px !important" : "unset")};
  }

  .submenu {
    position: relative;
    & > div {
      position: absolute;
      right: ${props => (props.minWidth ? props.minWidth : "181px")};
      top: 0%;
    }
  }
`;
