import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ImageContainer = styled.div`
  height: 351px;
  width: 496px;
  border: 2px solid #4e3cca;
  position: relative;
  background: ${props => `url(${props.bg})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-left: 30px;
  padding-right: 30px;

  &::after,
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 30%; /* Adjust as needed */
    /* backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); */
  }

  &::before {
    top: 0;
    background: linear-gradient(
      to bottom,
      rgba(32, 19, 102, 1) 0%,
      rgba(32, 19, 102, 0.8) 50%,
      rgba(32, 19, 102, 0.5) 75%,
      rgba(32, 19, 102, 0) 100%
    );
  }

  &::after {
    bottom: 0;
    background: linear-gradient(
      to top,
      rgba(32, 19, 102, 1) 0%,
      rgba(32, 19, 102, 0.8) 50%,
      rgba(32, 19, 102, 0.4) 75%,
      rgba(32, 19, 102, 0) 100%
    );
  }

  @media (min-width: 2000px) {
    height: 451px;
    width: 596px;
  }
  @media (min-width: 2000px) {
    height: 551px;
    width: 696px;
  }
`;

export const SmallTopBar = styled.div`
  background-color: #a498ed;
  border-radius: 4px;
  height: 18px;
  z-index: 3;
`;

export const PillButton = styled.div`
  width: 68px;
  height: 25px;
  border-radius: 50px;
  background: ${props => (props.active ? `linear-gradient(180deg, #7283F7 0%, #AA57FF 100%)` : `#3B35A3`)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  z-index: 2;
`;

export const ButtonRow = styled.div`
  display: flex;
  column-gap: 9px;
  margin-top: 12px;
`;

export const WinnersRow = styled.div`
  width: 100%;
  height: 100px;
  margin: auto 0;
  margin-top: 40px;
`