import { z } from "zod";

export const RuleSchema = z.object({
  title: z.string().optional(),
  content: z.any().optional(),
});
export const MultiStageFormDataSchema = z.object({
  // First Step Schema
  organizationId: z.string().min(1, "Organization ID is required"),
  gameId: z.coerce
    .number({
      required_error: "At least 1  player is required",
      invalid_type_error: "At least 1  player is required",
    })
    .min(1),
  title: z.string().min(1, "Title is required"),
  subtitle: z.string().min(1, "Subtitle is required"),
  prizePool: z.coerce.number().default(0),
  prizeToken: z.string().min(1, "Prize token is required"),
  entry: z.coerce.number().default(0),
  joinMode: z.enum(["solo", "team"], {
    invalid_type_error: "Please select one of the join modes",
    required_error: "Please select one of the join modes",
    invalid_enum_value: "Please select one of the join modes",
  }),
  teamPlayers: z.coerce.number({}).optional(),
  maxPlayers: z.coerce
    .number({
      required_error: "At least 1  player is required",
      invalid_type_error: "At least 1  player is required",
    })
    .min(1),
  // stages: z.coerce.number().default(1),

  // // Second Step Schema
  ////////////////////////////////////////////////////////////////////////////
  stages: z.array(
    z.object({
      title: z.string().min(1, "Title is required"),
      nextStageId: z.number(),
      stageRound: z.enum(["qualifier", "final", "prize pool", "group stage"], {
        invalid_type_error: "Please select a valid stage",
        required_error: "Please select a valid stage",
        invalid_enum_value: "Please select a valid stage",
      }),
      stageMode: z.enum(["LEADERBOARD", "ROUND_ROBIN", "SINGLE_ELIMINATION"], {
        invalid_type_error: "Please select one of the tournament modes",
        required_error: "Please select one of the tournament modes",
        invalid_enum_value: "Please select one of the tournament modes",
      }),
      groupSize: z.number().optional(),
      bestMatchSelectMode: z.enum(["BEST_MATCH", "SUMMARIZE_MATCH"], {
        invalid_type_error: "Please select one of the join modes",
        invalid_enum_value: "Please select one of the join modes",
      }).optional(),
      rules: z.array(RuleSchema).optional().default([]),
    }),
  ),

  ////////////////////////////////////////////////////////////////////////////

  // // Thiird Step Schema
  dates: z.array(
    z.object({
      registrationStartDate: z.coerce
        .date({
          errorMap: (issue, { defaultError }) => ({
            message: issue.code === "invalid_date" ? "Registration start date is required" : defaultError,
          }),
        })
        .optional(),
      registrationEndDate: z.coerce
        .date({
          errorMap: (issue, { defaultError }) => ({
            message: issue.code === "invalid_date" ? "Registration end date is required" : defaultError,
          }),
        })
        .optional(),
      tournamentStartDate: z.coerce.date({
        errorMap: (issue, { defaultError }) => ({
          message: issue.code === "invalid_date" ? "Tournament start date is required" : defaultError,
        }),
      }),
      tournamentEndDate: z.coerce.date({
        errorMap: (issue, { defaultError }) => ({
          message: issue.code === "invalid_date" ? "Tournament end date is required" : defaultError,
        }),
      }),
    }),
  ),

  ////////////////////////////////////////////////////////////////////////////

  // // Third Step
  winnersPolicy: z.array(
    z.object({
      type: z.string().min("1", "Winners mode type is required"),
      value: z.array(z.coerce.number()).min(1, "Value is required").default([]),
    }),
  ),
  winnerCount: z.coerce
    .number({
      required_error: "Winner count is required",
      invalid_type_error: "Winner count is required",
    })
    .min(1),
  percentageList: z.coerce.number().array().nonempty(),

  // rules: z.array(RuleSchema).optional().default([]),

  ////////////////////////////////////////////////////////////////////////////

  // // Fourth Step
  thumbnail: z.string().min(1, "Thumbnail is required"),
  bannerBgBack: z.string().min(1, "Banner background is required"),
  bannerBgFront: z.string().min(1, "Banner front image is required"),
});
