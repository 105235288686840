import { createContext, useContext, useEffect, useState } from "react";
import moment from "moment";
import { publicIpv4 } from "public-ip";
import * as crypto from "crypto-js";
import { useUserAddress } from "eth-hooks";
import { useGetSecHeaders } from "../hooks/useHeaders";
import { ENDPOINT } from "../constants/endpoints";
import { useWallet } from "./WalletProvider";
import { SEED, STATE_KEY } from "../constants/env";
import { CHAIN_ID } from "../constants/env";
import { useNetworkListener } from "../hooks/useNetworkListener";
import {
  getUserInLocalStorage,
  getUserOrganizationData,
  getUserOrganizationId,
  removeUserInLocalStorage,
  setUserInLocalStorage,
  setUserOrganizationData,
  setUserOrganizationId,
} from "../helpers/localStorage";
import { useAppState } from "./AppStateProvider";
import { useSession } from "./SessionProvider";
import { LINKING_WALLET, SOCIAL } from "../constants";
import axiosClient from "../api/axiosClient";

export const LoginContext = createContext(null);

const extendUserSessionMinutes = 1440;
let globalSecHeaders;

export const LoginProvider = ({ children }) => {
  const { injectedProvider, logoutOfWeb3Modal } = useWallet();
  const { setCloseConnectWalletModal, setIsConnectingWallet, linkWalletError, setLinkWalletError } = useAppState();
  const address = useUserAddress(injectedProvider);
  const headers = useGetSecHeaders();
  const { chainId } = useNetworkListener();

  const { session, setSession, checkUserSession, setCheckUserSession } = useSession();
  const [isFetching, setIsFetching] = useState(false);
  const [showBanModal, setShowBanModal] = useState(false);
  const [banReason, setBanReason] = useState("");
  const [userState, setUserState] = useState(null);
  const [orgIdState, setOrgIdState] = useState(() => getUserOrganizationId()); //organization Id in localstorage. Will update to a more descriptive name later
  const [orgDataState, setOrgDataState] = useState(() => getUserOrganizationData());

  const linkWalletFunction = async data => {
    setLinkWalletError("");
    localStorage.removeItem(LINKING_WALLET);
    setIsConnectingWallet(true);
    const firstLinkResponse = await linkWalletApiCall();
    if (
      !firstLinkResponse.data ||
      firstLinkResponse?.data?.error ||
      firstLinkResponse?.data?.message !== "SIGN_NONCE"
    ) {
      setIsConnectingWallet(false);
      setLinkWalletError(firstLinkResponse?.data?.data || "Something went wrong. Please try again later");
      return;
    }

    if (!address) return;
    let signedMessage = "";
    try {
      const signer = injectedProvider.getSigner(address);
      signedMessage = await signer.signMessage(firstLinkResponse?.data?.data);
    } catch (err) {
      setIsConnectingWallet(false);
      console.log("signature Error.... ", err);
      throw `Signature Error -> ${err}`;
    }

    const secondLinkResponse = await linkWalletApiCall({ signature: signedMessage });
    if (!secondLinkResponse.data || secondLinkResponse?.data?.error) {
      setIsConnectingWallet(false);
      setLinkWalletError(secondLinkResponse?.data?.data || "Something went wrong. Please try again later");
      return;
    }
    setLocalSession(
      { ...data.user, address: secondLinkResponse.data?.data },
      data.access_token,
      data.ip,
      data.expiresOn,
      data.loggedWith,
    );
    setIsConnectingWallet(false);
    setCloseConnectWalletModal(true);
    setLinkWalletError("");
  };
  const [socialAuthData, setSocialAuthData] = useState(undefined);

  useEffect(() => {
    if (headers) {
      globalSecHeaders = headers;
    }
  }, [headers]);

  useEffect(() => {
    if (userState) {
      setLocalSession(
        { ...session.user, ...userState },
        session.access_token,
        session.ip,
        session.expiresOn,
        session.loggedWith,
      );
      setUserState(null);
    }
  }, [userState]);

  useEffect(() => {
    // Using localStorage to check if there is a user logged in
    if (checkUserSession && getUserInLocalStorage() && !isFetching) {
      checkLocalSession();
      setCheckUserSession(false);
    } else if (checkUserSession) {
      setCheckUserSession(false);
    }
  }, [checkUserSession]);

  useEffect(() => {
    async function func() {
      if (socialAuthData) {
        const clientIP = await publicIpv4({ fallbackUrls: [ENDPOINT.IP_CONFIG] });
        setLocalSession(
          socialAuthData.user,
          socialAuthData.access_token,
          clientIP,
          moment().add(extendUserSessionMinutes, "minutes").unix(),
          socialAuthData.loggedWith,
        );
      }
    }

    func();
  }, [socialAuthData]);

  useEffect(() => {
    if (orgIdState === "") {
      localStorage.removeItem("orgId");
    } else {
      setUserOrganizationId(orgIdState);
    }
  }, [orgIdState]);

  useEffect(() => {
    if (orgDataState === "") {
      localStorage.removeItem("orgData");
    } else {
      setUserOrganizationData(orgDataState);
    }
  }, [orgDataState]);

  const updateDbSession = async (user, loggedWith) => {
    try {
      const clientIP = await publicIpv4({ fallbackUrls: [ENDPOINT.IP_CONFIG] });
      // const firstStepResponse = await logInFirstStep(address, clientIP);

      const response = await extendSessionApiCall(user.id, clientIP, disconnect, logoutOfWeb3Modal);

      if (response.access_token) {
        setLocalSession(
          response.user,
          response.access_token,
          clientIP,
          moment().add(extendUserSessionMinutes, "minutes").unix(),
          loggedWith,
        );
      }
      return;
    } catch (e) {
      console.log("error updating local user on background... ", e);
    }
  };

  const setLocalSession = (user, access_token, ipAddress, expiresOn, loggedWith) => {
    const userSession = { user, access_token, ip: ipAddress, expiresOn };
    if (loggedWith) {
      userSession.loggedWith = loggedWith;
    }
    // app state
    setSession(userSession);
    const encryptedState = crypto.AES.encrypt(JSON.stringify(userSession), SEED).toString();
    setUserInLocalStorage(encryptedState);
    setOrgIdState(userSession?.user?.organizations?.[0]?.organizationId || "");
    setOrgDataState(userSession?.user?.organizations?.[0]);
    return;
  };

  const getLocalStorageSession = async () => {
    // check if we have a valid session on local storage. Update on db ONLY if needed.
    const localStorageSession = localStorage.getItem(STATE_KEY);
    if (localStorageSession != null) {
      const sessionBytes = crypto.AES.decrypt(localStorageSession, SEED);
      const sessionObject = JSON.parse(sessionBytes.toString(crypto.enc.Utf8));

      if (
        sessionObject.expiresOn > moment().unix() &&
        (sessionObject.loggedWith === SOCIAL || sessionObject.user.address === address)
      ) {
        // check valid time to avoid API calls
        if (moment().add(10, "minutes").unix() >= sessionObject.expiresOn) {
          // need to update user session (db & locally) to extend another extendUserSessionMinutes.
          await updateDbSession(sessionObject.user, sessionObject.loggedWith);
        } else {
          // localstorage session is valid -> set recoil state
          setSession(sessionObject);
        }
        return true;
      }
    }
    return false;
  };

  const userLogIn = async () => {
    try {
      const clientIP = await publicIpv4({ fallbackUrls: [ENDPOINT.IP_CONFIG] });
      const firstStepResponse = await logInFirstStep(address, clientIP);
      if (firstStepResponse && firstStepResponse.user) {
        setLocalSession(
          firstStepResponse.user,
          firstStepResponse.access_token,
          clientIP,
          moment().add(extendUserSessionMinutes, "minutes").unix(),
          firstStepResponse.loggedWith,
        );
        return;
      }
      if (firstStepResponse.error && firstStepResponse.message === "BANNED_USER") {
        setShowBanModal(true);
        setBanReason(firstStepResponse.description || firstStepResponse.reason);
        return;
      }
      if (!firstStepResponse.nonce) {
        throw "Error trying to log in a user -> No nonce";
      }
      // signature requested - sending signature withour hasing
      // const messageHash = web3Hash.hashMessage(firstStepResponse.nonce);
      const messageHash = firstStepResponse.nonce;
      const signer = injectedProvider.getSigner(address);
      try {
        const signedMessage = await signer.signMessage(messageHash);
        const sndStepResponse = await logInSecondStep(address, clientIP, signedMessage);
        if (sndStepResponse.error) {
          throw "Oops...Something went wrong calling login second step.";
        }
        // create session
        setLocalSession(
          sndStepResponse.user,
          sndStepResponse.access_token,
          clientIP,
          moment().add(extendUserSessionMinutes, "minutes").unix(),
          sndStepResponse.loggedWith,
        );
        return;
      } catch (e) {
        // user refuse to sign the message.
        console.log("signature Error.... ", e);
        throw `Signature Error -> ${e}`;
      }
    } catch (e) {
      console.log("Error on userLogIn -> ", e);
      throw e;
    }
  };

  const checkLocalSession = async () => {
    const localStorageData = getUserInLocalStorage();
    if ((chainId != CHAIN_ID || !address) && (!localStorageData || localStorageData.loggedWith != SOCIAL)) {
      setIsFetching(false);

      return;
    }
    try {
      //get local data
      const localLoggedUser = await getLocalStorageSession();
      if (localLoggedUser) {
        if (localStorage.getItem(LINKING_WALLET) && address) {
          // linkWalletFunction
          // remove the linking variable
          setCloseConnectWalletModal(false);

          linkWalletFunction(localStorageData);
        }
        setIsFetching(false);
        return;
      }
      // No logged user -> clean old local storage
      setIsFetching(true);
      removeUserInLocalStorage();
      // do sign in
      // If user is logged in with social auth disconnect the user (because we do not have a way to automatically log in via socialAuth)
      // If user not logged in via socialAuth (i.e. via metamask) call userLogin.
      if (localStorageData.loggedWith === SOCIAL) {
        await disconnect();
        logoutOfWeb3Modal();
      } else {
        await userLogIn();
      }
      setIsFetching(false);
      return;
    } catch (e) {
      setIsFetching(false);
      console.log("check local session catch error ", e);
      // disconnect local & provider.
      await disconnect();
      logoutOfWeb3Modal();
      // throw e;
    }
  };

  const disconnect = async () => {
    setIsFetching(true);
    try {
      // remove server and local user session
      await removeDbSession(address);
      removeUserInLocalStorage();
      setIsFetching(false);
      return;
    } catch (e) {
      setIsFetching(false);
      return;
    }
  };

  const logout = async () => {
    await disconnect();
    logoutOfWeb3Modal();
    setSession(null);
    setOrgIdState("");
    setOrgDataState("");
    localStorage.removeItem("orgId");
    localStorage.removeItem("orgData");
    removeUserInLocalStorage();
  };

  const logInFirstStep = async (address, ipAddress) => {
    try {
      const response = await axiosClient.get(`${ENDPOINT.SIGNIN}?address=${address}&ip=${ipAddress}&admPanel=true`, {
        headers: {
          accept: "*/*",
        },
      });

      if (response && response.err) {
        return;
      }

      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const extendSessionApiCall = async (userId, ipAddress, disconnect, logoutOfWeb3Modal) => {
    try {
      const response = await axiosClient.put(
        `${ENDPOINT.SIGNIN}/extSession`,
        { userId, ipAddress, admPanel: true },
        {
          headers: {
            accept: "*/*",
            Authorization: globalSecHeaders.encryptedHeader,
          },
        },
      );
      return response.data;
    } catch (e) {}
  };

  const linkWalletApiCall = async body => {
    try {
      const response = await axiosClient.put(`user/wallet/connect`, body, {
        headers: {
          accept: "*/*",
          Authorization: globalSecHeaders.encryptedHeader,
        },
      });

      return response;
    } catch (e) {
      return { error: true, message: "Something went wrong", data: {} };
    }
  };

  /**
   * Sign Up / Log In - Second step
   **/
  const logInSecondStep = async (address, ipAddress, signedNonce) => {
    try {
      const postBody = {
        address,
        ip: ipAddress,
        signature: signedNonce,
        admPanel: true,
      };
      const response = await axiosClient.post(`${ENDPOINT.SIGNIN}`, postBody, {
        headers: {
          accept: "*/*",
        },
      });
      if (response && response.err) {
        throw "Error on second service response.";
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  const socialLoginApiCall = async (provider, provider_token) => {
    const clientIP = await publicIpv4({ fallbackUrls: [ENDPOINT.IP_CONFIG] });
    try {
      const postBody = {
        ipAddress: clientIP,
        provider,
        provider_token,
        admPanel: true,
      };
      const response = await axiosClient.put(`${ENDPOINT.SIGNIN}`, postBody, {
        headers: {
          accept: "*/*",
        },
      });
      if (response && response.err) {
        return { error: true, data: response.err };
      }
      return { error: false, data: response.data };
    } catch (e) {
      throw e;
    }
  };

  /**
   * Remove web session from server
   **/
  const removeDbSession = async () => {
    try {
      const response = await axiosClient.delete(`${ENDPOINT.USER}/${ENDPOINT.CLOSE_SESSION}`, {
        headers: {
          accept: "*/*",
          Authorization: globalSecHeaders.encryptedHeader,
        },
      });
      if (response && response.err) {
        return;
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    // We are also checking the local storage because this useEffect will also run on the initial render which satisifes our requirement of checking the local storage initially
    // if(isFetching) return;

    if ((injectedProvider && address) || getUserInLocalStorage()) {
      if (!localStorage.getItem(LINKING_WALLET)) setIsFetching(true);
      checkLocalSession();
    }
  }, [injectedProvider, address]);

  useEffect(() => {
    if (CHAIN_ID === chainId && !isFetching) {
      setIsFetching(true);
      checkLocalSession();
    }
  }, [chainId]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", () => {
        window.location.reload();
      });
      window.ethereum.on("disconnect", () => {
        console.log("account is disconnected ");
      });
    }
  }, [window.ethereum]);

  return (
    <LoginContext.Provider
      value={{
        isFetching,
        disconnect,
        showBanModal,
        setShowBanModal,
        banReason,
        socialLoginApiCall,
        setSocialAuthData,
        socialAuthData,
        setUserState,
        linkWalletError,
        logout,
        orgIdState,
        setOrgIdState,
        orgDataState,
        setOrgDataState,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export function useLogin() {
  // get the context
  const context = useContext(LoginContext);

  // if `undefined`, throw an error
  if (context === undefined || context === null) {
    throw new Error("useLoginContext was used outside of its Provider");
  }

  return context;
}
