import React from "react";
import { useMedia } from "use-media";

const useScreenSizes = () => {
  const xs = useMedia({ maxWidth: "575px" });
  const sm = useMedia({ minWidth: "576px", maxWidth: "767px" });
  const md = useMedia({ minWidth: "768px", maxWidth: "991px" });
  const lg = useMedia({ minWidth: "992px", maxWidth: "1199px" });
  const xl = useMedia({ minWidth: "1200px", maxWidth: "1440px" });
  const xl2 = useMedia({ minWidth: "1401px", maxWidth: "1535px" });
  const xl3 = useMedia({ minWidth: "1536px", maxWidth: "1919px" });
  const xl4 = useMedia({ minWidth: "1920px", maxWidth: "2559px" });
  const xl5 = useMedia({ minWidth: "2560px" });

  return {
    xs,
    sm,
    md,
    lg,
    xl,
    xl2,
    xl3,
    xl4,
    xl5
  };
};

export default useScreenSizes;
