import React, { createContext, useContext, useEffect, useState } from "react";
import * as S from "./styles";
import { TournamentDetailHeader } from "../../components/views/tournamentDetail/TournamentHeader";
import LoadingSkeleton from "../../components/common/LoadingSkeleton";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getStageInfo } from "../../api/stage";
import { useGetSecHeaders } from "../../hooks/useHeaders";
import { useLogin } from "../../providers/Loginprovider";
import { POOL_TYPES, STAGE_MODES } from "../../constants";
import { TournamentContext } from "../../providers/TournamentContext";
import MatchReportModal from "../../components/views/tournamentDetail/MatchReportModal";
import { BackArrow } from "../../assets/svg";
import { DownloadPlayerListModal } from "../../components/views/tournamentDetail/DownloadPlayersListModal";
import { approveTournament, executePoolAction, getPoolById, reactivatePool } from "../../api/pool";
import { notifyError, notifyInfo, notifySuccess } from "../../components/common/ToastComponent";
import { useHistory } from "react-router-dom";
import { isNonEmptyObject } from "../../utilities";
import TagButtons from "./components/TagButtons";
import { useSession } from "../../providers/SessionProvider";
import Sections from "./components/Sections";

export const TournamentDetail = ({ userType }) => {
  const { orgIdState } = useLogin();
  const { poolId } = useParams();
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();
  const { session } = useSession();

  const [selectedStage, setSelectedStage] = useState({});

  const [extraReportData, setExtraReportData] = useState({});
  const [isPlayerListDownloadModalOpen, setIsPlayerListDownloadModalOpen] = useState(false);
  const [playerListDownloadLink, setPlayerListDownloadLink] = useState("");
  const [showReportModal, setShowReportModal] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(1);

  const [Buttons, setButtons] = useState([
    { name: "Players", active: true },
    { name: "Brackets", active: false },
    { name: "Invitational Codes", active: false },
    { name: "Info", active: false },
  ]);
  const [activeSection, setActiveSection] = useState("Players");

  const history = useHistory();
  const goBack = () => {
    history?.goBack();
  };

  if (poolId === "undefined" || !poolId) {
    history.push("/");
  }

  // isFetching will be updated everytime a query is revalidated as well.We need it for loading indicators in different parts of the UI when revalidating the query
  const {
    data,
    isLoading: isLoadingPoolData,
    isFetching: isFetchingPoolData,
    error: loadingPoolDataError,
  } = useQuery({
    queryKey: ["pool", poolId],
    queryFn: () => getPoolById({ poolId, orgId: orgIdState }, headers),
    enabled: !!headers?.encryptedHeader && !!orgIdState,
    refetchOnWindowFocus: false,
    cacheTime: 10000, // Small cache time to resolve issue of pool page being unreachable if changing organiazationID from backend.
  });

  useEffect(() => {
    if (data?.data?.stages && data?.data?.stages?.length > 0) {
      setSelectedStage(data?.data?.stages?.find(stage => stage.isActive === true) || data?.data?.stages?.[0]);
    }
  }, [data?.data]);

  useEffect(() => {
    setActiveSection("Players");
  }, [selectedStage]);

  const {
    data: { data: stageInfo } = {},
    isLoading: isLoadingStageInfo,
    isError: isLoadingStageError,
  } = useQuery({
    queryKey: ["stageInfo", selectedStage],
    queryFn: () => getStageInfo({ poolId, orgId: orgIdState, stageId: selectedStage?.stageId }, headers),
    refetchOnWindowFocus: false,
    enabled: !!(selectedStage && isNonEmptyObject(selectedStage)), // need to use !! to convert falsy values to boolean. The query was running when enabled was set to undefined
  });

  // This if will be triggered if the API responds with an error status. We don't need to notifyError here since the QueeryWrapper will call the notifyError function
  if (loadingPoolDataError) {
    history.push("/");
  }

  // This if will be triggered if the API sends a success response,but with am `error` property as true
  if (data?.error) {
    notifyError(data?.message);
    history.push("/");
  }

  const { mutate: mutatePoolDetails, isLoading: mutatePoolLoading } = useMutation({
    mutationFn: ({ action, data }) => {
      return executePoolAction(
        {
          organizationId: orgIdState,
          poolId: poolId,
          action,
          data,
        },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.message || e?.data);
      if (variables.errorCb) {
        variables.errorCb();
      }
    },
    onSuccess: (responseData, variables) => {
      queryClient.setQueryData(["pool", poolId], prevData => {
        return {
          ...prevData,
          data: {
            ...prevData?.data,
            ...responseData?.data,
          },
        };
      });
      if (variables.successCb) variables.successCb(responseData);
    },
  });

  const { mutate: mutateTournamentApproval, isLoading: mutateTournamentApprovalLoading } = useMutation({
    mutationFn: variables => {
      return approveTournament(
        {
          decision: variables.decision,
          reason: variables.reason,
          organizationId: orgIdState,
          poolId: poolId,
        },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.message || e?.data);
      if (variables.errorCb) {
        variables.errorCb();
      }
    },
    onSuccess: (e, variables) => {
      queryClient.invalidateQueries(["pool", poolId]);
      variables.decision === "approve"
        ? notifySuccess(e?.message || e?.data)
        : notifyInfo(variables.decision === "deny" ? "Changes Requested" : e?.message || e?.data);
    },
  });

  const { mutate: mutatePoolReactivation, isLoading: mutateTournamentReactivationLoading } = useMutation({
    mutationFn: variables => {
      return reactivatePool(
        {
          organizationId: orgIdState,
          poolId: poolId,
          endDate: variables.endDate,
          successCb: variables.successCb,
          errorCb: variables.errorCb,
        },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.message || e?.data);
      if (variables.errorCb) {
        variables.errorCb();
      }
    },
    onSuccess: (e, variables) => {
      notifySuccess(e?.message || e?.data);
      queryClient.setQueryData(["pool", poolId], prevData => {
        return {
          ...prevData,
          data: {
            ...prevData?.data,
            ...e?.data,
          },
        };
      });
      if (variables.successCb) {
        variables.successCb();
      }
    },
  });

  const { data: poolData = {} } = data || {};
  // const stageIndex = poolData?.stages?.findIndex(stage => stage.stageId === selectedStage?.stageId);
  // const stageData = poolData?.stages?.[stageIndex && stageIndex !== -1 ? stageIndex : 0];

  return (
    <TournamentContext.Provider
      value={{
        poolData,
        showReportModal,
        setShowReportModal,
        extraReportData,
        setExtraReportData,
        stageId: selectedStage?.stageId,
        stage: selectedStage,
        selectedGroupId,
        setSelectedGroupId,
        stageInfo: stageInfo,
      }}
    >
      <S.MainContainer>
        <S.BackArrow onClick={goBack}>
          <BackArrow />
        </S.BackArrow>
        <S.InnerContainer>
          <S.TableContainer>
            {isLoadingPoolData ? (
              <LoadingSkeleton height="88px" />
            ) : (
              <TournamentDetailHeader
                data={poolData}
                mutatePoolDetails={mutatePoolDetails}
                mutatePoolLoading={mutatePoolLoading}
                mutateTournamentApproval={mutateTournamentApproval}
                mutateTournamentApprovalLoading={mutateTournamentApprovalLoading}
                mutatePoolReactivation={mutatePoolReactivation}
                mutatePoolReactivationLoading={mutateTournamentReactivationLoading}
                setPlayerListDownloadLink={setPlayerListDownloadLink}
                setIsPlayerListDownloadModalOpen={setIsPlayerListDownloadModalOpen}
                selectedStage={selectedStage}
                setSelectedStage={setSelectedStage}
                userType={session?.user?.userType || "USER"}
              />
            )}
          </S.TableContainer>

          <TagButtons {...{ activeSection, setActiveSection, poolData, selectedStage }} />
          <Sections
            {...{ poolData, activeSection, isFetchingPoolData, selectedStage, stageInfo, isLoadingStageInfo }}
          />
        </S.InnerContainer>
        <DownloadPlayerListModal
          showModal={isPlayerListDownloadModalOpen}
          downloadLink={playerListDownloadLink}
          setDownloadLink={setPlayerListDownloadLink}
          toggleModal={() => setIsPlayerListDownloadModalOpen(!isPlayerListDownloadModalOpen)}
        >
          Download
        </DownloadPlayerListModal>
        <MatchReportModal showModal={showReportModal} setShowModal={setShowReportModal} />
      </S.MainContainer>
    </TournamentContext.Provider>
  );
};
