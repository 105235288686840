import React, { useState } from "react";
import * as S from "./style";
import { Popover } from "antd";
// import { Notification } from "../../../assets/svg";
import Avatar from "../../common/Avatar";
import Flex from "../../common/Flex";
import { Button } from "../../common/Button";
import { useWallet } from "../../../providers/WalletProvider";
import { useSession } from "../../../providers/SessionProvider";
import { useLogin } from "../../../providers/Loginprovider";
import { Add, LogoutIcon, OrgDefaultIcon } from "../../../assets/svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LoadingSkeleton from "../../common/LoadingSkeleton";
import { getOrganizationsFromSession } from "../../../utilities";
import organizationDefaultAvatar from "../../../assets/svg/org-icon.svg";
export const Header = ({ collapseSidebar, setShowAuthModal, isFetching, logout }) => {
  const { loadWeb3Modal } = useWallet();
  const { session } = useSession();
  const { orgIdState, setOrgIdState, orgDataState, setOrgDataState } = useLogin();
  const [openMenu, setOpenMenu] = useState(false);

  const hideMenu = () => {
    setOpenMenu(false);
  };

  const handleOpenChange = newOpen => {
    setOpenMenu(newOpen);
  };

  const items = [
    {
      key: "1",
      label: <div onClick={logout}>Logout</div>,
    },
  ];

  const userOrganizations = getOrganizationsFromSession(session);

  return (
    <S.HeaderWrapper collapseSidebar={collapseSidebar}>
      <S.PageTitle />
      <S.ContentWrapper>
        {isFetching ? (
          <S.SkeletonWrapper>
            <LoadingSkeleton height="25px" width="120px" />
            <LoadingSkeleton height="33px" width="33px" circle />
          </S.SkeletonWrapper>
        ) : session?.user ? (
          <S.UserInformationWrapper>
            <Popover
              open={openMenu}
              onOpenChange={handleOpenChange}
              trigger="click"
              content={
                <div>
                  {userOrganizations.length > 0 ? (
                    <>
                      {userOrganizations.map(org => (
                        <S.MenuItem
                          onClick={() => {
                            setOrgIdState(org?.organizationId);
                            setOrgDataState(org);
                            hideMenu();
                          }}
                        >
                          <div>
                            <Avatar src={org?.thumbnail || organizationDefaultAvatar} isOrganization />
                            <p>{org?.organizationName}</p>
                          </div>
                          {org?.organizationId === orgIdState ? <div>Active</div> : null}
                        </S.MenuItem>
                      ))}

                      <hr style={{ borderColor: "#3F2FAA", margin: 0 }} />
                    </>
                  ) : null}
                  {/* <S.DisconnectMenuItem>
                    <Add />
                    <div>Add Account</div>
                  </S.DisconnectMenuItem> */}
                  <S.DisconnectMenuItem onClick={logout}>
                    <LogoutIcon />
                    <div>Disconnect</div>
                  </S.DisconnectMenuItem>
                </div>
              }
              overlayInnerStyle={{ padding: "4px 8px", background: "#362890", border: "1px solid #4e3dca" }}
              arrow={false}
              placement="bottomRight"
            >
              <S.UserDataWrapper>
                <S.NicknameAndOrganizationWrapper>
                  <S.Nickname>{session.user?.nick}</S.Nickname>
                  <S.Organization>{orgDataState?.organizationName}</S.Organization>
                </S.NicknameAndOrganizationWrapper>
                <S.AvatarWrapper>
                  <Avatar src={session.user?.thumbnail} />
                </S.AvatarWrapper>
              </S.UserDataWrapper>
            </Popover>
          </S.UserInformationWrapper>
        ) : (
          <Button
            borderRadius={"30px"}
            height={"35px"}
            width={"110px"}
            onClick={() => {
              setShowAuthModal(true);
            }}
          >
            Sign In
          </Button>
        )}
      </S.ContentWrapper>
    </S.HeaderWrapper>
  );
};
