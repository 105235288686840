import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { FormDataSchema } from "./schema";
import FirstStep from "./Steps/FirstStep";
import SecondStep from "./Steps/SecondStep";
import ThirdStep from "./Steps/ThirdStep";
import { isNonEmptyObject } from "../../../../utilities";
import { notifyWarning } from "../../../common/ToastComponent";
import FourthStep from "./Steps/FourthStep";
import Preview from "../preview";
import { POOL_MODES } from "../../../../constants";

const Form = ({
  processSubmission,
  previewData,
  setPreviewData,
  currentStep,
  setCurrentStep,
  nextBtnRef,
  prevBtnRef,
  submitBtnRef,
  steps = [],
  existingPoolData, // Data of pool if editing pool data
}) => {
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(FormDataSchema),
  });

  watch(data => setPreviewData(data));
  const currentInputValues = getValues();
  // watch(data => console.log("Watched Data: ", data));
  // console.log("Form Data Errors", errors);
  // console.log("Form Data Input State", previewData);
  function prev() {
    clearErrors();
    if (currentStep === 0) return;
    setCurrentStep(currentStep - 1);
  }
  async function next() {
    if (currentStep >= steps.length - 1) {
      return;
    }

    const fields = steps[currentStep].fields;
    const output = await trigger(fields, { shouldFocus: true });
    let customValidationErrors = false;

    if (currentStep === 0) {
      if (getValues("joinMode") === "team" && !getValues("teamPlayers")) {
        customValidationErrors = true;
        setError("teamPlayers", { type: "custom", message: "At least 1 team player is required" });
      }
    }
    if (currentStep === 2) {
      let percentageListArray = getValues("percentageList");
      if (!percentageListArray || !Array.isArray(percentageListArray)) {
        customValidationErrors = true;
        setError("percentageList", { type: "custom", message: "Need winner percentage list" });
      } else {
        let total = percentageListArray?.reduce((acc, curr) => {
          let current = Number(curr) || 0;
          return acc + current;
        }, 0);
        if (total !== 100) {
          customValidationErrors = true;
          setError("percentageTotal", { type: "custom", message: "Winner total must be equal to 100" });
        } else {
          clearErrors("percentageTotal");
        }
      }
    }

    if (currentStep === 2) {
      if (currentInputValues.titleInputTemp || currentInputValues.descInputTemp) {
        setValue("rules", [
          ...(currentInputValues?.rules ? currentInputValues.rules : []),
          { title: currentInputValues.titleInputTemp || "", content: currentInputValues.descInputTemp || "" },
        ]);

        setValue("titleInputTemp", "");
        setValue("descInputTemp", "");
      }
    }
    if (!output || customValidationErrors || isNonEmptyObject(errors)) return;
    setCurrentStep(currentStep + 1);
  }

  const stepProps = {
    register,
    errors,
    currentInputValues,
    previewData,
    setValue,
    setError,
    clearErrors,
    existingPoolData,
    watch
  };

  useEffect(() => {
    if (existingPoolData) {
      populateFieldsWithExistingData(existingPoolData);
    }
  }, [existingPoolData]);

  function populateFieldsWithExistingData(data = {}) {
    // Setting fields with data
    // Object.entries(existingPoolData).forEach(([name, value]) => setValue(name, value));

    // Some fields in the form schema does not have the same preoperty name as the data received from the BE
    // So we're setting the data individually here 
    setValue("organizationId", data.organizationId);
    setValue("subtitle", data.description);
    setValue("title", data.name);
    setValue("gameId", data.game);
    setValue("prizePool", data.poolPrize);
    setValue("entry", data.entrance);
    setValue("joinMode", data.joinMode?.toLowerCase());
    if (data.joinMode === "TEAM") {
      setValue("teamPlayers", data.gameDetails?.teamUsersQty)
    }
    if(data.poolMode ===  POOL_MODES.LEADERBOARD) {
      setValue("poolFormat", "LEADERBOARD"); 
    } else if(data.poolMode === POOL_MODES.ROUND_ROBIN) {
      setValue("poolFormat", "ROUND_ROBIN"); 
    } else {
      setValue("poolFormat", "SINGLE_ELIMINATION"); 
    }
    setValue("poolStageType", data.poolStageType);
    setValue("prizeToken", data.tokenSymbol); 
    setValue("maxPlayers", data.maxPlayers);


    setValue("tournamentStartDate", data.startDate);
    setValue("tournamentEndDate", data.endDate);
    setValue("registrationStartDate", data.registrationStartDate);
    setValue("registrationEndDate", data.registrationEndDate);

    setValue("winnerCount", data.winnersFees?.length)
    setValue("percentageList", data.winnersFees)
    setValue("rules", data.rules)

    // Not populating image fields as suggested by Dani

    // setValue("thumbnail", data.thumbnail)
    // setValue("bannerBgBack", data.detailHeaderBackImage)
    // setValue("bannerBgFront", data.detailHeaderFrontImage)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(processSubmission)}>
        {/* First Step */}
        {currentStep === 0 && <FirstStep {...stepProps} />}
        {currentStep === 1 && <SecondStep {...stepProps} />}
        {currentStep === 2 && <ThirdStep {...stepProps} />}
        {currentStep === 3 && <FourthStep {...stepProps} />}

        {currentStep === 4 && <Preview previewData={previewData} />}

        {/* The following buttons are hidden in the UI. They will be clicked by buttons on the outside oif the form (in the NewTournament file) using refs */}
        <button className="hidden" type="button" onClick={prev} ref={prevBtnRef}>
          Back
        </button>
        {currentStep < steps.length - 1 && (
          <button className="hidden" type="button" onClick={next} ref={nextBtnRef}>
            Next
          </button>
        )}
        {currentStep === steps.length - 1 && (
          <button className="hidden" type="submit" ref={submitBtnRef}>
            Submit
          </button>
        )}
      </form>
    </div>
  );
};

export default Form;
