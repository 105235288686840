import { POOL_TYPES } from "../constants";
import axiosClient from "./axiosClient";

export function swapBracketPlayers(data, headers) {
  if (!data?.organizationId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .put(
      `/admPanel/pool/swapPlayers/${data?.organizationId}/${data?.poolId}`,
      {
        poolId: data?.poolId,
        stageId: data?.stageId,
        playersToSwap: [{ ...data?.playerToSwap }, { ...data?.swapWith }],
      },
      {
        headers: { Authorization: headers.encryptedHeader },
      },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}
