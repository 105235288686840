import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import * as S from "./styles";
import { getTeams } from "../../api/teams";
import Text from "../../components/common/Text/Text";
import TeamRow from "../../components/views/players/teams/TeamRow";
import Container from "../../components/common/Container/Container";
import SearchBar from "../../components/views/players/shared/SearchBar";
import TeamDetailModal from "../../components/views/players/teams/TeamDetailModal";
import LoadingSkeleton from "../../components/common/LoadingSkeleton";
import { useLogin } from "../../providers/Loginprovider";
import { useGetSecHeaders } from "../../hooks/useHeaders";

const Teams = () => {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const { orgIdState } = useLogin();
  const headers = useGetSecHeaders();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const {
    data: teams,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["teams", searchQuery],
    queryFn: () => getTeams({ organizationId: orgIdState, searchQuery }, headers),
    refetchOnWindowFocus: false,
    enabled: !!headers?.encryptedHeader && !!orgIdState && !!searchQuery,
  });

  function handleClick(id) {
    setSelectedTeamId(id);
    setShowModal(true);
  }

  const teamsNotFound = teams && teams.length < 1;

  return (
    <>
      <S.MainContainer>
        <Container flex columnGap="17px" alignItems="center">
          <Text fontSize="38px">Teams</Text>
          <SearchBar setSearchQuery={setSearchQuery} placeholder="Insert name or team ID to search" />
        </Container>

        {searchQuery ? (
          <S.TableContainer>
            <S.TableHeader>
              <Text fontSize="13px">Nickname</Text>
              <Text fontSize="13px">ID</Text>
              <Text fontSize="13px">Visibility</Text>
            </S.TableHeader>
            <Container flex flexDirection="column" rowGap="12px">
              {isLoading ? (
                <>
                  <LoadingSkeleton height="50px" />
                  <LoadingSkeleton height="50px" />
                  <LoadingSkeleton height="50px" />
                </>
              ) : teamsNotFound ? (
                <Container flex justifyContent="center" alignItems="center" height="200px">
                  Teams Not Found
                </Container>
              ) : (
                teams?.map(team => (
                  <S.TeamRowWrapper onClick={() => handleClick(team.id)}>
                    <TeamRow data={team} key={team.id} />
                  </S.TeamRowWrapper>
                ))
              )}
            </Container>
          </S.TableContainer>
        ) : (
          <S.TableContainer>
            <Container flex justifyContent="center" alignItems="center">
              <div style={{ height: "100%" }}>
                <text>Please insert a name or the team ID in the search to look for teams</text>
              </div>
            </Container>
          </S.TableContainer>
        )}
      </S.MainContainer>

      <TeamDetailModal showModal={showModal} toggleModal={toggleModal} teamId={selectedTeamId} />
    </>
  );
};

export default Teams;
