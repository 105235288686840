import styled from "styled-components";
import Text from "../../../../common/Text/Text";

export const Container = styled.div`
  padding: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr) 0.1fr;
  border-radius: 10px;
  background: #3f2faa;
`;

export const CustomText = styled(Text)`
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

export const StatusContainer = styled.div`
  display: flex;
  width: 54px;
  height: 23px;
  padding: 7px 10px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #4e3cca;
`;

export const StatusText = styled(Text)`
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;