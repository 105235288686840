import React from "react";
import toast from "react-hot-toast";

import * as S from "./styles";
import { CloseBlue, CloseIcon, Error, Info, Success, Warning } from "../../../assets/svg";

const toastStyles = {
  default: {
    background: "#362890",
    borderRadius: "6px",
    color: "#fff",
    display: "flex",
    alignItems: "flex-start",
  },

  success: {
    background: "#B2FA4f",
    borderRadius: "6px",
    color: "#201366",
    display: "flex",
    alignItems: "flex-start",
  },
  info: {
    background: "#BE7EFF",
    borderRadius: "6px",
    color: "#fff",
    display: "flex",
    alignItems: "flex-start",
  },
  warning: {
    background: "#F28E49",
    borderRadius: "6px",
    color: "#fff",
    display: "flex",
    alignItems: "flex-start",
  },
  error: {
    background: "#ED6140",
    borderRadius: "6px",
    color: "#fff",
    display: "flex",
    alignItems: "flex-start",
  },
};

const toastIcons = {
  success: <Success />,
  info: <Info />,
  warning: <Warning />,
  error: <Error />,
};

// Inorder to display a custom notification, call the customNotification function like the following example:

// customNotification({
//   message: "This is the mesage",
//   desc: "Desc this is a ong description using for testing",
//   customIcon : <BurgerIcon/ >,
// })

export function customNotification({ message, desc, type, customIcon }) {
  const customStyle = toastStyles[type] || toastStyles["default"];
  const defaultStyles = {
    padding: "15px 20px",
    gap: "14px",
    justifyContent: "flex-start",
  };

  const CloseBtn = type === "success" ? CloseBlue : CloseIcon;

  toast(
    t => (
      <div style={{ ...customStyle, ...defaultStyles }}>
        <S.IconContainer>{customIcon ? customIcon : toastIcons[type]}</S.IconContainer>
        <S.TextContainer>
          <S.ErrorTitle>{message}</S.ErrorTitle>
          {desc ? <S.ErrorDescription>{desc}</S.ErrorDescription> : null}
        </S.TextContainer>
        <CloseBtn style={{ height: "12px", width: "12px", cursor: "pointer" }} onClick={() => toast.dismiss(t.id)} />
      </div>
    ),
    {
      position: "top-right",
      duration: 5000,
    },
  );
}

export function notifySuccess(message, desc) {
  customNotification({
    message: message || "Success!",
    desc,
    type: "success",
  });
}
export function notifyInfo(message, desc) {
  customNotification({
    message: message || "Info:",
    desc,
    type: "info",
  });
}
export function notifyWarning(message, desc) {
  customNotification({
    message: message || "Warning!",
    desc,
    type: "warning",
  });
}
export function notifyError(message, desc) {
  customNotification({
    message: message && typeof message === "string" ? message : "Something went wrong",
    desc,
    type: "error",
  });
}
