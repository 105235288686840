import React, { useRef } from "react";

import * as S from "../../styles";
import Container from "../../../../common/Container/Container";
import ErrorTooltip from "../../../../common/ErrorTooltip";
import ImagePicker from "../ImagePicker";
import Flex from "../../../../common/Flex";
import ImageTag from "../../../../common/ImageTag";

const ThirdStep = ({
  register,
  errors = {},
  setValue,
  currentInputValues = {},
  clearErrors,
  watch,
  assetTypeBeingUploaded,
  assetUploadMutation,
  isAssetUploadLoading,
  getValues,
}) => {
  const backBannerRef = useRef(null);
  const frontBannerRef = useRef(null);
  const thumbnailRef = useRef(null);
  const tournamentCardGameLogoRef = useRef(null);

  const backBannerError = errors?.backBanner?.message;
  const frontBannerError = errors?.frontBanner?.message;
  const thumbnailError = errors?.thumbnail?.message;
  const tournamentCardGameLogoError = errors?.tournamentCardGameLogo?.message;

  if (!register || !setValue) return <div />;
  return (
    <Container flex flexDirection="column" rowGap="27px">
      {/* Back Banner */}
      <S.RowContainer>
        <Flex columnGap="12px">
          <label>Back banner</label>
          {backBannerError && <ErrorTooltip message={backBannerError} isRelative />}
        </Flex>
        <S.ImageContainer>
          <div>
            <input
              type="file"
              className="hidden"
              ref={backBannerRef}
              onChange={e => {
                if (e.target?.files[0]) {
                  clearErrors("backBanner");
                  assetUploadMutation({ file: e.target.files[0], assetType: "backBanner" });
                }
              }}
              disabled={isAssetUploadLoading && assetTypeBeingUploaded === "backBanner"}
            />
            <ImagePicker
              handleClick={() => backBannerRef?.current?.click()}
              loading={isAssetUploadLoading && assetTypeBeingUploaded === "backBanner"}
            />
          </div>
          <S.ImageBlock isError={!!backBannerError}>
            <ImageTag src={getValues("backBanner")} width="199px" height="58px" />
          </S.ImageBlock>
        </S.ImageContainer>
      </S.RowContainer>

      {/* Front Banner */}
      <S.RowContainer>
        <Flex columnGap="12px">
          <label>Front banner</label>
          {frontBannerError && <ErrorTooltip message={frontBannerError} isRelative />}
        </Flex>
        <S.ImageContainer>
          <div>
            <input
              type="file"
              className="hidden"
              ref={frontBannerRef}
              onChange={e => {
                if (e.target?.files[0]) {
                  clearErrors("frontBanner");
                  assetUploadMutation({ file: e.target.files[0], assetType: "frontBanner" });
                }
              }}
              disabled={isAssetUploadLoading && assetTypeBeingUploaded === "frontBanner"}
            />
            <ImagePicker
              handleClick={() => frontBannerRef?.current?.click()}
              loading={isAssetUploadLoading && assetTypeBeingUploaded === "frontBanner"}
            />
          </div>
          <S.ImageBlock isError={!!frontBannerError}>
            <ImageTag src={getValues("frontBanner")} width="163px" height="95px" />
          </S.ImageBlock>
        </S.ImageContainer>
      </S.RowContainer>

      {/* Thumbnail */}
      <S.RowContainer>
        <Flex columnGap="12px">
          <label>Thumbnail</label>
          {thumbnailError && <ErrorTooltip message={thumbnailError} isRelative />}
        </Flex>
        <S.ImageContainer>
          <div>
            <input
              type="file"
              className="hidden"
              ref={thumbnailRef}
              onChange={e => {
                if (e.target?.files[0]) {
                  clearErrors("thumbnail");
                  assetUploadMutation({ file: e.target.files[0], assetType: "thumbnail" });
                }
              }}
              disabled={isAssetUploadLoading && assetTypeBeingUploaded === "thumbnail"}
            />
            <ImagePicker
              handleClick={() => thumbnailRef?.current?.click()}
              loading={isAssetUploadLoading && assetTypeBeingUploaded === "thumbnail"}
            />
          </div>
          <S.ImageBlock isError={!!thumbnailError}>
            <ImageTag src={getValues("thumbnail")} width="163px" height="95px" />
          </S.ImageBlock>
        </S.ImageContainer>
      </S.RowContainer>

      {/* Game Logo */}
      <S.RowContainer>
        <Flex columnGap="12px">
          <label>Game Logo</label>
          {tournamentCardGameLogoError && <ErrorTooltip message={tournamentCardGameLogoError} isRelative />}
        </Flex>
        <S.ImageContainer>
          <div>
            <input
              type="file"
              className="hidden"
              ref={tournamentCardGameLogoRef}
              onChange={e => {
                if (e.target?.files[0]) {
                  clearErrors("tournamentCardGameLogo");
                  assetUploadMutation({ file: e.target.files[0], assetType: "tournamentCardGameLogo" });
                }
              }}
              disabled={isAssetUploadLoading && assetTypeBeingUploaded === "tournamentCardGameLogo"}
            />
            <ImagePicker
              handleClick={() => tournamentCardGameLogoRef?.current?.click()}
              loading={isAssetUploadLoading && assetTypeBeingUploaded === "tournamentCardGameLogo"}
            />
          </div>
          <S.ImageBlock isError={!!tournamentCardGameLogoError}>
            <ImageTag src={getValues("tournamentCardGameLogo")} width="55px" height="55px" />
          </S.ImageBlock>
        </S.ImageContainer>
      </S.RowContainer>
    </Container>
  );
};

export default ThirdStep;
