import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

// export const Banner = styled.div`
//   height: 270px;
//   width: 100%;
//   /* width: 80%; */
//   margin: auto;
//   padding: 0 38px;
//   margin-top: 51px;
//   /* background-size: contain; */
//   background-repeat: no-repeat;
//   background-image: ${props => `url(${props.bg})`};
// `;

export const Banner = styled.img`
  max-width: 100%;
  margin-top: 51px;
  max-height: 134px;
`;

export const FrontBanner = styled.img`
  right: 0;
  bottom: 0;
  position: absolute;
  height: 90%;
`;

export const DetailsImg = styled.div`
  position: relative;
  height: 100%;
`;

export const DetailsContainer = styled.div`
  span {
    position: absolute;
    font-size: 14px;
    left: 16px;
    bottom: 100px;
    font-weight: lighter;
  }
  img {
    position: absolute;
    left: 16px;
    bottom: 14px;
  }

  @media (max-width: 1400px) {
    span {
      left: 16px;
      bottom: 88px;
    }
    img {
      scale: 0.8;
      left: -6px;
    }
  }

  @media (max-width: 1250px) {
    span {
      left: 16px;
      bottom: 75px;
    }
    img {
      scale: 0.8;
      left: -6px;
      bottom: 0
    }
  }

  @media (max-width: 1100px) {
    span, img {
      display: none;
    }
  }

  @media (min-width: 2200px) {
    span {
      bottom: 160px;
      left: 25px;
    }
    svg {
      scale: 1.6;
      left: 90px;
      bottom: 40px;
    }
  }
  @media (min-width: 2480px) {
    span {
      bottom: 200px;
      left: 30px;
    }
    svg {
      scale: 2;
      left: 140px;
      bottom: 70px;
    }
  }
`;

export const TournamentCard = styled.div`
  width: 210px;
  height: 265px;
  /* padding: 19px; */
  margin-top: 28px;
  border-radius: 8px;
  background-color: #3f2faa;
  position: relative;
  margin-bottom: 24px;
`;

export const TournamentCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

export const TournamentCardPicture = styled.div`
  width: 185.02px;
  height: 124.54px;
  border-radius: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.img})`};
`;

export const GameName = styled.div`
  font-size: 14px;
  margin-top: 9px;
  margin-left: 15px;
`;

export const GameDesc = styled.div`
  font-size: 10px;
  margin-top: 2px;
  font-weight: 325;
  margin-left: 15px;
  margin-bottom: 12px;
  color: rgba(164, 152, 237, 1);
`;

export const Pill = styled.div`
  display: flex;
  column-gap: 4px;
  height: 16.12px;
  min-width: 66.76px;
  align-items: center;
  border-radius: 4px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: rgba(78, 60, 202, 1);
`;

export const PillContainer = styled.div`
  margin-left: 15px;
  display: flex;
  row-gap: 4.6px;
  column-gap: 4.6px;
  width: 80%;
  flex-wrap: wrap;
`;

export const ProfileContainer = styled.div`
  left: 18px;
  bottom: 13px;
  position: absolute;
  display: flex;
  column-gap: 3px;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
  }
`;

export const SmallLogoContainer = styled.div`
  background: rgba(63, 47, 170, 0.5);
  border-radius: 6px;
  width: fit-content;
  height: 24px;
  display: flex;
  padding: 6px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
`;
