import styled from "styled-components";
import Input from "../../../../common/Input/Input";

export const SearchContainer = styled.div`
  width: 406px;
  height: 38px;
  position: relative;
`;

export const SearchInput = styled(Input)`
  border-radius: 100px;
  padding-left: 16px;
  padding-right: 48px;
`;
export const ButtonContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 6px;
`;

export const SearchButton = styled.button`
  border: 0;
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 28px;
  background-color: #6c47ff;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      stroke: #fff;
    }
  }
`;
