import styled from "styled-components";
import { breakpoints } from "../../../themes";

export const HeaderWrapper = styled.div`
  background-color: #201366;
  padding: 17px 0px 17px 45px;
  padding-right: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  position: fixed;
  z-index: 29;
  width: 100vw;

  ${props => `
    // top: ${props.visible ? "0" : "-200px"};
    // width: calc(100vw - ${props.collapseSidebar ? "198px" : "324px"});
  `}
  transition: all 0.2s linear;
  box-shadow: 0 0 30px #0c0433;

  ${breakpoints.down(breakpoints.tablet)} {
    padding: 17px 20px 17px 20px;
    width: 100vw;
    justify-content: space-between;
  }
`;

export const PageTitle = styled.span`
  font-size: 22px;
  line-height: 28px;
  color: white;
  display: none;
  ${breakpoints.down(breakpoints.tablet)} {
    display: block;
  }
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 23px;

  @media (max-width: 450px) {
    margin-right: 23px;
  }
`;

export const NotificationIconWrapper = styled(IconWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// User Data

export const UserInformationWrapper = styled.div`
  position: relative;
  display: flex;
  user-select: none;
  align-items: center;
  gap: 20px;
  color: ${props => props.theme.textMain};

  @media (max-width: 450px) {
    gap: 15px;
  }
  cursor: pointer;
`;

export const UserDataWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 450px) {
    gap: 15px;
  }
`;

export const NicknameAndOrganizationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Nickname = styled.span`
  color: white;
  font-size: 14px;
  font-weight: 350;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: right;

  @media (max-width: 451px) {
    display: none;
  }
`;

export const Organization = styled.span`
  color: #a498ed;
  font-size: 10px;
  font-weight: 325;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuItem = styled.div`
  height: 57px;
  width: 219px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 11px;
  margin-bottom: 7px;
  cursor: pointer;
  &:hover {
    background: #3f2faa;
  }
  div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  div:nth-child(2) {
    background: #6c47ff;
    color: #fff;
    text-align: center;
    font-size: 10px;
    font-weight: 350;
    padding: 3px 6px;
    border-radius: 18.5px;
  }
`;

export const DisconnectMenuItem = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  height: 40px;
  width: 219px;
  padding: 0 11px;
  margin-top: 9px;
  margin-bottom: 9px;
  &:hover {
    background: #3f2faa;
  }
`;

export const Separator = styled.div`
  border-color: #3f2faa;
  margin: 0;
`;
