import React, { useContext, useEffect, useState } from "react";
import * as S from "./styles";
import Flex from "../../../common/Flex";
import Avatar from "../../../common/Avatar";
import Modal from "../../../common/Modal/Modal";
import { BracketsDataContext } from "../../../../providers/BracketsDataProvider";
import { SwapAlt } from "../../../../assets/svg";
import { Button } from "../../../common/Button";
import Container from "../../../common/Container/Container";
import StageMovePlayerSelect from "./StageMovePlayerSelect";
import StageSelect from "./StageSelect";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notifyError, notifySuccess } from "../../../common/ToastComponent";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { movePlayersToOtherStages } from "../../../../api/stage";
import { useLogin } from "../../../../providers/Loginprovider";

const StageMoveModal = ({ stageId, poolData, playersToMoveStage = [], setPlayersToMoveStage }) => {
  const [stageToMovePlayersTo, setStageToMovePlayersTo] = useState(null);
  const [open, setOpen] = useState(false);

  const headers = useGetSecHeaders();
  const { orgIdState } = useLogin();

  const queryClient = useQueryClient();

  const toggleModal = () => setOpen(!open);

  const { mutate: movePlayersMutation, isLoading: mutateMoveLoading } = useMutation({
    mutationFn: args => {
      return movePlayersToOtherStages(
        {
          ...args,
          organizationId: orgIdState,
          poolId: poolData.id,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        // TODO: invalidating the fromStage players. Will need to invaldiate the toStage players as well
        // So if player A is sent from stage 1 to stage 2, the UI for both is updated.
        queryKey: ["players", orgIdState, poolData.id, stageId],
      });
      notifySuccess("Move successful");
      setPlayersToMoveStage([]);
      setStageToMovePlayersTo([]);
      setOpen(false);
    },
  });

  useEffect(() => {
    // Open the modal if user selects a player (or team) to move stages (in the players / teams section)
    // Only run this useEffect if modal is not opened. We do not need to run it if modal is already open, or close it if user unselects all players
    if (!open && playersToMoveStage.length) setOpen(true);
  }, [playersToMoveStage]);
  return (
    <Modal
      showModal={open}
      customStyles={{ width: "516px", height: "481px", borderRadius: "6px", overflowY: "visible", top: "40%" }}
      mobileStyles={{ width: "90%" }}
      toggleModal={toggleModal}
      beforeClose={() => {
        setPlayersToMoveStage([]);
        setStageToMovePlayersTo(null);
      }}
    >
      <Container flex flexDirection="column" justifyContent="space-between" height="85%">
        <div>
          <S.Header>Move Player</S.Header>

          <StageMovePlayerSelect {...{ stageId, poolData, playersToMoveStage, setPlayersToMoveStage }} />
          <StageSelect
            fromStage={stageId}
            stages={poolData.stages}
            stageToMovePlayersTo={stageToMovePlayersTo}
            setStageToMovePlayersTo={setStageToMovePlayersTo}
          />
        </div>

        <Container width="100%" flex justifyContent="center">
          {playersToMoveStage.length && stageToMovePlayersTo ? (
            <Button
              width="183px"
              height="42px"
              borderRadius="80px"
              onClick={() => {
                movePlayersMutation({
                  fromStage: stageId,
                  toStage: stageToMovePlayersTo,
                  players: playersToMoveStage,
                });
              }}
            >
              Move
            </Button>
          ) : null}
        </Container>
      </Container>
    </Modal>
  );
};

export default StageMoveModal;
