import React, { useState } from "react";
import { Popover } from "antd";

import * as S from "./styles";

import { DataRow } from "../../shared/styles";
import { Button, IconButton } from "../../../../common/Button";
import { Edit, ProfileIcon, Trash } from "../../../../../assets/svg";
import { truncateWithDots } from "../../../../../utilities";
import { notifyError, notifySuccess } from "../../../../common/ToastComponent";
import { deleteUserAccount, updateGameIdAccount } from "../../../../../api/users";
import { useGetSecHeaders } from "../../../../../hooks/useHeaders";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSession } from "../../../../../providers/SessionProvider";
import Avatar from "../../../../common/Avatar";
import Container from "../../../../common/Container/Container";
import EditAccountModal from "../../shared/EditAccountModal";
import Text from "../../../../common/Text/Text";

const TeamMemberAccountRow = ({ data = {}, teamId }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDelPopover, setOpenDelPopover] = useState(false);
  const { session } = useSession();
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();

  const hideDelPopover = () => {
    setOpenDelPopover(false);
  };

  const toggleEditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const handleOpenChange = newOpen => {
    setOpenDelPopover(newOpen);
  };

  const { mutate: deleteAccount, isLoading: isLoadingDeleteAccount } = useMutation({
    mutationFn: variables => {
      return deleteUserAccount(
        {
          accountId: variables?.accountId,
          userId: variables?.userId,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: responseData => {
      if (responseData?.error) {
        notifyError(responseData?.message);
        return;
      } else {
        notifySuccess("Account deleted successfully");
        queryClient.invalidateQueries({ queryKey: ["team", teamId] });
      }
    },
  });

  const { mutate: editGameId, isLoading: isLoadingEditGameId } = useMutation({
    mutationFn: variables => {
      return updateGameIdAccount(
        {
          accountId: variables?.accountId,
          username: variables?.username,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: responseData => {
      if (responseData?.error) {
        notifyError(responseData?.message);
        return;
      } else {
        queryClient.invalidateQueries({ queryKey: ["team", teamId] });
        notifySuccess("Account updated successfully");
        toggleEditModal();
      }
    },
  });

  return (
    <DataRow>
      <S.TeamMemberAccountGrid>
        <Container flex alignItems="center" columnGap="11px">
          <Avatar src={data.avatar} />
          <Text fontSize="13px">{truncateWithDots(data.blastUsername, 25)}</Text>
        </Container>
        <Text fontSize="13px">{truncateWithDots(data.visibleName, 25)}</Text>
        <Text fontSize="13px">{truncateWithDots(data.username, 25)}</Text>
        <Container flex>
          {session?.user?.userType === "SUPERUSER" && (
            <>
              <IconButton onClick={toggleEditModal}>
                <Edit />
              </IconButton>
              <EditAccountModal
                showModal={openEditModal}
                toggleModal={toggleEditModal}
                accountData={data}
                mutateEditGameId={editGameId}
                mutateEditGameIdLoading={isLoadingEditGameId}
              />
            </>
          )}
          <Popover
            content={
              <Container marginTop="22px">
                <Button
                  width="100%"
                  background="#3F2FAA"
                  height="50.885px"
                  borderRadius="30px"
                  onClick={() => {
                    hideDelPopover();
                    deleteAccount({ userId: data.userId, accountId: data.id });
                  }}
                >
                  Confirm
                </Button>
              </Container>
            }
            zIndex="9999"
            title="Are you sure you want to remove this gaming account from this user?"
            trigger="click"
            open={openDelPopover}
            onOpenChange={handleOpenChange}
            color="#7265C8"
            overlayInnerStyle={{
              color: "brown !important",
              width: "220px",
              padding: "19px",
              textAlign: "center",
            }}
            overlayStyle={{
              color: "green !important",
            }}
            overlayClassName="match-report-popover"
          >
            <IconButton onClick={() => setOpenDelPopover(true)} disabled={isLoadingDeleteAccount}>
              <Trash />
            </IconButton>
          </Popover>
        </Container>
      </S.TeamMemberAccountGrid>
    </DataRow>
  );
};

export default TeamMemberAccountRow;
