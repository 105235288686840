import { useEffect, useState } from "react";
import * as crypto from "crypto-js";
import { SEED } from "../constants/env";
import { useSession } from "../providers/SessionProvider";

export const useGetSecHeaders = () => {
  const { session } = useSession();
  const [headers, setHeaders] = useState({ token: null, address: null, ip: null, userId: null });
  useEffect(() => {
    if (session) {
      const secHeader = {
        token: session.access_token,
        address: session.user.address,
        ip: session.ip,
        userId: session.user ? session.user.id : "",
      };
      setHeaders({
        token: session.access_token,
        address: session.user.address,
        ip: session.ip,
        userId: session.user ? session.user.id : "",
        encryptedHeader: crypto.AES.encrypt(JSON.stringify(secHeader), SEED).toString(),
      });
    }
  }, [session, session?.user]);
  return headers;
};
