import { z } from "zod";

export const ContactFormSchema = z.object({
  nickname: z.string().min(1, "Nickname is required"),
  role: z.string().min(1, "Role is required"),
  discordLink: z.string().optional(),
  whatsapp: z.string().optional(),
  twitchUsername: z.string().optional(),
  extraInfo: z.string().optional(),
});
