import styled from "styled-components";
import { Button } from "../../../common/Button";

export const ModalContainer = styled.div`
  width: 501px;
  height: 256px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ModalTitle = styled.h2`
  font-size: 28px;
  font-weight: 325;
  line-height: 33.6px;
  text-align: left;
`;

export const ModalDescription = styled.p`
  margin-top: 25px;
  width: 349px;
  font-size: 16px;
  font-weight: 325;
  line-height: 19.2px;
  text-align: left;
  color: #a498ed;
`;

export const DangerButton = styled(Button)`
  border-radius: 30px;
  background: #ed6140;
  height: 42px;
  width: 122px;
`;
