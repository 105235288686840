import React, { useEffect, useState } from "react";
import * as S from "./styles";
import PlayerSelect from "./PlayerSelect";
import Flex from "../../../common/Flex";
import Avatar from "../../../common/Avatar";
import Modal from "../../../common/Modal/Modal";
import { SwapAlt } from "../../../../assets/svg";
import { Button } from "../../../common/Button";
import Container from "../../../common/Container/Container";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { useLogin } from "../../../../providers/Loginprovider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notifyError, notifySuccess } from "../../../common/ToastComponent";
import { swapGroupPlayers } from "../../../../api/group";

const GroupSwapModal = ({ playerToSwapGroup, setPlayerToSwapGroup, poolData, stageId }) => {
  const [open, setOpen] = useState(false);
  const [swapWith, setSwapWith] = useState(null);

  const headers = useGetSecHeaders();
  const { orgIdState } = useLogin();

  const toggleSwapModle = () => {
    setOpen(!open);
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!open && playerToSwapGroup) setOpen(true);
  }, [playerToSwapGroup]);

  const { mutate: swapGroupPlayersMutation, isLoading: swapIsLoading } = useMutation({
    mutationFn: ({ poolId, stageId, fromGroupId, fromPlayerId, toGroupId, toPlayerId }) => {
      return swapGroupPlayers(
        {
          organizationId: orgIdState,
          poolId: Number(poolId),
          stageId,
          fromGroupId,
          fromPlayerId,
          toGroupId,
          toPlayerId,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: (e, variables) => {
      notifySuccess("Swap successful");
      setPlayerToSwapGroup(null);
      setSwapWith(null);
      setOpen(false);
      queryClient.invalidateQueries({
        queryKey: ["stage_leaderboard", orgIdState, Number(poolData?.id), stageId, variables?.fromGroupId],
      });
    },
  });
  return (
    <Modal
      showModal={open}
      customStyles={{ width: "516px", height: "481px", borderRadius: "6px", overflowY: "visible", top: "40%" }}
      mobileStyles={{ width: "90%" }}
      toggleModal={toggleSwapModle}
      beforeClose={() => {
        setPlayerToSwapGroup(null);
        setSwapWith(null);
      }}
    >
      <S.Header>Swap Group</S.Header>
      {playerToSwapGroup && (
        <S.RelativeContainer>
          <S.Row highlight>
            <Flex alignItems="center" columnGap="14px">
              <Avatar src={playerToSwapGroup?.avatarURL} alt={`${playerToSwapGroup?.nick} avatar`} />
              <div>{playerToSwapGroup.nick}</div>
            </Flex>
          </S.Row>

          {!!swapWith && (
            <S.SwapIconContainer>
              <SwapAlt />
            </S.SwapIconContainer>
          )}
          {/* Select  Dropdown */}
          <PlayerSelect
            selectedPlayerToSwapWith={swapWith}
            onChange={v => setSwapWith(v)}
            fromGroupId={playerToSwapGroup?.groupId}
          />

          {!!swapWith && (
            <Container marginTop="117px">
              <Flex justifyContent="center">
                <Button
                  width="183px"
                  height="45px"
                  borderRadius="30px"
                  padding="12px 20px"
                  onClick={() => {
                    swapGroupPlayersMutation({
                      poolId: poolData?.id,
                      stageId: stageId,
                      fromGroupId: playerToSwapGroup?.groupId,
                      fromPlayerId: playerToSwapGroup?.aTag || playerToSwapGroup?.playerId,
                      toGroupId: swapWith?.groupId,
                      toPlayerId: swapWith?.aTag || playerToSwapGroup?.playerId,
                    });
                  }}
                  disabled={swapIsLoading}
                >
                  Swap
                </Button>
              </Flex>
            </Container>
          )}
        </S.RelativeContainer>
      )}
    </Modal>
  );
};

export default GroupSwapModal;
