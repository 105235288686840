import React from "react";
import * as S from "../styles";

const PoolBanner = ({ bgBackUrl = "", active = false, handleClick }) => {
  return (
    <S.Banner background={bgBackUrl} active={active} onClick={() => handleClick(bgBackUrl)} />
  );
};

export default PoolBanner;
