import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import * as S from "./styles";
import { getUsers } from "../../api/users";
import Text from "../../components/common/Text/Text";
import UserRow from "../../components/views/players/users/UserRow";
import Container from "../../components/common/Container/Container";
import SearchBar from "../../components/views/players/shared/SearchBar";
import UserDetailModal from "../../components/views/players/users/UserDetailModal";
import LoadingSkeleton from "../../components/common/LoadingSkeleton";
import { useLogin } from "../../providers/Loginprovider";
import { useGetSecHeaders } from "../../hooks/useHeaders";


const Users = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const { orgIdState } = useLogin();
  const [searchQuery, setSearchQuery] = useState("");
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const headers = useGetSecHeaders();

  const {
    data: users,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["users", searchQuery],
    queryFn: () => getUsers({ organizationId: orgIdState, searchQuery, limit: 30 }, headers),
    refetchOnWindowFocus: false,
    enabled: !!headers?.encryptedHeader && !!orgIdState,
  });

  function handleClick(id) {
    setSelectedUserId(id);
    setShowModal(true);
  }

  const usersNotFound = users && users.length < 1;
  return (
    <>
      <S.MainContainer>
        <Container flex columnGap="17px" alignItems="center">
          <Text fontSize="38px">Users</Text>
          <SearchBar setSearchQuery={setSearchQuery} />
        </Container>

        <S.TableContainer>
          <S.TableHeader>
            <Text fontSize="13px">Nickname</Text>
            <Text fontSize="13px">ID</Text>
            <Text fontSize="13px">Created At</Text>
          </S.TableHeader>
          <Container flex flexDirection="column" rowGap="12px">
            {isLoading ? (
              <>
                <LoadingSkeleton height="50px" />
                <LoadingSkeleton height="50px" />
                <LoadingSkeleton height="50px" />
              </>
            ) : usersNotFound ? (
              <Container flex justifyContent="center" alignItems="center" height="200px">
                User Not Found
              </Container>
            ) : (
              users?.map(user => (
                <S.UserRowWrapper onClick={() => handleClick(user.id)}>
                  <UserRow data={user} key={user.id} />
                </S.UserRowWrapper>
              ))
            )}
          </Container>
        </S.TableContainer>
      </S.MainContainer>

      <UserDetailModal
        showModal={showModal}
        toggleModal={toggleModal}
        userId={selectedUserId}
        setUserId={setSelectedUserId}
      />
    </>
  );
};

export default Users;
