import styled from "styled-components";
import { Button } from "../../../../common/Button";
import Text from "../../../../common/Text/Text";
import { ACTIVE } from "../../../../../constants/status";
import Container from "../../../../common/Container/Container";

export const AddressBlock = styled.div`
  height: 29px;
  display: flex;
  color: #a498ed;
  padding: 6px 11px;
  align-items: center;
  border-radius: 14.5px;
  justify-content: center;
  background-color: #3f2faa;
  white-space: nowrap;
  cursor: pointer;

  @media (max-width: 450px) {
    font-size: 10px;
  }
`;

export const StatBlock = styled.div`
  height: 82px;
  flex-grow: 1;
  display: flex;
  row-gap: 12px;
  padding: 13px 19px;
  border-radius: 6px;
  flex-direction: column;
  background-color: #4e3cca;
`;
export const StatsContainer = styled.div`
  /* marginTop="33px" flex columnGap="15px" */
  display: flex;
  column-gap: 15px;
  margin-top: 33px;

  @media (max-width: 450px) {
    row-gap: 15px;
    flex-direction: column;
  }
`;

export const SelectorButton = styled(Button)`
  background: ${props => (props.active ? "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)" : "#3F2FAA")};
`;

export const OrganizationRowWrapper = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr 1fr;
`;

export const TeamMemberGrid = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 3fr repeat(3, 2fr) 0.25fr;

  div:nth-child(3) {
    padding-left: 25px;
  }
  p:nth-child(3) {
    padding-left: 22px;
  }
`;

export const TeamMemberAccountGrid = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  /* grid-template-columns: 1.65fr 1fr 1.65fr 0.25fr; */
  grid-template-columns: 1.75fr 1fr 1.75fr 0.1fr;

  div:nth-child(3) {
    padding-left: 25px;
  }
  p:nth-child(3) {
    padding-left: 22px;
  }
`;

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 56px;

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 24px;
  }
`;

export const ButtonText = styled.span`
  white-space: nowrap;
`;

export const ThreeDotsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ThreeDotsButtonContainer = styled.div`
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.actionIsToggled ? "#4e3cca" : "transparent")};
  border-radius: 6px;
`;

export const ActionButton = styled(Button)`
  width: 100%;
  height: 37px;
  border-radius: 4px;
  background: #4e3cca;
  justify-content: flex-start;
  gap: 10px;
  &:disabled {
    color: #ffffff80;
  }
`;

export const ActionLabel = styled.span`
  color: #fff;
  font-size: 14px;
  font-weight: 325;
`;

export const StatusWrapper = styled.div`
  display: flex;
  height: 29px;
  padding: 7px 10px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #4e3cca;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

export const StatusDot = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  ${props => `
    display  : ${props.status === ACTIVE ? `block` : "none"};    
  `}
  background: #b2fa4f;
`;

export const StatusText = styled(Text)`
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  text-transform: capitalize;
`;

export const StatusSelector = styled.input`
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: 2px solid #705dec;
  margin: 0;
`;

export const StatusLabel = styled.span`
  font-size: 16px;
  font-weight: 325;
  line-height: 34px;
  text-align: left;
`;

export const Divider = styled.div`
  border-top: 1px solid #6350de;
  width: 100px;
`;

export const StatusContainer = styled(Container)`
  position: relative;
`;
