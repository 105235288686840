import React, { useRef } from "react";

import * as S from "../../styles";
import Container from "../../../../common/Container/Container";
import ErrorTooltip from "../../../../common/ErrorTooltip";
import ImagePicker from "../ImagePicker";
import Flex from "../../../../common/Flex";
import ImageTag from "../../../../common/ImageTag";

const SecondStep = ({
  register,
  errors = {},
  setValue,
  currentInputValues = {},
  clearErrors,
  watch,
  assetTypeBeingUploaded,
  assetUploadMutation,
  isAssetUploadLoading,
  getValues,
}) => {
  const gameCardRef = useRef(null);
  const mainBannerRef = useRef(null);
  const gameCardError = errors?.gameCard?.message;
  const mainBannerError = errors?.mainBanner?.message;

  if (!register || !setValue) return <div />;
  return (
    <Container flex flexDirection="column" rowGap="27px">
      {/* Logo Input */}
      <S.RowContainer>
        <Flex columnGap="12px">
          <label>Game Card</label>
          {gameCardError && <ErrorTooltip message={gameCardError} isRelative />}
        </Flex>
        <S.ImageContainer>
          <div>
            <input
              type="file"
              className="hidden"
              ref={gameCardRef}
              onChange={e => {
                if (e.target?.files[0]) {
                  clearErrors("gameCard");
                  assetUploadMutation({ file: e.target.files[0], assetType: "gameCard" });
                }
              }}
              disabled={isAssetUploadLoading && assetTypeBeingUploaded === "gameCard"}
            />
            <ImagePicker
              handleClick={() => gameCardRef?.current?.click()}
              loading={isAssetUploadLoading && assetTypeBeingUploaded === "gameCard"}
            />
          </div>
          <S.ImageBlock isError={!!gameCardError}>
            <ImageTag src={getValues("gameCard")} width="67.93px" height="78.26px" />
          </S.ImageBlock>
        </S.ImageContainer>
      </S.RowContainer>

      {/* Sidebar icon */}
      <S.RowContainer>
        <label>
          <Flex columnGap="12px">
            <div>Main banner</div>
            {mainBannerError && <ErrorTooltip message={mainBannerError} isRelative />}
          </Flex>
        </label>
        <S.ImageContainer>
          <div>
            <input
              type="file"
              className="hidden"
              ref={mainBannerRef}
              onChange={e => {
                if (e.target?.files[0]) {
                  clearErrors("mainBanner");
                  assetUploadMutation({ file: e.target.files[0], assetType: "mainBanner" });
                }
              }}
              disabled={isAssetUploadLoading && assetTypeBeingUploaded === "mainBanner"}
            />
            <ImagePicker
              handleClick={() => mainBannerRef?.current?.click()}
              loading={isAssetUploadLoading && assetTypeBeingUploaded === "mainBanner"}
            />
          </div>
          <S.ImageBlock isError={!!mainBannerError}>
            <ImageTag src={getValues("mainBanner")} width="207.14px" height="41.21px" />
          </S.ImageBlock>
        </S.ImageContainer>
      </S.RowContainer>
    </Container>
  );
};

export default SecondStep;
