import styled from "styled-components";
import { breakpoints } from "../../themes";
import { Button } from "../../components/common/Button";
import { PROCESSING_REGISTERED_USERS, RUNNING, STARTED } from "../../constants/status";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  padding: 0px 72px 10px 47px;
  ${breakpoints.down(breakpoints.laptopL)} {
    padding: 0px 38px 10px 47px;
  }
  ${breakpoints.down(breakpoints.laptop)} {
    padding: 0px 16px 10px 16px;
  }
`;

export const InnerContainer = styled.div`
  margin-top: 40px;
`;

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TagButtonContainer = styled.div`
  margin: 55px 0;
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const TagButton = styled(Button)`
  border-radius: 30px;
  background: ${props =>
    props.isActive ? "linear-gradient(180deg, #7283F7 0%, #AA57FF 100%)" : "#3f2faa"}; //gradient bg when active
  color: #ffffff;
  border: none;
  padding: 14px 30px;
  font-size: 16px;
  font-style: normal;
  font-weight: ${props => (props.isActive ? "bold" : "325")};
  line-height: normal;
  text-transform: capitalize;
  height: auto;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

`;

export const CreateBracketContainer = styled.div`
  height: 364px;
  border-radius: 6px;
  border: 1px solid rgba(114, 101, 200, 0.3);
  background: #3f2faa;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CreateBracketText = styled.span`
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 325;
  line-height: 36px;
`;

export const CreateBracketLink = styled.a`
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 325;
  line-height: 36px;
  color: #be7eff;
  text-decoration: underline;
  cursor: pointer;
`;

export const DataContainer = styled.div``;

export const DataHeader = styled.div`
  display: flex;
  gap: 10px;
`;

export const DataHeaderText = styled.h4`
  font-size: 24px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  margin-top: 0;
`;

export const DataHeaderPlayerCount = styled.span`
  border-radius: 14.5px;
  background: #342788;
  width: 93px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a498ed;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  text-transform: capitalize;
`;

export const DropdownSelectorContainer = styled.div`
  display: flex;
  gap: 25px;
`;

export const DropdownSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DropdownSelectorText = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

export const BracketDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BracketWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const BracketDataHeader = styled.div`
  display: grid;
  grid-template-columns: 0.5fr repeat(5, 1fr);
  height: 32px;
`;

export const BracketDataHeaderText = styled.p`
  margin: 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  color: #a498ed;
`;

export const BracketRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const BackArrow = styled.span`
  margin-bottom: 8px;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const StatusIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  ${props => `
    display  : ${
      props.status === RUNNING || props.status === STARTED || props.status === PROCESSING_REGISTERED_USERS
        ? `block`
        : "none"
    };    
  `}
  background: #b2fa4f;
`;

export const StatusText = styled.span`
  font-size: 11px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  margin: 0;
  color: #a498ed;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
  padding: 11px;
  border-radius: 20px;
  gap: 4px;
  background: #3f2faa;
  ${breakpoints.down("1079px")} {
    margin-top: 0;
  }
`;

export const GameImg = styled.img`
  width: 49px;
  height: 49px;
  border-radius: 8px;
`;

export const IdText = styled.span`
  color: #a498ed;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: 350;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

export const LeaderboardRow = styled.div`
  height: 54px;
  /* min-width: 768px; */
  display: grid;
  grid-template-columns: ${props => `0.5fr 1.5fr repeat(${props.dynamicColumnsCount}, 1fr) 1fr`};
  align-items: center;
  background: #3f2faa;
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 4px;
  // justify-content: space-between;
  div {
    justify-self: center;
  }
  /* div:first-child {
    justify-self: start;
  } */
  div:last-child {
    justify-self: end;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #4e3cca;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #342788f0;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #342788;
  }

  &::-webkit-scrollbar-corner {
    background: #342788f0 !important;
  }

  &:hover {
    cursor: ${props => (props.joinMode === "TEAM" ? "pointer" : "default")};
  }
`;

export const LeaderboardHeader = styled.div`
  height: 54px;
  display: grid;
  grid-template-columns: ${props => `0.5fr 1.5fr repeat(${props.dynamicColumnsCount}, 1fr) 1fr`};
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 4px;
  margin-top: 36px;
  font-size: 13px;
  font-weight: 325;
  color: #a498ed;
  // justify-content: space-between;
  div {
    justify-self: center;
  }
  div:first-child {
    /* justify-self: start; */
  }
  div:last-child {
    justify-self: end;
  }
`;

export const LeaderboardRowsContainer = styled.div`
  /* Container marginTop="12px" flex flexDirection="column" rowGap="12px" style={{ overflowX: "auto" }} */
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const LeaderboardSectionContainer = styled.div`
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 16px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #4e3cca;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #342788f0;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #342788;
  }

  &::-webkit-scrollbar-corner {
    background: #342788f0 !important;
  }
`;

export const LeaderboardSectionInnerContainer = styled.div`
  min-width: 900px;
`;

export const ChatContainer = styled.div`
  min-height: 200px;
  width: 100%;
  background-color: #3f2faa;
  border-radius: 6px;
  padding: 28px 24px;
`;

export const MessagesSection = styled.div`
  max-height: 280px;
  overflow-y: auto;
  padding-right: 4px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #3f2faa;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #342788f0;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #342788;
  }
`;

export const MessageBubble = styled.div`
  max-width: 627px;
  background-color: #4e3cca;
  font-size: 12px;
  padding: 15px;
  border-radius: 6px;
`;

export const MessageContainer = styled.div`
  display: flex;
  column-gap: 12px;
  flex-direction: ${props => (props.isUserAuthor ? "row-reverse" : "row")};
`;

export const InputContainer = styled.form`
  display: flex;
  height: 58px;
  background-color: #4e3cca;
  margin-top: 28px;
  border-radius: 8px;
  column-gap: 48px;
  align-items: center;
  input {
    padding-left: 11px;
  }
  /* padding: 0 11px; */
  @media (max-width: 540px) {
    /* background-color: red; */
    background-color: transparent;

    flex-direction: column;
    height: 100px;
    input {
      background-color: #4e3cca !important;
      width: 100%;
    }
  }
  /* @media (max-width: 400px) {
    column-gap: 20px;
    display: none;
  }
  @media (max-width: 360px) {
    column-gap: 4px;
  } */
`;
export const Input = styled.input`
  flex: 1;
  height: 100%;
  background-color: transparent;
  border-radius: 8px;
  outline: none;
  border: none;
  font-size: 16px;
  color: #fff;
  &:disabled {
    color: #ffffff80;
  }
  &::placeholder {
    color: #ffffff66;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  column-gap: 10px;
  margin-right: 11px;
  @media (max-width: 540px) {
    margin: 10px 0;
    align-self: self-end;
  }
`;
