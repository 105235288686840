import { z } from "zod";

export const FormDataSchema = z.object({
  // First Step Schema
  name: z.string().min(1, "Game name is required"),
  accPlaceholder: z.string().min(1, "Platform name is required"),
  platformIdentifier: z.string().min(1, "Platform identifier is required"),
  logo: z.string().min(1, "Logo is required"),
  sidebarIcon: z.string().min(1, "SidebarIcon is required"),

  // Second Step Schema
  gameCard: z.string().min(1, "Game card is required"),
  mainBanner: z.string().min(1, "Main banner is required" ),


  // Third Step Schema
  backBanner: z.string().min(1, "Back banner is required"),
  frontBanner: z.string().min(1, "Front banner is required" ),
  thumbnail: z.string().min(1, "Thumbnail is required" ),
  tournamentCardGameLogo: z.string().min(1, "Tournament Card Game Logo is required" ),


  // Fourth Step Schema
  winnerBg: z.string().min(1, "Winner background image is required")
});
