import React from "react";
import styled from "styled-components";
import { Maintenance } from "../../assets/svg";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background: #201366;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-style: normal;
  font-weight: 325;
  font-size: 38px;
  line-height: 46px;
  text-align: center;

  color: #ffffff;
  margin: 0;
`;

const Subtitle = styled.h2`
  width: 371px;
  font-style: normal;
  font-weight: 325;
  font-size: 16px;
  line-height: 19px;
  color: #a498ed;
  text-align: center;
`;

export const MaintenancePage = () => {
  return (
    <Container>
      <Maintenance />
      <Title>We are under maintenance</Title>
      <Subtitle>Sorry, we are doing some maintenance. Please check back soon.</Subtitle>
      {/* //TODO: Add the countdown */}
    </Container>
  );
};
