import axiosClient from "./axiosClient";

export function getOrganizationGameAssets({ organizationId, gameId }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!gameId) throw new Error("Please provide valid game ID");
  return axiosClient
    .get(`/admPanel/file/${organizationId}/gameAssets/${gameId}`, { headers: { Authorization: headers.encryptedHeader } })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function assetUpload({ organizationId, type, subType, banner }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  console.log(banner);
  const formData = new FormData();
  formData.append("file", banner);
  formData.append("type", type);
  formData.append("subType", subType);
  return axiosClient
    .post(`/admPanel/file/${organizationId}`, formData, {
      headers: { Authorization: headers.encryptedHeader, "Content-Type": "multipart/form-data" },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}
