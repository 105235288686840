import React from "react";
import { Tooltip } from "antd";

import * as S from "./styles";
import { WarningOutlineRed } from "../../../assets/svg";

const ErrorTooltip = ({ message, right, top, isRelative }) => {
  return (
    <S.ErrorTooltipContainer right={right} top={top} isRelative={isRelative}>
      <Tooltip overlayStyle={{ zIndex: "9999" }} title={message || "Invalid input"} placement="bottom" color="#7265C8">
        <WarningOutlineRed />
      </Tooltip>
    </S.ErrorTooltipContainer>
  );
};

export default ErrorTooltip;
