import styled from "styled-components";

export const HeaderPreview = styled.div`
  background: ${props => (props.bannerBgBack ? `url(${props.bannerBgBack})` : "#4C3BD4")};
  height: ${props => (props.isConfirmScreen ? `236px.38px` : `191.06px`)};
  width: ${props => (props.isConfirmScreen ? `738px` : `100%`)};
  position: relative;
  padding: 22px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
export const HeaderPreviewSection = styled.div`
  background: #342788;
  height: 320px;
  border-radius: 6px;
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 185px;
`;

export const GameDetails = styled.div`
  z-index: 2;
  position: relative;
`;

export const JoinBtn = styled.div`
  width: ${props => (props.isConfirmScreen ? "76px" : "53px")};
  height: ${props => (props.isConfirmScreen ? "25.29px" : "18px")};
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  p {
    color: #000;
    font-size: ${props => (props.isConfirmScreen ? "10px" : "7px")};
  }
`;

export const DetailBox = styled.div`
  background: #3d2faa90;
  padding: ${props => (props.isConfirmScreen ? "8px" : "4px")};
  width: ${props => (props.isConfirmScreen ? "94px" : "66px")};
  height: ${props => (props.isConfirmScreen ? "45px" : "38px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 4px;
  border-radius: 4px;

  span {
    color: #ffffff90;
    font-size: ${props => (props.isConfirmScreen ? "9px" : "7px")};
  }
  p {
    font-size: ${props => (props.isConfirmScreen ? "14px" : "10px")};
    margin: 0;
    padding: 0;
  }
  small {
    color: #ffffff80;
    font-size: ${props => (props.isConfirmScreen ? "10px" : "5px")};
  }

  svg {
    height: ${props => (props.isConfirmScreen ? "11.96px" : "7.88px")};
    width: ${props => (props.isConfirmScreen ? "13.75px" : "7.88px")};
  }
`;


export const TimeBlock = styled.div`
    width: 30px;
    height: 40px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    background-color: #ffffff33;
    justify-content: center;
`