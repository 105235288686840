import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import * as S from "./styles";
import { CaretArrow, MaginfyingGlass, Swap } from "../../../../assets/svg";
import useOnClickOutside from "../../../../hooks/useClickOutside";
import Flex from "../../../common/Flex";
import Text from "../../../common/Text/Text";
import Container from "../../../common/Container/Container";
import { BracketsDataContext } from "../../../../providers/BracketsDataProvider";
import { useLogin } from "../../../../providers/Loginprovider";
import { TournamentContext } from "../../../../providers/TournamentContext";
import { getPoolPlayerAndMatches } from "../../../../api/pool";
import Avatar from "../../../common/Avatar";
import LoadingSkeleton from "../../../common/LoadingSkeleton";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { NO_SHOW_PLAYER } from "../../../../constants";
import { getStageBracket } from "../../../../api/stage";
import { isNonEmptyObject } from "../../../../utilities";
import { Button } from "../../../common/Button";

const StageSelect = ({ fromStage, stages = [], stageToMovePlayersTo, setStageToMovePlayersTo }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Input state and functions
  const inputRef = useRef(null);

  // To autofocus the search input when dropdown opens
  useEffect(() => {
    if (open) inputRef?.current?.focus();
  }, [open]);

  return (
    <S.SelectContainer ref={ref}>
      <S.SelectLabelButton onClick={handleOpen}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" columnGap="10px">
            <Swap style={{ height: "34px" }} />
            <Text fontSize="16px">{stageToMovePlayersTo ? `Stage ${stageToMovePlayersTo}` : "Select Stage"}</Text>
          </Flex>

          <S.CaretContainer open={open}>
            <CaretArrow />
          </S.CaretContainer>
        </Flex>
      </S.SelectLabelButton>
      <S.DropdownStyle isVisible={open}>
        {/* <S.SearchContainer onClick={() => inputRef?.current?.focus()}>
          <Flex alignItems="center" columnGap="10px">
            <MaginfyingGlass />
            <S.SearchInputDivier />
          </Flex>
        </S.SearchContainer> */}
        {/* <S.SelectFiltersContainer hide={!branchOptions?.length}>
          {branchOptions.map(option => (
            <S.FilterButton active={selectedBranch === option.value} onClick={() => setSelectedBranch(option.value)}>
              Branch {option.value}
            </S.FilterButton>
          ))}
        </S.SelectFiltersContainer> */}

        <S.ListContainer>
          {stages.map(stage => (
            <S.DropdownItem
              disableStyles={stage.stageId <= fromStage}
              onClick={() => {
                if(stage.stageId <= fromStage ) return;
                setStageToMovePlayersTo(stage.stageId);
                handleClose();
              }}
              key={stage.stageId}
            >
              <Flex alignItems="center" columnGap="15px">
                <span>Stage {stage.stageId}</span>
              </Flex>
            </S.DropdownItem>
          ))}
        </S.ListContainer>
      </S.DropdownStyle>
    </S.SelectContainer>
  );
};

export default StageSelect;
