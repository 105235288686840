import { Checkbox } from "antd";
import styled from "styled-components";
import Text from "../../../common/Text/Text";

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ModalTitle = styled.h2`
  font-size: 28px;
  font-weight: 325;
  line-height: 33.6px;
  text-align: left;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 340px;
`;

export const Input = styled.input`
  width: 100%;
  height: 41px;
  gap: 0px;
  border-radius: 6px;
  opacity: 0px;
  background: #4e3cca;
  border: none;
  color: #ffffff;

  &::placeholder {
    color: #a498ed;
  }
`;

export const SuggestionsList = styled.ul`
  position: absolute;
  top: 140%;
  left: 0;
  width: 100%;
  background: #4e3cca;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1;
`;

export const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const ArrowButton = styled.div`
  width: 41px;
  height: 41px;
  background: #4e3cca;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlayerList = styled.div`
  width: 340px;
  height: 344px;
  overflow-y: auto;
  border-radius: 6px;
  background: #4e3cca;
  padding: 17px 14px;

  &input[type="checkbox"] {
    border: 2px solid #7265c8;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: transparent;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: transparent;
    border-color: #6c47ff;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6c47ff;
    border-color: transparent;
  }
`;

export const ConfirmationText = styled(Text)`
  max-width: 440px;
  font-size: 16px;
  font-weight: 325;
  line-height: 19.2px;
  text-align: left;
  color: #a498ed;
`;
