import styled from "styled-components";
import Modal from "styled-react-modal";

export const CloseIconContainer = styled.div`
  cursor: pointer;
`;

export const ModalHeader = styled.div`
  margin-bottom: 28px;
  line-height: 30px;
  color: rgba(209, 204, 239, 1);
  font-size: 20px;
`;

export const ModalContainer = Modal.styled`
width: fit-content;
overflow-y: auto;
display: flex;
flex-direction: column;
background-color: #342788;
border-radius: 6px;
opacity: 1;
transition : all 0.3s ease-in-out;
position: absolute;
top: 50%;
padding: 30px 25px; 
transform: translateY(-50%);
@media (max-width: 450px) {
  top: 20%;
  transform: translateY(-20%);
  ${props => ({ ...props.mobileStyles })};

};

@media (min-width: 448px) and (max-width: 768px) {
  ${props => ({ ...props.tabletStyles })};
}

@media (min-width: 768px) and (max-width: 1024px) {
  ${props => ({ ...props.laptopStyles })};
}

${({ styleOverrides }) => ({ ...styleOverrides })}


/* width */
&::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
&::-webkit-scrollbar-track {
  background: #4e3cca;
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background: #342788f0;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #342788;
}

&::-webkit-scrollbar-corner {
    background: #342788f0 !important;
  }

`;
