import React, { useContext, useState } from "react";
import * as S from "./styles";
import PlayerSelect from "./PlayerSelect";
import Flex from "../../../common/Flex";
import Avatar from "../../../common/Avatar";
import Modal from "../../../common/Modal/Modal";
import { BracketsDataContext } from "../../../../providers/BracketsDataProvider";
import { SwapAlt } from "../../../../assets/svg";
import { Button } from "../../../common/Button";
import Container from "../../../common/Container/Container";

const SwapModal = ({ swapBracketPlayersMutation = () => {}, isLoadingSwap = false }) => {
  const { openSwapModal, setOpenSwapModal, branchOptions, playerToSwap, setPlayerToSwap } =
    useContext(BracketsDataContext);
  const [swapWith, setSwapWith] = useState(null);

  const toggleSwapModle = () => {
    setOpenSwapModal(!openSwapModal);
  };

  return (
    <Modal
      showModal={openSwapModal}
      customStyles={{ width: "516px", height: "481px", borderRadius: "6px", overflowY: "visible", top: "40%" }}
      mobileStyles={{ width: "90%" }}
      toggleModal={toggleSwapModle}
      beforeClose={() => {
        setPlayerToSwap(null);
        setSwapWith(null);
      }}
    >
      <S.Header>Swap Modal</S.Header>
      {playerToSwap && (
        <S.RelativeContainer>
          <S.Row highlight>
            <Flex alignItems="center" columnGap="14px">
              <Avatar src={playerToSwap?.avatarUrl} alt={`${playerToSwap?.nickname} avatar`} />
              <div>{playerToSwap.nickname}</div>
            </Flex>
          </S.Row>

          {!!swapWith && (
            <S.SwapIconContainer>
              <SwapAlt />
            </S.SwapIconContainer>
          )}
          {/* Select  Dropdown */}
          <PlayerSelect
            selectedPlayerToSwapWith={swapWith}
            onChange={v => setSwapWith(v)}
            branchOptions={branchOptions}
          />

          {!!swapWith && (
            <Container marginTop="117px">
              <Flex justifyContent="center">
                <Button
                  width="183px"
                  height="45px"
                  borderRadius="30px"
                  padding="12px 20px"
                  onClick={() => swapBracketPlayersMutation({playerToSwap, swapWith})}
                  disabled={isLoadingSwap}
                >
                  Swap
                </Button>
              </Flex>
            </Container>
          )}
        </S.RelativeContainer>
      )}
    </Modal>
  );
};

export default SwapModal;
