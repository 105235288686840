import styled from "styled-components";

export const Container = styled.div`
  right: 0;
  bottom: 0;
  width: 85%;
  height: 85%;
  position: absolute;
  background-color: #2a1f70;
  display: flex;
`;

export const PreviewSidebar = styled.div`
  width: 87px;
  height: 100%;
  display: flex;
  row-gap: 22px;
  align-items: center;
  flex-direction: column;
`;

export const Separator = styled.div`
  width: 91px !important;
  height: 100%;
  display: flex;
  row-gap: 22px;
  align-items: center;
  flex-direction: column;
  background-color: #3f2faa;
`;

export const Logo = styled.div`
  width: 87px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 16px;
  background: linear-gradient(199.28deg, #12c2ea -51.84%, #7c00fa 98.68%);
`;

export const BannerContainer = styled.div`
  width: 85%;
  position: relative;
`;

export const Banner = styled.div`
  right: 0;
  top: 58px;
  width: 280px;
  display: flex;
  height: 216px;
  border-radius: 8px;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270.02deg, rgba(84, 49, 220, 0) -24.63%, #5431dc 65.83%);
`;

export const SidebarImg = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background: ${props => `url(${props.imgUrl})`};
`;

export const SidebarImgEmpty = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: #4e3cca;
`;
