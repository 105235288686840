import styled from "styled-components";
import Modal from "../../../../common/Modal/Modal";

export const AddPlayerModal = styled(Modal)``;

export const AddPlayerWrapper = styled.div`
  height: 390px;
  display: flex;
  flex-direction: column;
  gap: 17px;
`;

export const ModalTitle = styled.p`
  margin: 0;
  font-size: 26px;
  font-weight: 325;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
`;

export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
`;

export const PlayerListContainer = styled.div`
  background: #36279c;
  border: 1px solid #5647b5;
  border-radius: 4px;
  height: 290px;
  width: 100%;
  padding-left: 26px;
  padding-right: 10px;
  padding-top: 16px;
`;

export const PlayerListInner = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  ::-webkit-scrollbar {
    width: 6px;
    padding: 20px;
    margin: 20px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2b1c7e;
    padding: 0 4px;
    border-radius: 24px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3f338c;
  }
`;