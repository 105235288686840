import React from "react";
import Modal from "../../../common/Modal/Modal";
import * as S from "./styles";
import Flex from "../../../common/Flex";
import { ButtonUnstyled } from "../../../common/Button";

export const DeleteCodesConfirmationModal = ({
  showModal,
  toggleModal,
  mutateDeleteInvitationalCode,
  mutateDeleteInvCodeLoading,
  mutateDeleteAllTheInvitationalCodes,
  mutateDeleteAllInvCodeLoading,
  codesArray = [],
  organizationId,
  poolId,
  singleCode,
}) => {
  return (
    <Modal showModal={showModal} toggleModal={toggleModal}>
      <S.ModalContainer>
        <Flex flexDirection="column">
          <S.ModalTitle>{singleCode ? "Delete code?" : "Delete all codes?"}</S.ModalTitle>
          <S.ModalDescription>
            {singleCode
              ? "You are about to delete this code. There is no way to recuperate once it is deleted."
              : "You are about to delete all codes. There is no way to recuperate once it is deleted."}
          </S.ModalDescription>
        </Flex>
        <Flex justifyContent="flex-end" alignItems="center">
          <ButtonUnstyled onClick={toggleModal}>Cancel</ButtonUnstyled>
          <S.DangerButton
            onClick={() =>
              singleCode
                ? mutateDeleteInvitationalCode({
                    organizationId: organizationId,
                    poolId: poolId,
                    invitationalCode: codesArray,
                  })
                : mutateDeleteAllTheInvitationalCodes({
                    organizationId: organizationId,
                    poolId: poolId,
                    invitationalCodes: codesArray,
                  })
            }
            disabled={singleCode ? mutateDeleteInvCodeLoading : mutateDeleteAllInvCodeLoading}
          >
            Yes, delete
          </S.DangerButton>
        </Flex>
      </S.ModalContainer>
    </Modal>
  );
};
