import React from "react";
import * as S from "./styles";
import Avatar from "../../../../common/Avatar";
import Text from "../../../../common/Text/Text";
import { capitalizeFirstLetter, formatTimeStamp } from "../../../../../utilities";
import Container from "../../../../common/Container/Container";

const TeamRow = ({ data = {} }) => {
  return (
    <S.Container>
      <Container flex alignItems="center" justifyContent="center" columnGap="12px">
        <Avatar src={data.avatarURL} size="30px" />
        <Text fontSize="14px">{data.name}</Text>
      </Container>

      <Text fontSize="13px">{data.id}</Text>

      <Text fontSize="13px">{capitalizeFirstLetter(data.visibility)}</Text>
    </S.Container>
  );
};

export default TeamRow;
