import React, { useState } from "react";
import * as S from "./styles";
import Container from "../../../../../components/common/Container/Container";
import Flex from "../../../../../components/common/Flex";
import { CreateRuleModal } from "./CreateRuleModal";
import { EditRuleModal } from "./EditRuleModal";
import { truncateWithDots } from "../../../../../utilities";
import { Add } from "../../../../../assets/svg";

const RulesSection = ({ organizationId, poolRules = [] }) => {
  const [poolRulesArray, setPoolRulesArray] = useState(poolRules?.sort((a, b) => a.sortOrder - b.sortOrder));
  const [ruleTitle, setRuleTitle] = useState("");
  const [ruleContent, setRuleContent] = useState("");
  const [ruleId, setRuleId] = useState("");
  const [ruleSortOrder, setRuleSortOrder] = useState(1);

  const [showNewRulesModal, setShowNewRulesModal] = useState(false);
  const [showEditRulesModal, setShowEditRulesModal] = useState(false);

  const initialRules = [{ id: "new-rule", title: "Add new rule" }, ...poolRulesArray];

  const toggleNewRulesModal = () => {
    setShowNewRulesModal(!showNewRulesModal);
  };

  const toggleEditRulesModal = ({ rule = {}, trigger }) => {
    if (trigger === "delete" || trigger === "edit") {
      //* this validation is because when the endpoint finishes either deleting or editing the content the modal will prompt that rule is undefined
      setShowEditRulesModal(!showEditRulesModal);
    } else {
      // this else is used to set the initial data to the modal
      setShowEditRulesModal(!showEditRulesModal);
      setRuleSortOrder(rule.sortOrder);
      setRuleId(rule.id);
      setRuleTitle(rule.title);
      setRuleContent(rule.content);
    }
  };

  return (
    <Container flex flexDirection="column" rowGap="44px">
      <Flex>
        <S.PageTitle>Rules</S.PageTitle>
      </Flex>
      <Container>
        <S.RulesWrapper>
          {initialRules.map(rule =>
            rule.id === "new-rule" ? (
              <S.CreateRule onClick={toggleNewRulesModal}>
                <Flex columnGap="5px" alignItems="center">
                  <Add /> {rule.title}
                </Flex>
              </S.CreateRule>
            ) : (
              <S.RuleDetail onClick={() => toggleEditRulesModal({ rule })}>
                <Flex columnGap="10px">
                  <S.RuleOrder>{rule.sortOrder}</S.RuleOrder>
                  <Flex flexDirection="column" rowGap="17px" style={{ paddingTop: "3px" }}>
                    <span>{truncateWithDots(rule.title, 35)}</span>
                    <S.RuleContent>
                      {rule?.content ? (
                        <S.RuleContentMessage dangerouslySetInnerHTML={{ __html: truncateWithDots(rule?.content, 150) }} />
                      ) : (
                        <span>No additional details</span>
                      )}
                    </S.RuleContent>
                  </Flex>
                </Flex>
              </S.RuleDetail>
            ),
          )}
          <CreateRuleModal
            showModal={showNewRulesModal}
            toggleModal={toggleNewRulesModal}
            poolRulesArray={poolRulesArray}
            setPoolRulesArray={setPoolRulesArray}
            organizationId={organizationId}
          />
          <EditRuleModal
            showModal={showEditRulesModal}
            toggleModal={toggleEditRulesModal}
            poolRulesArray={poolRulesArray}
            setPoolRulesArray={setPoolRulesArray}
            ruleTitle={ruleTitle}
            setRuleTitle={setRuleTitle}
            ruleContent={ruleContent}
            setRuleContent={setRuleContent}
            ruleSortOrder={ruleSortOrder}
            setRuleSortOrder={setRuleSortOrder}
            ruleId={ruleId}
            setRuleId={setRuleId}
            organizationId={organizationId}
          />
        </S.RulesWrapper>
      </Container>
    </Container>
  );
};

export default RulesSection;
