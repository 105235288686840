import React from "react";
import * as S from "./styles";
import Text from "../../../../../common/Text/Text";
import Flex from "../../../../../common/Flex";
import { Absolute } from "../../../../../common/Container/Container";
import {
  CalendarPurple,
  Coin,
  MaticWhite,
  ProfileActiveIcon,
  Wallet,
} from "../../../../../../assets/svg";
import PreviewBannerPng from "../../../../../../assets/png/preview_banner_details.png"

const ThirdPreview = ({ previewData = {} }) => {
  const { backBanner, frontBanner, thumbnail, name, tournamentCardGameLogo } = previewData;
  return (
    <S.Container>
      <Flex justifyContent="center" style={{ maxWidth: "85%", position: "relative" }}>
        <S.Banner src={backBanner} />
        <S.FrontBanner src={frontBanner} />
        <S.DetailsContainer>
          {backBanner && <span>{name}</span>}
          {backBanner && <img src={PreviewBannerPng} />}
        </S.DetailsContainer>
      </Flex>
      {thumbnail && (
        <S.TournamentCard>
          <S.TournamentCardContainer>
            <S.TournamentCardPicture img={thumbnail} />
            {tournamentCardGameLogo && (
              <Absolute left="22px" top="18px">
                <S.SmallLogoContainer>
                  <img src={tournamentCardGameLogo} alt="Game Logo" style={{ maxWidth: "25px" }} />
                </S.SmallLogoContainer>
              </Absolute>
            )}
          </S.TournamentCardContainer>
          <S.GameName>{name}</S.GameName>
          <S.GameDesc>{name} subtitle</S.GameDesc>

          <S.PillContainer>
            <S.Pill>
              <Coin style={{ height: "9px", width: "9px" }} />
              <Text fontSize="8px">0.4</Text>
              <MaticWhite style={{ height: "7px", width: "7px" }} />
              <Text fontSize="8px" color="#A498ED">
                $0.74
              </Text>
            </S.Pill>
            <S.Pill>
              <Wallet style={{ height: "9px", width: "9px" }} />
              <Text fontSize="8px">Free Entry</Text>
            </S.Pill>
            <S.Pill>
              <CalendarPurple style={{ height: "9px", width: "9px" }} />
            </S.Pill>
          </S.PillContainer>

          <S.ProfileContainer>
            <ProfileActiveIcon />
            <Text fontSize="10px" color="#A498ED">
              5 players
            </Text>
          </S.ProfileContainer>
        </S.TournamentCard>
      )}
    </S.Container>
  );
};

export default ThirdPreview;
