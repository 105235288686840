import React, { useEffect, useRef, useState } from "react";
import { z } from "zod";
import moment from "moment/moment";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import * as S from "../../styles";
import Text from "../../../../common/Text/Text";
import { Dropdown } from "../../../../common/Dropdown";
import Container from "../../../../common/Container/Container";
import ErrorTooltip from "../../../../common/ErrorTooltip";
import Counter from "../../../../common/Counter";
import { AccordionArrow } from "../../../../../assets/svg";
import RuleComponent from "../../subcomponents/Rule";
import ReactQuill from "react-quill";
import { POOL_MODES, STAGE_MODES } from "../../../../../constants";

import "react-quill/dist/quill.snow.css";
import { createArrayFromNumber, rangeArray } from "../../../../../utilities";

const RulesContainer = props => {
  const {
    register,
    errors = {},
    currentInputValues = {},
    setValue,
    setError,
    clearErrors = () => {},
    watch = () => {},
    stageIndex,
  } = props;
  const i = stageIndex;
  const quill = useRef();
  return (
    <S.RowContainer alignItems="flex-start">
      <label>Rules</label>
      <Container>
        <RuleComponent rules={currentInputValues?.stages?.[i]?.rules} />
        <Container flex flexDirection="column" rowGap="6px">
          <S.TextInput
            width="100%"
            // onChange={e => {
            //   setRuleTitleInput(e.target.value);
            // }}
            // value={ruleTitleInput}
            {...register(`stages.${stageIndex}.titleInputTemp`)}
          />
          <ReactQuill
            className="ql-custom"
            onChange={(content, delta, source, editor) => {
              const textContent = editor?.getText(content);
              setValue(`stages.${i}.descInputTemp`, content);
            }}
            ref={quill}
          />
          <S.ButtonsContainer>
            <div>
              <button
                type="button"
                onClick={() => {
                  if (currentInputValues?.stages?.[i]?.rules && Array.isArray(currentInputValues?.stages?.[i]?.rules)) {
                    setValue(
                      `stages.${i}.rules`,
                      currentInputValues?.stages?.[i]?.rules.slice(
                        0,
                        currentInputValues?.stages?.[i]?.rules.length - 1,
                      ),
                    );
                  }
                }}
              >
                -
              </button>
              <button
                type="button"
                onClick={() => {
                  if (currentInputValues?.stages?.[i]?.rules && Array.isArray(currentInputValues?.stages?.[i]?.rules)) {
                    setValue(`stages.${i}.rules`, [
                      ...currentInputValues?.stages?.[i]?.rules,
                      {
                        title: currentInputValues?.stages?.[i]?.titleInputTemp || "",
                        content: currentInputValues?.stages?.[i]?.descInputTemp || "",
                      },
                    ]);
                  } else {
                    setValue(`stages.${i}.rules`, [
                      {
                        title: currentInputValues?.stages?.[i]?.titleInputTemp || "",
                        content: currentInputValues?.stages?.[i]?.descInputTemp || "",
                      },
                    ]);
                  }

                  setValue(`stages.${i}.titleInputTemp`, "");
                  setValue(`stages.${i}.descInputTemp`, "");
                  quill.current?.editor?.setText("");
                }}
              >
                +
              </button>
            </div>
          </S.ButtonsContainer>
        </Container>
      </Container>
    </S.RowContainer>
  );
};

const SecondStep = props => {
  const {
    register,
    errors = {},
    currentInputValues = {},
    setValue,
    setError,
    clearErrors = () => {},
    watch = () => {},
    stageCount,
  } = props;

  useEffect(() => {
    let arr = createArrayFromNumber(stageCount - 1);
    arr.forEach(i => {
      setValue(`stages.${i}.nextStageId`, i + 2);
    });
    setValue(`stages.${stageCount - 1}.nextStageId`, stageCount);
  }, [stageCount]);

  if (!setValue) return <div />;

  return (
    <Accordion>
      <Container flex flexDirection="column" rowGap="27px">
        {[...Array(stageCount).keys()].map((_, i) => (
          <AccordionItem>
            <AccordionItemHeading
              style={{ borderRadius: "6px", border: errors?.stages?.[i] ? "1px solid #ed6140" : "" }}
            >
              <AccordionItemButton>
                <span>Stage {i + 1}</span>{" "}
                <span>
                  <AccordionArrow />
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Container flex flexDirection="column" rowGap="27px">
                <S.RowContainer>
                  <label>Title</label>
                  <S.TextInput
                    {...register(`stages.${i}.title`)}
                    hasError={!!errors?.stages?.[i]?.title}
                    onInput={() => clearErrors(`stages.${i}.title`)}
                  />
                  {errors?.stages?.[i]?.title && <ErrorTooltip message={errors?.stages?.[i]?.title?.message} />}
                </S.RowContainer>

                {i < stageCount - 1 && (
                  <S.RowContainer>
                    <label>Next Stage Id</label>
                    <Dropdown
                      // Explanation for line below is provided at the bottom of the file
                      options={rangeArray(i, stageCount - 2)?.map(i => ({ name: i + 2, value: i + 2 }))}
                      hasError={!!errors?.stages?.[i]?.nextStageId}
                      defaultValue={currentInputValues?.stages?.[i]?.nextStageId}
                      setDefaultNull={!currentInputValues?.stages?.[i]?.nextStageId}
                      onChange={e => {
                        clearErrors(`stages.${i}.nextStageId`);
                        setValue(`stages.${i}.nextStageId`, e);
                      }}
                      value={watch(`stages.${i}.nextStageId`)}
                    />
                    {errors?.stages?.[i]?.nextStageId && (
                      <ErrorTooltip message={errors?.stages?.[i]?.nextStageId?.message} />
                    )}
                  </S.RowContainer>
                )}

                <S.RowContainer>
                  <label>Stage Round</label>
                  <Dropdown
                    options={[
                      { name: "Qualifier", value: "qualifier" },
                      { name: "Final", value: "final" },
                      { name: "Prize Pool", value: "prize pool" },
                      { name: "Group Stage", value: "group stage" },
                    ]}
                    hasError={!!errors?.stages?.[i]?.stageRound}
                    defaultValue={currentInputValues?.stages?.[i]?.stageRound}
                    setDefaultNull={!currentInputValues?.stages?.[i]?.stageRound} // NEW CHANGE
                    onChange={e => {
                      clearErrors(`stages.${i}.stageRound`);
                      setValue(`stages.${i}.stageRound`, e);
                    }}
                    value={watch(`stages.${i}.stageRound`)}
                  />
                  {errors?.stages?.[i]?.stageRound && (
                    <ErrorTooltip right="50px" top="12px" message={errors?.stages?.[i]?.stageRound?.message} />
                  )}
                </S.RowContainer>

                <S.RowContainer>
                  <label>Stage Mode</label>
                  <Dropdown
                    options={[
                      { name: "Leaderboard", value: "LEADERBOARD" },
                      { name: "Round Robin", value: "ROUND_ROBIN" },
                      { name: "Single Elimination", value: "SINGLE_ELIMINATION" },
                      // { name: "Best Of 3", value: "BEST_OF_3" },
                      // { name: "Best Of 5", value: "BEST_OF_5" },
                      // { name: "Best Of 7", value: "BEST_OF_7" },
                    ]}
                    hasError={!!errors?.stages?.[i]?.stageMode}
                    defaultValue={currentInputValues?.stages?.[i]?.stageMode}
                    setDefaultNull={!currentInputValues?.stages?.[i]?.stageMode}
                    value={watch(`stages.${i}.stageMode`)}
                    onChange={e => {
                      clearErrors(`stages.${i}.stageMode`);
                      // Resetting values of winners mode when changing stageMode because some winnersMode selection are not allowed for certain stage modes
                      clearErrors(`stages.${i}.bestMatchSelectMode`); // A bestMatchSelectMode error is related to current stage mode. So changing the Stage Mode should also clear the current bestMatchSelectMode error

                      // The if condition is to prevent setting winnerPolicy of the last stage. Because last property will not have a winnersPolicy object
                      if (i < stageCount - 1) {
                        setValue(`winnersPolicy.${i}.type`, "");
                        setValue(`winnersPolicy.${i}.value`, []);
                      }

                      setValue(`stages.${i}.stageMode`, e);

                      if (e !== "LEADERBOARD" && e !== "ROUND_ROBIN") {
                        // Group Size and Score Mode need to be disabled if stageMode is not LEADERBOARD or ROUND_ROBBIN
                        // Setting values to undefined in case user selects a LEADERBOARD or ROUND_ROBIN, updates values of the two properties below, and then changes the stage mode to some other option. The lines below will reset the values of the two properties (as they should be disabled in this case)
                        setValue(`stages.${i}.groupSize`, undefined);
                        setValue(`stages.${i}.bestMatchSelectMode`, undefined);
                      }
                    }}
                  />
                  {errors?.stages?.[i]?.stageMode && (
                    <ErrorTooltip right="50px" top="12px" message={errors?.stages?.[i]?.stageMode?.message} />
                  )}
                </S.RowContainer>

                <S.RowContainer>
                  <label>Group Size</label>
                  <Counter
                    onChange={e => {
                      clearErrors(`stages.${i}.groupSize`);
                      setValue(`stages.${i}.groupSize`, Number(e.target.value));
                      // if (e.target?.value > 999) {
                      //   setValue("maxPlayers", 999);
                      // } else {
                      //   if (e.target?.value === "") {
                      //     setValue("maxPlayers", 1);
                      //   } else {
                      //     setValue("maxPlayers", Number(e.target.value));
                      //   }
                      // }
                    }}
                    hasError={!!errors?.stages?.[i]?.groupSize}
                    onAdd={() => {
                      clearErrors(`stages.${i}.groupSize`);
                      setValue(
                        `stages.${i}.groupSize`,
                        currentInputValues?.stages?.[i]?.groupSize ? currentInputValues?.stages?.[i]?.groupSize + 1 : 1,
                      );
                    }} //Note (1) left at bottom of file for explanation
                    onSubtract={() => {
                      clearErrors(`stages.${i}.groupSize`);
                      if (currentInputValues?.stages?.[i]?.groupSize > 0) {
                        setValue(`stages.${i}.groupSize`, currentInputValues?.stages?.[i]?.groupSize - 1);
                      }
                    }}
                    value={currentInputValues.stages?.[i]?.groupSize}
                    disabled={
                      currentInputValues.stages?.[i]?.stageMode !== POOL_MODES.LEADERBOARD &&
                      currentInputValues.stages?.[i]?.stageMode !== POOL_MODES.ROUND_ROBIN
                    }
                    // Showing No Groups for LEADERBOARD and ROUND_ROBIN pools with 0 (or undefined) group size
                    altText={
                      [STAGE_MODES.LEADERBOARD, STAGE_MODES.ROUND_ROBIN].includes(
                        currentInputValues?.stages?.[i]?.stageMode,
                      ) &&
                      (currentInputValues.stages?.[i]?.groupSize === 0 || !currentInputValues?.stages?.[i]?.groupSize)
                        ? `No Groups`
                        : ``
                    }
                  />
                  {errors?.stages?.[i]?.groupSize && (
                    <ErrorTooltip right="50px" top="12px" message={errors?.stages?.[i]?.groupSize?.message} />
                  )}
                </S.RowContainer>

                <S.RowContainer>
                  <label>Score Mode</label>
                  <Container
                    flex
                    columnGap="13px"
                    style={!!errors?.stages?.[i]?.bestMatchSelectMode ? { border: "1px solid #ED6140", padding: "2px" } : {}}
                  >
                    <S.JoinModeBtn
                      type="button"
                      active={currentInputValues?.stages?.[i]?.bestMatchSelectMode === "BEST_MATCH"}
                      onClick={() => {
                        clearErrors(`stages.${i}.bestMatchSelectMode`);
                        setValue(`stages.${i}.bestMatchSelectMode`, "BEST_MATCH");
                      }}
                      disabled={
                        currentInputValues.stages?.[i]?.stageMode !== POOL_MODES.LEADERBOARD &&
                        currentInputValues.stages?.[i]?.stageMode !== POOL_MODES.ROUND_ROBIN
                      }
                    >
                      Best Match
                      {!!errors?.stages?.[i]?.bestMatchSelectMode && (
                        <ErrorTooltip message={errors?.stages?.[i]?.bestMatchSelectMode?.message} />
                      )}
                    </S.JoinModeBtn>
                    <S.JoinModeBtn
                      type="button"
                      active={currentInputValues?.stages?.[i]?.bestMatchSelectMode === "SUMMARIZE_MATCH"}
                      onClick={() => {
                        clearErrors(`stages.${i}.bestMatchSelectMode`);
                        setValue(`stages.${i}.bestMatchSelectMode`, "SUMMARIZE_MATCH");
                      }}
                      disabled={
                        currentInputValues.stages?.[i]?.stageMode !== POOL_MODES.LEADERBOARD &&
                        currentInputValues.stages?.[i]?.stageMode !== POOL_MODES.ROUND_ROBIN
                      }
                    >
                      Summarize Matches
                      {!!errors?.stages?.[i]?.bestMatchSelectMode && (
                        <ErrorTooltip message={errors?.stages?.[i]?.bestMatchSelectMode?.message} />
                      )}
                    </S.JoinModeBtn>
                  </Container>
                </S.RowContainer>

                <RulesContainer {...props} stageIndex={i} />
              </Container>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Container>
    </Accordion>
  );
};

export default SecondStep;

// Explanation for  options={rangeArray(i, stageCount - 1)?.map(i => ({ name: i + 2, value: i + 2 }))}
// Consider i is 0 and stageCount is 4.
// Range array will create the following array: [0, 1, 2, 3, 4]
// In the map, we are adding 2 to i because of the following reasons:
// - Our array is zero based, but our values need to start from 1. So, we need to replace 0 with 1, 1 with 2 and so on.This is the first reason to add a 1 in the name and value in map
// For each row, we cannot assign the current stage ID as the Next Stage ID. So the options for each row must not contain the currnt stage ID. For example, for the first row, we should not show stage 1 as an option. So in the map function, we need to add another 1.
// Therefore, we add 2 to both name and value.
// Regarding subtracting 2 from stageCount, this is explained below.
// The rangeArray function is returning the second argument inclusive in the array. Meaning if we pass 0 and 4, we are getting 4 in the array as well. Therefore we habe to first subtract 1.
// After subtracting 1, we get the following array [0,1,2,3]. Since we have to add 2 in the name and value (explained above), this array will give the following map result for the first row (i = 0) [2,3,4,5]. We do not have a 5th stage. So we need to subtract an extra number in the rangeArray. Therefore we use rangeArray(i, stageCount - 2)
// An easier explanation:
// rangeArray for 4 stages will give the array [0,1,2,3,4] (as rangeArray includes the second element in the array)
// For the first row, we only need three values (2,3,4). Therefore we are subtracting 2.
