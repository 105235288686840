import React from "react";
import * as S from "./styles";
import Flex from "../../../common/Flex";
import { GenerateInvitationalCode } from "../GenerateInvitationalCode";
import Container from "../../../common/Container/Container";
import { InvitationalCodeRow } from "../InvitationalCodeRow";

export const InvitationalCode = ({ gameDetails }) => {
  return (
    <Container flex flexDirection="column" rowGap="44px">
      <Flex flexDirection="column" rowGap="29px">
        <S.PageTitle>Invitational code</S.PageTitle>
        <GenerateInvitationalCode invitationalCodes={gameDetails?.invitationalCodes} />
      </Flex>
      <S.InvitationalCodeTable>
        {gameDetails?.invitationalCodes?.map(codeData => {
          return <InvitationalCodeRow data={codeData} />;
        })}
      </S.InvitationalCodeTable>
    </Container>
  );
};
