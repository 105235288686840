import React, { useRef } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { editTournamentRule, removeTournamentRule } from "../../../../../../api/pool";
import { useGetSecHeaders } from "../../../../../../hooks/useHeaders";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Modal from "../../../../../../components/common/Modal/Modal";
import * as S from "./style";
import Flex from "../../../../../../components/common/Flex";
import ReactQuill from "react-quill";
import { ButtonRound } from "../../../../../../components/common/Button";
import { Trash } from "../../../../../../assets/svg";
import Container from "../../../../../../components/common/Container/Container";
import Text from "../../../../../../components/common/Text/Text";
import { notifyError, notifySuccess } from "../../../../../../components/common/ToastComponent";

export const EditRuleModal = ({
  showModal,
  toggleModal,
  organizationId,
  poolRulesArray,
  setPoolRulesArray,
  ruleTitle = "",
  setRuleTitle,
  ruleContent = "",
  setRuleContent,
  ruleSortOrder = poolRulesArray?.length,
  setRuleSortOrder,
  ruleId = "",
}) => {
  const quill = useRef();
  const { poolId } = useParams();
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();

  const { mutate: editRuleMutation, isLoading: editRuleIsLoading } = useMutation({
    mutationFn: variables => {
      return editTournamentRule(
        {
          organizationId: variables.organizationId,
          poolId: variables.poolId,
          id: variables.id,
          title: variables.title,
          content: variables.content,
          sortOrder: variables.sortOrder,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: e => {
      notifySuccess(e?.message || e?.data);
      queryClient.invalidateQueries({ queryKey: ["pool", poolId] });
      setPoolRulesArray(e?.data?.sort((a, b) => a.sortOrder - b.sortOrder));
      toggleModal({ trigger: "edit" });
    },
  });

  const { mutate: removeRuleMutation, isLoading: removeRuleIsLoading } = useMutation({
    mutationFn: variables => {
      return removeTournamentRule(
        { organizationId: variables.organizationId, poolId: variables.poolId, id: variables.id },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: e => {
      notifySuccess(e?.message || e?.data);
      queryClient.invalidateQueries({ queryKey: ["pool", poolId] });
      setPoolRulesArray(e?.data);
      toggleModal({ trigger: "delete" });
    },
  });
  return (
    <Modal showModal={showModal} toggleModal={toggleModal}>
      <S.ModalWrapper>
        <S.ModalTitle>Edit rule</S.ModalTitle>
        <Flex flexDirection="column" rowGap="17px">
          <Flex columnGap="13px">
            <Flex flexDirection="column" rowGap="5px" style={{ width: "140px" }}>
              <S.Description>Rule number</S.Description>
              <S.StyledDropdown
                options={poolRulesArray.map(rule => ({
                  value: rule.sortOrder,
                  name: rule.sortOrder,
                  element: (
                    <Container flex columnGap="10px">
                      <Text>{rule.sortOrder}</Text>
                    </Container>
                  ),
                }))}
                onChange={e => setRuleSortOrder(e)}
                value={ruleSortOrder}
              />
            </Flex>
            <Flex flexDirection="column" rowGap="5px">
              <S.Description>Title</S.Description>
              <S.Input
                placeholder="Title"
                value={ruleTitle}
                onChange={e => {
                  setRuleTitle(e.target.value);
                }}
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" rowGap="5px" style={{ width: "100%" }}>
            <S.Description>Description</S.Description>
            <ReactQuill
              className="ql-custom"
              ref={quill}
              value={ruleContent}
              onChange={content => {
                setRuleContent(content);
              }}
            />
          </Flex>
          <Flex justifyContent="flex-end" columnGap="10px">
            <S.RoundedButtonOutline
              onClick={() => removeRuleMutation({ organizationId: organizationId, poolId: poolId, id: ruleId })}
              disabled={removeRuleIsLoading || editRuleIsLoading}
            >
              <Trash /> Delete Rule
            </S.RoundedButtonOutline>
            <ButtonRound
              onClick={() =>
                editRuleMutation({
                  organizationId: organizationId,
                  poolId: poolId,
                  id: ruleId,
                  title: ruleTitle,
                  content: ruleContent,
                  sortOrder: ruleSortOrder,
                })
              }
              disabled={editRuleIsLoading || removeRuleIsLoading}
            >
              Save
            </ButtonRound>
          </Flex>
        </Flex>
      </S.ModalWrapper>
    </Modal>
  );
};
