import { POOL_TYPES } from "../constants";
import axiosClient from "./axiosClient";

// TODO: Refactor the funciton below so that organizationid and filters are coming in the same object. This is the standard we are using for other functions
export function getPoolsByOrgId(organizationId, headers, filters) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .get(`/admPanel/pool/list/byOrg/${organizationId}?${new URLSearchParams(filters).toString()}`, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function getPoolById({ poolId, orgId }, headers) {
  if (!poolId) throw new Error("Please enter a valid pool id");
  if (!orgId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .get(
      `/admPanel/pool/info/${orgId}/${poolId}`,

      { headers: { Authorization: headers?.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function getPlayersFromPoolById({ poolId, organizationId }, headers) {
  if (!poolId) throw new Error("Please enter a valid pool id");
  if (!organizationId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .get(
      `/admPanel/pool/players/${organizationId}/${poolId}`,

      { headers: { Authorization: headers?.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function togglePoolVisibility({ organizationId, poolId, status = "enabled" }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  return axiosClient
    .put(
      `/admPanel/pool/setVisibility/${organizationId}/${poolId}/${status}`,
      {},
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function executePoolAction({ organizationId, poolId, action, data }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  return axiosClient
    .put(
      `/admPanel/pool/execPoolAction/${organizationId}/${poolId}`,
      { action, data },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => {
      if (res.data?.error) throw new Error(res.data?.message);
      return res.data;
    })
    .catch(err => {
      // API response  will get err.response. But in case we throw an error above in the then block, we will not get err.response, hence we are using this OR block
      throw err.response || err;
    });
}

export function getPoolPlayerAndMatches(data, headers) {
  const {
    organizationId,
    poolId,
    pageId = 1,
    branchId = 1,
    roundId = 0,
    stageId = 1,
    groupId = 0,
    onlyAddStageId = false, // This argument will be passed in when we need all players of a stage
  } = data;
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  let urlString = ``;
  if (onlyAddStageId) {
    urlString = `/admPanel/pool/players/${organizationId}/${poolId}?stageId=${stageId}`;
  } else {
    urlString = `/admPanel/pool/players/${organizationId}/${poolId}?pageId=${pageId}&branchId=${branchId}&roundId=${roundId}&stageId=${stageId}&groupId=${groupId}`;
  }
  return axiosClient
    .get(urlString, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function reportMatch(data, headers) {
  const { organizationId, poolId, userId, reports, stageId } = data;
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  return axiosClient
    .put(
      `/admPanel/pool/execPoolAction/${organizationId}/${poolId}`,
      {
        action: "report_match",
        data: {
          userId,
          reports,
          ...(stageId && { stageId }),
        },
      },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function updateReportedMatch(data, headers) {
  const { organizationId, poolId, report, playerId } = data;
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  return axiosClient
    .put(
      `/admPanel/pool/execPoolAction/${organizationId}/${poolId}`,
      {
        action: "update_match",
        data: {
          // Bracket Pool will not need player id
          report: { ...report, playerId: playerId },
        },
      },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function deleteReportedMatch(data, headers) {
  const { organizationId, poolId, report } = data;
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  return axiosClient
    .put(
      `/admPanel/pool/execPoolAction/${organizationId}/${poolId}`,
      {
        action: "delete_match",
        data: {
          poolId,
          report,
        },
      },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function movePlayerRound(data, headers) {
  const { organizationId, poolId, pageId, stageId, branchId, roundId, matchId, winnerPosition } = data;
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  return axiosClient
    .put(
      `/admPanel/pool/execPoolAction/${organizationId}/${poolId}`,
      {
        action: "set_match_winner",
        data: {
          pageId,
          stageId,
          branchId,
          roundId,
          matchId: Number(matchId),
          winnerPosition,
        },
      },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function reactivatePool(data, headers) {
  const { organizationId, poolId, endDate } = data;
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  return axiosClient
    .put(
      `/admPanel/pool/execPoolAction/${organizationId}/${poolId}`,
      {
        action: "re_activate_pool",
        data: {
          endDate,
        },
      },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function createTournament({ organizationId, poolData }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .post(`/admPanel/pool/createPool/${organizationId}`, poolData, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function approveTournament({ decision, reason, organizationId, poolId }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  return axiosClient
    .put(
      `/admPanel/pool/approveDeny/${organizationId}/${poolId}`,
      { decision, reason },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function addTournamentRule({ organizationId, poolId, title = "", content = "" }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  return axiosClient
    .post(
      `/admPanel/pool/rules/${organizationId}/${poolId}`,
      {
        rule: {
          title: title,
          content: content,
        },
      },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function editTournamentRule(
  { organizationId, poolId, id = "", title = "", content = "", sortOrder = 1 },
  headers,
) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  return axiosClient
    .put(
      `/admPanel/pool/rules/${organizationId}/${poolId}`,
      {
        rule: {
          id: id,
          title: title,
          content: content,
          sortOrder: sortOrder,
        },
      },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function removeTournamentRule({ organizationId, poolId, id = "" }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  return axiosClient
    .delete(`/admPanel/pool/rules/${organizationId}/${poolId}`, {
      headers: { Authorization: headers.encryptedHeader },
      data: {
        rule: {
          id,
        },
      },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function sendMessage({ message, organizationId, poolId }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  return axiosClient
    .post(
      `/admPanel/pool/adminMsg/${organizationId}/${poolId}`,
      { message },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function notifyResolve({ organizationId, poolId }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  return axiosClient
    .post(
      `/admPanel/pool/chgsDone/${organizationId}/${poolId}`,
      {},
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function editTournamentOrganization({ userOrganizationId, assignedOrganizationId, poolId }, headers) {
  if (!userOrganizationId || !assignedOrganizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  return axiosClient
    .put(
      `/admPanel/pool/edit/${userOrganizationId}/${poolId}`,
      { organizationId: assignedOrganizationId },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function getPoolTemplates({ poolId, orgId }, headers) {
  if (!poolId) throw new Error("Please enter a valid pool id");
  if (!orgId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .get(
      `/admPanel/pool/templates/${orgId}/${poolId}`,

      { headers: { Authorization: headers?.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function updatePoolTemplate({ poolId, orgId, templateType, templateId }, headers) {
  if (!poolId) throw new Error("Please enter a valid pool id");
  if (!templateId) throw new Error("Please provide a valid template id");
  if (!templateType) throw new Error("Please provide a valid template type");
  return axiosClient
    .put(
      `/admPanel/pool/templates/${orgId}/${poolId}/${templateType}/${templateId}`,
      {},
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function transferPlayers({ organizationId, poolId, poolIdToMove, playerList = [], removeOriginData }, headers) {
  if (!poolId) throw new Error("Please enter a valid pool id");
  if (!organizationId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .put(
      `/admPanel/pool/transferPlayers/${organizationId}/${poolId}`,
      {
        poolIdTo: poolIdToMove,
        poolsToMove: [
          {
            poolIdFrom: poolId,
            players: playerList,
          },
        ],
        removeOriginData: removeOriginData,
      },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}
