import React, { useContext, useState } from "react";
import * as S from "../../styles";
import { isNonEmptyObject } from "../../../../utilities";
import Container from "../../../../components/common/Container/Container";
import { Dropdown } from "../../../../components/common/Dropdown";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../../../components/layout/Loader/Loader";
import { getStageLeaderboard } from "../../../../api/stage";
import { useQuery } from "@tanstack/react-query";
import { useLogin } from "../../../../providers/Loginprovider";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import LeaderboardRow from "../LeaderboardRow";
import Text from "../../../../components/common/Text/Text";
import LeaderboardComponent from "../LeaderboardComponent";
import { TournamentContext } from "../../../../providers/TournamentContext";
import GroupMoveModal from "../../../../components/views/tournamentDetail/GroupMoveModal/GroupMoveModal";
import GroupSwapModal from "../../../../components/views/tournamentDetail/GroupSwapModal/GroupSwapModal";

const LeaderboardSection = props => {
  const { selectedStage = {}, stageInfo = {}, isLoadingStageInfo, poolId, isMultiStagePool, isRoundRobin, poolData = {} } = props;
  const { selectedGroupId, setSelectedGroupId } = useContext(TournamentContext);
  const headers = useGetSecHeaders();
  const { orgIdState } = useLogin();
  const {
    data: { data: stageLeaderboardData } = {},
    isLoadingStageLeaderboardData,
    isFetchingStageLeaderboardData,
    isError,
  } = useQuery({
    queryKey: ["stage_leaderboard", orgIdState, Number(poolId), selectedStage.stageId, selectedGroupId],
    queryFn: () =>
      getStageLeaderboard(
        {
          poolId,
          orgId: orgIdState,
          stageId: selectedStage.stageId,
          groupId: selectedGroupId,
        },
        headers,
      ),
    enabled: !!headers?.encryptedHeader && !!orgIdState && !!selectedStage.stageId,
    refetchOnWindowFocus: false,
  });

  const [playersToMoveGroup, setPlayersToMoveGroup] = useState([]);
  const [playerToSwapGroup, setPlayerToSwapGroup] = useState(null);

  if (isMultiStagePool) {
    if (isLoadingStageInfo) {
      return (
        <SpinnerLoaderWrapper>
          <SpinnerLoader />
        </SpinnerLoaderWrapper>
      );
    }

    if (!isNonEmptyObject(stageInfo)) {
      return (
        <Container flex justifyContent="center" alignItems="center">
          Something went wrong.
        </Container>
      );
    }
  }

  const displayData = isMultiStagePool ? stageInfo?.displayData : poolData.displayData;
  const leaderboardData = isMultiStagePool ? stageLeaderboardData : poolData?.leaderboard;

  return (
    <div>
      {stageInfo.groups && Array.isArray(stageInfo.groups) && stageInfo.groups.length && (
        <div>
          <div>Groups</div>
          <Container width="200px" marginTop="18px">
            <Dropdown
              options={stageInfo.groups?.map(group => ({ name: group.name, value: group.id }))}
              onChange={e => setSelectedGroupId(e)}
            />
          </Container>
        </div>

        // Leaderboard section
      )}
      {isError ? (
        <Container flex justifyContent="center" alignItems="center" height="100px">
          Error occured while getting group stage data
        </Container>
      ) : (isLoadingStageLeaderboardData || isFetchingStageLeaderboardData) && isMultiStagePool ? (
        <SpinnerLoaderWrapper>
          <SpinnerLoader />
        </SpinnerLoaderWrapper>
      ) : (
        <LeaderboardComponent
          displayData={displayData}
          leaderboardData={leaderboardData}
          joinMode={poolData.joinMode}
          isRoundRobin={isRoundRobin}
          setPlayersToMoveGroup={setPlayersToMoveGroup}
          setPlayerToSwapGroup={setPlayerToSwapGroup}
        />
      )}

      <GroupMoveModal
        stageId={selectedStage?.stageId}
        poolData={poolData}
        playersToMoveGroup={playersToMoveGroup}
        setPlayersToMoveGroup={setPlayersToMoveGroup}
      />
      <GroupSwapModal
        stageId={selectedStage?.stageId}
        poolData={poolData}
        playerToSwapGroup={playerToSwapGroup}
        setPlayerToSwapGroup={setPlayerToSwapGroup}
      />
    </div>
  );
};

export default LeaderboardSection;
