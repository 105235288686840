import React, { useState } from "react";
import * as S from "./styles";
import { CloseIcon, DemoVideo } from "../../../../assets/svg";
import { Button } from "../../../common/Button";

export const Banner = ({ displayBanner, toggleBanner }) => {
  return (
    <S.MainContainer displayBanner={displayBanner}>
      <S.InnerContainer>
        <S.Title>Take a look at this demo </S.Title>
        <S.Subtitle>
          We'll take you through the steps, different features and possibilities to create, manage and host your online
          tournaments.
        </S.Subtitle>
        {/* //TODO: Add the URL of the demo */}
        <Button
          width="195px"
          height="47px"
          borderRadius="30px"
          background="#ffffff"
          color="#201366"
          fontSize="16px"
          fontWeight="350"
        >
          Go To The Demo
        </Button>
      </S.InnerContainer>
      <S.DemoVideoWrapper>
        <DemoVideo />
      </S.DemoVideoWrapper>
      <S.CloseIconWrapper>
        <S.CloseButton onClick={toggleBanner}>
          <CloseIcon />
        </S.CloseButton>
      </S.CloseIconWrapper>
    </S.MainContainer>
  );
};
