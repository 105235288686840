import React, { useEffect, useRef } from "react";

import * as S from "../../styles";
import Container from "../../../../common/Container/Container";
import { Dropdown } from "../../../../common/Dropdown";
import ErrorTooltip from "../../../../common/ErrorTooltip";
import Counter from "../../../../common/Counter";
import { Camera, MaticWhite, ProfileIcon, TeamIcon, USDTWhite } from "../../../../../assets/svg";
import Text from "../../../../common/Text/Text";
import PoolThumbnail from "../../subcomponents/PoolThumbnail";
import PoolBanner from "../../subcomponents/PoolBanner";
import { assetUpload, getOrganizationGameAssets } from "../../../../../api/files";
import { useGetSecHeaders } from "../../../../../hooks/useHeaders";
import { useLogin } from "../../../../../providers/Loginprovider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { notifyError } from "../../../../common/ToastComponent";
import LoadingSkeleton from "../../../../common/LoadingSkeleton";
import { SpinnerLoader } from "../../../../common/SpinningLoader";
import FourthStep from "../../regularPoolForm/Steps/FourthStep";

const PLACEHOLDER_THUMBNAILS = [
  { id: 1, url: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/axie/origin/axie_inf_ori.png" },
  {
    id: 2,
    url: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/tekken_8/tekken8-thumbnail1.webp",
  },
  {
    id: 3,
    url: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/clash_royale/noxus/noxus-clash.png",
  },
  {
    id: 4,
    url: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/league_of_legends/lol_pool_thumbnail.jpg",
  },
  {
    id: 5,
    url: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/rocket-league/rl-thumbnail-1.png",
  },
];

const BANNER_THUMBNAILS = [
  {
    id: 1,
    bannerBgBack: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/axie/origin/poolDetailBG.png",
    bannerBgFront:
      "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/axie/origin/poolDetailFront.png",
  },
  {
    id: 2,
    bannerBgBack:
      "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/clash_royale/clashroyale-banner-tournament-3.jpg",
    bannerBgFront:
      "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/clash_royale/clashroyale-banner-3.png",
  },
  {
    id: 3,
    bannerBgBack:
      "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/yu-gi-oh/yu-gi-oh-banner-tournament.jpg",
    bannerBgFront:
      "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/yu-gi-oh/banner-front-yugioh-compressed.png",
  },
  {
    id: 4,
    bannerBgBack:
      "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/fifa/fifa-banner-tournament-3.jpg",
    bannerBgFront: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/organizations/Eryz/PNG%20(1).png",
  },
];

const FifthStep = ({ register, errors = {}, setValue, currentInputValues = {} }) => {
  return <FourthStep {...{ register, errors, setValue, currentInputValues }} />;
};

export default FifthStep;

// Dummy Banner BG
// https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/axie/origin/poolDetailBG.png

// Dummy Banner Front
// https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/axie/origin/poolDetailFront.png

// Dummy Thumbnail
// https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/axie/origin/axie_inf_ori.png
