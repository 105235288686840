import React, { useState } from "react";
import * as S from "./styles";
import Flex from "../../../common/Flex";
import { Copy, Trash } from "../../../../assets/svg";
import { copyTextToClipboard } from "../../../../utilities";
import { notifyError, notifySuccess } from "../../../common/ToastComponent";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { useLogin } from "../../../../providers/Loginprovider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { removeInvitationalCode } from "../../../../api/invitationalCodes";
import { DeleteCodesConfirmationModal } from "../DeleteCodesConfirmationModal";

export const InvitationalCodeRow = ({ data }) => {
  const headers = useGetSecHeaders();
  const { poolId } = useParams();
  const { orgIdState } = useLogin();
  const queryClient = useQueryClient();

  const codeToBeDeleted = [data.code];

  const [showDeleteCodeModal, setShowDeleteCodeModal] = useState(false);

  const toggleDeleteCodeModal = () => {
    setShowDeleteCodeModal(!showDeleteCodeModal);
  };

  const { mutate: mutateDeleteInvitationalCode, isLoading: mutateDeleteInvCodeLoading } = useMutation({
    mutationFn: variables => {
      return removeInvitationalCode(
        {
          organizationId: variables.organizationId,
          poolId: variables.poolId,
          invitacionalCodesArray: variables.invitationalCode,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: e => {
      toggleDeleteCodeModal();
      notifySuccess(e?.message || e?.data);
      queryClient.invalidateQueries({
        queryKey: ["pool", poolId],
      });
    },
  });

  return (
    <S.InvitationalCodeRowWrapper>
      <Flex justifyContent="space-between" alignItems="center" style={{ width: "100%" }}>
        <div>
          <S.DataText>{data.code}</S.DataText>
        </div>
        <Flex columnGap="19px" alignItems="center">
          <S.StatusWrapper>
            <S.StatusDot isActive={data.active ? true : false} />
            <S.StatusText>{data.active ? "Active" : "Inactive"}</S.StatusText>
          </S.StatusWrapper>
          <Flex alignItems="center">
            <S.ActionButton onClick={() => copyTextToClipboard(data.code, "Code copied successfully to clipboard")}>
              <Copy />
            </S.ActionButton>
            <S.ActionButton onClick={toggleDeleteCodeModal}>
              <Trash />
            </S.ActionButton>
          </Flex>
        </Flex>
      </Flex>
      <DeleteCodesConfirmationModal
        showModal={showDeleteCodeModal}
        toggleModal={toggleDeleteCodeModal}
        mutateDeleteInvitationalCode={mutateDeleteInvitationalCode}
        mutateDeleteInvCodeLoading={mutateDeleteInvCodeLoading}
        codesArray={codeToBeDeleted}
        organizationId={orgIdState}
        poolId={poolId}
        singleCode
      />
    </S.InvitationalCodeRowWrapper>
  );
};
