import React from "react";
import { createRoot } from 'react-dom/client'
import "./index.css";
import "./calendar.css";
import "./quill-editor.css";
import "./accordion.css";
import App from "./App";

const mainContainer = document.getElementById("root");
const root = createRoot(mainContainer);
root.render(<App />);
