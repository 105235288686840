import React from "react";
import Avatar from "../../../../common/Avatar";
import Container from "../../../../common/Container/Container";
import { SpinnerLoader } from "../../../../common/SpinningLoader";
import * as S from "./styles";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notifyError, notifySuccess } from "../../../../common/ToastComponent";
import { addMemberToTeam } from "../../../../../api/teams";

const AddTeamMemberRow = ({ data, teamId, headers, queryClient }) => {
  const { mutate: addMemberToTeamMutation, isLoading: isLoadingAddingMemberToTeam } = useMutation({
    mutationFn: variables => {
      return addMemberToTeam({ teamId, userId: variables.userId }, headers);
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: e => {
      if (e.error) {
        let errString = "";
        if (e.message && typeof e.message === "string") errString = e.message;
        else errString = "Something went wrong";

        throw new Error(errString);
      }
      queryClient.invalidateQueries({ queryKey: ["team", teamId] });
      notifySuccess("Member has been added to team succesfully");
    },
  });

  return (
    <Container flex alignItems="center" justifyContent="space-between">
      <Container flex alignItems="center" columnGap="11px">
        <Avatar src={data?.thumbnail} />
        <span>{data.nick}</span>
      </Container>
      <S.AddMemberButton
        disabled={isLoadingAddingMemberToTeam}
        onClick={() =>
          addMemberToTeamMutation({
            userId: data.id,
          })
        }
      >
        {isLoadingAddingMemberToTeam ? <SpinnerLoader /> : "Add"}
      </S.AddMemberButton>
    </Container>
  );
};

export default AddTeamMemberRow;
