import { useState, useEffect } from "react";
// import * as ethers from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import { useWallet } from "../providers/WalletProvider";

export function useNetworkListener() {
  const [chainId, setChainId] = useState();
  const { injectedProvider } = useWallet();

  useEffect(() => {
    const { ethereum } = window;

    if (ethereum && ethereum.on) {
      const handleChainChanged = id => {
        setChainId(parseInt(id, 16));
      };

      const handleAccountsChanged = accounts => {
        if (accounts.length > 0) {
        }
      };

      // const handleNetworkChanged = networkId => {
      //   console.log('HEREEEEE 22222')
      //   window.location.reload();
      // };

      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
      // ethereum.on("networkChanged", handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
          // ethereum.removeListener("networkChanged", handleNetworkChanged);
        }
      };
    }
  }, []);

  useEffect(() => {
    const checkDefaultEthereumProvider = async () => {
      const provider = await detectEthereumProvider();

      if (provider) {
        // console.log("Ethereum successfully detected!");

        // From now on, this should always be true:
        // provider === window.ethereum

        // Access the decentralized web!

        // Legacy providers may only have ethereum.sendAsync
        // Here chain_id is hexadecimal
        const id = await provider.request({
          method: "eth_chainId",
        });

        setChainId(parseInt(id.substring(2), 16));
      } else {
        // if the provider is not detected, detectEthereumProvider resolves to null
        console.error("Please install MetaMask!");
      }
    };

    if (injectedProvider) {
      injectedProvider.getNetwork().then(network => {
        // here chainId is decimal
        setChainId(network.chainId);
      });
    } else {
      checkDefaultEthereumProvider();
    }
  }, [injectedProvider]);

  return { chainId };
}
