import { useEffect, useState } from "react";

import * as S from "./styles";
import { CloseBlue, CloseIcon, Search } from "../../../../assets/svg";
import { IconButton } from "../../../common/Button";

const SearchBar = ({ filterProperties, setFilterProperties }) => {
  const [userInput, setUserInput] = useState("");

  function search() {
    let temp = { ...filterProperties };

    if (userInput) {
      temp["name"] = userInput;
    } else {
      delete temp["name"];
    }

    setFilterProperties(temp);
  }

  function clear() {
    let temp = { ...filterProperties };
    delete temp["name"];
    setUserInput("");
    setFilterProperties(temp);
  }

  return (
    <S.InputContainer>
      <S.Input placeholder="Search" value={userInput} onChange={e => setUserInput(e.target?.value)} />

      {filterProperties["name"] && (
        <IconButton onClick={clear}>
          <CloseBlue />
        </IconButton>
      )}

      <IconButton onClick={search}>
        <Search />
      </IconButton>
    </S.InputContainer>
  );
};

export default SearchBar;
