import React from "react";
import FirstPreview from "./previewScreens/FirstPreview/";
import SecondPreview from "./previewScreens/SecondPreview";
import ThirdPreview from "./previewScreens/ThirdPreview";
import FourthPreview from "./previewScreens/FourthPreview";

const Preview = ({ previewData = {}, currentStep = 0 }) => {
  return (
    <>
      {currentStep === 0 && <FirstPreview previewData={previewData} />}
      {currentStep === 1 && <SecondPreview previewData={previewData} />}
      {currentStep === 2 && <ThirdPreview previewData={previewData} />}
      {currentStep === 3 && <FourthPreview previewData={previewData} />}
    </>
  );
};

export default Preview;
