import React from "react";

import * as S from "./styles";
import Container from "../../../common/Container/Container";
import { BackArrow, CaretArrow, GreenCheck, LeftArrow, PurpleCaret } from "../../../../assets/svg";

// const steps = ["General Info", "Dates", "Details", "Styles", "Overview"];
const NavigationBar = ({ currentStep = 0, steps = [], containerStyle = {} }) => {
  return (
    <S.NavContainer style={{ ...containerStyle }}>
      {steps.map((step, index) => (
        <>
          <S.NavigationBox passed={currentStep > index} active={index === currentStep}>
            <span>{currentStep > index ? <GreenCheck /> : index + 1}</span>
            <div>{step?.name || step}</div>
          </S.NavigationBox>
          {index < steps.length - 1 && <PurpleCaret />}
        </>
      ))}
    </S.NavContainer>
  );
};

export default NavigationBar;
