import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSession } from "../../providers/SessionProvider";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../common/SpinningLoader";
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { session } = useSession();
  const [pausePeriodActive, setPausePeriodActive] = useState(true);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setPausePeriodActive(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  if (pausePeriodActive) {
    return (
      <SpinnerLoaderWrapper style={{height: "50vh"}}>
        <SpinnerLoader />
      </SpinnerLoaderWrapper>
    );
  }
  return (
    <Route
      {...rest}
      render={props => (session && session.access_token ? <Component {...props} /> : <Redirect to="/signin" />)}
    />
  );
};
export default PrivateRoute;
