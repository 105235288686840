import styled from "styled-components";
import Text from "../../../../common/Text/Text";

export const Container = styled.div`
  height: 50px;
  display: grid;
  padding-left: 21px;
  border-radius: 6px;
  align-items: center;
  background-color: #3f2faa;
  justify-items: flex-start;
  grid-template-columns: 2fr 1fr 2fr;
  min-width: 700px;
`;

export const StatusContainer = styled.div`
  display: flex;
  width: 54px;
  height: 23px;
  padding: 7px 10px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #4e3cca;
`;

export const StatusText = styled(Text)`
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;
