import { useContext, useEffect, useRef, useState } from "react";
import * as S from "./styles";
import { CaretArrow, Swap } from "../../../../assets/svg";
import useOnClickOutside from "../../../../hooks/useClickOutside";
import Flex from "../../../common/Flex";
import Text from "../../../common/Text/Text";
import { TournamentContext } from "../../../../providers/TournamentContext";

const GroupSelect = ({ fromGroupId, groupToMovePlayersTo, setGroupToMovePlayersTo }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Input state and functions
  const inputRef = useRef(null);

  const { stageInfo } = useContext(TournamentContext);

  // To autofocus the search input when dropdown opens
  useEffect(() => {
    if (open) inputRef?.current?.focus();
  }, [open]);

  return (
    <S.SelectContainer ref={ref}>
      <S.SelectLabelButton onClick={handleOpen}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" columnGap="10px">
            <Swap style={{ height: "34px" }} />
            <Text fontSize="16px">
              {groupToMovePlayersTo?.id ? groupToMovePlayersTo.name : "Select the group you want to move"}
            </Text>
          </Flex>

          <S.CaretContainer open={open}>
            <CaretArrow />
          </S.CaretContainer>
        </Flex>
      </S.SelectLabelButton>
      <S.DropdownStyle isVisible={open}>
        {/* <S.SearchContainer onClick={() => inputRef?.current?.focus()}>
          <Flex alignItems="center" columnGap="10px">
            <MaginfyingGlass />
            <S.SearchInputDivier />
          </Flex>
        </S.SearchContainer> */}
        {/* <S.SelectFiltersContainer hide={!branchOptions?.length}>
          {branchOptions.map(option => (
            <S.FilterButton active={selectedBranch === option.value} onClick={() => setSelectedBranch(option.value)}>
              Branch {option.value}
            </S.FilterButton>
          ))}
        </S.SelectFiltersContainer> */}

        <S.ListContainer>
          {stageInfo?.groups?.map(group => (
            <S.DropdownItem
              disableStyles={group.id == fromGroupId}
              onClick={() => {
                if (group.id == fromGroupId) return;
                setGroupToMovePlayersTo(group);
                handleClose();
              }}
              key={group.id}
            >
              <Flex alignItems="center" columnGap="15px">
                <span>{group.name}</span>
              </Flex>
            </S.DropdownItem>
          ))}
        </S.ListContainer>
      </S.DropdownStyle>
    </S.SelectContainer>
  );
};

export default GroupSelect;
