import React, { useEffect, useState } from "react";
import * as S from "./styles";
import Modal from "../../../common/Modal/Modal";
import { Button } from "../../../common/Button";
import Container from "../../../common/Container/Container";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notifyError, notifySuccess } from "../../../common/ToastComponent";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { useLogin } from "../../../../providers/Loginprovider";
import { movePlayersToOtherGroups } from "../../../../api/group";
import GroupMovePlayerSelect from "./GroupMovePlayerSelect";
import GroupSelect from "./GroupSelect";

const GroupMoveModal = ({ poolData, playersToMoveGroup = [], setPlayersToMoveGroup, stageId }) => {
  const [groupToMovePlayersTo, setGroupToMovePlayersTo] = useState(null);
  const [open, setOpen] = useState(false);

  const headers = useGetSecHeaders();
  const { orgIdState } = useLogin();

  const queryClient = useQueryClient();

  const toggleModal = () => setOpen(!open);

  const { mutate: movePlayersToOtherGroupsMutation, isLoading: mutateMoveLoading } = useMutation({
    mutationFn: args => {
      return movePlayersToOtherGroups(
        {
          ...args,
          organizationId: orgIdState,
          poolId: poolData.id,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: (e, variables) => {
      notifySuccess("Move successful");
      setPlayersToMoveGroup([]);
      setGroupToMovePlayersTo([]);
      setOpen(false);
      queryClient.invalidateQueries({
        queryKey: ["stage_leaderboard", orgIdState, Number(poolData?.id), variables?.stageId, variables?.fromGroupId],
      });
    },
  });

  useEffect(() => {
    // Open the modal if user selects a player (or team) to move stages (in the players / teams section)
    // Only run this useEffect if modal is not opened. We do not need to run it if modal is already open, or close it if user unselects all players
    if (!open && playersToMoveGroup.length) setOpen(true);
  }, [playersToMoveGroup]);
  return (
    <Modal
      showModal={open}
      customStyles={{ width: "516px", height: "481px", borderRadius: "6px", overflowY: "visible", top: "40%" }}
      mobileStyles={{ width: "90%" }}
      toggleModal={toggleModal}
      beforeClose={() => {
        setPlayersToMoveGroup([]);
        setGroupToMovePlayersTo(null);
      }}
    >
      <Container flex flexDirection="column" justifyContent="space-between" height="85%">
        <div>
          <S.Header>Move Player</S.Header>

          <GroupMovePlayerSelect {...{ poolData, playersToMoveGroup, setPlayersToMoveGroup }} />
          <GroupSelect
            fromGroupId={playersToMoveGroup?.[0]?.groupId}
            groupToMovePlayersTo={groupToMovePlayersTo}
            setGroupToMovePlayersTo={setGroupToMovePlayersTo}
          />
        </div>

        <Container width="100%" flex justifyContent="center">
          {playersToMoveGroup?.length && groupToMovePlayersTo ? (
            <Button
              width="183px"
              height="42px"
              borderRadius="80px"
              disabled={mutateMoveLoading}
              onClick={() => {
                movePlayersToOtherGroupsMutation({
                  stageId: stageId,
                  fromGroupId: playersToMoveGroup?.[0]?.groupId,
                  toGroupId: groupToMovePlayersTo?.id,
                  playersId: playersToMoveGroup,
                  playersId: playersToMoveGroup?.map(player => player.aTag || player.playerId),
                });
              }}
            >
              Move
            </Button>
          ) : null}
        </Container>
      </Container>
    </Modal>
  );
};

export default GroupMoveModal;
