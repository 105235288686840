export const LINKING_WALLET = "linking_wallet";
export const SOCIAL = "SOCIAL";
export const METAMASK_DEFAULT_CHAINS_ID = [1, 3, 4, 5, 42, 1337];
export const GAME_ID_MAP = {
  2: "Call Of Duty",
  3: "Pubg",
  5: "Axie Infinity",
  6: "Koins",
  7: "Ephere",
  8: "Valorant",
  9: "League of Legends",
  10: "CS:GO",
  11: "Rocket League",
  12: "Pro Evolution Soccer",
  14: "Dota 2",
  15: "Clash Royale",
  16: "Free Fire",
  17: "FIFA",
  18: "COD Mobile",
  19: "Yu-Gi-Oh!",
};
export const POOL_TYPES = {
  LEADERBOARD: "LEADERBOARD",
  BRACKETS: "BRACKETS",
  ROUND_ROBIN: "ROUND_ROBIN",
  MULTI_STAGE: "MULTI_STAGE",
};

export const NO_SHOW_PLAYER = "NO_SHOW_PLAYER";

export const STAGE_MODES = {
  BEST_OF_3: "BEST_OF_3",
  LEADERBOARD: "LEADERBOARD",
  ROUND_ROBIN: "ROUND_ROBIN",
}
export const STAGE_TYPES = {
  BRACKETS: "BRACKETS",
  LEADERBOARD: "LEADERBOARD",
}
export const POOL_MODES = {
  BEST_OF_3: "BEST_OF_3",
  BEST_OF_5: "BEST_OF_5",
  BEST_OF_7: "BEST_OF_7",
  LEADERBOARD: "LEADERBOARD",
  ROUND_ROBIN: "ROUND_ROBIN",
  SINGLE_ELIMINATION: "SINGLE_ELIMINATION",
  MULTI_STAGE: "MULTI_STAGE"
};

export const USER_TYPES = {
  SUPERUSER: "SUPERUSER"
}

export const POOL_TEMPLATE_NAME_MAP = {
  detailTemplates: "Detail template",
  mainTemplates: "Main template",
  reportTemplates: "Report template"
}

export const POOL_TEMPLATE_KEY_MAP = {
  detailTemplates: "details",
  mainTemplates: "main",
  reportTemplates: "report"
}