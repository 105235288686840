import React from "react";
import styled, { keyframes } from "styled-components";

export const InputOutlineStyled = styled.input`
  background-color: transparent;
  height: ${props => (props.height ? props.height : "40px")};
  width: ${props => (props.width ? props.width : "100%")};
  border-radius: 8px;
  border: 1px solid #cccccc66;
  padding: 0px 8px;
  color: #cccccc;
  &:focus {
    border: 1px solid #cccccc;
    outline: none;
  }
  ${props => ({ ...props.styles })};
`;

export const InputStyled = styled.input`
  background-color: #3f2faa;
  height: ${props => (props.height ? props.height : "40px")};
  width: ${props => (props.width ? props.width : "100%")};
  border-radius: 8px;
  border: none;
  padding: 0px 8px;
  color: #cccccc;

  &:focus {
    border: 1px solid #cccccc66;
    outline: none;
  }
  &::placeholder {
    color: #7265C8;
  }
  ${props => ({ ...props.styles })};
`;

export const Label = styled.label`
  font-size: 12px;
  white-space: nowrap;
  ${props => ({ ...props })};
`;
export const InputOutline = ({ value = "", setValue = () => {}, id = "", placeholder = "", styles = {}, ...rest }) => {
  return (
    <InputOutlineStyled
      placeholder={placeholder}
      value={value}
      onChange={e => setValue(e.target.value)}
      styles={{ ...styles }}
      id={id}
      {...rest}
    />
  );
};
const Input = ({ value = "", setValue = () => {}, id = "", placeholder = "", styles = {}, ...rest }) => {
  return (
    <InputStyled
      placeholder={placeholder}
      value={value}
      onChange={e => setValue(e.target.value)}
      styles={{ ...styles }}
      id={id}
      {...rest}
    />
  );
};

export const InputUncontrolled = props => {
  return <InputOutlineStyled {...props} />;
};

export default Input;
