import styled from "styled-components";
import { Button } from "../../../common/Button";

export const InvitationalCodeGeneratorWrapper = styled.div`
  padding: 28px 18px;
  background: #3f2faa;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CodeAmountWrapper = styled.div`
  width: 249px;
  height: 38px;
  border-radius: 6px;
  padding: 7px 6px;
  background-color: #4e3cca;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DecreaseValueButton = styled(Button)`
  padding: 8px 6px;
  height: 26px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 2px solid #6c47ff;
`;

export const CodeAmountInput = styled.input`
  background: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  text-align: center;
`;

export const IncreaseValueButton = styled(Button)`
  padding: 8px 6px;
  height: 26px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background: #6c47ff;
`;

export const GenerateButton = styled(Button)`
  display: flex;
  width: 162px;
  height: 38px;
  padding: 16px;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #6c47ff;
  color: #fff;

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

export const ThreeDotsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ThreeDotsButtonContainer = styled.div`
  cursor: pointer;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.actionIsToggled ? "#4e3cca" : "transparent")};
  border-radius: 6px;
`;

export const ActionButton = styled(Button)`
  width: 100%;
  height: 34px;
  border-radius: 4px;
  background: #4e3cca;
  justify-content: flex-start;
  gap: 10px;
  &:disabled {
    color: #ffffff80;
  }
`;

export const ActionLabel = styled.span`
  color: #fff;
  font-size: 14px;
  font-weight: 325;
`;
