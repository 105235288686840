import axiosClient from "./axiosClient";

export function getTeams({ organizationId, searchQuery = "", limit }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .get(`/admPanel/teams/search/${organizationId}?searchValue=${searchQuery}&limit=${limit}`, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function getTeamById({ organizationId, teamId, viewAll = false }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!teamId) throw new Error("Please provide a valid team id");
  return axiosClient
    .get(`/admPanel/teams/${organizationId}/${teamId}${viewAll ? `?viewAll=${viewAll}` : ""}`, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export async function updateOrganizationPermission({ organizationId, selectedOrganizationId, permission }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!selectedOrganizationId) throw new Error("Please enter a valid organization to update permissions");
  return Promise.resolve({ message: "success" });
  // await new Promise((resolve, reject) => setTimeout(() => resolve(), 1000));
  // return Promise.reject({ error: "failure" });
}

export async function deleteTeamMember({ teamId, userId }, headers) {
  if (!teamId) throw new Error("Please enter a valid team id");
  if (!userId) throw new Error("Please enter a valid user id");
  return axiosClient
    .delete(`/admPanel/team/member/${teamId}/${userId}`, { headers: { Authorization: headers.encryptedHeader } })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function addMemberToTeam({ teamId, userId }, headers) {
  if (!teamId) throw new Error("Please provide a valid team ID");
  if (!userId) throw new Error("Please provide a valid user ID");
  return axiosClient
    .put(`/admPanel/team/addMember/${teamId}/${userId}`, {}, { headers: { Authorization: headers.encryptedHeader } })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function changeTeamCaptain({ teamId, newCaptainUserId }, headers) {
  if (!teamId) throw new Error("Please provide a valid team ID");
  return axiosClient
    .put(
      `/admPanel/team/chgCaptain/${teamId}/${newCaptainUserId}`,
      {},
      {
        headers: { Authorization: headers.encryptedHeader },
      },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function changeTeamStatus({ teamId, status }, headers) {
  if (!status) throw new Error("Please provide a status");
  if (!teamId) throw new Error("Please provide a valid team ID");
  return axiosClient
    .put(`/admPanel/team/chgStatus/${teamId}/${status}`, {}, { headers: { Authorization: headers.encryptedHeader } })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}
