import styled from "styled-components";
import { breakpoints } from "../../../../themes";

export const MainContainer = styled.div`
  width: 100%;
  height: 290px;
  background: linear-gradient(78.19deg, #4516de 4.07%, #9b51e0 111.71%);
  border-radius: 6px;
  /* when displayBanner prop is false hide the banner but with an animation */
  display: ${props => props.displayBanner ? "flex" : "none"};
  transition: opacity 1s ease-in-out;
  position: relative;
`;

export const InnerContainer = styled.div`
  padding-left: 30px;
  padding-top: 20px;
  padding-right: 30px;
  padding-bottom: 20px;
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 11px;
  justify-content: center;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 90%;
  }
  ${breakpoints.down(breakpoints.mobileM)} {
    width: 85%;
  }
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  right: 15px;
  top: 4px;
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

export const DemoVideoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h3`
  font-style: normal;
  font-weight: 350;
  font-size: 28px;
  line-height: 34px;
  margin: 0;
`;

export const Subtitle = styled.p`
  font-style: normal;
  font-weight: 325;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  width: 80%;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 90%;
  }
  ${breakpoints.down(breakpoints.mobileM)} {
    width: 100%;
  }
`;
