import axiosClient from "./axiosClient";

export function getPoolById({ poolId, orgId }, headers) {
  if (!poolId) throw new Error("Please enter a valid pool id");
  if (!orgId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .get(
      `/admPanel/pool/info/${orgId}/${poolId}`,

      { headers: { Authorization: headers?.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function createContactInfo({ organizationId, poolId, data }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .post(`/admPanel/pool/contactInfo/${organizationId}/${poolId}`, data, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}
export function updateContactInfo({ organizationId, poolId, data }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .put(`/admPanel/pool/contactInfo/${organizationId}/${poolId}`, data, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}
export function deleteContactInfo({ organizationId, poolId, contactId }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .delete(
      `/admPanel/pool/contactInfo/${organizationId}/${poolId}`,
      {
        headers: { Authorization: headers.encryptedHeader },
        data: {
          id: contactId,
        },
      },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}
