import axiosClient from "./axiosClient";

export function getTokenList(chainId) {
  return axiosClient
    .get(`/token/list/${chainId}/true`)
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}
