import React, { useState } from "react";
import { Button } from "../../../../common/Button";
import Container from "../../../../common/Container/Container";
import Input, { Label } from "../../../../common/Input/Input";
import Modal from "../../../../common/Modal/Modal";
import styled from "styled-components";
import Text from "../../../../common/Text/Text";

const EditModalWrapper = styled.div`
  width: 431px;
`;

const ModalTitle = styled(Text)`
  font-size: 28px;
  font-weight: 325;
  line-height: 33.6px;
  text-align: left;
`;

const GameIdLabel = styled(Label)`
  color: #a498ed;
  font-size: 16px;
  font-weight: 325;
  line-height: 19.2px;
  text-align: left;
`;

function EditAccountModal({ toggleModal, showModal, accountData = {}, mutateEditGameId, mutateEditGameIdLoading }) {
  const [account, setAccount] = useState(accountData.platformUserId);

  return (
    <Modal showModal={showModal} toggleModal={toggleModal}>
      <EditModalWrapper>
        <Container flex flexDirection="column" rowGap="40px">
          <ModalTitle>Edit game ID</ModalTitle>
          <Container flex flexDirection="column" rowGap="53px">
            <Container flex alignItems="center" columnGap="12px">
              <GameIdLabel>Game ID</GameIdLabel>
              <Input
                value={account}
                placeholder="Game ID goes here"
                onChange={e => {
                  setAccount(e.target.value);
                }}
                disabled={mutateEditGameIdLoading}
              />
            </Container>
            <Container flex justifyContent="flex-end">
              <Button
                borderRadius="30px"
                onClick={() => mutateEditGameId({ username: account, accountId: accountData.id })}
                disabled={mutateEditGameIdLoading}
              >
                Update Game ID
              </Button>
            </Container>
          </Container>
        </Container>
      </EditModalWrapper>
    </Modal>
  );
}

export default EditAccountModal;
