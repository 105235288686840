import React, { useState } from "react";
import * as S from "./styles";
import { Search } from "../../../../../assets/svg";

const SearchBar = ({ setSearchQuery, placeholder = "" }) => {
  // Changing the setSearchQuery will refetch the request
  // So we have temporary state of userInput to hold the user's input value. setUserInput will call the setSearchQuery function
  // with the user input data which will fetch the search request
  const [userInput, setUserInput] = useState("");
  function handleInputChange(e) {
    if (e?.target?.value === "") {
      setSearchQuery("");
    }
    setUserInput(e?.target?.value);
  }
  function search(e) {
    e?.preventDefault();
    setSearchQuery?.(userInput);
  }
  return (
    <S.SearchContainer>
      <form onSubmit={search}>
        <S.SearchInput value={userInput} onChange={handleInputChange} placeholder={placeholder} />
        <S.ButtonContainer>
          <S.SearchButton style={{ color: "#fff" }} type="submit">
            <Search />
          </S.SearchButton>
        </S.ButtonContainer>
      </form>
    </S.SearchContainer>
  );
};

export default SearchBar;
