import React from "react";
import Avatar from "../../../../common/Avatar";
import Container from "../../../../common/Container/Container";
import * as S from "./styles";

const AddOrganizationUserRow = ({ organizationId, data, mutateAddUserToOrgLoading, mutateAddUserToOrg }) => {
  const defaultPermissions = ["CREATE", "READ", "UPDATE", "DELETE"];
  return (
    <Container flex alignItems="center" justifyContent="space-between">
      <Container flex alignItems="center" columnGap="11px">
        <Avatar src={data?.thumbnail} />
        <span>{data.nick}</span>
      </Container>
      <S.AddUserButton
        disabled={mutateAddUserToOrgLoading}
        onClick={() =>
          mutateAddUserToOrg({
            organizationId: organizationId,
            permissions: defaultPermissions,
            userId: data.id
          })
        }
      >
        Add
      </S.AddUserButton>
    </Container>
  );
};

export default AddOrganizationUserRow;
