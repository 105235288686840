import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import * as S from "./styles";
import { getGamesList } from "../../api/game";
import Text from "../../components/common/Text/Text";
import { Button } from "../../components/common/Button";
import { Add } from "../../assets/svg";
import { useSession } from "../../providers/SessionProvider";
import LoadingSkeleton from "../../components/common/LoadingSkeleton";

const Loader = () => {
  // Random width for loader text
  const randomWidth = indx => {
    if (indx % 3 === 0) return "50px";
    else if (indx % 4 === 0) return "70px";
    else return "90px";
  };
  return (
    <S.GameListContainer>
      {[...Array(20)].map((_, index) => (
        <div>
          <LoadingSkeleton width="230px" height="260px"  style={{ marginBottom: "6px" }} />
          <LoadingSkeleton width={randomWidth(index)} height="15px" />
        </div>
      ))}
    </S.GameListContainer>
  );
};
const Games = () => {
  const { data: gameList, isLoading } = useQuery({
    queryKey: ["gameList"],
    queryFn: () => getGamesList(),
    refetchOnWindowFocus: false,
  });

  const { session } = useSession();

  return (
    <S.Container>
      <S.Header>
        <Text fontSize="38px">Games</Text>
        {session?.user?.userType === "SUPERUSER" && (
          <Link to="/games/new">
            <Button borderRadius="50px" width="129px" height="37px">
              <Add style={{ height: "8px", marginRight: "12px" }} /> <Text fontSize="13px"> Add Game</Text>
            </Button>
          </Link>
        )}
      </S.Header>
      {isLoading ? (
        <Loader />
      ) : (
        <S.GameListContainer>
          {gameList?.map(game => (
            <div>
              <S.GameImg src={game.assets?.gameThumbnail} />
              <Text fontSize="12px" color="#D7CFE8">
                {game.visibleName}
              </Text>
            </div>
          ))}
          {session?.user?.userType === "SUPERUSER" && (
            <Link to="/games/new">
              <S.AddLink>
                <Add />
                <span>Add game</span>
              </S.AddLink>
            </Link>
          )}
        </S.GameListContainer>
      )}
    </S.Container>
  );
};

export default Games;
