import axiosClient from "./axiosClient";

export function getConversionRate(data = {}) {
  const { from = "USDT", amount = 1, to = "USD" } = data;
  return axiosClient
    .get(`/utils/conversion/${from}/${amount}/${to}`)
    .then(res => res.data?.price)
    .catch(err => {
      throw err.response;
    });
}
