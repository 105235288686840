import React, { useEffect, useMemo, useState } from "react";

import * as S from "./styles";
import Text from "../../../common/Text/Text";
import Container from "../../../common/Container/Container";
import { SpinnerLoader } from "../../../common/SpinningLoader";
import { CalendarPurple, Coin, Fire, Playing, ProfileIcon, TeamIcon, Trophy, Wallet } from "../../../../assets/svg";
import TokenIcon from "../../../common/TokenIcon";
import { getConversionRate } from "../../../../api/conversion";
import { useQuery } from "@tanstack/react-query";
import { formatTimeStamp, isNonEmptyObject } from "../../../../utilities";
import moment from "moment";

const DatePreview = props => {
  const { registrationStartDate, registrationEndDate, tournamentStartDate, tournamentEndDate } = props;
  const currentTime = Date.now();

  let timeLeftToStart = tournamentStartDate ? moment.duration(tournamentStartDate - currentTime) : {};

  if (
    registrationStartDate &&
    registrationEndDate &&
    registrationStartDate < currentTime &&
    registrationEndDate > currentTime
  )
    return (
      <S.StartDate gradientA="#7283F7" gradientB="#BE7EFF" style={{ width: "136px" }}>
        Registration Open
      </S.StartDate>
    );

  if (tournamentEndDate < currentTime) return <S.StartDate border="#FF5C5C">Finished</S.StartDate>;

  if (tournamentStartDate < currentTime)
    return (
      <S.StartDate border="#B2FA4F">
        <Container flex columnGap="7px" alignItems="center">
          <Playing />
          <span>Playing</span>
        </Container>
      </S.StartDate>
    );

  if (timeLeftToStart && timeLeftToStart._data) {
    if (timeLeftToStart._data.days > 0)
      return (
        <S.StartDate gradientA="#E544FF" gradientB="#FF9B18">
          In {timeLeftToStart._data.days} day{timeLeftToStart._data.days > 1 && "s"}
        </S.StartDate>
      );
    else
      return (
        <S.StartDate gradientA="#E544FF" gradientB="#FF9B18">
          <Container flex columnGap="7px" alignItems="center">
            <Fire />
            {/* NOTE: There is a padStart function in the utilities folder. Refactor the following code to use that function instead */}
            <span>
              {timeLeftToStart._data.hours?.toString()?.padStart(2, "0")}:
              {timeLeftToStart._data.minutes?.toString()?.padStart(2, "0")}:
              {timeLeftToStart._data.seconds?.toString()?.padStart(2, "0")}
            </span>
          </Container>
        </S.StartDate>
      );
  }

  return (
    <S.StartDate style={{ background: "#3f2faa", border: "2px solid #be7eff" }}>
      {/* {formatTimeStamp(tournamentStartDate, "MMM D - hh a")} */}
    </S.StartDate>
  );
};
const Preview = ({ previewData = {} }) => {
  const {
    data: prizeTokenConvRate = 0,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: [`prizeTokenConversionRate`, previewData.prizeToken],
    queryFn: () => getConversionRate({ from: previewData.prizeToken || "USDT" }),
    staleTime: 3600000,
    refetchOnWindowFocus: false,
  });

  return (
    <S.PreviewContainer>
      <S.PreviewCard>
        <S.PoolImage background={previewData.thumbnail}>
          {/* {previewData.tournamentStartDate ? formatTimeStamp(previewData.tournamentStartDate, "MMM D - hh a") : ""} */}
          <DatePreview
            registrationStartDate={
              previewData.dates ? previewData.dates[0]?.registrationStartDate : previewData.registrationStartDate
            }
            registrationEndDate={
              previewData.dates ? previewData.dates[0]?.registrationEndDate : previewData.registrationEndDate
            }
            tournamentStartDate={
              previewData.dates ? previewData.dates[0]?.tournamentStartDate : previewData.tournamentStartDate
            }
            tournamentEndDate={
              previewData.dates ? previewData.dates[0]?.tournamentEndDate : previewData.tournamentEndDate
            }
          />
        </S.PoolImage>
        <Container marginTop="35px" flex flexDirection="column" rowGap="5px">
          <Text fontSize="18px">{previewData.title || "Pool Name"}</Text>
          <Text fontSize="12px" color="#A498ED">
            {previewData.subtitle || ""}
          </Text>
        </Container>
        {/* <Container marginTop="16px" flex columnGap="16px">
          <S.TrophyIconContainer>
            <Trophy />
          </S.TrophyIconContainer>
          <Container flex flexDirection="column" justifyContent="center" rowGap="7px">
            <Container flex alignItems="center" columnGap="25px" justifyContent="space-between">
              <Text fontWeight="800" fontSize="13px">
                Prize Pool
              </Text>
              <Container flex alignItems="center" columnGap="8px">
                <Text fontSize="13px" fontWeight="800">
                  {previewData.prizePool || 0}
                </Text>
                <TokenIcon tokenName={previewData.prizeToken || "USDT"} style={{ width: "16px", height: "16px" }} />
                <Text fontSize="10px" color="#A498ED">
                  {isLoading || isFetching ? (
                    <SpinnerLoader style={{ width: "8px", height: "8px" }} />
                  ) : (
                    `$${Number(previewData.prizePool ? previewData.prizePool * prizeTokenConvRate : 0)?.toFixed(2)}`
                  )}
                </Text>
              </Container>
            </Container>
            <Container flex alignItems="center" columnGap="25px" justifyContent="space-between">
              <Text fontSize="13px">Entry</Text>
              <Container flex alignItems="center" columnGap="8px">
                <Text fontSize="13px" placeholder="Free">
                  {previewData.entry || 0}
                </Text>
                <TokenIcon tokenName={previewData.prizeToken || "USDT"} style={{ width: "16px", height: "16px" }} />
                <Text fontSize="10px" color="#A498ED">
                  {isLoading || isFetching ? (
                    <SpinnerLoader style={{ width: "8px", height: "8px" }} />
                  ) : (
                    `$${Number(previewData.entry ? previewData.entry * prizeTokenConvRate : 0)?.toFixed(2)}`
                  )}
                </Text>
              </Container>
            </Container>
          </Container>
        </Container> */}

        <Container marginTop="16px">
          <Container flex columnGap="7px">
            <S.DataBlock>
              <Container flex alignItems="center" columnGap="6px">
                <Coin />
                <Container flex columnGap="2px">
                  <Text fontSize="12px">{previewData.prizePool || 0}</Text>
                  <TokenIcon tokenName={previewData.prizeToken || "USDT"} style={{ width: "12px", height: "12px" }} />
                </Container>
                <Text fontSize="10px" color="#A498ED">
                  {isLoading || isFetching ? (
                    <SpinnerLoader style={{ width: "8px", height: "8px" }} />
                  ) : (
                    `$${Number(previewData.prizePool ? previewData.prizePool * prizeTokenConvRate : 0)?.toFixed(2)}`
                  )}
                </Text>
              </Container>
            </S.DataBlock>
            <S.DataBlock>
              <Container flex alignItems="center" columnGap="6px">
                <Wallet />
                <Container flex columnGap="2px">
                  <Text fontSize="13px" placeholder="Free">
                    {previewData.entry || "Free Entry"}
                  </Text>
                  {previewData.entry && (
                    <TokenIcon tokenName={previewData.prizeToken || "USDT"} style={{ width: "12px", height: "12px" }} />
                  )}{" "}
                </Container>
                {previewData.entry && (
                  <Text fontSize="10px" color="#A498ED">
                    {isLoading || isFetching ? (
                      <SpinnerLoader style={{ width: "8px", height: "8px" }} />
                    ) : (
                      `$${Number(previewData.entry ? previewData.entry * prizeTokenConvRate : 0)?.toFixed(2)}`
                    )}
                  </Text>
                )}
              </Container>
            </S.DataBlock>
          </Container>
          <Container marginTop="7px">
            <S.DataBlock style={{ width: "fit-content", minWidth: "136px" }}>
              <Container flex alignItems="center" columnGap="6px">
                <CalendarPurple style={{ width: "10px", height: "12px" }} />
                <Text fontSize="12px">
                  {previewData?.dates?.[0]?.tournamentStartDate || previewData.tournamentStartDate
                    ? formatTimeStamp(
                        previewData.dates
                          ? previewData.dates?.[0]?.tournamentStartDate
                          : previewData.tournamentStartDate,
                        "MMM D - hh:mm a",
                      )
                    : ""}
                </Text>
              </Container>
            </S.DataBlock>
          </Container>
        </Container>

        <Container marginTop="20px" marginBottom="4px" flex columnGap="20px">
          {previewData.joinMode === "team" && (
            <Container flex columnGap="7px">
              <TeamIcon style={{ width: "13.23px", height: "10.47px" }} />
              <Container flex columnGap="7px">
                <Text fontSize="10px" color="#A498ED">
                  0 {previewData.maxPlayers && `/ ${previewData.maxPlayers}`} teams
                </Text>
              </Container>
            </Container>
          )}
          <Container flex columnGap="7px">
            <ProfileIcon style={{ width: "13.23px", height: "10.47px" }} />
            <Container flex columnGap="7px">
              <Text fontSize="10px" color="#A498ED">
                0 players
              </Text>
            </Container>
          </Container>
          {/* {previewData.maxPlayers && (
            <Container flex columnGap="7px">
              <Text fontSize="10px" color="#A498ED">
                {previewData.maxPlayers} players
              </Text>
            </Container>
          )} */}
        </Container>
      </S.PreviewCard>
    </S.PreviewContainer>
  );
};

export default Preview;
