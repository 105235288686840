import { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import * as S from "./styles";
import { CaretArrow, MaginfyingGlass, Swap } from "../../../../assets/svg";
import useOnClickOutside from "../../../../hooks/useClickOutside";
import Flex from "../../../common/Flex";
import Text from "../../../common/Text/Text";
import Container from "../../../common/Container/Container";
import { useLogin } from "../../../../providers/Loginprovider";
import { TournamentContext } from "../../../../providers/TournamentContext";
import Avatar from "../../../common/Avatar";
import LoadingSkeleton from "../../../common/LoadingSkeleton";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { NO_SHOW_PLAYER } from "../../../../constants";
import { getStageLeaderboard } from "../../../../api/stage";

// Note: This is PlayerSelect for swap modal in brackets
const PlayerSelect = ({ onChange, selectedPlayerToSwapWith, fromGroupId }) => {
  const { poolData, stageInfo, stageId } = useContext(TournamentContext);
  const [currentValue, setCurrentValue] = useState("");
  const [open, setOpen] = useState(false);
  const { orgIdState } = useLogin();
  const ref = useRef(null);
  const headers = useGetSecHeaders();

  const groupOptions = stageInfo?.groups?.filter(group => group.id !== fromGroupId);

  const [selectedGroup, setSelectedGroup] = useState(() => groupOptions?.[0]?.id || 1);

  const { data: { data = [] } = {}, isLoading: isLoadingPlayers } = useQuery({
    queryKey: ["group_players", poolData?.id, stageId, selectedGroup],
    queryFn: () =>
      getStageLeaderboard({ poolId: poolData.id, orgId: orgIdState, stageId, groupId: selectedGroup }, headers),

    enabled: !!headers?.encryptedHeader && !!orgIdState && !!stageId,
  });

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleValueChange = value => {
    setCurrentValue(value);
  };
  const handleChange = value => {
    handleValueChange(value);
    // call method, if it exists
    if (onChange) onChange(value);
    // close, after all tasks are finished
    handleClose();
  };

  // // Input state and functions
  const inputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const handleQuerySeach = e => {
    setSearchQuery(e?.target?.value?.toLowerCase());
  };

  const filterPlayerList = player => {
    return player.aTag !== NO_SHOW_PLAYER && player?.nick?.toLowerCase()?.includes(searchQuery);
  };

  // To autofocus the search input when dropdown opens
  useEffect(() => {
    if (open) inputRef?.current?.focus();
  }, [open]);

  return (
    <S.SelectContainer ref={ref} hasPlayer={!!selectedPlayerToSwapWith}>
      <S.SelectLabelButton onClick={handleOpen}>
        {!!selectedPlayerToSwapWith ? (
          <Flex alignItems="center" columnGap="14px">
            <Avatar src={selectedPlayerToSwapWith?.avatarURL} alt={`${selectedPlayerToSwapWith?.nick} avatar`} />
            <div>{selectedPlayerToSwapWith?.nick}</div>
          </Flex>
        ) : (
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" columnGap="10px">
              <Swap style={{ height: "34px" }} />
              <Text fontSize="16px">Select Player</Text>
            </Flex>

            <S.CaretContainer open={open}>
              <CaretArrow />
            </S.CaretContainer>
          </Flex>
        )}
      </S.SelectLabelButton>
      <S.DropdownStyle isVisible={open}>
        <S.SearchContainer onClick={() => inputRef?.current?.focus()}>
          <Flex alignItems="center" columnGap="10px">
            <MaginfyingGlass />
            <S.SearchInputDivier />
          </Flex>

          <S.SearchInput ref={inputRef} onChange={handleQuerySeach} />
        </S.SearchContainer>
        <S.SelectFiltersContainer hide={!groupOptions?.length}>
          {groupOptions?.map(option => (
            <S.FilterButton active={selectedGroup === option.id} onClick={() => setSelectedGroup(option.id)}>
              {option.name}
            </S.FilterButton>
          ))}
        </S.SelectFiltersContainer>
        {isLoadingPlayers ? (
          <Container marginTop="12px">
            <Flex flexDirection="column" rowGap="10px">
              <Flex alignItems="center" columnGap="14px">
                <LoadingSkeleton borderRadius="999px" height="34px" width="34px" />
                <LoadingSkeleton height="17px" width="150px" />
              </Flex>
              <Flex alignItems="center" columnGap="14px">
                <LoadingSkeleton borderRadius="999px" height="34px" width="34px" />
                <LoadingSkeleton height="17px" width="150px" />
              </Flex>
              <Flex alignItems="center" columnGap="14px">
                <LoadingSkeleton borderRadius="999px" height="34px" width="34px" />
                <LoadingSkeleton height="17px" width="150px" />
              </Flex>
            </Flex>
          </Container>
        ) : (
          <S.PlayerListContainer>
            {/* {playersToDisplay?.filter(filterPlayerList)?.map((player, index) => ( */}
            {data?.filter(filterPlayerList)?.map((player, index) => (
              <S.DropdownItem
                onClick={() => handleChange(player)}
                active={player.userId === currentValue.userId}
                key={index}
              >
                <Flex alignItems="center" columnGap="15px">
                  <Avatar src={player.avatarURL} alt={`${player.nick} avatar`} />
                  <span>{player.nick || "N/A"}</span>
                </Flex>
              </S.DropdownItem>
            ))}
          </S.PlayerListContainer>
        )}
      </S.DropdownStyle>
    </S.SelectContainer>
  );
};

export default PlayerSelect;
