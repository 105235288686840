import styled from "styled-components";

export const TeamDetailWrapper = styled.div`
  width: 664px;
  height: 493px;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const TeamDetailTitle = styled.h2`
  font-size: 28px;
  font-weight: 325;
  line-height: 33.6px;
  text-align: left;
  color: #ffffff;
`;

// Data Section

export const TeamMembersHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 10px;
  `;

export const TeamMembersTitle = styled.span`
  color: #a498ed;
  font-size: 13px;
  font-weight: 325;
  line-height: 15.6px;
  text-align: left;
`;

export const TeamMembersContainer = styled.div`
  height: 490px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InfoWrapper = styled.div`
  height: 50px;
  background: #3f2faa;
  border-radius: 6px;
  padding: 0 10px;
  display: grid;
  grid-template-columns: repeat(2, 0.8fr); // Change to 3, 1fr once the change in the service is done.
`;
