import { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import * as S from "./styles";
import { CaretArrow, Cross, MaginfyingGlass, Swap } from "../../../../assets/svg";
import useOnClickOutside from "../../../../hooks/useClickOutside";
import Flex from "../../../common/Flex";
import Text from "../../../common/Text/Text";
import Container from "../../../common/Container/Container";
import { useLogin } from "../../../../providers/Loginprovider";
import { getPoolPlayerAndMatches } from "../../../../api/pool";
import Avatar from "../../../common/Avatar";
import LoadingSkeleton from "../../../common/LoadingSkeleton";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { NO_SHOW_PLAYER } from "../../../../constants";
import { IconButton } from "../../../common/Button";
import { TournamentContext } from "../../../../providers/TournamentContext";

const GroupMovePlayerSelect = ({ playersToMoveGroup, setPlayersToMoveGroup, stageId = 1, poolData = {} }) => {
  const { id: poolId } = poolData;
  const [currentValue, setCurrentValue] = useState("");
  const [open, setOpen] = useState(false);
  const { orgIdState } = useLogin();
  const ref = useRef(null);
  const headers = useGetSecHeaders();


  const { data: stagePlayersData, isLoading: isLoadingStagePlayers } = useQuery({
    queryKey: ["players", orgIdState, poolId, stageId], 
    queryFn: () =>
      getPoolPlayerAndMatches(
        {
          poolId,
          organizationId: orgIdState,
          stageId: stageId, 
          onlyAddStageId: true, 
        },
        headers,
      ),
    enabled: !!headers?.encryptedHeader && !!orgIdState && !!poolId,
    refetchOnWindowFocus: false,
  });

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleValueChange = value => {
    setCurrentValue(value);
  };
  const handleAdd = value => {
    setPlayersToMoveGroup(prev => [...prev, value]);
  };

  // Input state and functions
  const inputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const handleQuerySeach = e => {
    setSearchQuery(e?.target?.value?.toLowerCase());
  };

  // Filtering player according to the following criteria:
  // 1. Filtering the selected player as we need to swap with some other player
  // 2. Filtering "No Show Players"
  // 3. Filtering according to user search input
  const filterPlayerList = player => {
    return (
      player.aTag !== NO_SHOW_PLAYER &&
      player?.nick?.toLowerCase()?.includes(searchQuery) &&
      !playersToMoveGroup.map(x => x.userId).includes(player.userId) // Removing already selected players from search list
    );
  };

  const removePlayerFromList = playerId => {
    let temp = playersToMoveGroup?.filter(player => player.playerId !== playerId);
    setPlayersToMoveGroup(temp);
  };

  // To autofocus the search input when dropdown opens
  useEffect(() => {
    if (open) inputRef?.current?.focus();
  }, [open]);

  return (
    <S.SelectContainer ref={ref}>
      <S.SelectLabelButton onClick={handleOpen}>
        {playersToMoveGroup && playersToMoveGroup.length ? (
          <Flex rowGap="8px" columnGap="8px" style={{ flexWrap: "wrap" }}>
            {playersToMoveGroup.map(player => (
              <S.SelectedPlayerPill>
                <Flex alignItems="center" columnGap="14px">
                  <Flex alignItems="center" columnGap="11px">
                    <Avatar size="22px" src={player?.avatarUrl || player?.avatarURL} alt={`${player?.nick || player?.nickname} avatar`} />
                    <div>{player?.nick || player?.nickname}</div>
                  </Flex>

                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      removePlayerFromList(player?.playerId);
                    }}
                  >
                    <Cross style={{ height: "6px", width: "6px" }} />
                  </IconButton>
                </Flex>
              </S.SelectedPlayerPill>
            ))}
          </Flex>
        ) : (
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" columnGap="10px">
              <Swap style={{ height: "34px" }} />
              <Text fontSize="16px">Select Players</Text>
            </Flex>

            <S.CaretContainer open={open}>
              <CaretArrow />
            </S.CaretContainer>
          </Flex>
        )}
      </S.SelectLabelButton>
      <S.DropdownStyle isVisible={open}>
        <S.SearchContainer onClick={() => inputRef?.current?.focus()}>
          <Flex alignItems="center" columnGap="10px">
            <MaginfyingGlass />
            <S.SearchInputDivier />
          </Flex>

          <S.SearchInput ref={inputRef} onChange={handleQuerySeach} />
        </S.SearchContainer>
        {isLoadingStagePlayers ? (
          <Container marginTop="12px">
            <Flex flexDirection="column" rowGap="10px">
              <Flex alignItems="center" columnGap="14px">
                <LoadingSkeleton borderRadius="999px" height="34px" width="34px" />
                <LoadingSkeleton height="17px" width="150px" />
              </Flex>
              <Flex alignItems="center" columnGap="14px">
                <LoadingSkeleton borderRadius="999px" height="34px" width="34px" />
                <LoadingSkeleton height="17px" width="150px" />
              </Flex>
              <Flex alignItems="center" columnGap="14px">
                <LoadingSkeleton borderRadius="999px" height="34px" width="34px" />
                <LoadingSkeleton height="17px" width="150px" />
              </Flex>
            </Flex>
          </Container>
        ) : (
          <>
            <div style={{ borderBottom: "2px solid #4E3CCA", width: "100%", margin: "20px 0 1px" }}></div>
            <S.PlayerListContainer>
              {stagePlayersData?.data?.leaders
                ?.filter(filterPlayerList)
                ?.filter(player => {
                  if (playersToMoveGroup?.[0]) {
                    return player.groupId === playersToMoveGroup?.[0]?.groupId;
                  } else return true;
                })
                ?.map((player, index) => (
                  <S.DropdownItem
                    onClick={() => handleAdd(player)}
                    key={index}
                  >
                    <Flex alignItems="center" columnGap="15px">
                      <Avatar src={player.avatarUrl} alt={`${player.nick} avatar`} />
                      <span>{player.nick || "N/A"}</span>
                    </Flex>
                  </S.DropdownItem>
                ))}
            </S.PlayerListContainer>
          </>
        )}
      </S.DropdownStyle>
    </S.SelectContainer>
  );
};

export default GroupMovePlayerSelect;
