import React from "react";
import Modal from "../../../common/Modal/Modal";
import { Important } from "../../../../assets/svg";
import { Button } from "../../../common/Button";
import * as S from "./styles";

export const DownloadPlayerListModal = ({ toggleModal, showModal, downloadLink, setDownloadLink }) => {
  return (
    <Modal
      customStyles={{ width: "450px", padding: "25px" }}
      showModal={showModal}
      toggleModal={toggleModal}
      beforeClose={() => setDownloadLink("")}
    >
      <S.Wrapper>
        <div>{!!downloadLink ? "Your file is ready to download" : "Your file is being prepared to download"}</div>
        <S.ButtonContainer>
          <Button borderRadius="24px" disabled={!downloadLink}>
            <a
              style={{ cursor: !!downloadLink ? "pointer" : "default" }}
              download="player_list"
              href={downloadLink}
              onClick={toggleModal}
            >
              Download
            </a>
          </Button>
        </S.ButtonContainer>
      </S.Wrapper>
    </Modal>
  );
};
