import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import * as S from "../../styles";
import Flex from "../../../../components/common/Flex";
import Avatar from "../../../../components/common/Avatar";
import Text from "../../../../components/common/Text/Text";
import { Button } from "../../../../components/common/Button";
import { useLogin } from "../../../../providers/Loginprovider";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { useSession } from "../../../../providers/SessionProvider";
import { notifyResolve, sendMessage } from "../../../../api/pool";
import Container from "../../../../components/common/Container/Container";
import { notifyError } from "../../../../components/common/ToastComponent";
import { formatTimeStamp } from "../../../../utilities";
import { PENDING_ADJUSTMENTS } from "../../../../constants/status";

const ChangesRequiredSection = ({ messages, poolStatus }) => {
  const { session } = useSession();
  const [input, setInput] = useState("");
  const { orgIdState } = useLogin();
  const { poolId } = useParams();
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();
  const inputRef = useRef();
  const scrollToRef = useRef();

  // Sccrolling to last message on each new message
  useEffect(() => {
    scrollToRef?.current?.scrollIntoView({ behavior: "smooth" });
    inputRef?.current?.focus();
  }, [messages]);

  // Scrolling to last message when first visiting the section
  useEffect(() => {
    scrollToRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, []);
  const { mutate: sendMessageMutation, isLoading: isSending } = useMutation({
    mutationFn: args => {
      const { message } = args;
      return sendMessage(
        {
          organizationId: orgIdState,
          poolId: Number(poolId),
          message,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: resp => {
      if (resp.error) {
        throw new Error("Something went wrong");
      }
      setInput("");
      queryClient.setQueryData(["pool", poolId], oldQueryData => {
        return {
          ...oldQueryData,
          data: {
            ...oldQueryData.data,
            adminMsg: resp?.data,
          },
        };
      });
    },
  });

  const { mutate: notifyResolveMutation, isLoading: isLoadingResolve } = useMutation({
    mutationFn: () => {
      return notifyResolve(
        {
          organizationId: orgIdState,
          poolId: Number(poolId),
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: resp => {
      if (resp.error) {
        throw new Error("Something went wrong");
      }
      queryClient.setQueryData(["pool", poolId], oldQueryData => {
        return {
          ...oldQueryData,
          data: {
            ...oldQueryData.data,
            adminMsg: resp?.data?.messages,
            poolStatus: resp?.data?.poolStatus,
          },
        };
      });
    },
  });
  async function handleSubmit(e) {
    e.preventDefault();
    if (!input) return;
    await sendMessageMutation({ message: input });
  }

  function handleInput(e) {
    setInput(e.target?.value);
  }
  return (
    <S.ChatContainer>
      <S.MessagesSection>
        {messages.map(item => (
          <S.MessageContainer isUserAuthor={session?.user?.id === item.userId}>
            <Avatar src={item.thumbnail} size="38px" />
            <Container flex flexDirection="column" rowGap="7px" marginBottom="20px">
              <S.MessageBubble>
                <Container flex flexDirection="column">
                  <Text fontSize="12px" color="#ffffff80">
                    {item.nickname}
                  </Text>
                  <Container marginTop="9px">{item.message}</Container>
                </Container>
              </S.MessageBubble>
              <Text fontSize="11px" color="#ffffff66">
                {formatTimeStamp(item.timestamp, "DD/MM/YY - hh:mm a")}
              </Text>
            </Container>
          </S.MessageContainer>
        ))}
        <div style={{ visibility: "hidden" }} ref={scrollToRef} />
      </S.MessagesSection>
      <S.InputContainer onSubmit={handleSubmit}>
        <S.Input
          onChange={handleInput}
          disabled={isSending}
          value={input}
          ref={inputRef}
          placeholder="Type a message"
        />

        <S.BtnContainer>
          {poolStatus === PENDING_ADJUSTMENTS && (
            <Button
              disabled={isSending || isLoadingResolve}
              onClick={() => notifyResolveMutation()}
              type="button"
              background="#4E3CCA"
              border="2px solid #6C47FF"
              borderRadius="30px"
              width="81px"
              height="32px"
            >
              Resolve
            </Button>
          )}
          <Button
            disabled={isSending || !input || isLoadingResolve}
            type="submit"
            background="#6C47FF"
            borderRadius="30px"
            width="81px"
            height="32px"
          >
            Send
          </Button>
        </S.BtnContainer>
      </S.InputContainer>
    </S.ChatContainer>
  );
};

export default ChangesRequiredSection;
