import { POOL_TYPES } from "../constants";
import axiosClient from "./axiosClient";

export function getStageInfo({ poolId, orgId, stageId }, headers) {
  if (!poolId) throw new Error("Please enter a valid pool id");
  if (!orgId) throw new Error("Please enter a valid organization id");
  if (!stageId) throw new Error("Please enter a valid stage id");
  return axiosClient
    .get(
      `/admPanel/pool/info/${orgId}/${poolId}/stageInfo/${stageId}`,

      { headers: { Authorization: headers?.encryptedHeader } },
    )
    .then(res => {
      if (res.error) throw new Error("Something went wrong when fetching stage information");
      return res.data;
    })
    .catch(err => {
      throw err.response || err;
    });
}

export function getStageLeaderboard({ poolId, orgId, stageId, groupId = 1 }, headers) {
  if (!poolId) throw new Error("Please enter a valid pool id");
  if (!orgId) throw new Error("Please enter a valid organization id");
  if (!stageId) throw new Error("Please enter a valid stage id");
  return axiosClient
    .get(
      `/admPanel/pool/stage/leaderboard/${orgId}/${poolId}/${stageId}?groupId=${groupId}`,

      { headers: { Authorization: headers?.encryptedHeader } },
    )
    .then(res => {
      if (res.error) throw new Error("Something went wrong when fetching stage leaderboard information");
      return res.data;
    })
    .catch(err => {
      throw err.response || err;
    });
}

export function getStageBracket(data, headers) {
  const { organizationId, poolId, pageId = 1, branchId = 1, roundId = 0, stageId = 1 } = data;
  if (!poolId) throw new Error("Please enter a valid pool id");
  if (!organizationId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .get(
      `/admPanel/pool/stage/bracket/${organizationId}/${poolId}/${stageId}?pageId=${pageId}&branchId=${branchId}&roundId=${roundId}`,

      { headers: { Authorization: headers?.encryptedHeader } },
    )
    .then(res => {
      if (res.error) throw new Error("Something went wrong when fetching stage leaderboard information");
      return res.data;
    })
    .catch(err => {
      throw err.response || err;
    });
}

export function movePlayersToOtherStages({ organizationId, poolId, fromStage, toStage, players = [] }, headers) {
  if (!organizationId) throw new Error("Please provide a valid organization ID");
  if (!poolId) throw new Error("Please provide a valid user ID");
  return axiosClient
    .put(
      `/admPanel/pool/movePlayers/${organizationId}/${poolId}`,
      {
        fromStage,
        toStage,
        players: players.map(player => player.playerId),
      },
      {
        headers: { Authorization: headers.encryptedHeader },
      },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}
