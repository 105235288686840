import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useGetSecHeaders } from "../../../../../hooks/useHeaders";
import {
  addUserToOrganization,
  getOrganizationById,
  removeUserFromOrganization,
} from "../../../../../api/organizations";

import * as S from "./styles";
import { SpinnerLoaderWrapper, SpinnerLoader } from "../../../../common/SpinningLoader";
import Container from "../../../../common/Container/Container";
import DetailsWrapperModal from "../../shared/DetailsWrapperModal";
import Text from "../../../../common/Text/Text";
import { copyTextToClipboard, truncateWithDots } from "../../../../../utilities";
import ScrollableSection from "../../../../common/ScrollableSection";
import OrganizationMemberRow from "../OrganizationMemberRow";
import { updateOrganizationPermission } from "../../../../../api/users";
import { notifyError, notifySuccess } from "../../../../common/ToastComponent";
import { Button } from "../../../../common/Button";
import { Add } from "../../../../../assets/svg";
import AddPlayerToOrganizationModal from "../OrganizationAddPlayerModal";
import { useSession } from "../../../../../providers/SessionProvider";

const OrganizationDetailModal = ({ showModal, toggleModal, setOrganizationId, organizationId }) => {
  const [activeSection, setActiveSection] = useState("users");
  const [showAddPlayerToOrganization, setShowAddPlayerToOrganization] = useState(false);
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();
  const { session, setSession } = useSession();

  function handleClick(e) {
    setActiveSection(e.target?.textContent?.toLowerCase() || "");
  }

  function toggleAddPlayerModal() {
    setShowAddPlayerToOrganization(!showAddPlayerToOrganization);
  }

  const {
    data: organization,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["organization", organizationId],
    queryFn: () => getOrganizationById({ organizationId: organizationId, includeOrgStats: true }, headers),
    refetchOnWindowFocus: false,
    enabled: showModal && !!headers?.encryptedHeader && !!organizationId,
  });

  const addNewOrg = {
    createdOn: organization?.createdOn,
    id: `${organization?.id}_${organization?.owner}`,
    organizationId: organization?.id,
    organizationName: organization?.name,
    reference: `${organization?.id}_${organization?.owner}`,
    status: organization?.status,
    updatedByUserId: session?.user?.updatedByUserId,
    updatedOn: session?.user?.updatedOn,
    userId: session?.user?.userId,
    userNick: session?.user?.nick,
    userPermissions: ["CREATE", "READ", "UPDATE", "DELETE"],
  };

  const { mutate: mutatePermission, isLoading: mutatePermissionLoading } = useMutation({
    mutationFn: variables => {
      return updateOrganizationPermission(
        {
          organizationId: organizationId,
          selectedOrganizationId: variables.selectedOrganizationId,
          permissions: variables.permissions,
          userId: variables.userId,
        },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.message || e?.data);
      if (variables?.errorCb) variables?.errorCb();
    },
    onSuccess: (e, variables) => {
      queryClient.invalidateQueries({ queryKey: ["organization", organizationId] });
      if (variables?.successCb) variables?.successCb();
    },
  });

  const { mutate: mutateAddUserToOrg, isLoading: mutateAddUserToOrgLoading } = useMutation({
    mutationFn: variables => {
      return addUserToOrganization(
        { organizationId: variables.organizationId, permissions: variables.permissions, userId: variables.userId },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: (e, variables) => {
      queryClient.invalidateQueries({ queryKey: ["organization", organizationId] });
      if (headers.userId === variables.userId) {
        setSession({
          ...session,
          user: {
            ...session?.user,
            organizations: [...session?.user?.organizations, addNewOrg],
          },
        });
      }
      notifySuccess("User has been added successfully");
      toggleAddPlayerModal();
    },
  });

  const { mutate: mutateRemoveUserFromOrg, isLoading: mutateRemoveUserFromOrgLoading } = useMutation({
    mutationFn: variables => {
      return removeUserFromOrganization(
        {
          organizationId: organizationId,
          userId: variables.userId,
        },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: (e, variables) => {
      queryClient.invalidateQueries({ queryKey: ["organization", organizationId] });
      if (headers.userId === variables.userId) {
        setSession({
          ...session,
          user: {
            ...session?.user,
            organizations: session?.user?.organizations.filter(organization => organization.id !== addNewOrg.id),
          },
        });
      }
      notifySuccess("User has been removed successfully");
    },
  });

  const copyRefCode = () => {
    if (organization?.refCode) {
      copyTextToClipboard(organization?.refCode, "Referal Code copied successfully");
    }
  };

  const copyUrlParam = () => {
    if (organization?.urlParam) {
      copyTextToClipboard(organization?.urlParam, "customized link copied successfully");
    }
  };

  const dataNotFound = !!headers && !isLoading && !organization;

  return (
    <DetailsWrapperModal
      showModal={showModal}
      toggleModal={toggleModal}
      data={{ avatarUrl: organization?.thumbnail, bannerUrl: organization?.banner }}
      afterClose={() => setOrganizationId("")}
      isLoadingData={isLoading}
    >
      {dataNotFound ? (
        <Container height="300px" flex alignItems="center" justifyContent="center">
          Data Not Found
        </Container>
      ) : isLoading ? (
        <SpinnerLoaderWrapper style={{ height: "300px" }}>
          <SpinnerLoader />
        </SpinnerLoaderWrapper>
      ) : (
        <>
          <Container
            flex
            marginTop="56px"
            className="flex-col-mobile"
            justifyContent="space-between"
            alignItems="center"
          >
            <Container flex columnGap="16px" rowGap="12px" alignItems="center" className="flex-col-mobile">
              <Text fontSize="28px" mobileFontSize="16px">
                {organization.name}
              </Text>
              <S.AddressBlock>{organization.id}</S.AddressBlock>
            </Container>
            <Button
              height="37px"
              width="130px"
              background="transparent"
              border="2px solid #6C47FF"
              borderRadius="30px"
              onClick={toggleAddPlayerModal}
            >
              <Container flex alignItems="center" justifyContent="center" columnGap="10px">
                <Add />
                <span>Add User</span>
              </Container>
            </Button>
          </Container>

          <Container marginTop="22px" flex columnGap="32px" rowGap="12px" className="flex-col-mobile">
            <S.OrganizationBasicInfo flex alignItems="center" columnGap="78px">
              <Container flex alignItems="center" columnGap="9px">
                <S.OrganizationBasicInfoTitle>Referal Code</S.OrganizationBasicInfoTitle>
                <S.CustomText onClick={copyRefCode} fontSize="13px">
                  {truncateWithDots(organization.refCode, 75)}
                </S.CustomText>
              </Container>
              <Container flex alignItems="center" columnGap="9px">
                <S.OrganizationBasicInfoTitle>Customized link</S.OrganizationBasicInfoTitle>
                <S.CustomText onClick={copyUrlParam} fontSize="13px">
                  {truncateWithDots(organization.urlParam, 75)}
                </S.CustomText>
              </Container>
            </S.OrganizationBasicInfo>
          </Container>

          <S.StatsContainer>
            <S.StatBlock>
              <Text fontSize="24px">{organization.stats?.usersRegistered.length || 0}</Text>
              <Text fontSize="12px" color="#A498ED">
                Users Registered
              </Text>
            </S.StatBlock>
            <S.StatBlock>
              <Text fontSize="24px">{organization.stats?.usersJoinedPool.length || 0}</Text>
              <Text fontSize="12px" color="#A498ED">
                Users joined
              </Text>
            </S.StatBlock>
            <S.StatBlock>
              <Text fontSize="24px">{organization.stats?.usersPlayedMatch.length || 0}</Text>
              <Text fontSize="12px" color="#A498ED">
                Users played
              </Text>
            </S.StatBlock>
          </S.StatsContainer>

          <Container marginTop="28px" flex columnGap="14px">
            <S.SelectorButton
              active={activeSection === "users"}
              borderRadius="50px"
              height="39px"
              width="99px"
              onClick={handleClick}
            >
              Users
            </S.SelectorButton>
          </Container>

          {activeSection === "users" && (
            <Container marginTop="15px">
              <ScrollableSection minWidth="600px" scrollHeight="10px">
                <Container paddingLeft="10px" paddingRight="10px" marginBottom="10px">
                  <S.AccountRowWrapper>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      Nickname
                    </Text>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      ID
                    </Text>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      Role
                    </Text>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      Status
                    </Text>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      Permissions
                    </Text>
                  </S.AccountRowWrapper>
                </Container>
                <S.OrganizationMemberRowContainer>
                  {organization?.users?.map(account => (
                    <>
                      <OrganizationMemberRow
                        mutatePermission={mutatePermission}
                        mutateRemovePlayerFromOrg={mutateRemoveUserFromOrg}
                        data={account}
                        ownerId={organization.owner}
                        organizationId={organization.id}
                      />
                    </>
                  ))}
                </S.OrganizationMemberRowContainer>
              </ScrollableSection>
            </Container>
          )}
          <AddPlayerToOrganizationModal
            toggleModal={toggleAddPlayerModal}
            showModal={showAddPlayerToOrganization}
            organizationId={organization.id}
            mutateAddUserToOrg={mutateAddUserToOrg}
            mutateAddUserToOrgLoading={mutateAddUserToOrgLoading}
          />
        </>
      )}
    </DetailsWrapperModal>
  );
};

export default OrganizationDetailModal;
