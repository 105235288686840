import React, { useState } from "react";
import { getUsers } from "../../api/users";
import { useGetSecHeaders } from "../../hooks/useHeaders";
import { useLogin } from "../../providers/Loginprovider";
import { useQuery } from "@tanstack/react-query";
import Container from "./Container/Container";
import Modal from "./Modal/Modal";
import SearchBar from "../views/players/shared/SearchBar";
import styled from "styled-components";
import LoadingSkeleton from "./LoadingSkeleton";

const UserWrapper = styled.div`
  padding: 3px;
  border-radius: 6px;
  background-color: transparent;
  border: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background: #3f2faa;
    cursor: pointer;
  }

  & > span {
    margin: 0;
    font-size: 14px;
  }
`;

const Image = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
`;

function SearchUserModal({ toggleModal, showModal, setUser }) {
  const headers = useGetSecHeaders();
  const { orgIdState } = useLogin();
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: users,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["users", searchQuery],
    queryFn: () => getUsers({ organizationId: orgIdState, searchQuery, limit: 30 }, headers),
    refetchOnWindowFocus: false,
    enabled: !!headers?.encryptedHeader && !!orgIdState,
  });

  const usersNotFound = users && users.length < 1;

  return (
    <Modal
      toggleModal={toggleModal}
      showModal={showModal}
      afterClose={() => setSearchQuery("")}
      customStyles={{
        padding: "30px",
        overflowY: "none",
      }}
    >
      <Container flex flexDirection="column" rowGap="20px">
        <Container flex style={{ paddingTop: "20px" }}>
          <SearchBar setSearchQuery={setSearchQuery} />
        </Container>
        <Container flex flexDirection="column" rowGap="10px" style={{ height: "220px", overflowY: "auto" }}>
          {isLoading ? (
            <>
              <LoadingSkeleton height="50px" />
              <LoadingSkeleton height="50px" />
              <LoadingSkeleton height="50px" />
            </>
          ) : usersNotFound ? (
            <Container flex justifyContent="center" alignItems="center" height="200px">
              User Not Found
            </Container>
          ) : (
            users?.map((user, index) => (
              <UserWrapper
                key={index}
                onClick={() => {
                  setUser(user);
                  toggleModal();
                }}
              >
                <Image src={user?.thumbnail} width={25} height={25} />
                <span>{user?.nick}</span>
              </UserWrapper>
            ))
          )}
        </Container>
      </Container>
    </Modal>
  );
}

export default SearchUserModal;
