import React from "react";
import styled from "styled-components";

const TextStyled = styled.p`
  margin: 0;
  padding: 0;
  font-size: ${props => (props.fontSize ? props.fontSize : "16px")};
  @media (max-width: 450px) {
    font-size: ${props => (props.mobileFontSize ? props.mobileFontSize : props.fontSize ? props.fontSize : "16px")};
  }
  ${props => {
    delete props.children;
    delete props.fontSize;
    return { ...props };
  }};
`;

const Text = ({ children, ...rest }) => {
  return <TextStyled {...rest}>{children}</TextStyled>;
};

export default Text;
