import styled from "styled-components";

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

export const ErrorTitle = styled.div`
  font-size: 16px;
  justify-content: flex-start;
  min-width: 250px;
`;

export const ErrorDescription = styled.div`
  font-size: 14px;
  max-width: 350px;
`;

export const HeaderContainer = styled.div`
    display: flex;
`

export const IconContainer = styled.div`
  /* margin-top: 2px; */
  height: 0px;
`