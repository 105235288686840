import React, { useState } from "react";
import * as S from "./styles";
// import { InfoIconNew } from "../../../assets/svg";
// import { capitalizeFirstLetter, truncateWithDots } from "../../../helpers/utils";
import { Tooltip } from "antd";
import { Add, DiscordSquareSmall, Edit, InfoOutlinePurple, Trash, WhatsApp } from "../../../../../assets/svg";
import Text from "../../../../../components/common/Text/Text";
import Modal from "../../../../../components/common/Modal/Modal";
import Container from "../../../../../components/common/Container/Container";
import { capitalizeFirstLetter, truncateWithDots } from "../../../../../utilities";
import { ButtonOutline, IconButton } from "../../../../../components/common/Button";
import AddOrEditContactInfoForm from "./AddOrEditContactInfoForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createContactInfo, deleteContactInfo, updateContactInfo } from "../../../../../api/contactInfo";
import { useParams } from "react-router-dom";
import { useLogin } from "../../../../../providers/Loginprovider";
import { useGetSecHeaders } from "../../../../../hooks/useHeaders";
import { notifyError } from "../../../../../components/common/ToastComponent";
import Flex from "../../../../../components/common/Flex";
import useConfirm from "../../../../../hooks/useConfirm";

const ContactSection = ({ organizerInfo = [] }) => {
  const [showModal, setShowModal] = useState(false);
  const [existingData, setExistingData] = useState(null);
  const [contactIdToDelete, setContactIdToDelete] = useState();
  const { poolId } = useParams();
  const { orgIdState } = useLogin();
  const headers = useGetSecHeaders();

  const queryClient = useQueryClient();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const { mutate: createContactInfoMutation, isLoading: isCreatingContactInfo } = useMutation({
    mutationFn: data => {
      return createContactInfo(
        {
          organizationId: orgIdState,
          poolId,
          data,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pool", poolId] });
      setShowModal(false);
    },
  });

  const { mutate: updateContactInfoMutation, isLoading: isUpdatingContactInfo } = useMutation({
    mutationFn: data => {
      return updateContactInfo(
        {
          organizationId: orgIdState,
          poolId,
          data,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pool", poolId] });
      setShowModal(false);
    },
  });

  const { mutate: deleteContactInfoMutation, isLoading: isDeletingContactInfo } = useMutation({
    mutationFn: contactId => {
      return deleteContactInfo(
        {
          organizationId: orgIdState,
          poolId,
          contactId: contactId,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pool", poolId] });
    },
  });

  const { triggerModal, ConfirmDialogue } = useConfirm({
    callback: () => {
      if (!contactIdToDelete) {
        console.log("!!");
        return;
      }
      deleteContactInfoMutation(contactIdToDelete);
    },
    message: "You are about to delete this contact info. There is no way to recuperate once it is deleted.",
    title: "Delete contact info?",
    btnVariant: "delete"
  });

  function handleEdit(item) {
    setExistingData(item);
    setShowModal(true);
  }

  function handleDelete(id) {
    setContactIdToDelete(id);
    triggerModal();
  }

  const isLoadingAction = isCreatingContactInfo || isUpdatingContactInfo;
  return (
    <>
      <Container marginBottom="24px" flex justifyContent="flex-end">
        <ButtonOutline
          borderRadius="50px"
          border="2px solid #6C47FF"
          width="189px"
          height="42px"
          onClick={() => setShowModal(true)}
          disabled={isLoadingAction}
        >
          <Add style={{ marginRight: "8px" }} />
          Add contact Info
        </ButtonOutline>
      </Container>
      <S.ContactRowsContainer>
        {organizerInfo.length ? (
          <S.RowSpacing style={{minWidth: "1000px"}}>
            <Text fontSize="14px" color="#A498ED">
              Nickname
            </Text>
            <Text fontSize="14px" color="#A498ED">
              Role
            </Text>
            <Text fontSize="14px" color="#A498ED">
              Twitch
            </Text>
            <Text fontSize="14px" color="#A498ED">
              Discord
            </Text>
            <Text fontSize="14px" color="#A498ED">
              Whatsapp
            </Text>
          </S.RowSpacing>
        ) : null}
        {organizerInfo.map(item => (
          <S.ContactRow key={item.id}>
            <S.NameContainer>{item.nickname}</S.NameContainer>
            <span>{capitalizeFirstLetter(item.role)}</span>
            <span>{item.twitchUsername || "-"}</span>

            <span style={{ paddingLeft: "20px" }}>
              {item.discordLink ? (
                <a href={item.discordLink} target="_blank">
                  <DiscordSquareSmall />
                </a>
              ) : (
                "-"
              )}
            </span>

            <span style={{ paddingLeft: "20px" }}>
              {item.whatsapp ? (
                <a href={item.whatsapp} target="_blank">
                  <WhatsApp />
                </a>
              ) : (
                "-"
              )}
            </span>

            <span>
              {item.extraInfo ? (
                <Tooltip
                  title={item.extraInfo}
                  color="#7265C8"
                  align="center"
                  overlayStyle={{
                    maxWidth: "256px",
                    fontSize: "11px",
                    padding: "11px 8px",
                    borderRadius: "6px",
                  }}
                >
                  <InfoOutlinePurple style={{ justifySelf: "self-end" }} />
                </Tooltip>
              ) : null}
            </span>

            <Flex columnGap="23px" justifyContent="flex-end">
              <IconButton onClick={() => handleEdit(item)}>
                <Edit />
              </IconButton>

              <IconButton disabled={isDeletingContactInfo} onClick={() => handleDelete(item.id)}>
                <Trash />
              </IconButton>
            </Flex>
          </S.ContactRow>
        ))}
      </S.ContactRowsContainer>

      <Modal
        showModal={showModal}
        toggleModal={toggleModal}
        afterClose={() => setExistingData(null)}
        customStyles={{ width: "585px", padding: "20px 25px" }}
        laptopStyles={{ width: "100%" }}
        mobileStyles={{ width: "100%" }}
        header="Add Contact Info"
      >
        <AddOrEditContactInfoForm
          {...{ createContactInfoMutation, updateContactInfoMutation, existingData, isLoadingAction }}
        />
      </Modal>

      <ConfirmDialogue />
    </>
  );
};

export default ContactSection;
