import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { CalendarPurple, CloseIcon } from "../../assets/svg";
import moment from "moment";

function CustomDateTimePicker({
  value,
  onChange = () => {},
  disabled,
  allowClear = false,
  format = "",
  calendarIconStyles = {},
}) {
  return (
    <DateTimePicker
      className="custom-calendar-container"
      calendarClassName="custom-calendar "
      onChange={e => {
        onChange(moment(e).valueOf());
      }}
      value={value}
      disableClock
      clearIcon={allowClear ? <CloseIcon /> : null}
      calendarIcon={<CalendarPurple style={{ ...calendarIconStyles }} />}
      disabled={disabled}
      format={format || "dd-MM-y   -   hh:mm a"}
      dayPlaceholder="d"
      hourPlaceholder="h"
      minutePlaceholder="m"
      monthPlaceholder="MM"
      yearPlaceholder="YY"
    />
  );
}

export default CustomDateTimePicker;
