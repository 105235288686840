import styled from "styled-components";
import { breakpoints } from "../../../themes";

export const GameListBar = styled.div`
  min-width: 87px;
  background: #201366;
  position: relative;
  z-index: 999;

  ${breakpoints.down(breakpoints.tablet)} {
    display: none;
  }
`;

export const BlastLogoWrapper = styled.div`
  position: absolute;
  top: 0;
  background: linear-gradient(199.28deg, #12c2ea -51.84%, #7c00fa 98.68%);
  width: 91px;
  height: 73px;
  border-bottom-right-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
`;

export const GamesListInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 95%;
  justify-content: space-between;
  ::-webkit-scrollbar {
    display: none;
  }
  ${breakpoints.down(breakpoints.tablet)} {
    display: none;
  }
`;

export const GamesList = styled.div`
  margin-top: 98px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 470px;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const GameLink = styled.a`
  width: 150%;
  display: flex;
  justify-content: center;
  ${props =>
    props.isActive
      ? `justify-content: space-between; & img { position: relative; right: 50%; transform: translateX(50%); } & span { content: ''; width: 6px; height: 49px; background: #A77EFF; border-top-right-radius: 25px; border-bottom-right-radius: 25px; }`
      : `&span {display: none;}`};
`;

export const SidebarWrapper = styled.div`
  padding: 15px 20px 13px 14px;
  width: 91px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;
  background-color: #362890;
  transition: all 0.2s linear;

  ${breakpoints.down(breakpoints.tablet)} {
    display: none;
  }
`;

export const NavTopWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const NavBar = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const BurgerItem = styled.li`
  position: relative;
  width: 100%;
  height: 49px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1px;
  ${props => `
      // background: ${props.active || props.hover ? props.theme.lightBackgroundColor : `inherit`};
      border-radius: ${props.active || props.hover ? `10px` : `inherit`};
      color: ${props.active || props.hover ? props.theme.textSecond : props.theme.textMain};
    }
  `}
`;

export const NavItem = styled.li`
  width: 100%;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  ${props => `
      // background: ${props.active || props.hover ? props.theme.lightBackgroundColor : `inherit`};
      border-radius: ${props.active || props.hover ? `10px` : `inherit`};
      color: ${props.active || props.hover ? props.theme.textSecond : props.theme.textMain};    }
  `}
`;

export const SidebarToggleButton = styled.span`
  ${props => (props.visible ? "opacity: 1; width: 100%" : "opacity: 0; width: 0")};

  /* transition: all 0.6s linear; */
  transition: all 0.2s linear;

  overflow-x: hidden;
  display: flex;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : "center")};
  align-items: center;
`;

export const IconWrapper = styled.div``;

export const TitleWrapper = styled.span`
  width: 0;
  opacity: 0;
  margin-left: 0;
  overflow-x: hidden;
  transition: width 0.5s linear, opacity 0.2s linear;
`;

