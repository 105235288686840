import React, { useState } from "react";
import { Button } from "../../../../common/Button";
import { InfoOutlinePurple } from "../../../../../assets/svg";
import { Image, Input, InputWrapper, OrganizationOwnerSelector } from "./styles";
import { Tooltip } from "antd";
import Container from "../../../../common/Container/Container";
import DetailsWrapperModal from "../../shared/DetailsWrapperModal";
import CheckboxDropdown from "../../../../common/DropdownWithCheckbox";
import SearchUserModal from "../../../../common/SearchUserModal";

function CreateOrganizationModal({ showModal, toggleModal, mutateCreateOrg, mutateCreateOrgLoading }) {
  const options = [
    {
      name: "Create",
      value: "CREATE",
    },
    {
      name: "Read",
      value: "READ",
    },
    {
      name: "Update",
      value: "UPDATE",
    },
    {
      name: "Delete",
      value: "DELETE",
    },
  ];
  const [organizationName, setOrganizationName] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [urlParam, setUrlParam] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [showOrgOwnerModal, setShowOrgOwnerModal] = useState(false);

  const toggleOrgOwnerModal = () => {
    setShowOrgOwnerModal(!showOrgOwnerModal);
  };

  const resetValues = () => {
    setOrganizationName("");
    setReferralCode("");
    setUrlParam("");
    setSelectedPermissions([]);
    setSelectedOwner(null);
  };

  return (
    <DetailsWrapperModal showModal={showModal} toggleModal={toggleModal} afterClose={() => resetValues()}>
      <Container
        marginTop="56px"
        flex
        flexDirection="column"
        columnGap="16px"
        rowGap="43px"
        alignItems="center"
        className="flex-col-mobile"
      >
        <Container
          flex
          flexDirection="column"
          rowGap="16px"
          alignItems="center"
          justifyContent="center"
          className="flex-col-mobile"
          width="100%"
        >
          <InputWrapper>
            <Input
              placeholder="Organization name"
              value={organizationName}
              onChange={e => setOrganizationName(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <Input placeholder="Referral code" value={referralCode} onChange={e => setReferralCode(e.target.value)} />
            <Tooltip>
              <InfoOutlinePurple />
            </Tooltip>
          </InputWrapper>
          <InputWrapper>
            <Input placeholder="URL Param" value={urlParam} onChange={e => setUrlParam(e.target.value)} />
          </InputWrapper>
          <Container flex justifyContent="space-between" columnGap="16px" width="100%">
            {/* Add Organization owner */}
            <OrganizationOwnerSelector onClick={toggleOrgOwnerModal}>
              {selectedOwner !== null && <Image src={selectedOwner?.thumbnail} />}
              {selectedOwner === null ? "Organization owner" : selectedOwner?.nick}
            </OrganizationOwnerSelector>
            <CheckboxDropdown
              options={options}
              title="Permissions"
              selectedValues={selectedPermissions}
              onSelectedValuesChange={setSelectedPermissions}
            />
          </Container>
        </Container>
        <Button
          borderRadius="30px"
          height="37px"
          width="118px"
          onClick={() =>
            mutateCreateOrg({
              organizationName: organizationName,
              referralCode: referralCode,
              urlParam: urlParam,
              permissions: selectedPermissions,
              owner: selectedOwner.id,
              language: "EN"
            })
          }
          disabled={
            !organizationName || !referralCode || !urlParam || !selectedOwner || selectedPermissions.length === 0 || mutateCreateOrgLoading
          }
        >
          Create
        </Button>

        {/* Organization Owner Modal */}
        <SearchUserModal toggleModal={toggleOrgOwnerModal} showModal={showOrgOwnerModal} setUser={setSelectedOwner} />
      </Container>
    </DetailsWrapperModal>
  );
}

export default CreateOrganizationModal;
