import styled from "styled-components";

export const AccordionItemWrapper = styled.div`
  background: #3f2faa;
  border-radius: 10px;
  width: 100%;
`;

export const AccordionHeader = styled.div`
  height: 40px;
  /* padding: 20px; */
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 18px;
  align-items: center;
  padding-left: 18px;
  padding-bottom: 0px;

  :hover {
    cursor: pointer;
  }

  svg {
    transform: ${props => (props.visible ? "rotate(0deg)" : "rotate(-90deg)")};
    transition: transform 0.25s;
  }
`;

export const AccordionContent = styled.div`
  transition: all 0.15s linear;
  overflow: hidden;
  color: #d4cedf;
  height: ${props => (props.visible ? "fit-content" : "0")};
  padding: 0 28px;
  padding-bottom: ${props => (props.visible ? "18px" : "0px")};
`;

export const AccordionHeaderText = styled.p`
  font-size: 14px;
  line-height: 36px;
  color: white;
  margin: 0;
`;

export const AccordionContentText = styled.p`
  a {
    text-decoration: underline;
  }
`;
