import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import * as S from "./styles";

import moment from "moment";
import Text from "../../../common/Text/Text";
import Container from "../../../common/Container/Container";
import { ProfileIcon, TeamIcon } from "../../../../assets/svg";
import { formatTimeStamp, padStart } from "../../../../utilities";

const SingleTimeSpan = ({ value = 0 }) => {
  return (
    <S.TimeBlock>
      <Text fontWeight="900" fontSize="18px">
        {value}
      </Text>
    </S.TimeBlock>
  );
};

const TimeSpanGroup = ({ title = "", value = "00" }) => {
  const splittedValue = value.split("");
  return (
    <Container>
      <Container flex columnGap="3px" marginBottom="4px">
        {splittedValue.map(val => (
          <SingleTimeSpan value={val} />
        ))}
      </Container>
      <Text fontSize="8px" color="#ffffff80">
        {title}
      </Text>
    </Container>
  );
};
const TimeLeft = props => {
  const { tournamentStartDate } = props;
  let date = moment(tournamentStartDate);
  if (!date.isValid())
    return (
      <Container flex columnGap="24px">
        <TimeSpanGroup title="Days" value="00" />
        <TimeSpanGroup title="Hours" value="00" />
        <TimeSpanGroup title="Minutes" value="00" />
        <TimeSpanGroup title="Seconds" value="00" />
      </Container>
    );
  const currentTime = Date.now();
  const timeLeftToStart = tournamentStartDate ? moment.duration(tournamentStartDate - currentTime) : {};
  const totalDays = date.diff(currentTime, "days");

  if (!timeLeftToStart._data)
    return (
      <Container flex columnGap="24px">
        <TimeSpanGroup title="Days" value="00" />
        <TimeSpanGroup title="Hours" value="00" />
        <TimeSpanGroup title="Minutes" value="00" />
        <TimeSpanGroup title="Seconds" value="00" />
      </Container>
    );

  return (
    <Container flex columnGap="24px">
      <TimeSpanGroup title="Days" value={padStart(totalDays, 2, "0")} />
      <TimeSpanGroup title="Hours" value={padStart(timeLeftToStart._data?.hours, 2, "0")} />
      <TimeSpanGroup title="Minutes" value={padStart(timeLeftToStart._data?.minutes, 2, "0")} />
      <TimeSpanGroup title="Seconds" value={padStart(padStart(timeLeftToStart._data?.seconds, 2, "0"))} />
    </Container>
  );
};

const HeaderPreview = ({ previewData = {}, isConfirmScreen = false }) => {
  const queryClient = useQueryClient();
  // const prizeTokenConvRate = queryClient.getQueryData([`prizeTokenConversionRate`, previewData.prizeToken]) || 0;

  return (
    <S.HeaderPreviewSection isConfirmScreen={isConfirmScreen}>
      <S.HeaderPreview bannerBgBack={previewData.bannerBgBack} isConfirmScreen={isConfirmScreen}>
        <S.Image src={previewData.bannerBgFront} />

        <S.GameDetails>
          <Text fontSize="20px">{previewData.title}</Text>

          <Container marginTop="8px" marginBottom="15px">
            <Text fontSize="10px">Tournament starting in</Text>
            <Container marginTop="8px">
              <TimeLeft tournamentStartDate={previewData.tournamentStartDate} />
            </Container>
          </Container>

          <Container marginTop="11px" flex columnGap="11px" alignItems="center">
            <S.JoinBtn isConfirmScreen={isConfirmScreen}>
              <p>Join</p>
            </S.JoinBtn>
            <Text fontSize="12px">{formatTimeStamp(previewData.tournamentStartDate, "MMM Do - hh:mm a")}</Text>
            <Container flex columnGap="24px">
              {previewData.joinMode === "team" && (
                <Container flex alignItems="center" columnGap="4px">
                  <TeamIcon style={{ width: "13.23px", height: "10.47px" }} />
                  <Text fontSize="12px">0 / {previewData.maxPlayers} teams</Text>
                </Container>
              )}
              <Container flex alignItems="center" columnGap="4px">
                <ProfileIcon style={{ width: "13.23px", height: "10.47px" }} />
                <Text fontSize="12px">0 players</Text>
              </Container>
            </Container>
          </Container>
        </S.GameDetails>
      </S.HeaderPreview>
    </S.HeaderPreviewSection>
  );
};

export default HeaderPreview;
