import styled from "styled-components";
import { Button } from "../../../common/Button";

export const PlayerRowContainer = styled.div`
  border-radius: 6px;
  background-color: #3f2faa;
  padding: 10px 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1.4fr 1.6fr 0.6fr;

  div.score-row {
    /* max-width: 250px; */
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 11px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: fit-content;
    row-gap: 30px;
    align-items: start;
    padding: 17px 16px;
    div.score-row {
      max-width: 100%;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 11px;
      margin-left: 50px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  &:hover {
    cursor: ${props => (props.joinMode === "TEAM" ? "pointer" : "default")};
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  column-gap: 12px;
  justify-self: end;
  align-items: flex-end;
  @media (max-width: 768px) {
    margin-left: 48px;
    column-gap: 12px;
  }
`;

export const PlayerData = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
`;

export const PlayerName = styled.span`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

export const RowActionButton = styled(Button)`
  /* width: 109px;
  height: 37px;
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: 32px;
  background: #4e3cca; */
  padding: 0;
  border: none;
  background: transparent;
  span {
    display: none;
  }

  @media (max-width: 768px) {
    width: 113px;
    height: 33px;
    border-radius: 6px;
    background: #4e3cca;
    font-size: 14px;
    text-align: center;
    span {
      display: block;
    }
  }

  @media (max-width: 320px) {
    width: fit-content;
    padding: 2px 8px;
    span {
      display: none;
    }
  }
`;

export const PlayerReportModalRow = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 425px) {
    align-items: start;
    row-gap: 6px;
    flex-direction: column;
  }
`;

export const ScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.winner ? "#46AD3D" : props.draw ? "#FFAE42" : "#ED6140")};
  width: 22px;
  height: 22px;
  border-radius: 4px;
  font-size: 8px;
  font-weight: 325;
`;

export const EditUserContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ActionButton = styled(Button)`
  width: 100%;
  height: 34px;
  border-radius: 4px;
  background: #4e3cca;
  justify-content: flex-start;
  gap: 10px;
  &:disabled {
    color: #fffffff5;
  }
`;

export const ActionLabel = styled.span`
  font-size: 14px;
  font-weight: 325;
  line-height: 16.8px;
  text-align: left;
`;
