import React, { useState } from "react";

import RuleAccordionItem from "../../../common/RuleAccordion";
import Container from "../../../common/Container/Container";

const RuleComponent = ({ rules = [] }) => {
  if (rules.length < 1) return null;
  return (
    <div>
      {rules.map((rule, index) => {
        if (!rule.title && !rule.content) return null;
        return (
          <Container marginBottom="12px">
            <RuleAccordionItem key={index} title={rule.title} content={rule.content} />
          </Container>
        );
      })}
    </div>
  );
};

export default RuleComponent;
