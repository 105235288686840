import styled from "styled-components";

export const PageTitle = styled.h2`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  margin: 0;
`;

/* Rules Section CSS */

export const ActionButton = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

export const RowContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: ${props => (props.alignItems ? props.alignItems : "center")};
  column-gap: 85px;
  position: relative;

  label {
    width: 85px;
    font-size: 13px;
  }
`;

export const TextInput = styled.input`
  height: 40px;
  border-radius: 4px;
  padding: 12px 15px;
  border: none;
  color: #fff;
  /* border: 1px solid #7a6ddb; */
  border: ${props => (props.hasError ? "1px solid #ED6140" : "unset")};
  outline: none;
  background-color: #3f2faa;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  width: ${props => (props.width ? props.width : "100%")};
`;

export const RulesWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const CreateRule = styled.button`
  background-color: transparent;
  width: 400px;
  height: 186px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #4e3cca;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 325;
  line-height: 16.8px;
  text-align: center;
  color: #ffffff;

  &:hover {
    cursor: pointer;
  }
`;

export const RuleDetail = styled.a`
  width: 400px;
  height: 186px;
  background-color: #342788;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:hover {
    cursor: pointer;
  }
`;

export const RuleOrder = styled.span`
  background: #6c47ff;
  min-width: 26px;
  width: 26px;
  height: 26px;
  border-radius: 6px;
  opacity: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 350;
  line-height: 16.8px;
  text-align: center;
`;

export const RuleTitle = styled.span`
  font-size: 14px;
  font-weight: 350;
  line-height: 16.8px;
  text-align: left;
  text-transform: capitalize;
`;

export const RuleContent = styled.div`
  height: 100%;
  overflow-y: auto;
  p {
    margin: 0;
  }
`;

export const RuleContentMessage = styled.span `
 & > * {
    margin-top: 0;
  }
`;
