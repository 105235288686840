import React, { useContext, useState } from "react";
import * as S from "./styles";
import { Tooltip } from "antd";
import Avatar from "../../../common/Avatar";
import { Check, Cross, Edit, MoveNextRound, MovePreviousRound, Swap } from "../../../../assets/svg";
import { TournamentContext } from "../../../../providers/TournamentContext";
import Modal from "../../../common/Modal/Modal";
import Flex from "../../../common/Flex";
import { Button, ButtonOutline } from "../../../common/Button";
import Container from "../../../common/Container/Container";
import { BracketsDataContext } from "../../../../providers/BracketsDataProvider";
import { ARCHIVED, FINISHED } from "../../../../constants/status";
import { TournamentTeamDetailModal } from "../../../views/tournamentDetail/TeamDetailModal";

export const BracketRow = ({
  data = {},
  status,
  handleBracketsReportAction = () => {},
  movePlayerRound = () => {},
  isLoadingMovePlayer = false,
  playerPos = 1,
  finalPageRound,
  results = [],
  matchNumber = 1,
  poolStatus,
  joinMode,
}) => {

  const [showMoveConfirm, setShowMoveConfirm] = useState(false);
  const { setOpenSwapModal, setPlayerToSwap, selectedRound, selectedBranch } = useContext(BracketsDataContext);

  const actionsDisabled = poolStatus === FINISHED || poolStatus === ARCHIVED;

  const [showTeamDetailModal, setShowTeamDetailModal] = useState(false);

  const toggleTeamDetailModal = () => {
    if (joinMode === "TEAM") {
      setShowTeamDetailModal(!showTeamDetailModal);
    } else {
      return;
    }
  };

  return (
    <S.MainContainer>
      <S.IdWrapper></S.IdWrapper>
      <S.PlayerWrapper onClick={() => toggleTeamDetailModal()} joinMode={joinMode}>
        <Avatar src={data.avatarUrl} />
        <S.PlayerName>{data.nickname}</S.PlayerName>
      </S.PlayerWrapper>
      <S.ResultsWrapper>
        {results?.map(item => (
          <S.ScoreWrapper isWinner={item === "W"}>{item}</S.ScoreWrapper>
        ))}
      </S.ResultsWrapper>
      <S.StatusWrapper>
        {status !== "FINISHED" ? <S.StatusIndicator /> : null}
        <S.StatusText>{status}</S.StatusText>
      </S.StatusWrapper>
      <S.ActionsWrapper>
        <Tooltip
          title={actionsDisabled ? "Action not allowed for finished matches or pools" : "Report"}
          placement="top"
          color="#7265C8"
        >
          <S.ActionButton disabled={actionsDisabled} onClick={() => handleBracketsReportAction(data?.userId)}>
            <Edit />
          </S.ActionButton>
        </Tooltip>
        <Tooltip
          title={
            isLoadingMovePlayer
              ? ""
              : finalPageRound
              ? "This is the final round. Cannot move player forward"
              : actionsDisabled
              ? "Action not allowed for finished matches or pools"
              : "Move to next round"
          }
          placement="top"
          color="#7265C8"
        >
          <S.ActionButton
            disabled={isLoadingMovePlayer || actionsDisabled || finalPageRound}
            onClick={() => setShowMoveConfirm(true)}
          >
            <MoveNextRound />
          </S.ActionButton>
        </Tooltip>

        {selectedRound === 1 && (
          <Tooltip title="Swap" placement="top" color="#7265C8">
            <S.ActionButton
              onClick={() => {
                setPlayerToSwap({ ...data, match: Number(matchNumber), position: playerPos, branch: selectedBranch });
                setOpenSwapModal(true);
              }}
              disabled={actionsDisabled}
            >
              <Swap />
            </S.ActionButton>
          </Tooltip>
        )}
      </S.ActionsWrapper>

      <Modal
        showModal={showMoveConfirm}
        toggleModal={() => setShowMoveConfirm(!showMoveConfirm)}
        mobileStyles={{ width: "90%" }}
      >
        <div>
          <Container marginBottom="16px" marginTop="8px">
            <div>Are you sure you want to move {data.nickname || "this player"} to the next round?</div>
          </Container>

          <Flex columnGap="12px" justifyContent="end">
            <ButtonOutline onClick={() => setShowMoveConfirm(false)}>Cancel</ButtonOutline>
            <Button
              borderRadius="4px"
              onClick={() => {
                movePlayerRound(playerPos);
                setShowMoveConfirm(false);
              }}
            >
              Confirm
            </Button>
          </Flex>
        </div>
      </Modal>
      <TournamentTeamDetailModal showModal={showTeamDetailModal} toggleModal={toggleTeamDetailModal} data={data?.teamMembers}/>
    </S.MainContainer>
  );
};
