import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import * as S from "../../styles";
import Container from "../../../../common/Container/Container";
import ErrorTooltip from "../../../../common/ErrorTooltip";
import { Dropdown } from "../../../../common/Dropdown";
import Text from "../../../../common/Text/Text";
import { WarningOutlineRed } from "../../../../../assets/svg";
import RuleComponent from "../../subcomponents/Rule";
import { formatNumberWithLetters } from "../../../../../utilities";

const ThirdStep = ({ register, errors = {}, currentInputValues = {}, setValue, setError, clearErrors, watch }) => {
  const quill = useRef();

  useEffect(() => {
    if (currentInputValues.descInputTemp === "") quill.current?.editor?.setText("");
  }, [currentInputValues.descInputTemp]);

  if (!register || !setValue) return <div />;

  return (
    <Container flex flexDirection="column" rowGap="23px">
      <S.RowContainer>
        <label>Winners</label>
        <Dropdown
          options={[
            { name: "1", value: 1 },
            { name: "2", value: 2 },
            { name: "3", value: 3 },
            { name: "4", value: 4 },
            { name: "5", value: 5 },
          ]}
          onChange={e => {
            clearErrors("winnerCount");
            clearErrors("percentageList");
            clearErrors("percentageTotal");
            setValue("winnerCount", e);
            setValue("percentageList", new Array(e).fill(undefined));
          }}
          value={watch("winnerCount") || currentInputValues.winnerCount || 0}
          hasError={!!errors.winnerCount}
        />
        {errors.winnerCount && <ErrorTooltip message={errors.winnerCount?.message} right="30px" />}
      </S.RowContainer>
      <S.RowContainer alignItems="flex-start">
        <label>Percentage</label>
        <S.FieldsContainer hidden={!currentInputValues?.percentageList} hasError={errors.percentageList?.message}>
          {errors.percentageList && (
            <Container flex columnGap="8px" alignItems="center" marginBottom="10px">
              <WarningOutlineRed style={{ width: "14px" }} />
              <Text color="#ED6140" fontSize="12px">
                {errors.percentageList?.message
                  ? errors.percentageList?.message
                  : " Valid input is required for each row"}
              </Text>
            </Container>
          )}
          {errors.percentageTotal && (
            <Container flex columnGap="8px" alignItems="center" marginBottom="10px">
              <WarningOutlineRed style={{ width: "14px" }} />
              <Text color="#ED6140" fontSize="12px">
                {errors.percentageTotal?.message}
              </Text>
            </Container>
          )}
          {currentInputValues.winnerCount && (
            <Container flex flexDirection="column" rowGap="7px">
              {[...Array(Number(currentInputValues.winnerCount)).keys()].map((item, index) => (
                <S.PercentageInputRow key={item}>
                  <Text fontSize="11px">{formatNumberWithLetters(item + 1)} Position</Text>
                  <Container flex columnGap="6px" alignItems="center">
                    <S.PercentageInput
                      type="number"
                      onChange={e => {
                        let temp = currentInputValues.percentageList || [];
                        temp[index] = e.target.value;

                        setValue("percentageList", temp);
                        clearErrors("percentageList");
                        clearErrors("percentageTotal");
                      }}
                      value={currentInputValues?.percentageList?.[index] || ""}
                      hasError={!!errors?.percentageList?.[index]}
                    />
                    <Text fontSize="11px">%</Text>
                  </Container>
                </S.PercentageInputRow>
              ))}
              <S.PercentageInputRow>
                <Text fontSize="11px">Total</Text>
                <Container flex columnGap="6px" alignItems="center">
                  <S.PercentageInput
                    type="number"
                    disabled
                    value={
                      currentInputValues.percentageList
                        ? currentInputValues.percentageList.reduce((acc, curr) => {
                            let current = Number(curr) || 0;
                            return acc + current;
                          }, 0)
                        : 0
                    }
                    hasError={!!errors?.percentageTotal}
                  />
                  <Text fontSize="11px">%</Text>
                </Container>
              </S.PercentageInputRow>
            </Container>
          )}
        </S.FieldsContainer>
      </S.RowContainer>

      {/* Rules */}
      <S.RowContainer alignItems="flex-start">
        <label>Rules</label>
        <Container>
          <RuleComponent rules={currentInputValues.rules} />
          <Container flex flexDirection="column" rowGap="6px">
            <S.TextInput
              width="100%"
              // onChange={e => {
              //   setRuleTitleInput(e.target.value);
              // }}
              // value={ruleTitleInput}
              {...register("titleInputTemp")}
            />
            <ReactQuill
              className="ql-custom"
              onChange={(content, delta, source, editor) => {
                const textContent = editor?.getText(content);
                setValue("descInputTemp", content);
              }}
              ref={quill}
            />
            <S.ButtonsContainer>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    if (currentInputValues.rules && Array.isArray(currentInputValues.rules)) {
                      setValue("rules", currentInputValues.rules.slice(0, currentInputValues.rules.length - 1));
                    }
                  }}
                >
                  -
                </button>
                <button
                  type="button"
                  onClick={() => {
                    if (currentInputValues.rules && Array.isArray(currentInputValues.rules)) {
                      setValue("rules", [
                        ...currentInputValues.rules,
                        {
                          title: currentInputValues.titleInputTemp || "",
                          content: currentInputValues.descInputTemp || "",
                        },
                      ]);
                    } else {
                      setValue("rules", [
                        {
                          title: currentInputValues.titleInputTemp || "",
                          content: currentInputValues.descInputTemp || "",
                        },
                      ]);
                    }

                    setValue("titleInputTemp", "");
                    setValue("descInputTemp", "");
                    quill.current?.editor?.setText("");
                  }}
                >
                  +
                </button>
              </div>
            </S.ButtonsContainer>
          </Container>
        </Container>
      </S.RowContainer>
    </Container>
  );
};

export default ThirdStep;
