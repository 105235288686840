import styled from "styled-components";

export const NavContainer = styled.div`
  margin-top: 45px;
  margin-bottom: 81px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  svg {
    min-width: 15px;
    min-height: 12px;
  }
`;
export const NavigationBox = styled.div`
  width: 100%;
  height: 61px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  column-gap: 13px;
  padding: 22px 18px;
  position: relative;
  ${props =>
    `border: ${props.active ? "3px solid #6C47FF" : "unset"};
    background: ${props.passed ? "#6C47FF" : "#342788"};
  `}

  span {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    background-color: #6c47ff;
    display: flex;
    align-items: center;
    justify-content: center;
    ${props => `
        border: ${props.active || props.passed ? "unset" : "3px solid #3F2FAA"};
        background: ${props.active ? "#6C47FF" : "#2C2081"};
    `}
    svg {
      width: 11.22px;
      height: 7.65px;
    }
  }
`;
