import axiosClient from "./axiosClient";

export function getOrganizations({ searchQuery = "" }, headers) {
  return axiosClient
    .get(`/admPanel/organizations/search/${searchQuery}`, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function getOrganizationById({ organizationId, includeOrgStats = false }, headers) {
  if (!organizationId) throw new Error("Please provide a valid organization id");
  return axiosClient
    .get(`/admPanel/organizations/${organizationId}/${includeOrgStats}`, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function createOrganization(
  { organizationName, referralCode, urlParam, selectedPermissions, selectedOwner, language },
  headers,
) {
  if (!organizationName) throw new Error("Please provide a name for the organization");
  if (!referralCode) throw new Error("Please provide a referral code");
  if (!urlParam) throw new Error("Please provide a parameter for the URL of the organization");
  if (!language) throw new Error("Please provide a Language for the Organization");

  return axiosClient
    .post(
      `/admPanel/organization/create`,
      {
        name: organizationName,
        thumbnail: "",
        banner: "",
        language: language,
        owner: selectedOwner,
        refCode: referralCode,
        urlParam: urlParam,
        user: headers.userId,
        userPermissions: selectedPermissions,
      },
      {
        headers: { Authorization: headers.encryptedHeader },
      },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function addUserToOrganization({ organizationId, userId, permissions }, headers) {
  if (!organizationId) throw new Error("Please provide a valid organization ID");
  if (!userId) throw new Error("Please provide a valid user ID");
  return axiosClient
    .put(
      `/admPanel/organization/addUser/${organizationId}/${userId}`,
      {
        permissions: permissions,
      },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function removeUserFromOrganization({ organizationId, userId }, headers) {
  if (!organizationId) throw new Error("Please provide a valid organization ID");
  if (!userId) throw new Error("Please provide a valid user ID");
  return axiosClient
    .put(
      `/admPanel/organization/removeUser/${organizationId}/${userId}`,
      {},
      {
        headers: { Authorization: headers.encryptedHeader },
      },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}
