import styled from "styled-components";

export const CreateBracketModalContainer = styled.div`
  width: 501px;
  height: 482px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
  gap: 25px;
`;

export const CreateBracketModalHeader = styled.div`
  width: 100%;
  height: 31px;
`;

export const CreateBracketModalHeaderTitle = styled.h3`
  font-size: 28px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  text-align: center;
  margin: 0;
`;

export const CreateBracketModalContent = styled.div`
  width: 389px;
  height: 125px;
`;

export const CreateBracketModalContentText = styled.p`
  color: #a498ed;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  margin: 0;
`;

export const CreateBracketModalFooter = styled.div`
  width: 389px;
  height: 31px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CreateBracketModalLink = styled.a`
  color: #a498ed;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
`;
