import styled from "styled-components";
import { Dropdown } from "../../../../../../components/common/Dropdown";

export const ModalWrapper = styled.div`
  width: 585px;
  display: flex;
  flex-direction: column;
`;

export const ModalTitle = styled.h2`
  font-size: 28px;
  font-weight: 325;
  line-height: 33.6px;
  text-align: left;
  margin-top: 0;
`;

export const StyledDropdown = styled(Dropdown)`
  width: 100px;
  color: #ffffff;
`;

export const Description = styled.span`
  font-size: 14px;
  font-weight: 325;
  line-height: 16.8px;
  text-align: left;
`;

export const Input = styled.input`
  height: 37px;
  top: 127px;
  left: 149px;
  padding: 12px 18px 12px 12px;
  gap: 10px;
  border-radius: 6px;
  opacity: 0px;
  background: #3f2faa;
  border: none;
  color: #ffffff;
  &::placeholder {
    color: #a498ed;
  }
`;
