import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import * as S from "./styles";
import { CaretArrow, MaginfyingGlass, Swap } from "../../../../assets/svg";
import useOnClickOutside from "../../../../hooks/useClickOutside";
import Flex from "../../../common/Flex";
import Text from "../../../common/Text/Text";
import Container from "../../../common/Container/Container";
import { BracketsDataContext } from "../../../../providers/BracketsDataProvider";
import { useLogin } from "../../../../providers/Loginprovider";
import { TournamentContext } from "../../../../providers/TournamentContext";
import { getPoolPlayerAndMatches } from "../../../../api/pool";
import Avatar from "../../../common/Avatar";
import LoadingSkeleton from "../../../common/LoadingSkeleton";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { NO_SHOW_PLAYER } from "../../../../constants";
import { getStageBracket } from "../../../../api/stage";
import { isNonEmptyObject } from "../../../../utilities";

// Note: This is PlayerSelect for swap modal in brackets
const PlayerSelect = ({ onChange, branchOptions = [], selectedPlayerToSwapWith }) => {
  const { selectedBranch: defaultBranch, playerToSwap, isMultiStagePool } = useContext(BracketsDataContext);
  const { poolData, stageId } = useContext(TournamentContext);
  const [currentValue, setCurrentValue] = useState("");
  const [open, setOpen] = useState(false);
  const { orgIdState } = useLogin();
  const ref = useRef(null);
  const headers = useGetSecHeaders();

  const [selectedBranch, setSelectedBranch] = useState(() => (typeof defaultBranch === "number" ? defaultBranch : 1)); // This will be the branch whose players will be displayed in the Select dropdown. Initially, the branch selected in the brackets will be set as the selectedBranch by default

  const { data: { data = {} } = {}, isLoading: isLoadingPlayers } = useQuery({
    queryKey: ["branch_players", orgIdState, poolData?.id, selectedBranch],
    queryFn: () =>
      isMultiStagePool
        ? getStageBracket(
            {
              poolId: poolData?.id,
              organizationId: orgIdState,
              stageId: stageId,
              pageId: 1,
              branchId: selectedBranch,
              roundId: 1, // Round and Page IDs will be set at 1.
            },
            headers,
          )
        : getPoolPlayerAndMatches(
            {
              poolId: poolData?.id,
              organizationId: orgIdState,
              pageId: 1,
              branchId: selectedBranch,
              roundId: 1, // Round and Page IDs will be set at 1.
            },
            headers,
          ),
    enabled: !!headers?.encryptedHeader && !!orgIdState,
    refetchOnWindowFocus: false,
  });


  const playersToDisplay = useMemo(() => {
    if (!data || !data.bracketDetail || !data.bracketDetail?.matches || !isNonEmptyObject(data.bracketDetail.matches))
      return [];
    return Object.values(data?.bracketDetail?.matches)?.reduce((acc, curr) => {
      acc.push(curr.p1Data, curr.p2Data);
      return acc;
    }, []);
  }, [data.bracketDetail, selectedBranch]);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleValueChange = value => {
    setCurrentValue(value);
  };
  const handleChange = value => {
    handleValueChange(value);
    // call method, if it exists
    if (onChange) onChange(value);
    // close, after all tasks are finished
    handleClose();
  };

  // Input state and functions
  const inputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const handleQuerySeach = e => {
    setSearchQuery(e?.target?.value?.toLowerCase());
  };

  // Filtering player according to the following criteria:
  // 1. Filtering the selected player as we need to swap with some other player
  // 2. Filtering "No Show Players"
  // 3. Filtering according to user search input
  const filterPlayerList = player => {
    return (
      player.userId !== playerToSwap?.userId &&
      player.aTag !== NO_SHOW_PLAYER &&
      player?.nickname?.toLowerCase()?.includes(searchQuery)
    );
  };

  // To autofocus the search input when dropdown opens
  useEffect(() => {
    if (open) inputRef?.current?.focus();
  }, [open]);

  return (
    <S.SelectContainer ref={ref} hasPlayer={!!selectedPlayerToSwapWith}>
      <S.SelectLabelButton onClick={handleOpen}>
        {!!selectedPlayerToSwapWith ? (
          <Flex alignItems="center" columnGap="14px">
            <Avatar src={selectedPlayerToSwapWith?.avatarUrl} alt={`${selectedPlayerToSwapWith?.nickname} avatar`} />
            <div>{selectedPlayerToSwapWith.nickname}</div>
          </Flex>
        ) : (
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" columnGap="10px">
              <Swap style={{ height: "34px" }} />
              <Text fontSize="16px">Select Player</Text>
            </Flex>

            <S.CaretContainer open={open}>
              <CaretArrow />
            </S.CaretContainer>
          </Flex>
        )}
      </S.SelectLabelButton>
      <S.DropdownStyle isVisible={open}>
        <S.SearchContainer onClick={() => inputRef?.current?.focus()}>
          <Flex alignItems="center" columnGap="10px">
            <MaginfyingGlass />
            <S.SearchInputDivier />
          </Flex>

          <S.SearchInput ref={inputRef} onChange={handleQuerySeach} />
        </S.SearchContainer>
        <S.SelectFiltersContainer hide={!branchOptions?.length}>
          {branchOptions.map(option => (
            <S.FilterButton active={selectedBranch === option.value} onClick={() => setSelectedBranch(option.value)}>
              Branch {option.value}
            </S.FilterButton>
          ))}
        </S.SelectFiltersContainer>
        {isLoadingPlayers ? (
          <Container marginTop="12px">
            <Flex flexDirection="column" rowGap="10px">
              <Flex alignItems="center" columnGap="14px">
                <LoadingSkeleton borderRadius="999px" height="34px" width="34px" />
                <LoadingSkeleton height="17px" width="150px" />
              </Flex>
              <Flex alignItems="center" columnGap="14px">
                <LoadingSkeleton borderRadius="999px" height="34px" width="34px" />
                <LoadingSkeleton height="17px" width="150px" />
              </Flex>
              <Flex alignItems="center" columnGap="14px">
                <LoadingSkeleton borderRadius="999px" height="34px" width="34px" />
                <LoadingSkeleton height="17px" width="150px" />
              </Flex>
            </Flex>
          </Container>
        ) : (
          <S.PlayerListContainer>
            {playersToDisplay?.filter(filterPlayerList)?.map((player, index) => (
              <S.DropdownItem
                onClick={() => handleChange(player)}
                active={player.userId === currentValue.userId}
                key={index}
              >
                <Flex alignItems="center" columnGap="15px">
                  <Avatar src={player.avatarUrl} alt={`${player.nickname} avatar`} />
                  <span>{player.nickname || "N/A"}</span>
                </Flex>
              </S.DropdownItem>
            ))}
          </S.PlayerListContainer>
        )}
      </S.DropdownStyle>
    </S.SelectContainer>
  );
};

export default PlayerSelect;
