import React, { useRef } from "react";
import useOnClickOutside from "../../../hooks/useClickOutside";

import * as S from "./styles";

export const Actions = ({
  closeActionsMenu,
  showActions,
  children,
  left,
  right,
  bottom,
  top,
  minWidth,
  calendarContainer = false,
  calendarContainerRightCol = false,
}) => {
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (closeActionsMenu) {
      closeActionsMenu();
    }
  });

  return (
    <S.ActionsContainer
      calendarContainer={calendarContainer}
      calendarContainerRightCol={calendarContainerRightCol}
      isOpen={showActions}
      left={left}
      right={right}
      bottom={bottom}
      top={top}
      minWidth={minWidth}
      ref={ref}
    >
      {children}
    </S.ActionsContainer>
  );
};
