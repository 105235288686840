import React from "react";
import styled from "styled-components";
import { HashRouter, Switch, Route } from "react-router-dom";
import AppRouter from "./views/Routes";
import { NotFoundPage } from "./views/error/404Page";
import { MaintenancePage } from "./views/error/MaintenancePage";
import { SignInPage } from "./views/SignIn/SignInPage";
import { WalletProvider } from "./providers/WalletProvider";
import { SessionProvider } from "./providers/SessionProvider";
import { AppStateProvider } from "./providers/AppStateProvider";
import { LoginProvider } from "./providers/Loginprovider";
import CustomQueryClientProvider from "./components/wrappers/QueryWrapper";
import { Toaster } from "react-hot-toast";

const AppWrapper = styled.div`
  background: white;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;

  & .image-hover-opacity:hover {
    opacity: 0.8;
  }

  & .image-hover-opacity {
    opacity: 0.5;
    cursor: pointer;
  }
`;

function App() {
  return (
    <HashRouter>
      <Toaster
        toastOptions={{
          style: {
            background: "transparent",
            padding: 0,
            maxWidth: "unset",
            boxShadow: "none"
          },
        }}
      />
      <AppWrapper>
        <WalletProvider>
          <AppStateProvider>
            <SessionProvider>
              <LoginProvider>
                <CustomQueryClientProvider>
                  <Switch>
                    <Route exact path="/404" component={NotFoundPage} />
                    <Route exact path="/maintenance" component={MaintenancePage} />
                    <Route exact path="/signin" component={SignInPage} />
                    <AppRouter />
                  </Switch>
                </CustomQueryClientProvider>
              </LoginProvider>
            </SessionProvider>
          </AppStateProvider>
        </WalletProvider>
      </AppWrapper>
    </HashRouter>
  );
}

export default App;
