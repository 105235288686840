import styled from "styled-components";
import { breakpoints } from "../../themes";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  padding: 0px 76px 10px 81px;
  ${breakpoints.down(breakpoints.laptopL)} {
    padding: 0px 38px 10px 47px;
  }
  ${breakpoints.down(breakpoints.laptop)} {
    padding: 0px 16px 10px 16px;
  }
`;

export const TableContainer = styled.div`
  &::-webkit-scrollbar {
    width: 2px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #3f2faa;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #342788f0;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #342788;
  }

  margin-top: 56px;
  overflow-x: auto;
`;

export const TableHeader = styled.div`
  display: grid;
  color: #a498ed;
  padding-left: 21px;
  margin-bottom: 42px;
  min-width: 700px !important;
  grid-template-columns: 1fr 1.5fr 1fr 1fr;
`;

// export const MainContainer = styled.div`
//   width: 100%;
//   min-height: 48px;
//   display: grid;
//   grid-template-columns: 1fr 3fr 3fr repeat(2, 2fr); //1fr 6fr repeat(3, 2fr)
//   background-color: #342788;
//   align-items: center;
//   div:last-child {
//     justify-content: center;
//   }

//   &:nth-child(odd) {
//     border-top-left-radius: 10px;
//     border-top-right-radius: 10px;
//   }

//   &:nth-child(even) {
//     border-bottom-left-radius: 10px;
//     border-bottom-right-radius: 10px;
//   }

//   // HERE
//   @media (max-width: 468px) {
//     width: 768px;
//     grid-template-columns: 1fr 4fr repeat(3, 2fr);
//   }
// `;

// export const ScoreWrapper = styled.div`
//   background: ${props => (props.isWinner ? "#46AD3D" : "#ED6140")};
//   width: 26px;
//   height: 26px;
//   border-radius: 4px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

export const UserRowWrapper = styled.div`
  cursor: pointer;
  &:hover {
    div {
      background-color: #3F2FAAb3;
    }
  }
`;
