import React, { useEffect, useState } from "react";
import { z } from "zod";
import moment from "moment/moment";

import * as S from "../../styles";
import Text from "../../../../common/Text/Text";
import Container from "../../../../common/Container/Container";
import ErrorTooltip from "../../../../common/ErrorTooltip";
import CustomDateTimePicker from "../../../../common/CustomDateTimePicker";
import { Tooltip } from "antd";
import { POOL_MODES } from "../../../../../constants";

const SecondStep = ({ register, errors = {}, currentInputValues = {}, setValue, setError, clearErrors = () => {} }) => {
  const { registrationStartDate, registrationEndDate, tournamentStartDate, tournamentEndDate } = currentInputValues;
  useEffect(() => {
    clearErrors(["registrationStartDate", "registrationEndDate", "tournamentStartDate", "tournamentEndDate"]);
    let formattedRegStartDate = registrationStartDate ? moment(registrationStartDate) : null;
    let formattedRegEndDate = registrationEndDate ? moment(registrationEndDate) : null;
    let formattedTournamentStartDate = tournamentStartDate ? moment(tournamentStartDate) : null;
    let formattedTournamentEndDate = tournamentEndDate ? moment(tournamentEndDate) : null;

    if (formattedRegStartDate && !formattedRegEndDate) {
      setError("registrationEndDate", {
        type: "custom",
        message: "Registration end date is required if registration start date has been provided",
      });
    }
    if (formattedRegEndDate && !formattedRegStartDate) {
      setError("registrationStartDate", {
        type: "custom",
        message: "Registration start date is required if registration end date has been provided",
      });
    }

    if (formattedRegStartDate && formattedRegEndDate && formattedRegStartDate.isAfter(formattedRegEndDate)) {
      setError("registrationStartDate", {
        type: "custom",
        message: "Registration start date cannot be after registration end date",
      });
    }

    if (formattedTournamentStartDate) {
      if (formattedRegEndDate && formattedRegEndDate.isAfter(formattedTournamentStartDate)) {
        setError("registrationEndDate", {
          type: "custom",
          message: "Registration end date cannot be after tournament start date",
        });
      }

      if (formattedTournamentEndDate && formattedTournamentStartDate.isAfter(formattedTournamentEndDate)) {
        setError("tournamentStartDate", {
          type: "custom",
          message: "Tournament start date cannot be tournament start date",
        });
      }
    }
  }, [registrationStartDate, registrationEndDate, tournamentStartDate, tournamentEndDate]);

  const isPoolModeLeaderboard = currentInputValues?.poolFormat === POOL_MODES.LEADERBOARD;

  if (!setValue) return <div />;

  return (
    <Container flex flexDirection="column" rowGap="27px">
      <Container>
        <S.UnderlineContainer>
          <Text fontSize="16px">Registration date</Text>
        </S.UnderlineContainer>

        <Container flex marginTop="14px" columnGap="23px" justifyContent="space-between">
          <Container flex flexDirection="column" rowGap="10px">
            {/* Registration Start Date */}
            <Container flex position="relative">
              <Text>Start</Text>
              {errors.registrationStartDate && (
                <ErrorTooltip top="2px" message={errors.registrationStartDate?.message} />
              )}
            </Container>
            <Tooltip
              title={isPoolModeLeaderboard ? "Register dates are not available for leaderboard type tournaments" : ""}
              color="#7265C8"
            >
              <S.CalendarPickerContainer hasError={!!errors.registrationStartDate}>
                <CustomDateTimePicker
                  value={currentInputValues.registrationStartDate}
                  onChange={v => {
                    clearErrors("registrationStartDate");
                    if (isNaN(v)) {
                      setValue("registrationStartDate", 0);
                    } else {
                      setValue("registrationStartDate", v);
                    }
                  }}
                  disabled={isPoolModeLeaderboard}
                  format="dd/MM/y          hh:mm a"
                  calendarIconStyles={{
                    position: "absolute",
                    left: currentInputValues.registrationStartDate ? "120px" : "100px",
                    top: "0px",
                  }}
                  allowClear
                />
              </S.CalendarPickerContainer>
            </Tooltip>
          </Container>

          {/* Registration End Date */}
          <Container flex flexDirection="column" rowGap="10px">
            <Container flex position="relative">
              <Text>End</Text>
              {errors.registrationEndDate && <ErrorTooltip top="2px" message={errors.registrationEndDate?.message} />}
            </Container>
            <Tooltip
              title={isPoolModeLeaderboard ? "Register dates are not available for leaderboard type tournaments" : ""}
              color="#7265C8"
            >
              <S.CalendarPickerContainer hasError={!!errors.registrationEndDate}>
                <CustomDateTimePicker
                  value={currentInputValues.registrationEndDate}
                  onChange={v => {
                    clearErrors("registrationEndDate");
                    if (isNaN(v)) {
                      setValue("registrationEndDate", 0);
                    } else {
                      setValue("registrationEndDate", v);
                    }
                  }}
                  allowClear
                  disabled={isPoolModeLeaderboard}
                  format="dd/MM/y          hh:mm a"
                  calendarIconStyles={{
                    position: "absolute",
                    left: currentInputValues.registrationEndDate ? "120px" : "100px",
                    top: "0px",
                  }}
                />
              </S.CalendarPickerContainer>
            </Tooltip>
          </Container>
        </Container>
      </Container>

      <Container marginTop="64px">
        <S.UnderlineContainer>
          <Text fontSize="16px">Tournament date</Text>
        </S.UnderlineContainer>

        <Container flex marginTop="14px" columnGap="23px" justifyContent="space-between">
          <Container flex flexDirection="column" rowGap="10px">
            {/* Tournament Start Date */}
            <Container flex position="relative">
              <Text>Start</Text>
              {errors.tournamentStartDate && <ErrorTooltip top="2px" message={errors.tournamentStartDate?.message} />}
            </Container>
            <S.CalendarPickerContainer hasError={!!errors.tournamentStartDate}>
              <CustomDateTimePicker
                value={currentInputValues.tournamentStartDate}
                onChange={v => {
                  clearErrors("tournamentStartDate");
                  setValue("tournamentStartDate", v);
                }}
                format="dd/MM/y          hh:mm a"
                calendarIconStyles={{
                  position: "absolute",
                  left: currentInputValues.tournamentStartDate ? "120px" : "100px",
                  top: "0px",
                }}
              />
            </S.CalendarPickerContainer>
          </Container>

          {/* Tournament End Date */}
          <Container flex flexDirection="column" rowGap="10px">
            <Container flex position="relative">
              <Text>End</Text>
              {errors.tournamentEndDate && <ErrorTooltip top="2px" message={errors.tournamentEndDate?.message} />}
            </Container>
            <S.CalendarPickerContainer hasError={!!errors.tournamentEndDate}>
              <CustomDateTimePicker
                value={currentInputValues.tournamentEndDate}
                onChange={v => {
                  clearErrors("tournamentEndDate");
                  setValue("tournamentEndDate", v);
                }}
                format="dd/MM/y          hh:mm a"
                calendarIconStyles={{
                  position: "absolute",
                  left: currentInputValues.tournamentEndDate ? "120px" : "100px",
                  top: "0px",
                }}
              />
            </S.CalendarPickerContainer>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default SecondStep;
