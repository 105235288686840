import React from "react";
import { Checkbox } from "antd";
import styled from "styled-components";

const CustomCheckboxGroup = styled(Checkbox.Group)`

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6c47ff;
    border-color: transparent;
  }

  & > label {
    color: #ffffff;
    text-transform: capitalize;
  }
`;

export const CheckBox = ({ options = [], onChange, value }) => {
  return (
    <CustomCheckboxGroup
      options={options.map(option => ({ label: option.name, value: option.value }))}
      value={value}
      onChange={onChange}
      
    />
  );
};
