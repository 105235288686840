import styled from "styled-components";
import { Button } from "../../../common/Button";
import { Popover } from "antd";

export const PlayerRowContainer = styled.div`
  height: 88px;
  border-radius: 6px;
  background-color: #3f2faa;
  padding: 0 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const PlayerData = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`;

export const PlayerName = styled.span`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

export const RemovePlayerButton = styled(Button)`
  width: 109px;
  height: 37px;
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: 32px;
  background: #4e3cca;
`;

export const PlayerReportModalRow = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 425px) {
    align-items: start;
    row-gap: 6px;
    flex-direction: column;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 4px;
  height: 40px;
  color: #cccccc;
  border-radius: 8px;
  border: 1px solid #cccccc66;
  background-color: transparent;
`;

export const ReportRow = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  border-radius: 8px;
  background-color: #3f2faa;
`;

export const ReportRowContainer = styled.div`
  margin-bottom: 14px;
  background: ${props => (props.isHeaderCol ? "transparent" : "#3f2faa")};
  color: ${props => (props.isHeaderCol ? "#A498ED" : "#fff")};
  padding: 16px 24px;
  padding-right: ${props => (props.isEdit ? "0px" : "24px")};
  border-radius: 4px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    /* width: 2px;
    height: 8Wpx; */
    height: 0;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #3f2faa;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #342788f0;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #342788;
  }

  div.data-row {
    flex-basis: 140px;
    flex-grow: 0;
    flex-shrink: 0;

    white-space: nowrap;
    overflow: hidden;
    margin: 0 20px;

    /* text-align: center; */
  }

  .edit-field {
    width: 127px;
    margin-left: 16px;
    border: 1px solid #a498ed !important;
    background-color: transparent !important;
  }

  form {
    /* style={{ display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: "10px" }} */
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: space-between;
  }
`;
