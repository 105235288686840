import { useMutation } from "@tanstack/react-query";

import * as S from "./styles";
import Text from "../../components/common/Text/Text";
import Container from "../../components/common/Container/Container";
import { BackArrow } from "../../assets/svg";
import Form from "../../components/views/newGame/form";
import Preview from "../../components/views/newGame/preview";
import { useEffect, useRef, useState } from "react";
import NavigationBar from "../../components/views/newTournament/navigationBar";
import { Button, ButtonUnstyled } from "../../components/common/Button";
import { createGame } from "../../api/game";
import { useGetSecHeaders } from "../../hooks/useHeaders";
import { useLogin } from "../../providers/Loginprovider";
import { notifyError, notifySuccess } from "../../components/common/ToastComponent";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../components/common/SpinningLoader";
import { useHistory, useParams } from "react-router-dom";
import { useSession } from "../../providers/SessionProvider";

const steps = [
  {
    id: 0,
    name: "General Info",
    fields: ["name", "accPlaceholder", "platformIdentifier", "logo", "sidebarIcon"],
  },
  {
    id: 1,
    name: "Style",
    fields: ["gameCard", "mainBanner"],
  },
  {
    id: 2,
    name: "Tournament detail",
    fields: ["backBanner", "frontBanner", "thumbnail", "tournamentCardGameLogo"],
  },
  {
    id: 3,
    name: "Winners bg",
    fields: ["backBanner", "frontBanner", "thumbnail"],
  },
];

const NewGame = () => {
  const { orgIdState } = useLogin();
  const headers = useGetSecHeaders();
  const history = useHistory();

  const [previewData, setPreviewData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const { session } = useSession();

  const params = useParams();
  const [existingGameData, setExistingGameData] = useState();
  const [isLoadingExistingGameData, setisLoadingExistingGameData] = useState(false);

  // useEffect(() => {
  //   // // Checking if there is an existing pool Id in the params which would indicate that we are cloning a pool
  //   if (!params?.gameId || !headers?.token) return;
  //   // using react query here was causing issues hence using the fetch function directly
  //   async function getData() {
  //     try {
  //       setisLoadingExistingGameData(true);
  //       // get game data here
  //       // const resp = await getPoolById({ poolId: params?.poolId, orgId: orgIdState }, headers);
  //       if (resp.error) {
  //         notifyError("Something went wrong");
  //         history.push("/");
  //       } else {
  //         setExistingGameData(resp);
  //       }
  //     } catch (err) {
  //       notifyError(err.message || err);
  //       history.push("/");
  //     } finally {
  //       setisLoadingExistingGameData(false);
  //     }
  //   }
  //   getData();
  // }, [params?.poolId, headers]);

  const { mutate: createGameMutation, isLoading: loadingGameCreation } = useMutation({
    mutationFn: variables => {
      console.log(variables);
      return createGame(
        {
          organizationId: orgIdState,
          body: variables.data,
        },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.data?.message || e?.message || e?.data);
    },
    onSuccess: (data, variables) => {
      notifySuccess(data?.message || `Game created successfully`);
      history.push(`/games`);
    },
  });

  async function processSubmission(data) {
    const formattedData = {
      name: data.name,
      accPlaceholder: data.accPlaceholder,
      platformIdentifier: data.platformIdentifier,
      logoImgUrl: data.logo,
      sidebarImgUrl: data.sidebarIcon,
      gameImgUrl: data.gameCard,
      mainBannerImgUrl: data.mainBanner,
      backBannerImgUrl: data.backBanner,
      frontBannerImgUrl: data.frontBanner,
      thumbnailImgUrl: data.thumbnail,
      winnerImgUrl: data.winnerBg,
      thumbnailLogoImgUrl: data.thumbnail,
    };
    // console.log("Submitted Data: ", existingPoolData);
    // return;
    createGameMutation({ data: formattedData });
  }

  const nextBtnRef = useRef();
  const prevBtnRef = useRef();
  const submitBtnRef = useRef();

  useEffect(() => {
    if (session?.user?.userType !== "SUPERUSER") {
      notifyError("You are not authorized for this action");
      history.push("/games");
    }
  }, [session?.user]);

  return (
    <>
      <S.MainContainer>
        <ButtonUnstyled style={{ padding: 0 }} onClick={() => history.goBack()}>
          <BackArrow />
        </ButtonUnstyled>
        <Container flex columnGap="17px" alignItems="center" marginTop="8px">
          <Text fontSize="38px">New Game</Text>
        </Container>
        <NavigationBar
          currentStep={currentStep}
          steps={steps}
          containerStyle={{ marginTop: "25px", marginBottom: "35px" }}
        />
        {params?.gameId && isLoadingExistingGameData ? (
          <SpinnerLoaderWrapper>
            <SpinnerLoader />
          </SpinnerLoaderWrapper>
        ) : (
          <S.SectionContainer isConfirmScreen={currentStep === 3}>
            <Form
              {...{
                previewData,
                currentStep,
                setCurrentStep,
                setPreviewData,
                processSubmission,
                nextBtnRef,
                prevBtnRef,
                submitBtnRef,
                steps,
                existingGameData: existingGameData?.data,
              }}
            />
            <Container flex flexDirection="column" justifyContent="space-between" rowGap="30px">
              <S.PreviewCardSection>
                <Preview previewData={previewData} currentStep={currentStep} />
              </S.PreviewCardSection>
            </Container>
          </S.SectionContainer>
        )}
      </S.MainContainer>

      {params?.gameId && isLoadingExistingGameData ? null : (
        <Container flex justifyContent="center" marginTop="63px" columnGap="23px">
          {currentStep > 0 && (
            <ButtonUnstyled
              type="button"
              onClick={() => prevBtnRef?.current?.click()}
              width="76px"
              height="36px"
              borderRadius="30px"
            >
              Back
            </ButtonUnstyled>
          )}
          {currentStep < steps.length - 1 && (
            <Button
              type="button"
              onClick={() => nextBtnRef?.current?.click()}
              width="76px"
              height="36px"
              borderRadius="30px"
            >
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button
              onClick={() => submitBtnRef?.current?.click()}
              disabled={loadingGameCreation}
              width="76px"
              height="36px"
              borderRadius="30px"
            >
              {loadingGameCreation ? <SpinnerLoader /> : "Submit"}
            </Button>
          )}
        </Container>
      )}
    </>
  );
};

export default NewGame;
