import styled from "styled-components";

export const CounterContainer = styled.div`
  height: 40px;
  background-color: #3f2faa;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  border: ${props => (props.hasError ? "1px solid #ED6140" : "unset")};

  button {
    width: 26px;
    height: 26px;
    border: 2px solid #4e3cca;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
  }

  button:first-child {
    background-color: transparent;
  }
  button:last-child {
    background-color: #6c47ff;
  }
`;

export const Input = styled.input`
  background-color: transparent;
  color: #fff;
  border: none;
  outline: none;
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
