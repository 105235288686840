import React from "react";
import * as S from "./styles";
import WinnersImg from "../../../../../../assets/png/winners_placeholder.png"
import LeaderboardImg from "../../../../../../assets/png/leaderboard_placeholder.png"


const FourthPreview = ({ previewData = {} }) => {
  const { winnerBg } = previewData;
  return (
    <S.Container>
      <S.ImageContainer bg={winnerBg}>
        <S.SmallTopBar />
        <S.ButtonRow>
          <S.PillButton>Bracket</S.PillButton>
          <S.PillButton>Players</S.PillButton>
          <S.PillButton active>Winners</S.PillButton>
          <S.PillButton>Rules</S.PillButton>
        </S.ButtonRow>
        <S.WinnersRow >
          <img src={WinnersImg} height="120px" width="100%" />
        </S.WinnersRow>
        <img src={LeaderboardImg} width="90%" style={{position: "absolute", bottom: "0px", zIndex: "999"}}/>
      </S.ImageContainer>
    </S.Container>
  );
};

export default FourthPreview;
