import React from "react";
import styled from "styled-components";

const ContainerStyled = styled.div`
  ${props => `
    margin-top: ${props.marginTop ? props.marginTop : "unset"};
    margin-left: ${props.marginLeft ? props.marginLeft : "unset"};
    margin-right: ${props.marginRight ? props.marginRight : "unset"};
    margin-bottom: ${props.marginBottom ? props.marginBottom : "unset"};
    padding-top: ${props.paddingTop ? props.paddingTop : "unset"};
    padding-left: ${props.paddingLeft ? props.paddingLeft : "unset"};
    padding-right: ${props.paddingRight ? props.paddingRight : "unset"};
    padding-bottom: ${props.paddingBottom ? props.paddingBottom : "unset"};
    height: ${props.height ? props.height : "unset"};
    width: ${props.width ? props.width : "unset"};

    display: ${props.flex ? "flex" : "block"};
    flex-direction: ${props.flex ? (props.flexDirection ? props.flexDirection : "row") : "unset"};
    column-gap: ${props.flex && props.columnGap ? props.columnGap : "unset"};
    row-gap: ${props.flex && props.rowGap ? props.rowGap : "unset"};
    align-items: ${props.flex && props.alignItems ? props.alignItems : "unset"};
    justify-content: ${props.flex && props.justifyContent ? props.justifyContent : "unset"};
    position:  ${props.position ? props.position : "static"};
    background:  ${props.background ? props.background : "unset"};
    border-radius:  ${props.radius ? props.radius : "unset"};
  `}
`;

const RelativeStyled = styled.div`
  position: relative;
`;

export const AbsoluteStyled = styled.div`
  position: absolute;
  top: ${props => props.top || "initial" };
  bottom: ${props => props.bottom || "initial" };
  left: ${props => props.left || "initial" };
  right: ${props => props.right || "initial" };
`

const Container = props => {
  const { children, className = "", ...rest } = props;
  return (
    <ContainerStyled {...rest} className={className}>
      {children}
    </ContainerStyled>
  );
};

export const Relative = ({ children }) => {
  return <RelativeStyled>{children}</RelativeStyled>;
};

export const Absolute = ({children, ...rest}) => {
  return <AbsoluteStyled {...rest}>{children}</AbsoluteStyled>
}

export default Container;
