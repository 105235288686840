import React from "react";
import styled from "styled-components";
import { Select } from "antd";
import { useState } from "react";

const { Option } = Select;

const DropdownContainer = styled.div`
  height: 37px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3f2faa;
  gap: 10px;
  border: ${props => (props.hasError ? "1px solid #ed6140" : "unset")};
  ${props => ({ ...props.styles })};
`;

const DropdownSelectContainer = styled.div`
  position: relative;
  display: block;
  border: none;
  width: 100%;
  line-height: 3;
  overflow: hidden;
  border-radius: 0.25em;
`;

const DropdownSelect = styled(Select)`
  appearance: none;
  background: transparent;
  border: none;
  height: 100%;
  width: 100%;
  padding-left: 0px;
  display: flex;
  .ant-select-selector {
    ${props => ({ ...props.selectStyles })}
    color: #fff;
  }

  .ant-select-dropdown-menu-item {
    color: #fff;
  }

  .ant-select-selection-placeholder {
    color: #ffffff66;
  }

  .ant-select-selection-item {
    ${props => ({ ...props.selectionItemCss })}
  }

  .ant-select-arrow {
    color: #A498ED;
  }
`;

const DropdownOption = styled(Option)``;

export const Dropdown = ({
  options=[],
  onChange,
  value,
  placeholder,
  id = "",
  setDefaultNull = false,
  containerStyles = {},
  selectStyles = {},
  disabled = false,
  className = "",
  hasError = false,
  defaultValue,
  dropdownStyles = {},
  dropdownOptionStyles = {},
  selectionItemCss = {},
  hideArrow = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DropdownContainer styles={containerStyles} hasError={hasError}>
      <DropdownSelectContainer onClick={toggleOpen} isOpen={isDropdownOpen} hideArrow={hideArrow}>
        <DropdownSelect
          id={id}
          defaultValue={defaultValue ? defaultValue : setDefaultNull ? undefined : options[0]?.value}
          placeholder={placeholder}
          bordered={false}
          dropdownStyle={{
            zIndex: 9999,
            backgroundColor: "#3f2faa",
            color: "#fff",
            borderRadius: "4px",
            ...dropdownStyles,
          }}
          selectionItemCss={{ ...selectionItemCss }}
          onChange={onChange}
          value={value}
          selectStyles={{ ...selectStyles, color: "#fff !important" }}
          disabled={disabled}
          className={className}
          onDropdownVisibleChange={e => setIsDropdownOpen(e)}
        >
          {options.map(({ name, element: Element, value }, index) => {
            return (
              <DropdownOption
                key={index}
                value={value}
                style={{ color: "#fff", backgroundColor: "#3f2faa", ...dropdownOptionStyles }}
              >
                {Element ? Element : name}
              </DropdownOption>
            );
          })}
        </DropdownSelect>
      </DropdownSelectContainer>
    </DropdownContainer>
  );
};
