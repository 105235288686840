import styled from "styled-components";

export const DataRow = styled.div`
  height: 50px;
  padding: 0 28px;
  display: flex;
  align-items: center;
  background: #3f2faa;
  border-radius: 10px;
  justify-content: space-between;
`;
