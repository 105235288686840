import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
import Web3Modal /* , { local } */ from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Web3Provider } from "@ethersproject/providers";
import { INFURA_ID } from "../constants/env";
import { NETWORKS } from "../constants/networks";
// import ether from "ethers";

const WalletAugmentedContext = React.createContext();

function useWalletAugmented() {
  // get the context
  const context = useContext(WalletAugmentedContext);

  // if `undefined`, throw an error
  if (context === undefined || context === null) {
    throw new Error("useWalletContext was used outside of its Provider");
  }

  return context;
}

function WalletAugmented({ children }) {
  const [injectedProvider, setInjectedProvider] = useState("");
  const networkRpcs = {};
  Object.keys(NETWORKS)?.forEach(networkName => {
    networkRpcs[NETWORKS[networkName].chainId] = NETWORKS[networkName].rpcUrl;
  });
  const web3Modal = new Web3Modal({
    // network: "mainnet", // optional
    cacheProvider: true, // optional
  });

  // if web3modal is added to the dependency array it makes flick and bug the "dashboard"
  const loadWeb3Modal = useCallback(async () => {
    try {
      const provider = await web3Modal.connect();
      setInjectedProvider(new Web3Provider(provider, "any"));
    } catch (err) {
      console.log("User closed wallet modal");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInjectedProvider]);

  // const logoutOfWeb3Modal = useCallback(async () => {
  const logoutOfWeb3Modal = async () => {
    web3Modal.clearCachedProvider();
    localStorage.removeItem("walletconnect");
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1);
    // }, [web3Modal]);
  };

  const contextValue = useMemo(
    () => ({ injectedProvider, loadWeb3Modal, logoutOfWeb3Modal, web3Modal }),
    [injectedProvider, web3Modal],
  );

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  return <WalletAugmentedContext.Provider value={contextValue}>{children}</WalletAugmentedContext.Provider>;
}

function WalletProvider({ children }) {
  return <WalletAugmented>{children}</WalletAugmented>;
}

export { useWalletAugmented as useWallet, WalletProvider };
