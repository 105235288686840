import axiosClient from "./axiosClient";

export function generateInvitationalCodes({ organizationId, poolId, invitationalCodeAmount }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!invitationalCodeAmount || invitationalCodeAmount === 0)
    throw new Error("Please select at least 1 invitational code");
  return axiosClient
    .put(
      `/admPanel/pool/invitationalCode/${organizationId}/${poolId}`,
      {
        poolId: poolId,
        quantity: invitationalCodeAmount,
      },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function removeInvitationalCode({organizationId, poolId, invitacionalCodesArray = []}, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!poolId) throw new Error("Please enter a valid pool id");
  return axiosClient
    .put(
      `/admPanel/pool/execPoolAction/${organizationId}/${poolId}`,
      {
        action: "remove_inv_code",
        data: {
          invitationalCodes: invitacionalCodesArray
        },
      },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}