import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { FormDataSchema } from "./schema";

import { isNonEmptyObject } from "../../../../utilities";
import { assetUpload, getOrganizationGameAssets } from "../../../../api/files";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { notifyError } from "../../../common/ToastComponent";
import { useLogin } from "../../../../providers/Loginprovider";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import FirstStep from "./steps/FirstStep";
import SecondStep from "./steps/SecondStep";
import ThirdStep from "./steps/ThirdStep";
import FourthStep from "./steps/FourthStep";

const Form = ({
  processSubmission,
  previewData,
  setPreviewData,
  currentStep,
  setCurrentStep,
  nextBtnRef,
  prevBtnRef,
  submitBtnRef,
  steps = [],
  existingGameData,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(FormDataSchema),
  });

  watch(data => setPreviewData(data));
  const currentInputValues = getValues();

  const { orgIdState } = useLogin();
  const headers = useGetSecHeaders();

  function prev() {
    clearErrors();
    if (currentStep === 0) return;
    setCurrentStep(currentStep - 1);
  }
  async function next() {
    if (currentStep >= steps.length - 1) {
      return;
    }

    const fields = steps[currentStep].fields;
    const output = await trigger(fields, { shouldFocus: true });
    let customValidationErrors = false;

    // if (currentStep === 0) {
    //   if (getValues("joinMode") === "team" && !getValues("teamPlayers")) {
    //     customValidationErrors = true;
    //     setError("teamPlayers", { type: "custom", message: "At least 1 team player is required" });
    //   }
    // }
    if (!output || customValidationErrors || isNonEmptyObject(errors)) return;
    setCurrentStep(currentStep + 1);
  }

  const queryClient = useQueryClient();

  const [assetTypeBeingUploaded, setAssetTypeBeingUploaded] = useState("");
  const { mutate: assetUploadMutation, isLoading: isAssetUploadLoading } = useMutation({
    mutationFn: variables => {
      setAssetTypeBeingUploaded(variables.assetType); // Will help in showing loader on the correct input
      return assetUpload(
        {
          organizationId: orgIdState,
          type: "games",
          subType: `tmp`,
          banner: variables.file, // using name banner as the assetUpload API function is expecting this name
        },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: data => {
      setValue(assetTypeBeingUploaded, data.url);
    },
    onSettled: () => {
      setAssetTypeBeingUploaded("");
    },
  });

  const stepProps = {
    register,
    errors,
    currentInputValues,
    previewData,
    setValue,
    setError,
    clearErrors,
    existingGameData,
    watch,
    assetUploadMutation,
    assetTypeBeingUploaded,
    isAssetUploadLoading,
    getValues,
  };

  //   useEffect(() => {
  //     if (existingGameData) {
  //       populateFieldsWithExistingData(existingGameData);
  //     }
  //   }, [existingGameData]);

  //   function populateFieldsWithExistingData(data = {}) {
  //     // Setting fields with data
  //     // Object.entries(existingGameData).forEach(([name, value]) => setValue(name, value));

  //     // Some fields in the form schema does not have the same preoperty name as the data received from the BE
  //     // So we're setting the data individually here
  //     setValue("organizationId", data.organizationId);
  //     setValue("subtitle", data.description);
  //     setValue("title", data.name);
  //     setValue("gameId", data.game);
  //     setValue("prizePool", data.poolPrize);
  //     setValue("entry", data.entrance);
  //     setValue("joinMode", data.joinMode?.toLowerCase());
  //     if (data.joinMode === "TEAM") {
  //       setValue("teamPlayers", data.gameDetails?.teamUsersQty);
  //     }
  //     if (data.poolMode === POOL_MODES.LEADERBOARD) {
  //       setValue("poolFormat", "LEADERBOARD");
  //     } else if (data.poolMode === POOL_MODES.ROUND_ROBIN) {
  //       setValue("poolFormat", "ROUND_ROBIN");
  //     } else {
  //       setValue("poolFormat", "SINGLE_ELIMINATION");
  //     }
  //     setValue("poolStageType", data.poolStageType);
  //     setValue("prizeToken", data.tokenSymbol);
  //     setValue("maxPlayers", data.maxPlayers);

  //     setValue("tournamentStartDate", data.startDate);
  //     setValue("tournamentEndDate", data.endDate);
  //     setValue("registrationStartDate", data.registrationStartDate);
  //     setValue("registrationEndDate", data.registrationEndDate);

  //     setValue("winnerCount", data.winnersFees?.length);
  //     setValue("percentageList", data.winnersFees);
  //     setValue("rules", data.rules);

  //     // Not populating image fields as suggested by Dani

  //     // setValue("thumbnail", data.thumbnail)
  //     // setValue("bannerBgBack", data.detailHeaderBackImage)
  //     // setValue("bannerBgFront", data.detailHeaderFrontImage)
  //   }

  return (
    <div>
      <form onSubmit={handleSubmit(processSubmission)}>
        {/* First Step */}
        {currentStep === 0 && <FirstStep {...stepProps} />}
        {currentStep === 1 && <SecondStep {...stepProps} />}
        {currentStep === 2 && <ThirdStep {...stepProps} />}
        {currentStep === 3 && <FourthStep {...stepProps} />}

        <button className="hidden" type="button" onClick={prev} ref={prevBtnRef}>
          Back
        </button>
        {currentStep < steps.length - 1 && (
          <button className="hidden" type="button" onClick={next} ref={nextBtnRef}>
            Next
          </button>
        )}
        {currentStep === steps.length - 1 && (
          <button className="hidden" type="submit" ref={submitBtnRef}>
            Submit
          </button>
        )}
      </form>
    </div>
  );
};

export default Form;
