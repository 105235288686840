import React, { useEffect, useRef, useState } from "react";
import { Popover } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import * as S from "./styles";

import { changeTeamCaptain, deleteTeamMember as deleteTeamMemberApiCall } from "../../../../../api/teams";
import { DataRow } from "../../shared/styles";
import { Button, IconButton } from "../../../../common/Button";
import { Crown, ProfileIcon, ThreeDots, Trash } from "../../../../../assets/svg";
import Container from "../../../../common/Container/Container";
import Text from "../../../../common/Text/Text";
import { useGetSecHeaders } from "../../../../../hooks/useHeaders";
import { useLogin } from "../../../../../providers/Loginprovider";
import { notifyError, notifySuccess } from "../../../../common/ToastComponent";
import Avatar from "../../../../common/Avatar";
import {
  capitalizeFirstLetter,
  copyTextToClipboard,
  truncateWithDots,
  truncateWithMiddleDots,
} from "../../../../../utilities";

import { useSession } from "../../../../../providers/SessionProvider";
import { Actions } from "../../../../common/Actions/Actions";

const TeamMemberRow = ({ data = {}, teamData = {} }) => {
  const [openDelPopover, setOpenDelPopover] = useState(false);
  const [openCapPopover, setOpenCapPopover] = useState(false);
  const [toggleAction, setToggleAction] = useState(false);
  const toggleActions = () => {
    setToggleAction(!toggleAction);
  };

  const { session } = useSession();
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();

  const hideDelPopover = () => {
    setOpenDelPopover(false);
  };

  const hideCapPopover = () => {
    setOpenCapPopover(false);
  };

  const handleOpenDelChange = newOpen => {
    setOpenDelPopover(newOpen);
  };

  const handleOpenCapChange = newOpen => {
    setOpenCapPopover(newOpen);
  };

  const { mutate: deleteTeamMember, isLoading: isDeletingTeamMember } = useMutation({
    mutationFn: variables => {
      console.log(variables?.userId, variables?.teamId);
      setOpenDelPopover(false);
      return deleteTeamMemberApiCall(
        {
          teamId: variables?.teamId,
          userId: variables?.userId,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: responseData => {
      if (responseData?.error) {
        notifyError(responseData?.message);
        return;
      } else {
        notifySuccess("Team member deleted successfully");
        queryClient.invalidateQueries({ queryKey: ["team", teamData.id] });
      }
      setOpenDelPopover(false);
      toggleActions();
    },
  });

  const { mutate: setTeamMemberAsCaptain, isLoading: isSettingTeamMemberAsCaptain } = useMutation({
    mutationFn: variables => {
      setOpenCapPopover(false);
      return changeTeamCaptain({ teamId: variables?.teamId, newCaptainUserId: variables?.userId }, headers);
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: responseData => {
      if (responseData?.error) {
        notifyError(responseData?.message);
        return;
      } else {
        notifySuccess("Now player is the new captain");
        queryClient.invalidateQueries({ queryKey: ["team", teamData.id] });
      }
      setOpenCapPopover(false);
      toggleActions();
    },
  });

  const captain = teamData?.players?.find((player) => player.role === "captain");
  const captainUserId = captain ? captain?.userId : null;

  const actionMenu = [
    {
      icon: null,
      label: "Set Player as Captain",
      popoverTitle: "Are you sure you want to set this user as the captain?",
      showPopoverFn: () => {
        setOpenCapPopover(true);
      },
      open: openCapPopover,
      onOpenChange: handleOpenCapChange,
      onClick: () => {
        setTeamMemberAsCaptain({ teamId: teamData.id, userId: data.userId });
      },
      visible: session?.user?.userType === "SUPERUSER",
      disabled: data?.userId === captainUserId
    },
    {
      icon: <Trash />,
      label: "Remove player",
      popoverTitle: "Are you sure you want to delete this user from the team?",
      showPopoverFn: () => {
        setOpenDelPopover(true);
      },
      open: openDelPopover,
      onOpenChange: handleOpenDelChange,
      onClick: () => {
        deleteTeamMember({ teamId: teamData.id, userId: data.userId });
      },
      visible: true,
      disabled: data?.userId === captainUserId
    },
  ];

  const bottomRef = useRef();
  useEffect(() => {
    bottomRef?.current?.scrollIntoView({
      behavior: "smooth"
    });
  }, [toggleAction]);

  const DotMenu = ({ disabled = false }) => {
    return (
      <S.ThreeDotsContainer
        onClick={e => {
          e.preventDefault();
        }}
      >
        <S.ThreeDotsButtonContainer
          disabled={disabled}
          onClick={() => {
            if (disabled) return;
            toggleActions();
          }}
          actionIsToggled={toggleAction}
        >
          <ThreeDots />
        </S.ThreeDotsButtonContainer>
        <Actions
          showActions={toggleAction}
          right="0px"
          top="40px"
          closeActionsMenu={() => {
            setToggleAction(false);
          }}
        >
          {actionMenu
            .filter(action => action.visible)
            .map((action, index) => (
              <Popover
                key={index}
                content={
                  <Container marginTop="22px">
                    <Button
                      width="100%"
                      background="#3F2FAA"
                      height="50.885px"
                      borderRadius="30px"
                      onClick={() => {
                        action.onClick();
                      }}
                    >
                      Confirm
                    </Button>
                  </Container>
                }
                zIndex="9999"
                title={action.popoverTitle}
                trigger="click"
                open={action.open}
                onOpenChange={action.onOpenChange}
                color="#7265C8"
                overlayInnerStyle={{
                  color: "brown !important",
                  width: "220px",
                  padding: "19px",
                  textAlign: "center",
                }}
                overlayStyle={{
                  color: "green !important",
                }}
              >
                <S.ActionButton
                  onClick={action.showPopoverFn}
                  disabled={action.disabled || isSettingTeamMemberAsCaptain || isDeletingTeamMember}
                >
                  {action.icon}
                  <S.ActionLabel>{action.label}</S.ActionLabel>
                </S.ActionButton>
              </Popover>
            ))}

            <div style={{height: "2px", width: "100%", background: "red", visibility: "hidden"}} ref={bottomRef}></div>
        </Actions>
      </S.ThreeDotsContainer>
    );
  };

  return (
    <DataRow>
      <S.TeamMemberGrid>
        <Container flex alignItems="center" columnGap="11px">
          <Avatar src={data.thumbnail} />
          <Text fontSize="13px">{truncateWithDots(data.nick, 20)}</Text>
        </Container>
        <Text
          onClick={() => copyTextToClipboard(data.userId, "Address copied successfully")}
          fontSize="13px"
          style={{ cursor: "pointer" }}
        >
          {truncateWithMiddleDots(data.userId)}
        </Text>
        <div>{data.role === "captain" ? <Crown /> : <ProfileIcon />}</div>
        <Text fontSize="13px">{capitalizeFirstLetter(data.status)}</Text>
        <DotMenu />
      </S.TeamMemberGrid>
    </DataRow>
  );
};

export default TeamMemberRow;
