import { useMedia } from "use-media";
import { Link, useHistory } from "react-router-dom";
import React, { useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import * as S from "./styles";
import Flex from "../../../common/Flex";
import { GAME_ID_MAP, POOL_MODES } from "../../../../constants";
import { Actions } from "../../../common/Actions/Actions";
import { notifyError } from "../../../common/ToastComponent";
import { useLogin } from "../../../../providers/Loginprovider";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { Edit, ErrorRed, Finish, Play, ThreeDots } from "../../../../assets/svg";
import { executePoolAction, togglePoolVisibility } from "../../../../api/pool";
import { formatTimeStamp, poolStatusFormatter, truncateWithDots } from "../../../../utilities";
import Container from "../../../common/Container/Container";
import { PENDING_ADJUSTMENTS, PENDING_REVISION } from "../../../../constants/status";
import useScreenSizes from "../../../../hooks/useScreenSizes";
// import { getUserOrganizationId } from "../../../helpers/localStorage";

export const TournamentRow = ({ data = {}, userType = "" }) => {
  const queryClient = useQueryClient();
  const [toggleAction, setToggleAction] = useState(false);
  const visibilityToggleRef = useRef(null);

  const toggleActions = () => {
    setToggleAction(!toggleAction);
  };

  const isSmallScreen = useMedia({ maxWidth: 768 });

  const history = useHistory();
  const headers = useGetSecHeaders();
  const { orgIdState } = useLogin();
  const { md, lg, xl } = useScreenSizes();
  let truncateLength;
  if (md) {
    truncateLength = 30;
  } else if (lg) {
    truncateLength = 45;
  } else if (xl) {
    truncateLength = 70;
  } else {
    truncateLength = 90;
  }

  const {
    mutate: mutatePoolVisibility,
    error: mutatePoolError,
    isLoading: mutatePoolVisibilityLoading,
  } = useMutation({
    mutationFn: ({ status }) => {
      return togglePoolVisibility(
        {
          organizationId: orgIdState,
          poolId: data.id,
          status: status,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
      if (visibilityToggleRef?.current) {
        visibilityToggleRef.current.checked = !visibilityToggleRef.current.checked;
      }
    },
  });

  const { mutate: mutatePoolDetails, isLoading: mutatePoolDetailsLoading } = useMutation({
    mutationFn: ({ action }) => {
      return executePoolAction(
        {
          organizationId: orgIdState,
          poolId: data.id,
          action,
        },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.message || e?.data);
      if (variables.errorCb) variables.errorCb();
    },
  });

  const actionsMenu = [
    {
      label: "Start",
      icon: <Play />,
      onClick: () =>
        mutatePoolDetails({
          action: "start_pool",
        }),
    },
    {
      label: "Finish",
      icon: <Finish />,
      onClick: () =>
        mutatePoolDetails({
          action: "finish_pool ",
        }),
    },
    {
      label: "Clone",
      icon: <Edit />,
      onClick: () => history.push(`/pool/${data?.id}/clone`),
      hidden: userType !== "SUPERUSER" || data?.poolMode === POOL_MODES.MULTI_STAGE,
    },
  ];

  const disableActions = [PENDING_ADJUSTMENTS, PENDING_REVISION].includes(data.poolStatus);

  const DotMenu = ({ disabled = false }) => (
    <S.ThreeDotsContainer
      onClick={e => {
        e.preventDefault();
      }}
    >
      <S.ThreeDotsButtonContainer
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          toggleActions();
        }}
        actionIsToggled={toggleAction}
      >
        <ThreeDots />
      </S.ThreeDotsButtonContainer>
      <Actions showActions={toggleAction} right="0px" top="40px" closeActionsMenu={() => setToggleAction(false)}>
        {actionsMenu
          .filter(item => !item.hidden)
          .map((action, index) => (
            <S.ActionButton key={index} onClick={action.onClick} disabled={mutatePoolDetailsLoading}>
              {action.icon}
              <S.ActionLabel>{action.label}</S.ActionLabel>
            </S.ActionButton>
          ))}
      </Actions>
    </S.ThreeDotsContainer>
  );

  return (
    <Link to={`/pool/${data.id}`}>
      <S.TournamentRowContainer poolStatus={data.poolStatus}>
        {!isSmallScreen && (
          <S.TournamentData>
            <S.TournamentText>{data.id}</S.TournamentText>
          </S.TournamentData>
        )}
        <Flex justifyContent="space-between" alignItems="start" style={{ width: "100%" }}>
          <S.TournamentData>
            <S.Image src={data.thumbnail || "https://placehold.co/49x49"} alt="Game Logo" />
            <div>
              {isSmallScreen && (
                <Container marginBottom="5px">
                  <Flex columnGap="6px" alignItems="center">
                    <S.LabelSmallScreen>ID</S.LabelSmallScreen>
                    <small>{data.id}</small>
                  </Flex>
                </Container>
              )}

              <Flex columnGap="6px" alignItems="center">
                <S.LabelSmallScreen>Name</S.LabelSmallScreen>
                <S.PoolLink onClick={() => history.push(`/pool/${data?.poolId}`)}>
                  {truncateWithDots(data.name || "", truncateLength)}
                </S.PoolLink>
              </Flex>

              {/* {isSmallScreen && (
                <Flex columnGap="6px" alignItems="center">
                  <S.LabelSmallScreen>Game</S.LabelSmallScreen>
                  <small>{GAME_ID_MAP[data.game]}</small>
                </Flex>
              )} */}
            </div>
          </S.TournamentData>

          {isSmallScreen && <DotMenu disabled={disableActions} />}
        </Flex>

        {!isSmallScreen && (
          <S.TournamentData>
            <S.TournamentText>{GAME_ID_MAP[data.game]}</S.TournamentText>
          </S.TournamentData>
        )}
        {/* <S.TournamentData>
          <S.TournamentText>{GAME_ID_MAP[data.game] || data.game}</S.TournamentText>
        </S.TournamentData> */}
        <S.LabelSmallScreen marginTop="21px">Registration Period</S.LabelSmallScreen>
        <S.TournamentData style={{ width: "100%", marginTop: "-8px" }}>
          <S.DatesRowContainer>
            <div>
              <S.DateText>
                {data.registrationStartDate ? formatTimeStamp(data.registrationStartDate, "MMM Do - hh:mm a") : "-"}
              </S.DateText>
            </div>
            <div>
              <S.DateText>
                {data.registrationEndDate ? formatTimeStamp(data.registrationEndDate, "MMM Do - hh:mm a") : "-"}
              </S.DateText>
            </div>
          </S.DatesRowContainer>
        </S.TournamentData>

        <S.LabelSmallScreen marginTop="13px">Tournament Date</S.LabelSmallScreen>
        <S.TournamentData style={{ width: "100%", marginTop: "-8px" }}>
          <S.DatesRowContainer>
            <div>
              <S.DateText>{data.startDate ? formatTimeStamp(data.startDate, "MMM Do - hh:mm a") : "-"}</S.DateText>
            </div>
            <div>
              <S.DateText>{data.endDate ? formatTimeStamp(data.endDate, "MMM Do - hh:mm a") : "-"}</S.DateText>
            </div>
          </S.DatesRowContainer>
        </S.TournamentData>

        <S.LabelSmallScreen marginTop="17px">Status</S.LabelSmallScreen>
        <S.StatusWrapper>
          <S.StatusIndicator status={data.poolStatus} />
          {data.poolStatus === PENDING_ADJUSTMENTS && <ErrorRed style={{ width: "15px" }} />}
          <S.StatusText>{poolStatusFormatter(data.poolStatus)}</S.StatusText>{" "}
        </S.StatusWrapper>

        <S.LabelSmallScreen marginTop="17px" marginBottom="6px">
          Disabled/Enabled
        </S.LabelSmallScreen>
        <S.ActionsContainer
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <S.SwitchLabel>
            <S.SwitchInput
              type="checkbox"
              defaultChecked={data.isPublished}
              ref={visibilityToggleRef}
              onChange={e => mutatePoolVisibility({ status: e.target.checked ? "enabled" : "disabled" })}
              disabled={mutatePoolVisibilityLoading || disableActions}
            />
            <S.Switch disabled={mutatePoolVisibilityLoading || disableActions} />
          </S.SwitchLabel>

          {!isSmallScreen && <DotMenu disabled={disableActions} />}
        </S.ActionsContainer>
      </S.TournamentRowContainer>
    </Link>
  );
};
