import React from "react";
import Modal from "../../../common/Modal/Modal";
import * as S from "./styles";
import Avatar from "../../../common/Avatar";
import Flex from "../../../common/Flex";
import { CopyViolet, Crown, DiscordViolet, EmailViolet, PhoneViolet } from "../../../../assets/svg";
import { truncateWithMiddleDots } from "../../../../utilities";

export const TournamentTeamDetailModal = ({ data = [], toggleModal, showModal }) => {
  return (
    <Modal showModal={showModal} toggleModal={toggleModal}>
      <S.TeamDetailTitle>Team Members</S.TeamDetailTitle>
      <S.TeamDetailWrapper>
        <S.TeamMembersHeader>
          <S.TeamMembersTitle>Blast nickname</S.TeamMembersTitle>
          <S.TeamMembersTitle>Game account</S.TeamMembersTitle>
        </S.TeamMembersHeader>
        <S.TeamMembersContainer>
          {data.map(player => (
            <S.InfoWrapper>
              <Flex alignItems="center" columnGap="13px">
                <Avatar />
                <p>{truncateWithMiddleDots(player.nick)}</p>
                {player.role === "captain" && <Crown />}
              </Flex>
              <Flex alignItems="center">
                {/* <span>{truncateWithMiddleDots(player.platformUserId)}</span> */}
                <span>{player.platformUserId}</span>
              </Flex>
              {/* <Flex alignItems="center" columnGap="18px" justifyContent="flex-end">
                <DiscordViolet />
                <EmailViolet />
                <PhoneViolet />
                <CopyViolet />
              </Flex> */}
            </S.InfoWrapper>
          ))}
        </S.TeamMembersContainer>
      </S.TeamDetailWrapper>
    </Modal>
  );
};
