import React, { useEffect, useRef, useState } from "react";
import * as S from "./styles";
import { DottedMenuIcon } from "../../../assets/svg";

const DottedMenuComponent = ({ children, menuStyles = {}, customComponent, rotateIcon = false }) => {
  const [showDotMenu, setShowDotMenu] = useState(false);
  const toggleDotMenu = () => setShowDotMenu(!showDotMenu);
  const dotMenuRef = useRef({});

  useEffect(() => {
    if (!dotMenuRef?.current) return;
    const checkIfClickedOutside = e => {
      if (showDotMenu && dotMenuRef.current && !dotMenuRef.current.contains(e.target)) {
        setShowDotMenu(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showDotMenu]);

  return (
    <S.DottedMenuCotnainer ref={dotMenuRef}>
      {customComponent ? (
        <div style={{ cursor: "pointer" }} onClick={toggleDotMenu}>
          {customComponent}
        </div>
      ) : (
        <S.DottedMenuButton onClick={toggleDotMenu}>
          <DottedMenuIcon style={{ transform: rotateIcon ? "unset" : "rotate(90deg)" }} />
        </S.DottedMenuButton>
      )}

      <div>
        {showDotMenu && (
          <S.MenuContent style={{ ...menuStyles }}>{children({ showDotMenu, setShowDotMenu })}</S.MenuContent>
        )}
      </div>
    </S.DottedMenuCotnainer>
  );
};

export default DottedMenuComponent;

// Example of how to use component
// <DottedMenuComponent>
//   {({ setShowDotMenu }) => (
//     <buttom
//       onClick={() => {
// any function
// showDotMenuFalse(false)
//       }}
//     >
//       Make Captain
//     </buttom>
//   )}
// </DottedMenuComponent>
