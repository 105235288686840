import styled from "styled-components";
import Container from "../../../../common/Container/Container";
import Text from "../../../../common/Text/Text";
import { Button } from "../../../../common/Button";

export const AddressBlock = styled.div`
  height: 29px;
  display: flex;
  color: #a498ed;
  padding: 6px 11px;
  align-items: center;
  border-radius: 14.5px;
  justify-content: center;
  background-color: #3f2faa;
  @media (max-width: 450px) {
    font-size: 10px;
  }
`;

export const OrganizationBasicInfo = styled(Container)`
  width: 100%;
  height: 44px;
  border-top: 1px solid #3f2faa;
  border-bottom: 1px solid #3f2faa;
`;

export const OrganizationBasicInfoTitle = styled(Text)`
  color: #a498ed;
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

export const CustomText = styled(Text)`
  font-size: 13px;
  &:hover {
    cursor: pointer;
  }
`;

export const StatsContainer = styled.div`
  display: grid;
  column-gap: 15px;
  margin-top: 33px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 665px) {
    row-gap: 15px;
    grid-template-columns: 1fr;
    flex-direction: column;
  }
`;

export const StatBlock = styled.div`
  height: 82px;
  flex-grow: 1;
  display: flex;
  row-gap: 12px;
  padding: 13px 19px;
  border-radius: 6px;
  flex-direction: column;
  background-color: #4e3cca;
`;

export const SelectorButton = styled(Button)`
  background: ${props => (props.active ? "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)" : "#3F2FAA")};
`;

export const AccountRowWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr) 0.1fr;
`

export const OrganizationMemberRowContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 130px;
  overflow-y: auto;
`
