import React from "react";
import styled from "styled-components";
import DefaultAvatar  from "../../assets/svg/default-avatar.svg";


const AvatarWrap = styled.img`
  cursor: pointer;
  object-fit: cover;
  ${props => `
    border-radius: ${props.borderRadius ? props.borderRadius : props.type === "square" ? "3px" : "50%"};
    width: ${props.size ? props.size : "33px"};
    height: ${props.size ? props.size : "33px"}
    `}
`;

const Avatar = ({ src, size, borderRadius, customStyles={}, alt }) => {
  return (
    <AvatarWrap
      src={src || DefaultAvatar}
      size={size}
      borderRadius={borderRadius}
      alt={alt || "avatar"}
      style={customStyles}
    />
  );
};

export default Avatar;
