import styled from "styled-components";

export const InvitationalCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 44px;
`;

export const PageTitle = styled.h2`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  margin: 0;
`;

export const InvitationalCodeTable = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(404px, 1fr));
  grid-row-gap: 15px;
  grid-column-gap: 10px;
`;

