import axiosClient from "./axiosClient";

export function getUsers({ organizationId, searchQuery = "", limit = 999 }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  return axiosClient

    .get(`/admPanel/users/search/${organizationId}?searchValue=${searchQuery}&limit=${limit}`, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function getUserById({ organizationId, userId }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!userId) throw new Error("Please provide a valid user id");
  return axiosClient
    .get(`/admPanel/users/${organizationId}/${userId}`, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export async function updateOrganizationPermission(
  { organizationId, userId, selectedOrganizationId, permissions },
  headers,
) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!selectedOrganizationId) throw new Error("Please enter a valid organization to update permissions");
  return axiosClient
    .put(
      `/admPanel/user/organization/updPermissions/${organizationId}/${userId}`,
      { permissions: permissions },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data.userPermissions)
    .catch(err => {
      throw err.response;
    });
}

export async function deleteUserAccount({ accountId, userId }, headers) {
  if (!userId) throw new Error("Please enter a valid user id");
  if (!accountId) throw new Error("Please enter a valid account id");

  return axiosClient
    .delete(`/admPanel/user/account/${userId}/${accountId}`, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export async function updateGameIdAccount({ username, accountId }, headers) {
  if (!username) throw new Error("Please edit the username");
  return axiosClient
    .put(
      `/admPanel/user/account/${accountId}`,
      { username: username },
      { headers: { Authorization: headers.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}
