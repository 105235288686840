import styled from "styled-components";
import { breakpoints } from "../../../../themes";
import { Button } from "../../../common/Button";
import { PROCESSING_REGISTERED_USERS, RUNNING, STARTED } from "../../../../constants/status";

export const DataHeader = styled.div`
  width: 100%;
  background-color: #201366;
  height: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 15px;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: 350;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

export const IdText = styled.span`
  color: #a498ed;
`;

export const TournamentDetailHeaderContainer = styled.div`
  /* overflow-y: hidden; */
  height: 210px;
  width: 100%;
  border-radius: 6px;
  border-top-left-radius: 0px;
  background-color: #3f2faa;
  display: flex;
  align-items: center;
  padding: 13px 15px;
  position: relative;

  ${breakpoints.down("1079px")} {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-top: 17px;
    padding-bottom: 17px;
  }
  ${breakpoints.down(breakpoints.mobileS)} {
    padding: 11px;
  }
  @media (max-width: 1079px) {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-top: 17px;
    padding-bottom: 17px;
  }

  & > div:last-of-type {
    justify-content: end;
  }
`;

export const TournamentData = styled.div`
  width: 100%;
  display: flex;
  gap: 25px;

  @media (max-width: 1079px) {
    flex-direction: column;
  }
`;

export const TournamentText = styled.span`
  position: relative;
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;
  user-select: none;
`;

export const TournamentId = styled(TournamentText)`
  ${breakpoints.down("1079px")} {
    display: none;
  }
`;

export const GameImg = styled.img`
  width: 49px;
  height: 49px;
  border-radius: 8px;
`;

export const TournamentBanner = styled.img`
  border-radius: 6px;
  height: 182px;
  width: 303px;
`;

export const PoolName = styled.span`
  font-size: 18px;
  font-weight: 350;
  ${breakpoints.down(breakpoints.mobileL)} {
    font-size: 12px;
  }
`;

export const Label = styled.label`
  color: #a498ed;
  font-size: 12px;
  font-weight: 325;
  margin-top: ${props => (props?.marginTop ? props.marginTop : "unset")};
  ${breakpoints.down("1079px")} {
    display: block;
  }
`;

export const PoolValues = styled.span`
  font-size: 13px;
  font-weight: 350;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
  min-width: 71px;
`;

export const TokenImg = styled.img`
  width: 11px;
  height: 11px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 350;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  ${breakpoints.down("1079px")} {
    display: none;
  }
`;

export const EditMenuContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const RequestChangesContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const RowButton = styled.button`
  cursor: pointer;
  width: ${props => (props.width ? props.width : "85px")};
  height: 33px;
  /* background: ${props => (props.actionIsToggled ? "#4e3cca" : "transparent")}; */
  background: #4e3cca;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  border: 0;
  color: #fff;
  svg {
    pointer-events: none;
  }
  /* alignItems="center" justifyContent="center" height="100%" columnGap="8px" style={{ height: "100%" }} */
`;

export const ActionButton = styled(Button)`
  width: 100%;
  height: 34px;
  border-radius: 4px;
  background: #4e3cca;
  justify-content: flex-start;
  gap: 10px;
  &:disabled {
    color: #fffffff5;
  }
`;

export const ActionLabel = styled.span`
  color: #fff;
  font-size: 14px;
  font-weight: 325;
`;

export const ReasonButton = styled(Button)`
  width: fit-content;
  white-space: nowrap;
  height: 34px;
  background: ${props => (props.reasonSelected ? "#6c47ff" : "#4e3cca")};
`;

export const ReasonLabel = styled.span`
  font-size: 12px;
  font-weight: 325;
  line-height: 14.4px;
  text-align: center;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 19px 22px;
`;

export const ActionDateContainer = styled.div`
  width: 295px;
  height: 40px;
  border-radius: 4px;
  background: #342788;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`;

export const ActionContainer = styled.div`
  width: 295px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 23px;
  padding: 0 10px;
`;

export const ActionMenuLabel = styled.button`
  all: unset; // removes all default button styles
  margin-top: 45px;
  font-size: 13px;
  font-weight: 350;
  line-height: 15px;
  color: ${props => (props.color ? props.color : "#fff")};
  cursor: pointer;

  &:disabled {
    cursor: wait;
    opacity: 0.3;
  }
`;

export const ReasonMenuLabel = styled.button`
  all: unset;
  max-width: 50px;
  height: 19px;
  font-size: 13px;
  font-weight: 350;
  text-align: center;
  color: ${props => (props.color ? props.color : "#fff")};
  cursor: pointer;

  &:disabled {
    cursor: wait;
    opacity: 0.3;
  }
`;

export const TextAreaContainer = styled.div`
  width: 295px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 19px 10px;
  align-items: center;
  justify-content: center;
`;

export const TextAreaWrapper = styled.div`
  width: 100%;
  border-radius: 6px;
  border: 1.5px solid #7a6ddb;
  height: 89px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

export const CustomTextArea = styled.textarea`
  background: transparent;
  border: none;
  height: 100%;
  color: #fff;
  outline: none;
  padding-top: 8px;
  color: ${props => (props.disabled ? "#ffffff80" : "#fff")};
  font-family: Gotham;
`;

export const TextAreaLabel = styled.label`
  color: #a498ed;
  font-size: 12px;
  font-weight: 325;
`;

export const TextLabel = styled.span`
  font-size: 14px;
  font-weight: 325;
`;

export const StatusIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  ${props => `
    display  : ${
      props.status === RUNNING || props.status === STARTED || props.status === PROCESSING_REGISTERED_USERS
        ? `block`
        : "none"
    };    
  `}
  background: #b2fa4f;
`;

export const StatusText = styled.span`
  font-size: 11px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  margin: 0;
  color: #a498ed;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
  padding: 11px;
  border-radius: 20px;
  gap: 4px;
  min-width: 86px;
  margin-right: 24px;
  background: #3f2faa;
  ${breakpoints.down("1079px")} {
    margin-top: 0;
  }
`;

export const DatesRowContainer = styled.div`
  display: block;
  margin-top: 12px;
  ${breakpoints.down("1079px")} {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 12px;
    div.dates-row-div {
      padding-left: 13px;
      background-color: #342788;
      min-width: 145px;
      border-radius: 4px;
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
    }
  }
  ${breakpoints.down(breakpoints.mobileM)} {
    div {
      min-width: 122px;
      padding: 2px 4px;
    }
  }
`;

export const DateTextWrapper = styled.div`
  background: #4e3cca;
  height: 29px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 7px 8px;
`;
export const DateText = styled.span`
  white-space: nowrap;
  ${breakpoints.down("1079px")} {
    font-size: 12px;
    font-weight: 325;
  }
  ${breakpoints.down(breakpoints.mobileM)} {
    font-size: 11px;
  }
`;

export const BtnRow = styled.div`
  display: flex;
  column-gap: 9px;

  button {
    /* background: pink !important; */
  }
  @media (max-width: 1079px) {
    div:first-child,
    button:first-of-type {
      flex-grow: 1;
    }
  }
`;

export const PoolDataWrapper = styled.div`
  border-left: 1px solid #4e3cca;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 128px;
  gap: 20px;
  column-gap: 51px;
  padding: 0 25px;
`;

export const TabButton = styled.button`
  background-color: ${props => (props.active ? "rgba(63, 47, 170, 1)" : "rgba(52, 39, 136, 1)")};
  color: #fff;
  height: 39px;
  width: 84px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
`;

export const TemplateMenuContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 18px;
`;

export const MaxPlayersInput = styled.input`
  background-color: #4e3cca;
  outline: none;
  border: none;
  color: #fff;
  padding: 2px;
  padding-left: 8px;
  border-radius: 2px;
  width: 100%;

  /* height: 15px;
  font-size: 8px; */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const MaxPlayersInputContainer = styled.div`
  width: 60px;
  border-radius: 2px;
  position: relative;
  display: inline-block;
`;
export const MaxPlayersInputButton = styled.button`
  right: 0;
  border: 0;
  height: 100%;
  outline: none;
  cursor: pointer;
  position: absolute;
  background: transparent;
  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
`;
