import axiosClient from "./axiosClient";

export function movePlayersToOtherGroups(
  { poolId, stageId, fromGroupId, toGroupId, playersId, organizationId },
  headers,
) {
  if (!organizationId) throw new Error("Please provide a valid organization ID");
  if (!poolId) throw new Error("Please provide a valid user ID");
  return axiosClient
    .put(
      `/admPanel/pool/movePlayersBtwGroups/${organizationId}`,
      {
        poolId,
        stageId,
        fromGroupId,
        toGroupId,
        playersId,
      },
      {
        headers: { Authorization: headers.encryptedHeader },
      },
    )
    .then(res => {
      if (res.data?.error) throw new Error(res.data.message || "Something went wrong");
      return res.data;
    })
    .catch(err => {
      throw err.response || err;
    });
}

export function swapGroupPlayers(
  { poolId, stageId, fromGroupId, fromPlayerId, toGroupId, toPlayerId, organizationId },

  headers,
) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .put(
      `/admPanel/pool/swapPlayersGroups/${organizationId}`,
      {
        poolId: poolId,
        stageId: stageId,
        fromGroupId: fromGroupId,
        fromPlayerId: fromPlayerId,
        toGroupId: toGroupId,
        toPlayerId: toPlayerId,
      },
      {
        headers: { Authorization: headers.encryptedHeader },
      },
    )
    .then(res => {
      if (res.data?.error) throw new Error(res.data.message || "Something went wrong");
      return res.data;
    })
    .catch(err => {
      throw err.response || err;
    });
}
