// BACKEND BASE URL's
export const WEBAPP_BE_API_BASE_URL = process.env.REACT_APP_WEBAPP_BE_API_BASE_URL;

// FOR ADD TO CALENDAR
export const GOOGLE_CALENDAR_URL = process.env.REACT_APP_GOOGLE_CALENDAR_URL;
export const OUTLOOK_CALENDAR_URL = process.env.REACT_APP_OUTLOOK_CALENDAR_URL;

// DISCORD OAUTH2
export const DISCORD_CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID;
export const DISCORD_REDIRECT_URI = process.env.REACT_APP_DISCORD_REDIRECT_URI;
export const DISCORD_ENCRYPTED_VALUE = process.env.REACT_APP_DISCORD_ENCRYPTED_VALUE;
export const DISCORD_AUTHORIZE_URL = process.env.REACT_APP_DISCORD_AUTHORIZE_URL;

// FIREBASE
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MSG_SENDER_ID = process.env.REACT_APP_FIREBASE_MSG_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;

// CHAIN
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID ? parseInt(process.env.REACT_APP_CHAIN_ID) : 137;
export const CHAIN_NAME = process.env.REACT_APP_CHAIN;
export const CHAIN_RPC_URL = process.env.REACT_APP_CHAIN_RPC_URL;
export const CHAIN_TOKEN_NAME = process.env.REACT_APP_CHAIN_TOKEN_NAME;
export const CHAIN_TOKEN_SYMBOL = process.env.REACT_APP_CHAIN_TOKEN_SYMBOL;
export const CHAIN_TOKEN_DECIMALS = process.env.REACT_APP_CHAIN_TOKEN_DECIMALS;

export const INFURA_ID = process.env.REACT_APP_INFURA_ID;
export const STATE_KEY = process.env.REACT_APP_STATE_KEY;
export const SEED = process.env.REACT_APP_CRYPTO_SEED;
