import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import * as S from "./styles";
import SearchBar from "../../shared/SearchBar";
import { getUsers } from "../../../../../api/users";
import { useGetSecHeaders } from "../../../../../hooks/useHeaders";
import LoadingSkeleton from "../../../../common/LoadingSkeleton";
import Container from "../../../../common/Container/Container";
import AddOrganizationUserRow from "../AddOrganizationUser";

const AddPlayerToOrganizationModal = ({
  toggleModal,
  showModal,
  organizationId,
  mutateAddUserToOrg,
  mutateAddUserToOrgLoading,
}) => {
  const headers = useGetSecHeaders();
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: users,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["searchUser", searchQuery],
    queryFn: () => getUsers({ organizationId: organizationId, searchQuery }, headers),
    refetchOnWindowFocus: false,
    enabled: !!headers?.encryptedHeader && !!organizationId,
  });

  const usersNotFound = users && users.length < 1;

  return (
    <S.AddPlayerModal showModal={showModal} toggleModal={toggleModal} afterClose={() => setSearchQuery("")}>
      <S.AddPlayerWrapper>
        <S.ModalTitle>Add User</S.ModalTitle>
        <S.UserWrapper>
          <SearchBar setSearchQuery={setSearchQuery} />
          <S.PlayerListContainer>
            <S.PlayerListInner>
              {isLoading ? (
                <>
                  <LoadingSkeleton height="50px" />
                  <LoadingSkeleton height="50px" />
                  <LoadingSkeleton height="50px" />
                </>
              ) : usersNotFound ? (
                <Container flex justifyContent="center" alignItems="center" height="200px">
                  User Not Found
                </Container>
              ) : (
                users?.map(user => (
                  <AddOrganizationUserRow
                    data={user}
                    key={user.id}
                    mutateAddUserToOrg={mutateAddUserToOrg}
                    mutateAddUserToOrgLoading={mutateAddUserToOrgLoading}
                    organizationId={organizationId}
                  />
                ))
              )}
            </S.PlayerListInner>
          </S.PlayerListContainer>
        </S.UserWrapper>
      </S.AddPlayerWrapper>
    </S.AddPlayerModal>
  );
};

export default AddPlayerToOrganizationModal;
