import React from "react";
import * as S from "./styles";
import Text from "../../../../common/Text/Text";

const OrganizationRow = ({ data = {} }) => {
  return (
    <S.Container>
      <Text fontSize="13px">{data.id}</Text>
      <Text fontSize="13px">{data.name}</Text>
      <S.StatusContainer>
        <S.StatusText>{data.status === "ACTIVE" ? "Active" : data.status}</S.StatusText>
      </S.StatusContainer>
    </S.Container>
  );
};

export default OrganizationRow;
