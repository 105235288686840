import axios from "axios";
import { ENDPOINT } from "../constants/endpoints";
import axiosClient from "./axiosClient";

export function getGamesList() {
  return axiosClient
    .get(ENDPOINT.GAME)
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}


export function createGame({ organizationId, body }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .post(`/admPanel/game/createGame/${organizationId}`, body, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}