import styled from "styled-components";
import { breakpoints } from "../../themes";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  padding: 0px 72px 10px 47px;
  ${breakpoints.down(breakpoints.laptopL)} {
    padding: 0px 38px 10px 47px;
  }
  ${breakpoints.down(breakpoints.laptop)} {
    padding: 0px 16px 10px 16px;
  }
`;

export const Sections = styled.div`
  display: grid;
  column-gap: 51px;
  grid-template-columns: repeat(2, 1fr);
`;

export const SectionContainer = styled.div`
  cursor: pointer;
  background-color: #3f2faa;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: ${props => (props.active ? "3px solid #7A6DDB" : "unset")};
`;
