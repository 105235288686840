import React, { useRef } from "react";

import * as S from "../../styles";
import Container from "../../../../common/Container/Container";
import ErrorTooltip from "../../../../common/ErrorTooltip";
import ImagePicker from "../ImagePicker";
import Flex from "../../../../common/Flex";
import ImageTag from "../../../../common/ImageTag";

const FourthStep = ({
  register,
  errors = {},
  setValue,
  getValues,
  assetTypeBeingUploaded,
  assetUploadMutation,
  isAssetUploadLoading,
  clearErrors,
}) => {
  const winnerBgRef = useRef(null);

  const winnerBgError = errors?.winnerBg?.message;

  if (!register || !setValue) return <div />;
  return (
    <Container flex flexDirection="column" rowGap="27px">
      {/* Logo Input */}
      <S.RowContainer>
        <Flex columnGap="12px">
          <label>Winners background</label>
          {winnerBgError && <ErrorTooltip message={winnerBgError} isRelative />}
        </Flex>
        <S.ImageContainer>
          <div>
            <input
              type="file"
              className="hidden"
              ref={winnerBgRef}
              onChange={e => {
                if (e.target?.files[0]) {
                  clearErrors("winnerBg");
                  assetUploadMutation({ file: e.target.files[0], assetType: "winnerBg" });
                }
              }}
              disabled={isAssetUploadLoading && assetTypeBeingUploaded === "winnerBg"}
            />
            <ImagePicker
              handleClick={() => winnerBgRef?.current?.click()}
              loading={isAssetUploadLoading && assetTypeBeingUploaded === "winnerBg"}
            />
          </div>
          <S.ImageBlock isError={!!winnerBgError}>
            <ImageTag src={getValues("winnerBg")} width="137px" height="77px" />
          </S.ImageBlock>
        </S.ImageContainer>
      </S.RowContainer>
    </Container>
  );
};

export default FourthStep;
