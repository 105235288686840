import React from "react";
import styled from "styled-components";

export const FlexStyled = styled.div`
  display: flex;
  align-items: ${props => (props.alignItems ? props.alignItems : "unset")};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : "unset")};
  column-gap: ${props => (props.columnGap ? props.columnGap : "unset")};
  row-gap: ${props => (props.rowGap ? props.rowGap : "unset")};
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : "row")};
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : "unset")};
`;

const Flex = ({ children, ...rest }) => {
  return <FlexStyled {...rest}>{children}</FlexStyled>;
};

export default Flex;
