import styled from "styled-components";

export const Container = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
