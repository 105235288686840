import React, { useRef, useState } from "react";
import * as S from "./styles";
import { CaretArrow, Check, ThreeDots } from "../../../assets/svg";
import useOnClickOutside from "../../../hooks/useClickOutside";
import Container from "../Container/Container";

const MenuContainer = ({
  closeMenu,
  openMenu,
  children,
  left,
  right,
  bottom,
  top,
  minWidth,
  calendarContainer = false,
  calendarContainerRightCol = false,
}) => {
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (closeMenu) {
      closeMenu();
    }
  });

  return (
    <S.StyleMenuContainer
      calendarContainer={calendarContainer}
      calendarContainerRightCol={calendarContainerRightCol}
      isOpen={openMenu}
      left={left}
      right={right}
      bottom={bottom}
      top={top}
      minWidth={minWidth}
      ref={ref}
    >
      {children}
    </S.StyleMenuContainer>
  );
};

const MenuOptionContent = ({ item }) => {
  return (
    <>
      <>{item.icon}</>
      <Container flex justifyContent="space-between" alignItems="center" width="100%" columnGap="30px">
        <S.MenuOptionLabel>{item.name}</S.MenuOptionLabel>
        {item.subMenu ? (
          <CaretArrow style={{ rotate: "275deg" }} />
        ) : item.selected ? (
          <Check style={{ scale: "0.7" }} />
        ) : <Container height="15px" width="15px" />}
      </Container>
    </>
  );
};

const MenuOption = ({ index, item, disableOptions }) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const toggleSubMenu = () => setOpenSubMenu(prev => true);
  return (
    <S.MenuOptionBtn
      // selected={item.selected || item.subMenu ? item.subMenu.some(subMenuItem => subMenuItem.selected) : false} // Keeping this code for future reference
      selected={item.selected}
      position="relative"
      key={index}
      onClick={item.subMenu ? toggleSubMenu : item.onClick}
      disabled={item.disabled || disableOptions}
    >
      <MenuOptionContent item={item} />

      {/* Submenu */}
      {item.subMenu ? (
        <div className="submenu">
          <MenuContainer openMenu={openSubMenu} closeMenu={() => setOpenSubMenu(false)}>
            {item.subMenu?.map(subMenuItem => (
              <S.MenuOptionBtn
                selected={subMenuItem.selected}
                onClick={subMenuItem.onClick}
                disabled={subMenuItem.disabled || disableOptions}
              >
                <MenuOptionContent item={subMenuItem} />
              </S.MenuOptionBtn>
            ))}
          </MenuContainer>
        </div>
      ) : null}
    </S.MenuOptionBtn>
  );
};
const Menu = ({ disabled = false, menuItems = [], triggerComponent, disableOptions = false }) => {
  const [openMenu, setOpenMenu] = useState("");
  const toggleMenu = () => setOpenMenu(prev => !prev);
  return (
    <S.ThreeDotsContainer
      onClick={e => {
        e.preventDefault();
      }}
    >
      <S.ThreeDotsButtonContainer
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          toggleMenu();
        }}
        openMenu={openMenu}
      >
        {triggerComponent ? triggerComponent : <ThreeDots />}
      </S.ThreeDotsButtonContainer>
      <MenuContainer openMenu={openMenu} right="0px" top="40px" closeMenu={() => setOpenMenu(false)}>
        {menuItems
          .filter(item => !item.hidden)
          .map((item, index) => {
            return <MenuOption index={index} item={item} disableOptions={disableOptions} />;
          })}
      </MenuContainer>
    </S.ThreeDotsContainer>
  );
};
export default Menu;
