import styled from "styled-components";
import { breakpoints } from "../../themes";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  padding: 0px 72px 10px 47px;
  ${breakpoints.down(breakpoints.laptopL)} {
    padding: 0px 38px 10px 47px;
  }
  ${breakpoints.down(breakpoints.laptop)} {
    padding: 0px 16px 10px 16px;
  }
`;

export const SectionContainer = styled.div`
  margin-top: 45px;
  display: flex;
  column-gap: 45px;
  & > div {
    border-radius: 6px;
    flex: 1;
    padding: 35px 34px;
  }
  & > div:first-child {
    min-height: ${props => (props.isConfirmScreen ? `fit-content` : `643px`)};
    background-color: #342788;
    width: 9000px !important;
  }

  & > div:nth-child(2) {
    background: ${props => (props.isConfirmScreen ? `#342788` : `transparent`)};
    padding: ${props => (props.isConfirmScreen ? `35px 34px` : `0`)};
    height: ${props => (props.isConfirmScreen ? `438px` : `unset`)};
  }
`;

export const PreviewCardSection = styled.div`
  background: #342788;
  height: 100%;
  border-radius: 6px;
`;

export const AssetPreviewSection = styled.div`
  background: #342788;
  height: 183px;
  border-radius: 6px;
`;
