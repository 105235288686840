import styled from "styled-components";

export const Container = styled.div`
  height: 50px;
  display: grid;
  padding-left: 21px;
  border-radius: 6px;
  align-items: center;
  background-color: #3f2faa;
  justify-items: flex-start;
  grid-template-columns: 2fr 2fr 1fr;
  min-width: 700px !important;
`;
// export const Tabs = styled.div`
//   width: 302px;
//   height: 47px;
//   background: #201366;
//   border-radius: 13px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 43px;
//   margin-top: 56px;
//   @media (max-width: 375px) {
//     width: 100%;
//     padding-left: 2px;
//     padding-right: 2px;
//   }
// `;
