import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 8px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin-top: 32px;
  align-self: center;
`;
