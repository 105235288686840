import * as S from "./styles";
import { useState } from "react";
import { CaretArrow } from "../../../assets/svg";

const RuleAccordionItem = ({ title, content }) => {
  const [visibility, setVisibility] = useState(false);

  const handleToggleVisibility = () => {
    setVisibility(!visibility);
  };

  return (
    <S.AccordionItemWrapper visible={visibility}>
      <S.AccordionHeader onClick={handleToggleVisibility} visible={visibility}>
        <CaretArrow />
        <S.AccordionHeaderText>{title}</S.AccordionHeaderText>
      </S.AccordionHeader>
      <S.AccordionContent visible={visibility}>
        <S.AccordionContentText style={{ whiteSpace: "pre-wrap" }} id="rule-content">
          <div dangerouslySetInnerHTML={{__html: content}} />
        </S.AccordionContentText>
      </S.AccordionContent>
    </S.AccordionItemWrapper>
  );
};

export default RuleAccordionItem;