const JOINING = "JOINING";
const STARTED = "STARTED";
const RUNNING = "RUNNING";
const FINISHED = "FINISHED";
const ARCHIVED = "ARCHIVED";
const NOT_STARTED = "NOT_STARTED";
const BRACKETS_CREATED = "BRACKETS_CREATED"; 
const PENDING_REVISION = "PENDING_REVISION"; 
const PENDING_TO_APROVE = "PENDING_TO_APROVE";
const FINISHED_COLLECTING_DATA = "FINISHED_COLLECTING_DATA";
const PROCESSING_REGISTERED_USERS = "PROCESSING_REGISTERED_USERS";
const PENDING_ADJUSTMENTS = "PENDING_ADJUSTMENTS";
const ACTIVE = "active";
const INACTIVE = "inactive";
const DELETE = "deleted";

export const STATUS_MAP = {
  STARTED,
  RUNNING,
  JOINING,
  FINISHED,
  ARCHIVED,
  NOT_STARTED,
  BRACKETS_CREATED,
  PENDING_REVISION,
  PENDING_TO_APROVE,
  FINISHED_COLLECTING_DATA,
  PROCESSING_REGISTERED_USERS,
  PENDING_ADJUSTMENTS,
  ACTIVE,
  INACTIVE,
  DELETE
};

export {
  STARTED,
  RUNNING,
  JOINING,
  FINISHED,
  ARCHIVED,
  NOT_STARTED,
  PENDING_REVISION,
  BRACKETS_CREATED,
  PENDING_TO_APROVE,
  FINISHED_COLLECTING_DATA,
  PROCESSING_REGISTERED_USERS,
  PENDING_ADJUSTMENTS,
  ACTIVE,
  INACTIVE,
  DELETE
};
