import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import * as S from "./styles";
import Text from "../../components/common/Text/Text";
import Container from "../../components/common/Container/Container";
import { BackArrow } from "../../assets/svg";
import Form from "../../components/views/newTournament/regularPoolForm";
import Preview from "../../components/views/newTournament/preview";
import { useEffect, useRef, useState } from "react";
import NavigationBar from "../../components/views/newTournament/navigationBar";
import { Button, ButtonUnstyled } from "../../components/common/Button";
import HeaderPreview from "../../components/views/newTournament/headerPreview";
import { createTournament, getPoolById } from "../../api/pool";
import { useGetSecHeaders } from "../../hooks/useHeaders";
import { useLogin } from "../../providers/Loginprovider";
import { notifyError, notifySuccess, notifyWarning } from "../../components/common/ToastComponent";
import axiosClient from "../../api/axiosClient";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../components/common/SpinningLoader";
import { useNetworkListener } from "../../hooks/useNetworkListener";
import { getTokenList } from "../../api/tokens";
import { useParams } from "react-router-dom";
import { useSession } from "../../providers/SessionProvider";

const steps = [
  {
    id: 0,
    name: "General Info",
    fields: [
      "gameId",
      "title",
      "subtitle",
      "prizePool",
      "prizeToken",
      "entry",
      "joinMode",
      "teamPlayers",
      "maxPlayers",
      "poolFormat",
      "poolStageType",
      "organizationId",
    ],
  },
  {
    id: 1,
    name: "Dates",
    fields: ["registrationStartDate", "registrationEndDate", "tournamentStartDate", "tournamentEndDate"],
  },
  { id: 2, name: "Details", fields: ["winnerCount", "percentageList", "rules"] },
  { id: 3, name: "Styles", fields: ["thumbnail", "bannerBgFront", "bannerBgBack"] },
  { id: 4, name: "Overview", fields: [] },
];

const navigationSteps = ["General Info", "Dates", "Details", "Styles", "Overview"];

const NewTournament = () => {
  const { orgIdState } = useLogin();
  const { chainId } = useNetworkListener();
  const headers = useGetSecHeaders();
  const history = useHistory();

  const params = useParams();
  const [existingPoolData, setExistingPoolData] = useState();
  const [isLoadingExistingPoolData, setIsLoadingExistingPoolData] = useState(false);
  useEffect(() => {
    // Checking if there is an existing pool Id in the params which would indicate that we are cloning a pool
    if (!params?.poolId || !headers?.token) return;
    //using react query here was causing issues hence using the fetch function directly
    async function getData() {
      try {
        setIsLoadingExistingPoolData(true);
        const resp = await getPoolById({ poolId: params?.poolId, orgId: orgIdState }, headers);
        if (resp.error) {
          notifyError("Something went wrong");
          history.push("/");
        } else {
          setExistingPoolData(resp);
        }
      } catch (err) {
        notifyError(err.message || err);
        history.push("/");
      } finally {
        setIsLoadingExistingPoolData(false);
      }
    }
    getData();
  }, [params?.poolId, headers]);

  const { mutate: createFormMutation, isLoading: loadingFormCreation } = useMutation({
    mutationFn: variables => {
      return createTournament(
        {
          organizationId: variables.data?.selectedOrgId,
          poolData: variables.data,
        },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: (data, variables) => {
      if (variables.selectedOrgId !== orgIdState) {
        notifyWarning(
          `Tournament ${data} created successfully. Please switch to the corresponding organization to see it.`,
        );
        history.push("/");
      } else {
        notifySuccess(`Pool ${data} created successfully`);
        history.push(`/pool/${data}`);
      }
    },
  });

  const { data: { data: tokenList, error: tokenListrror } = {}, isLoadingChainList } = useQuery({
    queryKey: ["tokenList", chainId],
    queryFn: () => getTokenList(chainId),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!chainId,
  });

  async function processSubmission(data) {
    const selectedToken = tokenList?.find(token => token.symbol === data.prizeToken);

    if (!selectedToken) {
      notifyError("Selected token data is invalid");
      return;
    }
    const formattedData = {
      selectedOrgId: data.organizationId,
      description: data.subtitle,
      detailHeaderBackImage: data.bannerBgBack,
      detailHeaderFrontImage: data.bannerBgFront,
      endDate: data.tournamentEndDate ? moment(data.tournamentEndDate)?.valueOf() : undefined,
      gameDetails: {}, // Confirm
      gameId: data.gameId,
      gameMode: 1, // Confirm
      joinMode: data.joinMode?.toUpperCase(),
      maxPlayers: data.maxPlayers,
      minPlayers: data.percentageList?.length, // Confirm
      name: data.title,
      poolFormat: data.poolFormat?.toUpperCase(), // What are all the possible values
      poolPrize: data.prizePool,
      registrationEndDate: data.registrationEndDate ? moment(data.registrationEndDate)?.valueOf() : 0,
      registrationStartDate: data.registrationStartDate ? moment(data.registrationStartDate)?.valueOf() : 0,
      rules: data.rules,
      teamUsersQty: data.teamPlayers,
      startDate: data.tournamentStartDate ? moment(data.tournamentStartDate)?.valueOf() : 0,
      thumbnail: data.thumbnail,
      winnersFees: data.percentageList,
      poolStageType: data.poolStageType,
      tokenAddress: selectedToken.contractAddress,
      tokenImage: selectedToken.thumbnail,
      tokenName: selectedToken.name,
      tokenSymbol: selectedToken.symbol,
    };
    // console.log("Submitted Data: ", existingPoolData);
    // return;
    createFormMutation({ data: formattedData });
  }

  const [previewData, setPreviewData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const { session } = useSession();

  const nextBtnRef = useRef();
  const prevBtnRef = useRef();
  const submitBtnRef = useRef();

  useEffect(() => {
    if(session?.user?.userType !== "SUPERUSER") {
      notifyError("Unauthorized");
      history.push("/")
    }
  }, [session?.user])

  return (
    <>
      <S.MainContainer>
        <BackArrow />
        <Container flex columnGap="17px" alignItems="center" marginTop="8px">
          <Text fontSize="38px">New Tournament</Text>
        </Container>
        <NavigationBar currentStep={currentStep} steps={navigationSteps} />
        {params?.poolId && isLoadingExistingPoolData ? (
          <SpinnerLoaderWrapper>
            <SpinnerLoader />
          </SpinnerLoaderWrapper>
        ) : (
          <S.SectionContainer isConfirmScreen={currentStep === 4}>
            <Form
              {...{
                previewData,
                currentStep,
                setCurrentStep,
                setPreviewData,
                processSubmission,
                nextBtnRef,
                prevBtnRef,
                submitBtnRef,
                steps,
                existingPoolData: existingPoolData?.data,
              }}
            />
            {currentStep < 4 && (
              <Container flex flexDirection="column" justifyContent="space-between" rowGap="30px">
                <S.PreviewCardSection>
                  <Preview previewData={previewData} />
                </S.PreviewCardSection>
                {currentStep === 3 && <HeaderPreview previewData={previewData} />}
              </Container>
            )}
            {currentStep === 4 && <HeaderPreview isConfirmScreen previewData={previewData} />}
          </S.SectionContainer>
        )}
      </S.MainContainer>

      {params?.poolId && isLoadingExistingPoolData ? null : (
        <Container flex justifyContent="center" marginTop="63px" columnGap="23px">
          {currentStep > 0 && (
            <ButtonUnstyled type="button" onClick={() => prevBtnRef?.current?.click()}>
              Back
            </ButtonUnstyled>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="button" onClick={() => nextBtnRef?.current?.click()}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button onClick={() => submitBtnRef?.current?.click()} disabled={loadingFormCreation}>
              {loadingFormCreation ? <SpinnerLoader /> : "Submit"}
            </Button>
          )}
        </Container>
      )}
    </>
  );
};

export default NewTournament;
