import React, { useRef, useState } from "react";
import Modal from "../../../../../../components/common/Modal/Modal";
import * as S from "./style";
import Flex from "../../../../../../components/common/Flex";
import ReactQuill from "react-quill";
import { ButtonRound } from "../../../../../../components/common/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addTournamentRule } from "../../../../../../api/pool";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useGetSecHeaders } from "../../../../../../hooks/useHeaders";
import { notifyError, notifySuccess } from "../../../../../../components/common/ToastComponent";

export const CreateRuleModal = ({
  showModal,
  toggleModal,
  poolRulesArray = [],
  setPoolRulesArray,
  organizationId = "",
}) => {
  const quill = useRef();
  const { poolId } = useParams();
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();

  const [ruleTitle, setRuleTitle] = useState("");
  const [ruleContent, setRuleContent] = useState("");
  const [ruleSortOrder, setRuleSortOrder] = useState(poolRulesArray?.length + 1);

  const { mutate: addNewRuleMutation, isLoading: addNewRuleIsLoading } = useMutation({
    mutationFn: variables => {
      return addTournamentRule(
        {
          organizationId: variables.organizationId,
          poolId: variables.poolId,
          title: variables.title,
          content: variables.content,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: e => {
      notifySuccess(e?.message || e?.data);
      queryClient.invalidateQueries({ queryKey: ["pool", poolId] });
      setPoolRulesArray(e?.data);
      setRuleTitle("");
      setRuleContent("");
      setRuleSortOrder(e?.data?.length + 1);
      toggleModal();
    },
  });

  return (
    <Modal showModal={showModal} toggleModal={toggleModal}>
      <S.ModalWrapper>
        <S.ModalTitle>Add new rule</S.ModalTitle>
        <Flex flexDirection="column" rowGap="20px">
          <Flex columnGap="13px" style={{ width: "100%" }}>
            <Flex flexDirection="column" rowGap="5px"style={{ width: "140px"}}>
              <S.Description>Rule number</S.Description>
              <S.StyledDropdown
                disabled
                value={ruleSortOrder}
                placeholder="Rule Number"
                onChange={e => setRuleSortOrder(e)}
              />
            </Flex>
            <Flex flexDirection="column" rowGap="5px" style={{ width: "100%" }}>
              <S.Description>Title</S.Description>
              <S.Input
                placeholder="Title"
                value={ruleTitle}
                onChange={e => {
                  setRuleTitle(e.target.value);
                }}
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" rowGap="5px" style={{ width: "100%" }}>
            <S.Description>Description</S.Description>
            <ReactQuill
              className="ql-custom"
              ref={quill}
              value={ruleContent}
              onChange={content => {
                setRuleContent(content);
              }}
            />
          </Flex>
          <Flex justifyContent="flex-end">
            <ButtonRound
              onClick={() =>
                addNewRuleMutation({
                  organizationId: organizationId,
                  poolId: poolId,
                  title: ruleTitle,
                  content: ruleContent,
                })
              }
              disabled={addNewRuleIsLoading}
            >
              Save
            </ButtonRound>
          </Flex>
        </Flex>
      </S.ModalWrapper>
    </Modal>
  );
};
