import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as S from "./styles";
import SearchBar from "../../shared/SearchBar";
import { getUsers } from "../../../../../api/users";
import { useGetSecHeaders } from "../../../../../hooks/useHeaders";
import LoadingSkeleton from "../../../../common/LoadingSkeleton";
import Container from "../../../../common/Container/Container";
import { useLogin } from "../../../../../providers/Loginprovider";
import AddTeamMemberRow from "./AddTeamMemberRow";
import { addMemberToTeam } from "../../../../../api/teams";
import { notifyError, notifySuccess } from "../../../../common/ToastComponent";
import Avatar from "../../../../common/Avatar";
import { teamJoinStatusFormatter } from "../../../../../utilities";

const TeamAddMemberModal = ({ toggleModal, showModal, teamId, currentPlayers }) => {
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState("");
  const { orgIdState } = useLogin();

  const {
    data: users,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["searchUser", searchQuery],
    queryFn: () => getUsers({ organizationId: orgIdState, searchQuery }, headers),
    refetchOnWindowFocus: false,
    enabled: !!headers?.encryptedHeader && !!orgIdState,
  });

  const usersNotFound = users && users.length < 1;

  return (
    <S.AddMemberModal showModal={showModal} toggleModal={toggleModal} afterClose={() => setSearchQuery("")}>
      <S.AddMemberWrapper>
        <S.ModalTitle>Add Member</S.ModalTitle>
        <S.UserWrapper>
          <SearchBar setSearchQuery={setSearchQuery} />
          <S.MemberListContainer>
            <S.MemberListInner>
              {isLoading ? (
                <>
                  <LoadingSkeleton height="50px" />
                  <LoadingSkeleton height="50px" />
                  <LoadingSkeleton height="50px" />
                </>
              ) : usersNotFound ? (
                <Container flex justifyContent="center" alignItems="center" height="200px">
                  User Not Found
                </Container>
              ) : (
                // Passing headers and queryClient as props so that the hooks don't have to be called for each member row
                users?.map(user => {
                  const playerFound = currentPlayers.find(player => player.userId === user.id);
                  if (
                    playerFound &&
                    playerFound.status !== "deleted" &&
                    playerFound.status !== "cancelled"
                  )
                    return (
                      <Container flex alignItems="center" justifyContent="space-between">
                        <Container flex alignItems="center" columnGap="11px">
                          <Avatar src={user?.thumbnail} />
                          <span>{user.nick}</span>
                        </Container>
                        <S.Pill>{teamJoinStatusFormatter(playerFound.status)}</S.Pill>
                      </Container>
                    );

                  return (
                    <AddTeamMemberRow
                      key={user.id}
                      data={user}
                      teamId={teamId}
                      headers={headers}
                      queryClient={queryClient}
                    />
                  );
                })
              )}
            </S.MemberListInner>
          </S.MemberListContainer>
        </S.UserWrapper>
      </S.AddMemberWrapper>
    </S.AddMemberModal>
  );
};

export default TeamAddMemberModal;
