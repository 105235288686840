import styled from "styled-components";

// export const CreateBracketContainer = styled.div`
//   height: 364px;
//   border-radius: 6px;
//   border: 1px solid rgba(114, 101, 200, 0.3);
//   background: #3f2faa;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

export const PreviewContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PreviewCard = styled.div`
  width: 298px;
  min-height: 370.13px;
  padding: 14.66px;
  border-radius: 6px;
  background-color: #3f2faa;
`;

export const PoolImage = styled.div`
  width: 268.69px;
  height: 175.88px;
  position: relative;
  border-radius: 6px;
  background: ${props => (props.background ? `url(${props.background})` : "#201366")};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const StartDate = styled.div`
  position: absolute;
  min-width: 106px;
  height: 36px;
  bottom: -15px;
  left: 4px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background: ${props =>
    props.gradientA && props.gradientB
      ? `linear-gradient(#3f2faa, #3f2faa) padding-box, linear-gradient(to right, ${props.gradientA}, ${props.gradientB}) border-box`
      : "#3f2faa"};
  border-radius: 50px;
  border: ${props => `2px solid ${props.border ? props.border : "transparent"}`};
`;

export const TrophyIconContainer = styled.div`
  display: flex;
  width: 48.38px;
  height: 46.18px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  background-color: #4e3cca;
`;

export const AmountSection = styled.div`
  display: flex;
  background-color: red;
`;

export const DataBlock = styled.div`
   /* borderRadius="8px" background="#4E3CCA" padding="7px" style={{ minWidth: "93px" }} */
   border-radius: 8px;
   background-color: #4E3CCA;
   padding: 7px;
   min-width: 93px;
   display: flex;
   align-items: center;
`