import React, { useState } from "react";
import * as S from "./styles";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import OptionRow from "./OptionRow";
import { CaretArrow } from "../../../../assets/svg";
import { executePoolAction } from "../../../../api/pool";
import DottedMenuComponent from "../../../common/DottedMenu";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import useConfirm from "../../../../hooks/useConfirm";
import { useLogin } from "../../../../providers/Loginprovider";
import { notifyError } from "../../../common/ToastComponent";

const ROUND_MAP = {
  SINGLE_ELIMINATION: "Best of 1",
  BEST_OF_3: "Best of 3",
  BEST_OF_5: "Best of 5",
  BEST_OF_7: "Best of 7",
};

const ROUND_SHORT_FORM_MAP = {
  SINGLE_ELIMINATION: "BO1",
  BEST_OF_3: "BO3",
  BEST_OF_5: "BO5",
  BEST_OF_7: "BO7",
};

const RoundSelectorTrigger = ({ roundMode, disabled }) => (
  <S.RoundSelector disabled={disabled}>
    {ROUND_SHORT_FORM_MAP[roundMode]} <CaretArrow style={{ transform: "rotate(275deg)" }} />
  </S.RoundSelector>
);

const RoundSelectorDropdown = ({
  updateRoundMode,
  roundMode,
  isLoadingUpdateRoundMode = false,
  isRefetchingData = false,
}) => {
  const [selectedRoundMode, setSelectedRoundMode] = useState("");

  const { triggerModal, ConfirmDialogue } = useConfirm({
    callback: () => updateRoundMode({ roundMode: selectedRoundMode }),
    message: "Are you sure you want to update the round mode?"
  });

  function handleSelect(value) {
    setSelectedRoundMode(value);
    triggerModal();
  }

  // If there is no roundMode, that means there is data not fetched yet or some issues with the brackets. So not displaying the selector
  if(!roundMode) return null;
  return (
    <>
      <DottedMenuComponent
        customComponent={
          <RoundSelectorTrigger disabled={isLoadingUpdateRoundMode || isRefetchingData} roundMode={roundMode} />
        }
        menuStyles={{ marginTop: "10px" }}
      >
        {() => (
          <S.RoundSelectionMenu>
            {Object.entries(ROUND_MAP).map(([value, label]) => (
              <OptionRow value={value} handleSelect={handleSelect} label={label} active={value === roundMode} />
            ))}
          </S.RoundSelectionMenu>
        )}
      </DottedMenuComponent>
      <ConfirmDialogue />
    </>
  );
};

export default RoundSelectorDropdown;
