import styled from "styled-components";
import { DefaultAvatar } from "../../../../../assets/svg";

export const AvatarContainer = styled.div`
  top: 46px;
  left: 30px;
  width: 112.8px;
  height: 112.8px;
  position: absolute;
  border-radius: 100px;
  background-size: cover;
  border: ${props => props.hideBorder ? "unset" : "3px solid #be7eff"};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Avatar = styled.div`
  width: 100.8px;
  height: 100.8px;
  border-radius: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.src || "/avatar-default.png"})`};
`;

export const ImagesContainer = styled.div`
  height: 119px;
  display: flex;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.src || "/banner-default.png"})`};
`;
