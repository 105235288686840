import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";

export const SessionContext = createContext(null);

export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [checkUserSession, setCheckUserSession] = useState(false);
  return (
    <SessionContext.Provider value={{ session, setSession, checkUserSession, setCheckUserSession }}>
      {children}
    </SessionContext.Provider>
  );
};

export function useSession() {
  // get the context
  const context = useContext(SessionContext);

  // if `undefined`, throw an error
  if (context === undefined || context === null) {
    throw new Error("useSessionContext was used outside of its Provider");
  }

  return context;
}
