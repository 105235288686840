import React, { useEffect, useMemo, useState } from "react";
import moment from "moment/moment";
import { useMedia } from "use-media";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { getGamesList } from "../../../../api/game";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";

import * as S from "./styles";
import Flex from "../../../common/Flex";
import Container from "../../../common/Container/Container";
import { Actions } from "../../../common/Actions/Actions";
import { notifyError, notifySuccess } from "../../../common/ToastComponent";
import CustomDateTimePicker from "../../../common/CustomDateTimePicker";
import {
  capitalizeFirstLetter,
  formatNumberWithLetters,
  formatTimeStamp,
  getPlayers,
  poolStatusFormatter,
  truncateWithDots,
  truncateWithMiddleDots,
} from "../../../../utilities";
import {
  Calendar,
  Check,
  Edit,
  Export,
  Finish,
  GreenCheck,
  Play,
  RegistrationIcon,
  RoundedCheck,
  RoundedCross,
  ThreeDots,
} from "../../../../assets/svg";
import organizationDefaultAvatar from "../../../../assets/svg/org-icon.svg";
import { Tooltip } from "antd";
import { Dropdown } from "../../../common/Dropdown";
import { PENDING_REVISION } from "../../../../constants/status";
import { useSession } from "../../../../providers/SessionProvider";
import { editTournamentOrganization, getPoolTemplates, updatePoolTemplate } from "../../../../api/pool";
import { useLogin } from "../../../../providers/Loginprovider";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Avatar from "../../../common/Avatar";
import Menu from "../../../common/Menu";
import { POOL_MODES, POOL_TEMPLATE_KEY_MAP, POOL_TEMPLATE_NAME_MAP } from "../../../../constants";

export const TournamentDetailHeader = ({
  data,
  mutatePoolDetails,
  mutatePoolLoading,
  mutateTournamentApproval,
  mutateTournamentApprovalLoading,
  mutatePoolReactivation,
  mutatePoolReactivationLoading,
  setPlayerListDownloadLink,
  setIsPlayerListDownloadModalOpen,
  selectedStage,
  setSelectedStage,
  userType,
}) => {
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();
  const { session } = useSession();
  const isMultiStagePool = data?.poolMode === POOL_MODES.MULTI_STAGE;

  //! States and functions that toggles the menus
  const [toggleTitleMenu, setToggleTitleMenu] = useState(false);
  const [toggleEditStartTimeMenu, setToggleEditStartTimeMenu] = useState(false);
  const [toggleEditEndTimeMenu, setToggleEditEndTimeMenu] = useState(false);
  const [toggleEditRegStartTimeMenu, setToggleEditRegStartTimeMenu] = useState(false);
  const [toggleEditRegEndTimeMenu, setToggleEditRegEndTimeMenu] = useState(false);
  const [toggleActionMenu, setToggleActionMenu] = useState(false);
  const [toggleChangeMenu, setToggleChangesMenu] = useState(false);
  const [isShowingMaxPlayersInput, setIsShowingMaxPlayersInput] = useState(false);
  const [isReactivatingPool, setIsReactivatingPool] = useState(false);

  const { orgIdState } = useLogin();
  const { poolId } = useParams();
  const userOrganizations = session?.user?.organizations;
  const matchingOrganization = userOrganizations?.find(org => org.organizationId === data.organizationId);
  const [organizationData, setOrganizationData] = useState(matchingOrganization);
  const [organizationId, setOrganizationId] = useState(matchingOrganization?.organizationId);

  const toggleActionsMenu = () => {
    setToggleActionMenu(!toggleActionMenu);
  };

  const closeActionsMenu = () => {
    setToggleActionMenu(false);
  };

  const toggleChangesMenu = () => {
    setToggleChangesMenu(!toggleChangeMenu);
  };

  const closeChangesMenu = () => {
    setToggleChangesMenu(false);
  };

  const [nameInput, setNameInput] = useState(data.name);
  const [descInput, setDescInput] = useState(data.description);
  const [menuItemsToDisplay, setMenuItemsToDisplay] = useState([]);

  // Added these lines to resolve an issue related to registrationStartDate and registrationEndDate being undefined for some pools, which was causing unexpected errors when trying to update dates.

  const registrationStartDateData = isMultiStagePool
    ? selectedStage.registrationStartDate || 0
    : data.registrationStartDate || 0;

  const registrationEndDateData = isMultiStagePool
    ? selectedStage.registrationEndDate || 0
    : data.registrationEndDate || 0;

  const startDateData = isMultiStagePool ? selectedStage.startDate || 0 : data.startDate || 0;
  const endDateData = isMultiStagePool ? selectedStage.endDate || 0 : data.endDate || 0;
  // data.registrationEndDate = data.registrationEndDate || 0;

  const [startDate, setStartDate] = useState(startDateData);
  const [endDate, setEndDate] = useState(endDateData);
  const [registrationStartDate, setRegistrationStartDate] = useState(registrationStartDateData);
  const [registrationEndDate, setRegistrationEndDate] = useState(registrationEndDateData);

  const [reasonText, setReasonText] = useState("");

  const isSmallScreen = useMedia({ maxWidth: 1079 });
  const isLargeScreen = useMedia({ minWidth: 1535 });

  const [maxPlayerInputVal, setMaxPlayerInputVal] = useState(data.maxPlayers);

  const { data: gameList } = useQuery({
    queryKey: ["gameList", data.game],
    queryFn: () => getGamesList(),
    refetchOnWindowFocus: false,
    enabled: !!headers?.encryptedHeader && !!data.game, // enabled needs a boolean property. The !! operator converts the value of headers?.encryptedHeader to a boolean
  });

  function handleDateChecks(type) {
    if (type === "reg_start_date") {
      if (!registrationStartDate) {
        throw new Error("Invalid date entered");
      }
      let formattedRegStartDate = moment(registrationStartDate);
      if (!formattedRegStartDate?._isValid) {
        throw new Error("Invalid date entered");
      }

      if (registrationEndDateData && formattedRegStartDate?.isAfter(registrationEndDateData)) {
        throw new Error("Registration start date cannot be later than the registration end date");
      } else if (formattedRegStartDate?.isAfter(startDateData)) {
        throw new Error("Registration start date cannot be later than the pool start date");
      } else if (formattedRegStartDate?.isAfter(endDateData)) {
        throw new Error("Registration start date cannot be later than the pool end date");
      }
    }

    if (type === "reg_end_date") {
      if (!registrationEndDate) {
        throw new Error("Invalid date entered");
      }
      let formattedRegEndtDate = moment(registrationEndDate);
      if (!formattedRegEndtDate?._isValid) {
        throw new Error("Invalid date entered");
      }

      if (formattedRegEndtDate?.isBefore(registrationStartDateData)) {
        throw new Error("Registration end date cannot be before the registration start date");
      }
    }

    if (type === "pool_start_date") {
      if (!startDate) {
        throw new Error("Invalid date entered");
      }
      let formattedPoolStartDate = moment(startDate);
      if (!formattedPoolStartDate?._isValid) {
        throw new Error("Invalid date entered");
      }
      if (formattedPoolStartDate?.isBefore(registrationStartDateData)) {
        throw new Error("Pool start date cannot be before the registration start date");
      } else if (formattedPoolStartDate?.isAfter(endDateData)) {
        throw new Error("Pool start date cannot be later than the pool end date");
      }
    }

    if (type === "pool_end_date") {
      if (!endDate) {
        throw new Error("Invalid date entered");
      }
      let formattedPoolEndDate = moment(endDate);
      if (!formattedPoolEndDate?._isValid) {
        throw new Error("Invalid date entered");
      }
      if (formattedPoolEndDate?.isBefore(registrationStartDateData)) {
        throw new Error("Pool end date cannot be before the registration start date");
      } else if (formattedPoolEndDate?.isBefore(startDateData)) {
        throw new Error("Pool end date cannot be before the pool start date");
      }
    }
    if (type === "re_activate_pool") {
      if (!endDate) {
        throw new Error("Invalid date entered");
      }
      let formattedPoolEndDate = moment(endDate);
      if (!formattedPoolEndDate?._isValid) {
        throw new Error("Invalid date entered");
      }
      if (formattedPoolEndDate?.isBefore(registrationStartDateData)) {
        throw new Error("Pool end date cannot be before the registration start date");
      } else if (formattedPoolEndDate?.isBefore(startDateData)) {
        throw new Error("Pool end date cannot be before the pool start date");
      }
    }
  }

  function updateDates(type) {
    try {
      let date = "";
      let successCb;
      let errorCb;
      let argObj = {};

      handleDateChecks(type);

      if (type === "reg_start_date") {
        date = registrationStartDate;
        argObj = {
          reg_end_date: registrationEndDateData,
        };
        successCb = () => setToggleEditRegStartTimeMenu(false);
        errorCb = () => setRegistrationStartDate(registrationStartDateData);
      } else if (type === "reg_end_date") {
        date = registrationEndDate;
        argObj = {
          reg_start_date: registrationStartDateData,
        };
        successCb = () => setToggleEditRegEndTimeMenu(false);
        errorCb = () => setRegistrationEndDate(registrationEndDateData);
      } else if (type === "pool_end_date") {
        date = endDate;
        argObj = {
          pool_start_date: startDateData,
        };
        successCb = () => setToggleEditEndTimeMenu(false);
        errorCb = () => setEndDate(endDateData);
      } else {
        date = startDate;
        argObj = {
          pool_end_date: endDateData,
        };
        successCb = () => setToggleEditStartTimeMenu(false);
        errorCb = () => setStartDate(startDateData);
      }
      mutatePoolDetails({
        action: "set_pool_dates",
        data: {
          [type]: date,
          ...argObj,
        },
        successCb: res => {
          queryClient.setQueryData(["pool", res?.data?.poolId?.toString()], prevData => {
            return {
              ...prevData,
              data: {
                ...prevData.data,
                poolStatus: res?.data?.poolStatus,
              },
            };
          });
          successCb(); // the localSuccessCb we created above
        },
        errorCb: errorCb,
      });
    } catch (e) {
      notifyError(e?.message || e?.data);
    }
  }

  function reactivatePool(type) {
    let successCb;
    let errorCb;

    handleDateChecks(type);

    successCb = () => setToggleEditEndTimeMenu(false);
    errorCb = () => setEndDate(endDateData);

    mutatePoolReactivation({
      endDate: endDate,
      successCb: successCb,
      errorCb: errorCb,
    });
  }

  function updateMaxPlayers(playersCount) {
    if (!playersCount || !typeof playersCount === "number") {
      notifyError("Please provide a valid number for max players");
      return;
    }
    try {
      mutatePoolDetails({
        action: "update_max_players",
        data: {
          maxPlayers: playersCount,
        },
        successCb: res => {
          queryClient.setQueryData(["pool", poolId], prevData => {
            return {
              ...prevData,
              data: {
                ...prevData.data,
                maxPlayers: res?.data,
              },
            };
          });
          setMaxPlayerInputVal(res?.data);
          setIsShowingMaxPlayersInput(false);
          notifySuccess("Max players updated successfully");
        },
      });
    } catch (e) {
      notifyError(e?.message || e?.data);
    }
  }

  const timestamp = new Date();
  const timestampFormated = moment(timestamp).format("MMMM Do - h a");
  const dateFormated = moment(timestamp).format("MMMM/DD/YYYY");

  const menuData = [
    {
      id: "edit-title-subtitle",
      label: "Edit Title and Subtitle",
      icon: <Edit />,
      action_id: "update_title",
    },
    {
      id: "edit-reg-start-date",
      label: "Edit registration start date",
      icon: <RegistrationIcon />,
      action_id: "set_pool_dates",
    },
    {
      id: "edit-reg-end-date",
      label: "Edit registration end date",
      icon: <RegistrationIcon />,
      action_id: "set_pool_dates",
    },
    {
      id: "edit-start-date",
      label: "Edit tournament start date",
      icon: <Calendar />,
      action_id: "set_pool_dates",
    },
    {
      id: "edit-end-date",
      label: "Edit tournament end date",
      icon: <Calendar />,
      action_id: "set_pool_dates",
    },
    {
      id: "start",
      label: "Start Tournament",
      icon: <Play />,
      action_id: "start_pool",
    },
    {
      id: "update-max-players",
      label: "Update Max Players",
      icon: <Edit />,
      action_id: "update_max_players",
    },
    // {
    //   id: "finish",
    //   label: "Finish Tournament",
    //   icon: <Finish />,
    //   action_id: "finish_pool",
    // },
    // {
    //   id: "export",
    //   label: "Export players",
    //   icon: <Export />,
    //   action_id: "export_players_list",
    // },
  ];

  const poolInfo = [
    {
      label: "Prize Pool",
      value: (
        <Flex alignItems="center" columnGap="6px">
          {data?.poolPrize > 0 ? data?.poolPrize : 0}
          <S.TokenImg src={data?.tokenImage} />
        </Flex>
      ),
    },
    {
      label: "Join mode",
      value: capitalizeFirstLetter(data.joinMode),
    },
    {
      label: "Entry Fee",
      value: (
        <Flex alignItems="center" columnGap="6px">
          {data?.entrance > 0 ? data?.entrance : 0}
          <S.TokenImg src={data?.tokenImage} />
        </Flex>
      ),
    },
    {
      label: "Format",
      value: capitalizeFirstLetter(data?.poolType === "MULTI_STAGE" ? selectedStage?.stageType : data?.poolFormat),
    },
  ];

  const btnConditionals = useMemo(() => {
    return {
      finishBtn: data.actions?.find(item => item.action === "finish_pool" && item.enabled),
      exportPlayersBtn: data.actions?.find(item => item.action === "export_players_list" && item.enabled),
    };
  }, [data]);

  // function to when the user clicks in one of the elements checks the id and then does the action
  const handleActionClick = e => {
    const { id } = e.target;

    switch (id) {
      case "edit-title-subtitle":
        if (!toggleTitleMenu) {
          // Resetting name and description input in case user attempted to change either of these earlier and got an error. We need to show accurate data if user closes the menu and opens again
          setNameInput(data.name);
          setDescInput(data.description);
        }
        setToggleTitleMenu(!toggleTitleMenu);
        closeActionsMenu();
        break;
      case "edit-start-date":
        setToggleEditStartTimeMenu(!toggleEditStartTimeMenu);
        closeActionsMenu();
        break;
      case "edit-end-date":
        setToggleEditEndTimeMenu(!toggleEditEndTimeMenu);
        closeActionsMenu();
        break;
      case "edit-reg-start-date":
        setToggleEditRegStartTimeMenu(!toggleEditRegStartTimeMenu);
        closeActionsMenu();
        break;
      case "edit-reg-end-date":
        setToggleEditRegEndTimeMenu(!toggleEditRegEndTimeMenu);
        closeActionsMenu();
        break;
      case "start":
        mutatePoolDetails({
          action: "start_pool",
          successCb: () => closeActionsMenu(),
        });
        break;
      case "finish":
        mutatePoolDetails({
          action: "finish_pool",
          successCb: () => closeActionsMenu(),
        });
        break;
      case "export":
        setIsPlayerListDownloadModalOpen(true);
        mutatePoolDetails({
          action: "export_players_list",
          successCb: data => {
            setPlayerListDownloadLink(data?.data?.fileURL);
            // Set download link and download modal boolean here
            closeActionsMenu();
          },
        });
        break;
      case "update-max-players":
        setIsShowingMaxPlayersInput(!isShowingMaxPlayersInput);
        closeActionsMenu();
        break;
      case "re-activate-pool":
        setToggleEditEndTimeMenu(!toggleEditEndTimeMenu);
        setIsReactivatingPool(!isReactivatingPool);
        closeActionsMenu();
      default:
        break;
    }
  };

  useEffect(() => {
    if (!data.actions) return;
    setMenuItemsToDisplay(
      menuData.filter(menuItem => data.actions.find(actionItem => actionItem.action === menuItem.action_id)?.enabled),
    );

    // We can use the following in case we need to display but disable actions that have enable property set to false from BE.
    // If using this, also add disabled property to the component disabling the button. A comment is added below for more details
    // setMenuItemsToDisplay(
    //   menuData.map(menuItem => ({
    //     ...menuItem,
    //     enabled: data.actions.find(actionItem => actionItem.action === menuItem.action_id)?.enabled,
    //   })),
    // );
  }, [data?.actions]);

  const EditMenu = () => (
    <S.EditMenuContainer>
      <S.RowButton onClick={toggleActionsMenu} actionIsToggled={toggleActionMenu}>
        <Edit />
        Edit
      </S.RowButton>
      <Actions
        closeActionsMenu={closeActionsMenu}
        toggleActions={toggleActionsMenu}
        showActions={toggleActionMenu}
        top="40px"
        right="0px"
      >
        {menuItemsToDisplay && menuItemsToDisplay?.length ? (
          menuItemsToDisplay.map((actions, index) => (
            <S.ActionButton
              key={index}
              id={actions.id}
              onClick={e => {
                handleActionClick(e);
              }}
              // disabled={mutatePoolLoading || !actions.enabled} // This can be used if we decide to show and disable actions that have enabled false from BE
              disabled={mutatePoolLoading}
            >
              {actions.icon}
              <S.ActionLabel id={actions.id}>{actions.label}</S.ActionLabel>
            </S.ActionButton>
          ))
        ) : (
          <S.ActionButton onClick={handleActionClick} disabled={false} id="re-activate-pool">
            Re-activate tournament
          </S.ActionButton>
        )}
      </Actions>
    </S.EditMenuContainer>
  );

  const { mutate: mutateEditOrganization, isLoading: mutateEditOrganizationIsLoading } = useMutation({
    mutationFn: variables => {
      return editTournamentOrganization(
        {
          poolId: variables.poolId,
          userOrganizationId: variables.userOrganizationId,
          assignedOrganizationId: variables.assignedOrganizationId,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: e => {
      notifySuccess(e?.message || e?.data);
      queryClient.invalidateQueries({
        queryKey: ["pool", poolId],
      });
    },
  });

  const [poolTemplates, setPoolTemplates] = useState([]);

  const {
    data: poolTemplatesResponse,
    isLoading: isLoadingPoolTemplates,
    refetch: refetchPoolTemplates,
    isRefetching,
  } = useQuery({
    queryKey: ["poolTemplates", poolId],
    queryFn: () => getPoolTemplates({ orgId: orgIdState, poolId }, headers),
    refetchOnWindowFocus: false,
    enabled: !!headers?.encryptedHeader && !!orgIdState,
  });

  const { mutate: mutatePoolTemplate, isLoading: isLoadingMutatePoolTemplate } = useMutation({
    mutationFn: variables => {
      return updatePoolTemplate(
        {
          poolId,
          orgId: orgIdState,
          templateId: variables.templateId,
          templateType: variables.templateType,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: e => {
      if (e.error) {
        throw new Error("Something went wrong");
      }
      notifySuccess(e?.message || e?.data);
      refetchPoolTemplates();

      // NOTE: invalidateQueries does not work if enabled option is used in the query function. So refetch is being used
      // https://stackoverflow.com/questions/68577988/invalidate-queries-doesnt-work-react-query
    },
  });

  const disableTemplateOptions = isRefetching || isLoadingMutatePoolTemplate;

  function populateTemplateSubMenuArray(key, arr) {
    if (!key || !arr || !Array.isArray(arr)) return [];

    let tempArr = arr.map(item => ({
      name: item.name,
      selected: item.selected,
      onClick: () => mutatePoolTemplate({ templateId: item.id, templateType: POOL_TEMPLATE_KEY_MAP[key] }),
    }));

    return tempArr;
  }
  function formatPoolTemplateDataForMenuComponent(data) {
    if (!data) return;

    let keys = Object.keys(data);

    let temp = keys.map(key => ({
      name: POOL_TEMPLATE_NAME_MAP[key],
      subMenu: populateTemplateSubMenuArray(key, data[key]),
      disabled: data[key]?.length < 1,
    }));
    setPoolTemplates(temp);
  }

  useEffect(() => {
    formatPoolTemplateDataForMenuComponent(poolTemplatesResponse?.data);

    if (poolTemplatesResponse && poolTemplatesResponse.error) {
      notifyError("Something went wrong");
    }
  }, [poolTemplatesResponse?.data, poolTemplatesResponse?.error]);

  // if (!data) return null;
  return (
    <>
      <S.DataHeader>
        <Flex alignItems="center" columnGap="17px">
          <S.GameImg
            src={gameList?.find(game => game?.id === data?.game)?.assets?.profileIcon || "https://placehold.co/49x49"}
            alt="game Logo"
          />
          <Flex flexDirection="column" rowGap="4px">
            <Flex columnGap="10px" alignItems="center">
              <S.Title>
                {truncateWithDots(data.name || "Pool Name", isSmallScreen ? 40 : isLargeScreen ? 150 : 70)}
              </S.Title>
              <S.StatusWrapper>
                <S.StatusIndicator status={data.poolStatus} />
                <S.StatusText>{poolStatusFormatter(data.poolStatus)}</S.StatusText>
              </S.StatusWrapper>
            </Flex>
            <S.IdText>ID: {data.id}</S.IdText>
          </Flex>
        </Flex>
        {/* Button Menu */}
        <S.BtnRow>
          {userType === "SUPERUSER" && data.poolStatus === PENDING_REVISION && (
            <>
              {/* Approve Button */}
              <S.RowButton onClick={() => mutateTournamentApproval({ decision: "approve", reason: "" })}>
                <RoundedCheck /> Approve
              </S.RowButton>
              <S.RequestChangesContainer>
                {/* Request changes Button */}
                <S.RowButton onClick={toggleChangesMenu} actionIsToggled={toggleChangeMenu} width="150px">
                  <RoundedCross /> Request changes
                </S.RowButton>
                <Actions
                  closeActionsMenu={closeChangesMenu}
                  toggleActions={toggleChangesMenu}
                  showActions={toggleChangeMenu}
                  top="40px"
                  right="0px"
                >
                  <Container flex flexDirection="column" rowGap="15px">
                    <Container>
                      <S.TextAreaContainer>
                        <S.TextAreaWrapper>
                          <S.TextAreaLabel>Comment</S.TextAreaLabel>
                          <S.CustomTextArea value={reasonText} onChange={e => setReasonText(e.target.value)} />
                        </S.TextAreaWrapper>
                      </S.TextAreaContainer>
                    </Container>
                    <Container flex flexDirection="row" justifyContent="flex-end" columnGap="10px" paddingBottom="10px">
                      <S.ReasonMenuLabel color="#A498ED" onClick={toggleChangesMenu}>
                        Cancel
                      </S.ReasonMenuLabel>
                      <S.ReasonMenuLabel
                        color="#be7eff"
                        onClick={() => mutateTournamentApproval({ decision: "deny", reason: reasonText })}
                      >
                        Save
                      </S.ReasonMenuLabel>
                    </Container>
                  </Container>
                </Actions>
              </S.RequestChangesContainer>
            </>
          )}

          {/* Edit Menu Button. Should only be displayed if there are any items to show */}
          <EditMenu />

          {/* Finish Pool Button. Should only  display if "finish_pool" action is enabled from the backend */}
          {btnConditionals.finishBtn ? (
            <S.RowButton id="finish" onClick={handleActionClick}>
              <Finish />
              Finish
            </S.RowButton>
          ) : null}

          {/* Finish Pool Button. Should only  display if "export_players_list" action is enabled from the backend */}
          {btnConditionals.exportPlayersBtn ? (
            <Tooltip title="Export Players List" placement="top" color="#7265C8">
              <S.RowButton width="32px" id="export" onClick={handleActionClick}>
                <Export />
              </S.RowButton>
            </Tooltip>
          ) : null}
        </S.BtnRow>
      </S.DataHeader>
      <div>
        <Container marginTop="24px" flex columnGap="6px">
          {data?.stages?.map(stage => (
            <S.TabButton onClick={() => setSelectedStage(stage)} active={selectedStage?.stageId === stage?.stageId}>
              Stage {stage.stageId}
            </S.TabButton>
          ))}
        </Container>
        <S.TournamentDetailHeaderContainer>
          <Flex alignItems="center" style={{ width: "100%" }}>
            <S.TournamentData>
              <S.TournamentBanner src={data?.thumbnail || "https://placehold.co/303x182"} alt="Tournament Banner" />
              <Flex flexDirection="column" alignItems="start" rowGap="24px">
                <S.TextWrapper>
                  <S.Subtitle style={{ marginRight: "24px" }}>{data.description || "Pool Description"}</S.Subtitle>
                  <Actions showActions={toggleTitleMenu} top="50px" left="0px">
                    <S.TextAreaContainer>
                      <S.TextAreaWrapper>
                        <S.TextAreaLabel>Title*</S.TextAreaLabel>

                        <S.CustomTextArea
                          disabled={mutatePoolLoading}
                          value={nameInput}
                          onChange={e => setNameInput(e.target.value)}
                        />
                      </S.TextAreaWrapper>
                      <S.TextAreaWrapper>
                        <S.TextAreaLabel>Subtitle</S.TextAreaLabel>
                        <S.CustomTextArea
                          disabled={mutatePoolLoading}
                          value={descInput}
                          onChange={e => setDescInput(e.target.value)}
                        />
                      </S.TextAreaWrapper>
                      <S.ActionContainer>
                        <S.ActionMenuLabel color="#A498ED" onClick={() => setToggleTitleMenu(false)}>
                          Cancel
                        </S.ActionMenuLabel>
                        <S.ActionMenuLabel
                          color="#BE7EFF"
                          disabled={mutatePoolLoading}
                          onClick={() =>
                            mutatePoolDetails({
                              action: "update_title",
                              data: {
                                title: nameInput,
                                description: descInput,
                              },
                              successCb: () => setToggleTitleMenu(false),
                            })
                          }
                        >
                          Save
                        </S.ActionMenuLabel>
                      </S.ActionContainer>
                    </S.TextAreaContainer>
                  </Actions>
                </S.TextWrapper>
                <Flex
                  justifyContent="space-between"
                  columnGap="74px"
                  rowGap="30px"
                  flexWrap={isSmallScreen ? "wrap" : "unset"}
                >
                  <Flex flexDirection="column" rowGap="13px" justifyContent="center">
                    <Flex flexDirection="column" rowGap="4px">
                      <S.Label>Registration Date</S.Label>
                      <Flex columnGap="9px">
                        <div className="dates-row-div">
                          <S.TournamentText>
                            <S.DateTextWrapper>
                              <S.DateText>
                                {registrationStartDateData
                                  ? formatTimeStamp(registrationStartDateData, "MMM Do - hh:mm a")
                                  : "-"}
                              </S.DateText>
                            </S.DateTextWrapper>
                            <Actions showActions={toggleEditRegStartTimeMenu} top="40px" left="0px" calendarContainer>
                              <S.DateContainer>
                                <S.TournamentText>Registration Start Dates</S.TournamentText>
                                <S.ActionDateContainer justifyContent="space-between">
                                  <CustomDateTimePicker
                                    disabled={mutatePoolLoading}
                                    value={registrationStartDate}
                                    onChange={setRegistrationStartDate}
                                  />
                                </S.ActionDateContainer>
                                <S.ActionContainer>
                                  <S.ActionMenuLabel
                                    color="#A498ED"
                                    onClick={() => {
                                      setToggleEditRegStartTimeMenu(false);
                                      setRegistrationStartDate(registrationStartDateData); // resetting to original data in case user cancels after making changes in calendar
                                    }}
                                  >
                                    Cancel
                                  </S.ActionMenuLabel>
                                  <S.ActionMenuLabel
                                    disabled={mutatePoolLoading}
                                    color="#BE7EFF"
                                    onClick={() => updateDates("reg_start_date")}
                                  >
                                    Save
                                  </S.ActionMenuLabel>
                                </S.ActionContainer>
                              </S.DateContainer>
                            </Actions>
                          </S.TournamentText>
                        </div>
                        <div className="dates-row-div">
                          <S.TournamentText>
                            <S.DateTextWrapper>
                              <S.DateText>
                                {registrationEndDateData
                                  ? formatTimeStamp(registrationEndDateData, "MMM Do - hh:mm a")
                                  : "-"}
                              </S.DateText>
                            </S.DateTextWrapper>
                            <Actions
                              showActions={toggleEditRegEndTimeMenu}
                              top="40px"
                              left="0px"
                              calendarContainer
                              calendarContainerRightCol
                            >
                              <S.DateContainer>
                                <S.TournamentText>Registration End Date</S.TournamentText>
                                <S.ActionDateContainer justifyContent="space-between">
                                  <CustomDateTimePicker
                                    disabled={mutatePoolLoading}
                                    value={registrationEndDate}
                                    onChange={setRegistrationEndDate}
                                  />
                                </S.ActionDateContainer>
                                <S.ActionContainer>
                                  <S.ActionMenuLabel
                                    color="#A498ED"
                                    onClick={() => {
                                      setToggleEditRegEndTimeMenu(false);
                                      setRegistrationEndDate(registrationEndDateData); // resetting to original data in case user cancels after making changes in calendar
                                    }}
                                  >
                                    Cancel
                                  </S.ActionMenuLabel>
                                  <S.ActionMenuLabel
                                    disabled={mutatePoolLoading}
                                    color="#BE7EFF"
                                    onClick={() => updateDates("reg_end_date")}
                                  >
                                    Save
                                  </S.ActionMenuLabel>
                                </S.ActionContainer>
                              </S.DateContainer>
                            </Actions>
                          </S.TournamentText>
                        </div>
                      </Flex>
                    </Flex>
                    <Flex flexDirection="column" rowGap="4px">
                      <S.Label>Tournament Date</S.Label>
                      <Flex columnGap="9px">
                        <S.TournamentText>
                          <S.DateTextWrapper>
                            <S.DateText>
                              {startDateData ? formatTimeStamp(startDateData, "MMM Do - hh:mm a") : "-"}
                            </S.DateText>
                          </S.DateTextWrapper>
                          <Actions showActions={toggleEditStartTimeMenu} top="40px" left="0px" calendarContainer>
                            <S.DateContainer>
                              <S.TournamentText>Pool Start Date</S.TournamentText>
                              <S.ActionDateContainer justifyContent="space-between">
                                <CustomDateTimePicker
                                  disabled={mutatePoolLoading}
                                  value={startDate}
                                  onChange={setStartDate}
                                />
                              </S.ActionDateContainer>
                              <S.ActionContainer>
                                <S.ActionMenuLabel
                                  color="#A498ED"
                                  onClick={() => {
                                    setToggleEditStartTimeMenu(false);
                                    setStartDate(startDateData); // resetting to original data in case user cancels after making changes in calendar
                                  }}
                                >
                                  Cancel
                                </S.ActionMenuLabel>
                                <S.ActionMenuLabel
                                  disabled={mutatePoolLoading}
                                  color="#BE7EFF"
                                  onClick={() => updateDates("pool_start_date")}
                                >
                                  Save
                                </S.ActionMenuLabel>
                              </S.ActionContainer>
                            </S.DateContainer>
                          </Actions>
                        </S.TournamentText>
                        <S.TournamentText>
                          <S.DateTextWrapper>
                            <S.DateText>
                              {endDateData ? formatTimeStamp(endDateData, "MMM Do - hh:mm a") : "-"}
                            </S.DateText>
                          </S.DateTextWrapper>
                          <Actions
                            showActions={toggleEditEndTimeMenu}
                            top="40px"
                            left="0px"
                            calendarContainer
                            calendarContainerRightCol
                          >
                            <S.DateContainer>
                              <S.TournamentText>Pool End Date</S.TournamentText>
                              <S.ActionDateContainer justifyContent="space-between">
                                <CustomDateTimePicker
                                  disabled={mutatePoolLoading}
                                  value={endDate}
                                  onChange={setEndDate}
                                />
                              </S.ActionDateContainer>
                              <S.ActionContainer>
                                <S.ActionMenuLabel
                                  color="#A498ED"
                                  onClick={() => {
                                    setToggleEditEndTimeMenu(false);
                                    setEndDate(endDateData); // resetting to original data in case user cancels after making changes in calendar
                                  }}
                                >
                                  Cancel
                                </S.ActionMenuLabel>
                                <S.ActionMenuLabel
                                  disabled={mutatePoolLoading}
                                  color="#BE7EFF"
                                  onClick={() =>
                                    !isReactivatingPool
                                      ? updateDates("pool_end_date")
                                      : reactivatePool("re_activate_pool")
                                  }
                                >
                                  Save
                                </S.ActionMenuLabel>
                              </S.ActionContainer>
                            </S.DateContainer>
                          </Actions>
                        </S.TournamentText>
                      </Flex>
                    </Flex>
                  </Flex>
                  <S.PoolDataWrapper>
                    <Flex columnGap="51px">
                      {poolInfo?.map(info => (
                        <Flex flexDirection="column">
                          <S.Label>{info.label}</S.Label>
                          <S.PoolValues>{info.value}</S.PoolValues>
                        </Flex>
                      ))}
                    </Flex>
                    <Flex columnGap="51px">
                      {data.joinMode === "TEAM" && (
                        <Flex flexDirection="column">
                          <S.Label>Teams</S.Label>
                          <S.PoolValues>
                            {`${data?.leaderboard?.length} /  ${!isShowingMaxPlayersInput ? data.maxPlayers : ""}`}
                            {isShowingMaxPlayersInput && data.joinMode === "TEAM" && (
                              <S.MaxPlayersInputContainer>
                                <S.MaxPlayersInput
                                  type="number"
                                  value={maxPlayerInputVal}
                                  onChange={e => setMaxPlayerInputVal(e.target?.value)}
                                />
                                <S.MaxPlayersInputButton
                                  disabled={mutatePoolLoading}
                                  onClick={() => updateMaxPlayers(maxPlayerInputVal)}
                                >
                                  <Check style={{ width: "10px", paddingTop: "2px" }} />
                                </S.MaxPlayersInputButton>
                              </S.MaxPlayersInputContainer>
                            )}
                          </S.PoolValues>
                        </Flex>
                      )}
                      <Flex flexDirection="column">
                        <S.Label>Players</S.Label>
                        <S.PoolValues>
                          {data.joinMode === "TEAM"
                            ? getPlayers(data?.leaderboard)
                            : `${data?.leaderboard?.length} / ${!isShowingMaxPlayersInput ? data.maxPlayers : ""}`}
                          {isShowingMaxPlayersInput && data.joinMode !== "TEAM" && (
                            <S.MaxPlayersInputContainer>
                              <S.MaxPlayersInput
                                type="number"
                                value={maxPlayerInputVal}
                                onChange={e => setMaxPlayerInputVal(e.target?.value)}
                              />
                              <S.MaxPlayersInputButton
                                disabled={mutatePoolLoading}
                                onClick={() => updateMaxPlayers(maxPlayerInputVal)}
                              >
                                <Check style={{ width: "10px", paddingTop: "2px" }} />
                              </S.MaxPlayersInputButton>
                            </S.MaxPlayersInputContainer>
                          )}
                        </S.PoolValues>
                      </Flex>
                      {data?.winnersFees && Array.isArray(data?.winnersFees) ? (
                        <Flex flexDirection="column">
                          <S.Label>Winners fee</S.Label>
                          <S.PoolValues>
                            <Dropdown
                              value="Fee"
                              containerStyles={{ background: "#4E3CCA", width: "146px", height: "22px" }}
                              dropdownStyles={{ background: "#4E3CCA" }}
                              dropdownOptionStyles={{ background: "#4E3CCA" }}
                              options={data?.winnersFees?.map((fee, indx) => ({
                                name: fee,
                                value: fee,
                                element: (
                                  <Container width="100%" flex justifyContent="space-between">
                                    <span>{formatNumberWithLetters(indx + 1)} winner</span>
                                    <span style={{ fontWeight: "600" }}>{fee}%</span>
                                  </Container>
                                ),
                              }))}
                            />
                          </S.PoolValues>
                        </Flex>
                      ) : null}
                    </Flex>
                    {userType === "SUPERUSER" && (
                      <Flex flexDirection="column">
                        <S.Label>Organization</S.Label>
                        <S.PoolValues>
                          <Dropdown
                            value={organizationData?.organizationName}
                            containerStyles={{ background: "#4E3CCA", width: "146px", height: "22px" }}
                            dropdownStyles={{ background: "#4E3CCA" }}
                            dropdownOptionStyles={{ background: "#4E3CCA" }}
                            selectionItemCss={{ display: "flex", alignItems: "center" }}
                            options={userOrganizations?.map((organization, indx) => ({
                              name: organization?.organizationName,
                              value: organization?.organizationId,
                              element: (
                                <Container
                                  width="100%"
                                  flex
                                  justifyContent="space-between"
                                  alignItems="center"
                                  columnGap="5px"
                                >
                                  <Avatar
                                    src={organizationDefaultAvatar}
                                    size="20px"
                                    alt="organization default image"
                                  />

                                  <span>{truncateWithMiddleDots(organization?.organizationName)}</span>
                                </Container>
                              ),
                            }))}
                            onChange={e => {
                              setOrganizationData(userOrganizations?.find(org => org.organizationId === e));
                              setOrganizationId(e);
                              mutateEditOrganization({
                                userOrganizationId: orgIdState,
                                poolId: poolId,
                                assignedOrganizationId: organizationId,
                              });
                            }}
                          />
                        </S.PoolValues>
                      </Flex>
                    )}
                  </S.PoolDataWrapper>
                </Flex>
              </Flex>
            </S.TournamentData>
          </Flex>
          {userType === "SUPERUSER" && (
            <S.TemplateMenuContainer>
              <Menu
                menuItems={poolTemplates}
                disabled={isLoadingPoolTemplates}
                disableOptions={disableTemplateOptions}
              />
            </S.TemplateMenuContainer>
          )}
        </S.TournamentDetailHeaderContainer>
      </div>
    </>
  );
};
