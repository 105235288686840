import React from "react";
import * as S from "./styles";
import Avatar from "../../../../common/Avatar";
import Text from "../../../../common/Text/Text";
import { formatTimeStamp } from "../../../../../utilities";
import Container from "../../../../common/Container/Container";

const UserRow = ({ data }) => {
  return (
    <S.Container>
      <Container flex alignItems="center" justifyContent="center" columnGap="12px" style={{background:"transparent"}}>
        <Avatar src={data.thumbnail} size="30px" />
        <Text fontSize="14px">{data.nick}</Text>
      </Container>

      <Text fontSize="13px">{data.id}</Text>

      <Text fontSize="13px">{formatTimeStamp(data.createdOn, "MMM Do yyyy")}</Text>
    </S.Container>
  );
};

export default UserRow;
