import React, { useState } from "react";
import { Popover } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import * as S from "./styles";

import { deleteUserAccount, updateGameIdAccount } from "../../../../../api/users";
import { DataRow } from "../../shared/styles";
import { Button, IconButton } from "../../../../common/Button";
import { Edit, Trash } from "../../../../../assets/svg";
import Container from "../../../../common/Container/Container";
import Text from "../../../../common/Text/Text";
import { useGetSecHeaders } from "../../../../../hooks/useHeaders";
import { notifyError, notifySuccess } from "../../../../common/ToastComponent";
import EditAccountModal from "../../shared/EditAccountModal";
import { useSession } from "../../../../../providers/SessionProvider";

const AccountRow = ({ account = {} }) => {
  const [openDelPopover, setOpenDelPopover] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();

  const { session } = useSession();

  const hideDelPopover = () => {
    setOpenDelPopover(false);
  };

  const handleOpenChange = newOpen => {
    setOpenDelPopover(newOpen);
  };

  const toggleEditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const { mutate: deleteAccount, isLoading: isLoadingDeleteAccount } = useMutation({
    mutationFn: variables => {
      return deleteUserAccount(
        {
          accountId: variables?.accountId,
          userId: variables?.userId,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: responseData => {
      if (responseData?.error) {
        notifyError(responseData?.message);
        return;
      } else {
        notifySuccess("Account deleted successfully");
        queryClient.invalidateQueries({ queryKey: ["user", account.userId] });
      }
    },
  });

  const { mutate: editGameId, isLoading: isLoadingEditGameId } = useMutation({
    mutationFn: variables => {
      return updateGameIdAccount(
        {
          accountId: variables?.accountId,
          username: variables?.username,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: responseData => {
      if (responseData?.error) {
        notifyError(responseData?.message);
        return;
      } else {
        queryClient.invalidateQueries({ queryKey: ["user", account.userId] });
        notifySuccess("Account updated successfully");
        toggleEditModal();
      }
    },
  });

  return (
    <DataRow>
      <S.AccountRoWrapper>
        <Text fontSize="13px">{account.visibleName}</Text>
        <Text fontSize="13px">{account.platformUserId}</Text>
      </S.AccountRoWrapper>
      {session?.user?.userType === "SUPERUSER" && (
        <>
          <IconButton onClick={toggleEditModal}>
            <Edit />
          </IconButton>
          <EditAccountModal
            showModal={openEditModal}
            toggleModal={toggleEditModal}
            accountData={account}
            mutateEditGameId={editGameId}
            mutateEditGameIdLoading={isLoadingEditGameId}
          />
        </>
      )}
      <Popover
        content={
          <Container marginTop="22px">
            <Button
              width="100%"
              background="#3F2FAA"
              height="50.885px"
              borderRadius="30px"
              onClick={() => {
                hideDelPopover();
                deleteAccount({ userId: account.userId, accountId: account.id });
              }}
            >
              Confirm
            </Button>
          </Container>
        }
        zIndex="9999"
        title="Are you sure you want to delete this account?"
        trigger="click"
        open={openDelPopover}
        onOpenChange={handleOpenChange}
        color="#7265C8"
        overlayInnerStyle={{
          color: "brown !important",
          width: "220px",
          padding: "19px",
          textAlign: "center",
        }}
        overlayStyle={{
          color: "green !important",
        }}
        overlayClassName="match-report-popover"
      >
        <IconButton onClick={() => setOpenDelPopover(true)} disabled={isLoadingDeleteAccount}>
          <Trash />
        </IconButton>
      </Popover>
    </DataRow>
  );
};

export default AccountRow;
