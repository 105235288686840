import React from "react";
import { BlastLogo } from "../../../../../../assets/svg";
import * as S from "./styles";

const FirstPreview = ({ previewData = {} }) => {
  const { logo, sidebarIcon } = previewData;
  return (
    <S.Container>
      <S.PreviewSidebar>
        <S.Logo>
          <BlastLogo />
        </S.Logo>
        {sidebarIcon ? <S.SidebarImg imgUrl={sidebarIcon} /> : <S.SidebarImgEmpty />}
        <S.SidebarImgEmpty />
        <S.SidebarImgEmpty />
      </S.PreviewSidebar>
      <S.Separator />
      <S.BannerContainer>
        <S.Banner>{logo && <img src={logo} width="120px" height="80px" />}</S.Banner>
      </S.BannerContainer>
    </S.Container>
  );
};

export default FirstPreview;
