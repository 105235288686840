import React from "react";

import * as S from "./styles";
import Flex from "../../../common/Flex";
import { Check } from "../../../../assets/svg";

const OptionRow = ({ label, value, active, handleSelect }) => {
  return (
    <S.OptionRowContainer onClick={() => handleSelect(value)}>
      <S.Tick active={active}>{active && <Check style={{ width: "8px", height: "12px" }} />}</S.Tick>
      {label}
    </S.OptionRowContainer>
  );
};

export default OptionRow;
