/* eslint-disable react/require-default-props */
import React, { useContext, useState, useEffect } from "react";
import { useUserAddress } from "eth-hooks";
import { useWallet } from "./WalletProvider";

const AppStateContext = React.createContext();

function AppStateProvider({ children }) {
  const { injectedProvider } = useWallet();
  const address = useUserAddress(injectedProvider);
  const appLoading = false;
  const txPending = false;

  // state variables to determine whether to show wallet install modal
  const [showWalletOnboarding, setShowWalletOnboarding] = useState(false);
  const [isConnectingWallet, setIsConnectingWallet] = useState(false);
  const [linkWalletError, setLinkWalletError] = useState("");
  const [closeConnectWalletModal, setCloseConnectWalletModal] = useState(false);
  const [platformState, setPlatformState] = useState();

  // function that toggles wallet install modal
  const toggleWalletOnboarding = () => {
    setShowWalletOnboarding(!showWalletOnboarding);
  };

  return (
    <AppStateContext.Provider
      value={{
        isLoading: appLoading,
        txPending,
        address,
        aTag: "",
        platform: "",
        nick: "",
        showWalletOnboarding,
        toggleWalletOnboarding,
        platformState,
        isConnectingWallet,
        setIsConnectingWallet,
        closeConnectWalletModal,
        setCloseConnectWalletModal,
        linkWalletError,
        setLinkWalletError,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
}

function useAppState() {
  return useContext(AppStateContext);
}

export { AppStateProvider, useAppState };
