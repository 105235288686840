import React from "react";
import * as S from "./styles";
import { Add, Substract } from "../../../assets/svg";
import Text from "../Text/Text";
const Counter = ({
  onAdd = () => {},
  onSubtract = () => {},
  onChange = () => {},
  value = 0,
  disabled = false,
  hasError = false,
  altText = "",
}) => {
  return (
    <S.CounterContainer disabled={disabled} hasError={hasError}>
      <button disabled={disabled} type="button" onClick={onSubtract} style={{color: "#fff", fontSize: "24px", paddingBottom: "4px"}}>
        -
      </button>
      {/* The replace used in the code below is to remove leading zeroes from numbers. But we do not want to replace zero if there is no other value after it, therefore the conditional used */}
      <S.Input
        value={value > 0 ? value?.toString()?.replace(/^0+/, "") : value}
        onChange={onChange}
        type="number"
        style={{ display: altText ? "none" : "flex" }}
      />
      {altText && <Text fontSize="12px">{altText}</Text>}
      <button disabled={disabled} type="button" onClick={onAdd}>
        <Add />
      </button>
    </S.CounterContainer>
  );
};

export default Counter;
