import styled from "styled-components";
import { css } from "styled-components";

export const Header = styled.div`
  color: #fff;
  font-size: 28px;
  font-weight: 325;
  margin-bottom: 27px;
`;

export const Row = styled.div`
  width: 100%;
  /* height: 60px; */
  display: flex;
  padding: 19px 13px;
  border-radius: 6px;
  background-color: ${props => (props.highlight ? "#6C47FF" : "#3F2FAA")};
`;

export const SelectContainer = styled.div`
  position: relative;
  margin: 0;
  margin-top: ${props => (props.hasPlayer ? "5px" : "8px")};
`;

export const SelectLabelButton = styled.button`
  border: none;
  color: #fff;
  /* align-items: center;
  justify-content: space-between; */
  cursor: pointer;
  transition: 0.3s ease;
  width: 100%;
  padding: 19px 13px;
  border-radius: 6px;
  background-color: #3f2faa;
  text-align: left;
  max-height: 90px;
  overflow: auto;

  &::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
&::-webkit-scrollbar-track {
  background: #4e3cca;
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background: #342788f0;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #342788;
}

&::-webkit-scrollbar-corner {
    background: #342788f0 !important;
  }

  /* &:hover {
    background-color: #eee;
  } */
`;

export const DropdownStyle = styled.div`
  position: absolute;
  left: 0;
  max-height: 377px;
  padding: 18px 14px;
  background: #3f2faa;
  color: #fff;
  transition: max-height 0.2s ease;
  overflow: scroll;
  width: 100%;
  top: 85px;
  overflow-x: hidden;
  overflow-y: hidden;
  border-radius: 4px;
  z-index: 999;
  ${p =>
    p.isVisible !== true &&
    css`
      max-height: 40px;
      visibility: hidden;
    `}
`;

export const PlayerListContainer = styled.div`
  overflow-x: hidden;
  height: 250px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 24px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #3f2faa;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #342788f0;
    border: 8px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 999px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #342788;
    background-clip: padding-box;
  }
`;
export const ListContainer = styled.div`
  overflow-x: hidden;
  height: 250px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 24px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #3f2faa;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #342788f0;
    border: 8px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 999px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #342788;
    background-clip: padding-box;
  }
`;

export const DropdownItem = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0.15rem 0;
  padding: 0.8rem 0.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: ${props => (props.disableStyles ? "#ffffff66" : "#fff")};
  border-radius: 0.3rem;
  cursor: ${props => (props.disableStyles ? "default" : "pointer")};
  ${p =>
    p.active &&
    css`
      color: #ffffff;
      font-weight: 500;
    `}
  &:hover, :focus, :focus:hover {
    width: 100%;
    /* height: 40px; */
    outline: none;
    color: ${props => (props.disableStyles ? "#ffffff66" : "#fafafa")};
    background-color: ${props => (props.disableStyles ? "transparent" : "#5242c2")};
  }
`;

export const SelectFiltersContainer = styled.div`
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 18px;
  border-bottom: 1px solid #4e3cca;
  display: ${props => (props.hide ? "none" : "flex")};
  @media (max-width: 425px) {
    max-width: 100%;
    padding-left: 2px;
    padding-right: 2px;
    height: 60px;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
`;

export const FilterButton = styled.div`
  height: 30px;
  display: flex;
  min-width: 69px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 325;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #4e3cca;
  color: ${props => (props.active ? "#342788" : "#fff")};
  background-color: ${props => (props.active ? "#F8F8F8" : "#4e3cca")};
`;

export const CaretContainer = styled.div`
  transform: ${props => (props.open ? "unset" : "rotate(-90deg)")};
  transition: transform 0.2s linear;
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const SwapIconContainer = styled.div`
  position: absolute;
  z-index: 999;
  left: 45%;
  top: 17%;
`;

export const SearchContainer = styled.div`
  width: 100%;
  height: 38px;
  background-color: #4e3cca;
  border-radius: 6px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
`;
export const SearchIconContainer = styled.div`
  border: 1px solid green;
`;
export const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: transparent;
  border: 0;
  color: #fff;
`;

export const SearchInputDivier = styled.div`
  height: 22px;
  width: 1px;
  background: #6652e5;
  margin-right: 12px;
`;

export const SelectedPlayerPill = styled.div`
  width: fit-content;
  height: 34px;
  border-radius: 100px;
  background-color: #6C47FF;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 7px 12px;
`