import React, { useEffect } from "react";
import Modal from "../../../common/Modal/Modal";
import * as S from "./styles";
import { Important } from "../../../../assets/svg";
import { Button } from "../../../common/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLogin } from "../../../../providers/Loginprovider";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { executePoolAction } from "../../../../api/pool";
import { notifyError } from "../../../common/ToastComponent";
import { useParams } from "react-router-dom";

export const CreateBracketModal = ({ toggleModal, showModal, setIsCreateBracketsLoading }) => {
  const { poolId } = useParams();
  const { orgIdState } = useLogin();
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();

  const { mutate: mutatePoolDetails, isLoading: mutatePoolLoading } = useMutation({
    mutationFn: () => {
      return executePoolAction(
        {
          organizationId: orgIdState,
          poolId: poolId,
          action: "bracket_ini",
        },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pool", poolId] });
    },
  });

  useEffect(() => {
    if (setIsCreateBracketsLoading) {
      setIsCreateBracketsLoading(mutatePoolLoading);
    }
  }, [mutatePoolLoading]);

  return (
    <Modal showModal={showModal} toggleModal={toggleModal}>
      <S.CreateBracketModalContainer>
        <Important />
        <S.CreateBracketModalHeader>
          <S.CreateBracketModalHeaderTitle>Create the Bracket</S.CreateBracketModalHeaderTitle>
        </S.CreateBracketModalHeader>
        <S.CreateBracketModalContent>
          <S.CreateBracketModalContentText>
            The brackets are created automatically after the register period. Once you create the bracket you're closing
            the registration period and this step can't be get back.
          </S.CreateBracketModalContentText>
        </S.CreateBracketModalContent>
        <S.CreateBracketModalFooter>
          <S.CreateBracketModalLink>No, keep registration open</S.CreateBracketModalLink>
          <Button
            borderRadius="30px"
            width="122px"
            height="42px"
            onClick={() => {
              toggleModal();
              mutatePoolDetails();
            }}
          >
            Yes, Create
          </Button>
        </S.CreateBracketModalFooter>
      </S.CreateBracketModalContainer>
    </Modal>
  );
};
