import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import styled from "styled-components";
import { breakpoints } from "../themes";
import { Sidebar } from "../components/layout/Sidebar/Sidebar";
import { Header } from "../components/layout/Header/Header";
import { HomePage } from "./Home/HomePage";
import { TournamentDetail } from "./TournamentDetail/TournamentDetail";
import { ModalProvider } from "styled-react-modal";
import NetworkSwitch from "../components/layout/NetworkSwitch/NetworkSwitch";
import { useWallet } from "../providers/WalletProvider";
import { useLogin } from "../providers/Loginprovider";
import AuthModal from "../components/features/auth/AuthModal";
import PrivateRoute from "../components/wrappers/PrivateRoute";
import Users from "./Users";
import Teams from "./Teams";
import { useSession } from "../providers/SessionProvider";
import Organizations from "./Organizations";
import NewTournament from "./NewTournament";
import NewMultiStageTournament from "./NewMultistageTiurnament";
import TournamentTypeSelect from "./TournamentTypeSelect";
import NewGame from "./NewGame";
import Games from "./Games";

const MainWrapper = styled.div`
  height: 100vh;
  ${props => `
     width: calc(100% - 91px);
     transition: ${props.collapseSidebar ? "unset" : "all 0.2s linear"};
  `}
  padding-bottom: 95px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #201366;
  ${breakpoints.down(breakpoints.laptop)} {
    width: 100%;
  }
`;

const MainContainer = styled.div`
  background-color: #201366;
  margin-top: 95px;
`;

const ModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1500;
  background-color: rgba(0, 0, 0, 0.45);
  align-items: center;
  justify-content: center;
  ${breakpoints.down(breakpoints.tablet)} {
    padding-top: 130px;
    align-items: flex-start;
  }
`;

export default function AppRouter() {
  const [collapseSidebar, setCollapseSidebar] = useState(true);
  const [scrollSize] = useState(0); // This variable is used to hold the width of the browser's scrollbar, which will be used to prevent the mobile navbar from overlapping with the scrollbar
  const { injectedProvider } = useWallet();
  const [showAuthModal, setShowAuthModal] = useState(false);

  const { socialLoginApiCall, setSocialAuthData, socialAuthData, isFetching, logout } = useLogin();
  const { session } = useSession();

  useEffect(() => {
    // setCollapseSidebar to false after three seconds
    if (collapseSidebar === false) {
      const timeoutId = setTimeout(() => {
        setCollapseSidebar(true);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [collapseSidebar]);

  return (
    <>
      {injectedProvider && <NetworkSwitch />}
      <Sidebar
        collapseSidebar={collapseSidebar}
        setCollapseSidebar={setCollapseSidebar}
        scrollSize={scrollSize}
        userType={session?.user?.userType || "USER"}
      />
      <Header
        collapseSidebar={collapseSidebar}
        setShowAuthModal={setShowAuthModal}
        isFetching={isFetching}
        logout={logout}
      />
      <MainWrapper collapseSidebar={collapseSidebar}>
        <ModalProvider backgroundComponent={ModalBackground}>
          <MainContainer>
            <Switch>
              <PrivateRoute exact path="/" component={HomePage} />
              <PrivateRoute exact path="/pool/:poolId" component={TournamentDetail} />
              <PrivateRoute exact path="/users" component={Users} />
              <PrivateRoute exact path="/teams" component={Teams} />
              <PrivateRoute exact path="/organizations" component={Organizations} />
              <PrivateRoute exact path="/calendar" />
              <PrivateRoute exact path="/new" component={TournamentTypeSelect} />
              <PrivateRoute exact path="/single-new" component={NewTournament} />
              <PrivateRoute exact path="/multistage-new" component={NewMultiStageTournament} />
              <PrivateRoute exact path="/pool/:poolId/clone" component={NewTournament} />
              <PrivateRoute exact path="/games" component={Games} />
              <PrivateRoute exact path="/games/new" component={NewGame} />

              <Route path="*">
                <Redirect to="/404" />
              </Route>
            </Switch>
          </MainContainer>
          <AuthModal
            showAuthModal={showAuthModal}
            setShowAuthModal={setShowAuthModal}
            socialLoginApiCall={socialLoginApiCall}
            setSocialAuthData={setSocialAuthData}
          />
        </ModalProvider>
      </MainWrapper>
    </>
  );
}
