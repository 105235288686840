import React from "react";
import * as S from "./styles";
import ImageTag from "../../../../../common/ImageTag";
import Text from "../../../../../common/Text/Text";
import Container, { Absolute, Relative } from "../../../../../common/Container/Container";

const SecondPreview = ({ previewData = {} }) => {
  const { gameCard, mainBanner, name, logo } = previewData;
  return (
    <S.Container>
      <ImageTag src={gameCard} width="171.72px" height="197.82px" />
      <Container width="197.82px" marginTop="18px" marginBottom="37px" paddingLeft="12px">
        {gameCard && (
          <Text fontSize="12px" color="#d7cfe8">
            {name}
          </Text>
        )}
      </Container>
      <Relative>
        <ImageTag src={mainBanner} width="505.8px" height="100.62px" />
        {mainBanner && (
          <Absolute top="18px" left="30px">
            <ImageTag src={logo} style={{maxHeight: "68px"}} />
          </Absolute>
        )}
      </Relative>
    </S.Container>
  );
};

export default SecondPreview;
