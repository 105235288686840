import { SEED, STATE_KEY } from "../constants/env";
import * as crypto from "crypto-js";

function checkUserAndId(user) {
  if (!user || !user.id) {
    console.log(`User Id not found while setting local storage`);
    return;
  }
  return user.id;
}
export function setUserInLocalStorage(state) {
  localStorage.setItem(STATE_KEY, state);
}

export function removeUserInLocalStorage() {
  localStorage.removeItem(STATE_KEY);
}

export function getUserInLocalStorage() {
  const item = localStorage.getItem(STATE_KEY);
  if (!item) return false;
  const sessionBytes = crypto.AES.decrypt(item, SEED);
  const sessionObject = JSON.parse(sessionBytes.toString(crypto.enc.Utf8));
  return sessionObject;
}

export function setUserOrganizationId(id) {
  localStorage.setItem("orgId", id);
}
export function getUserOrganizationId() {
  return localStorage.getItem("orgId") || "";
}

export function setUserOrganizationData(data) {
  localStorage.setItem("orgData", JSON.stringify(data));
}

export function getUserOrganizationData() {
  return localStorage.getItem("orgData") || "";
}