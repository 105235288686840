import React, { useEffect, useRef, useState } from "react";

import * as S from "../../styles";
import Container from "../../../../common/Container/Container";
import { Dropdown } from "../../../../common/Dropdown";
import ErrorTooltip from "../../../../common/ErrorTooltip";
import Counter from "../../../../common/Counter";
import { Camera, MaticWhite, ProfileIcon, TeamIcon, USDTWhite } from "../../../../../assets/svg";
import Text from "../../../../common/Text/Text";
import PoolThumbnail from "../../subcomponents/PoolThumbnail";
import PoolBanner from "../../subcomponents/PoolBanner";
import { assetUpload, getOrganizationGameAssets } from "../../../../../api/files";
import { useGetSecHeaders } from "../../../../../hooks/useHeaders";
import { useLogin } from "../../../../../providers/Loginprovider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { notifyError } from "../../../../common/ToastComponent";
import LoadingSkeleton from "../../../../common/LoadingSkeleton";
import { SpinnerLoader } from "../../../../common/SpinningLoader";
import PoolBannerFront from "../../subcomponents/PoolBannerFront";

const PLACEHOLDER_THUMBNAILS = [
  { id: 1, url: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/axie/origin/axie_inf_ori.png" },
  {
    id: 2,
    url: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/tekken_8/tekken8-thumbnail1.webp",
  },
  {
    id: 3,
    url: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/clash_royale/noxus/noxus-clash.png",
  },
  {
    id: 4,
    url: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/league_of_legends/lol_pool_thumbnail.jpg",
  },
  {
    id: 5,
    url: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/rocket-league/rl-thumbnail-1.png",
  },
];

const BANNER_THUMBNAILS = [
  {
    id: 1,
    bannerBgBack: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/axie/origin/poolDetailBG.png",
    bannerBgFront:
      "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/axie/origin/poolDetailFront.png",
  },
  {
    id: 2,
    bannerBgBack:
      "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/clash_royale/clashroyale-banner-tournament-3.jpg",
    bannerBgFront:
      "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/clash_royale/clashroyale-banner-3.png",
  },
  {
    id: 3,
    bannerBgBack:
      "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/yu-gi-oh/yu-gi-oh-banner-tournament.jpg",
    bannerBgFront:
      "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/yu-gi-oh/banner-front-yugioh-compressed.png",
  },
  {
    id: 4,
    bannerBgBack:
      "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/fifa/fifa-banner-tournament-3.jpg",
    bannerBgFront: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/organizations/Eryz/PNG%20(1).png",
  },
];

const ImagePicker = ({ handleClick, loading = false }) => {
  return (
    <S.ImagePicker loading={loading} onClick={handleClick}>
      {loading ? <SpinnerLoader /> : <Camera />}
    </S.ImagePicker>
  );
};

const BannerPicker = ({ handleClick, loading = false, header = "", subtext = "", styles = {} }) => {
  return (
    <S.ImagePicker loading={loading} onClick={handleClick} style={{ height: "65px", ...styles }}>
      {loading ? (
        <SpinnerLoader />
      ) : (
        <Container flex rowGap="2px" flexDirection="column" alignItems="center">
          <Camera style={{ height: "13px", width: "15px" }} />
          <Text fontSize="10px">{header}</Text>
          <Text fontSize="9px" color="#ffffff80">
            {subtext}
          </Text>
        </Container>
      )}
    </S.ImagePicker>
  );
};

const FourthStep = ({ register, errors = {}, setValue, currentInputValues = {} }) => {
  const thumbnailInputRef = useRef(null);
  const bannerForegroundInputRef = useRef(null);
  const bannerBackgroundInputRef = useRef(null);
  const headers = useGetSecHeaders();
  const { orgIdState } = useLogin();
  const queryClient = useQueryClient();

  // Creating state variables for thumbnails and banners so that we can show reversed list (showing reversed data directly was causing issues)
  const [thumbnailList, setThumbnailList] = useState([]);
  const [bannerBgList, setBannerBgList] = useState([]);
  const [bannerFrList, setBannerFrList] = useState([]);

  const [assetTypeBeingUploaded, setAssetTypeBeingUploaded] = useState(""); // This variable will be used to hold the type of asset being upoaded to determine which input should show a loader.
  const {
    data: assets,
    isLoading: isAssetsLoading,
    isFetching: isAssetsFetching,
    isError: isAssetsError,
  } = useQuery({
    queryKey: ["assets", orgIdState, currentInputValues.gameId],
    queryFn: () =>
      getOrganizationGameAssets({ organizationId: orgIdState, gameId: currentInputValues.gameId }, headers),
    refetchOnWindowFocus: false,
    enabled: !!headers?.encryptedHeader && !!orgIdState && !!currentInputValues.gameId,
  });

  const { mutate: assetUploadMutation, isLoading: assetUploadLoading } = useMutation({
    mutationFn: variables => {
      setAssetTypeBeingUploaded(variables.assetType); // Will help in showing loader on the correct input
      return assetUpload(
        {
          organizationId: orgIdState,
          type: "organizations",
          subType: `${orgIdState}/${variables.assetType}/${currentInputValues.gameId}`,
          banner: variables.file,
        },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["assets", orgIdState, currentInputValues.gameId] });
    },
    onSettled: () => {
      setAssetTypeBeingUploaded("");
    },
  });

  useEffect(() => {
    if (!assets || !assets.data || assets.error) return;
    if (assets.data?.thumbnails && Array.isArray(assets.data?.thumbnails)) {
      setThumbnailList(assets?.data?.thumbnails?.reverse());
    }

    if (!assets.data?.bannersBg || !Array.isArray(assets.data?.bannersBg)) return;

    setBannerBgList(assets.data?.bannersBg?.reverse());
    if (!assets.data?.bannersFr || !Array.isArray(assets.data?.bannersFr)) return;

    setBannerFrList(assets.data?.bannersFr?.reverse());
  }, [assets]);

  useEffect(() => {
    if (Array.isArray(thumbnailList) && thumbnailList.length > 0 && !currentInputValues.thumbnail)
      setValue("thumbnail", thumbnailList[0]?.url);

    if (Array.isArray(bannerBgList) && bannerBgList.length > 0 && !currentInputValues.bannerBgBack)
      setValue("bannerBgBack", bannerBgList[0]?.url);

    // We do not set bannerFr automatically, because abnnerFr is optional 
  }, [thumbnailList, bannerBgList, bannerFrList]);
  console.log("errors: ", errors)

  useEffect(() => {
    if (errors.thumbnail) notifyError("Thumbnail is required");
    if (errors.bannerBgBack) notifyError("Valid banner background is required");
    if (errors.bannerBgFront) notifyError("Valid banner front image is requiredr");
  }, [errors.bannerBgBack, errors.bannerBgFront, errors.thumbnail]);

  if (!setValue) return <div />;
  return (
    <Container>
      {/* Thumbnail Section */}
      <Container flex flexDirection="column" rowGap="27px" marginBottom="44px">
        <Container>
          <Text fontSize="20px">Thumbnail</Text>
        </Container>
        <S.ThumbnailsContainer>
          {isAssetsLoading ? (
            <Container flex columnGap="19px">
              <LoadingSkeleton height="110.66px" width="150.65px" />
              <LoadingSkeleton height="110.66px" width="150.65px" />
            </Container>
          ) : (
            <>
              <input
                type="file"
                className="hidden"
                ref={thumbnailInputRef}
                onChange={e => assetUploadMutation({ file: e.target.files[0], assetType: "thumbnails" })}
                disabled={isAssetsLoading || (assetUploadLoading && assetTypeBeingUploaded === "thumbnails")}
              />
              <ImagePicker
                handleClick={() => thumbnailInputRef?.current?.click()}
                loading={isAssetsLoading || (assetUploadLoading && assetTypeBeingUploaded === "thumbnails")}
              />
              {thumbnailList?.map(img => (
                <PoolThumbnail
                  active={currentInputValues.thumbnail === img.url}
                  handleClick={() => setValue("thumbnail", img.url)}
                  bgUrl={img.url}
                  key={img.url}
                />
              ))}
            </>
          )}
        </S.ThumbnailsContainer>
      </Container>

      {/* Banner Section */}
      <Container flex flexDirection="column" rowGap="27px">
        <Container>
          <Text fontSize="20px">Banner</Text>
        </Container>

        {/* Banner 1 Section */}
        <S.BannerContainer>
          {isAssetsLoading ? (
            <Container flex columnGap="19px">
              <LoadingSkeleton height="62px" width="240px" />
              <LoadingSkeleton height="62px" width="240px" />
            </Container>
          ) : (
            <>
              <input
                type="file"
                className="hidden"
                ref={bannerBackgroundInputRef}
                onChange={e => assetUploadMutation({ file: e.target.files[0], assetType: "banners_bg" })}
                disabled={isAssetsLoading || (assetUploadLoading && assetTypeBeingUploaded === "banners_bg")}
              />
              <BannerPicker
                handleClick={() => bannerBackgroundInputRef?.current?.click()}
                loading={isAssetsLoading || (assetUploadLoading && assetTypeBeingUploaded === "banners_bg")}
                header="Banner 1"
                subtext="Recommended size 1087x280. JPG"
              />
              {bannerBgList?.map(banner => (
                <PoolBanner
                  key={banner.url}
                  bgBackUrl={banner.url}
                  active={currentInputValues.bannerBgBack === banner.url}
                  handleClick={urlArg => {
                    setValue("bannerBgBack", urlArg);
                    // setValue("bannerBgFront", frontImgUrlArg);
                  }}
                />
              ))}
            </>
          )}
        </S.BannerContainer>

        {/* Banner 2 Section */}
        <>
          {isAssetsLoading ? (
            <Container flex columnGap="19px">
              <LoadingSkeleton height="62px" width="240px" />
              <LoadingSkeleton height="62px" width="240px" />
            </Container>
          ) : (
            <S.BannerFrContainer>
              <input
                type="file"
                className="hidden"
                ref={bannerForegroundInputRef}
                onChange={e => assetUploadMutation({ file: e.target.files[0], assetType: "banners_fr" })}
                disabled={isAssetsLoading || (assetUploadLoading && assetTypeBeingUploaded === "banners_fr")}
              />
              <BannerPicker
                handleClick={() => bannerForegroundInputRef?.current?.click()}
                loading={isAssetsLoading || (assetUploadLoading && assetTypeBeingUploaded === "banners_fr")}
                header="Banner 2"
                subtext="Recommended size 841x482. JPG"
                styles={{ gridColumnStart: 1, gridColumnEnd: 3 }}
              />
              {bannerFrList?.map(banner => (
                <PoolBannerFront
                  key={banner.url}
                  active={currentInputValues.bannerBgFront === banner.url}
                  handleClick={urlArg => {
                    console.log(urlArg);
                    setValue("bannerBgFront", urlArg);
                  }}
                  bgFrontUrl={banner.url}
                />
              ))}
            </S.BannerFrContainer>
          )}
        </>
      </Container>
    </Container>
  );
};

export default FourthStep;

// Dummy Banner BG
// https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/axie/origin/poolDetailBG.png

// Dummy Banner Front
// https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/axie/origin/poolDetailFront.png

// Dummy Thumbnail
// https://storage.googleapis.com/blast-prod-1.appspot.com/assets/games/axie/origin/axie_inf_ori.png
