import React from "react";
import { EthereumLogo, MaticWhite, USDTWhite } from "../../../assets/svg";

const TokenIcon = ({ tokenName, style = {} }) => {
  if (!tokenName || typeof tokenName != "string") return <div />;
  let tokenNameUpperCase = tokenName.toUpperCase();
  if (tokenNameUpperCase === "USDT") return <USDTWhite style={style} />;
  if (tokenNameUpperCase === "MATIC") return <MaticWhite style={style} />;
  if (tokenNameUpperCase === "ETH") return <EthereumLogo style={style} />;
  return <div />;
};

export default TokenIcon;
