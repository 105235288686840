import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import * as S from "./styles";
import { BackArrow, TournamentCardIcon } from "../../assets/svg";
import Container from "../../components/common/Container/Container";
import Text from "../../components/common/Text/Text";
import Flex from "../../components/common/Flex";
import { Button, ButtonUnstyled } from "../../components/common/Button";
import { Dropdown } from "../../components/common/Dropdown";
import { Tooltip } from "antd";

const TournamentTypeSelect = () => {
  const [stageCount, setStageCount] = useState(2);
  const [tournamentType, setTournamentType] = useState("");
  const history = useHistory();
  function goToFormPage() {
    if (tournamentType === "single") {
      history.push("single-new");
    } else if (tournamentType === "multistage") {
      history.push({ pathname: "multistage-new", state: { stageCount } });
    } else return;
  }
  return (
    <>
      <S.MainContainer>
        <ButtonUnstyled style={{ padding: 0 }} onClick={() => history.goBack()}>
          <BackArrow />
        </ButtonUnstyled>
        <Container flex columnGap="17px" alignItems="center" marginTop="8px" marginBottom="35px">
          <Text fontSize="38px">New Tournament</Text>
        </Container>

        <S.Sections>
          <S.SectionContainer active={tournamentType === "single"} onClick={() => setTournamentType("single")}>
            <TournamentCardIcon />
            <Container marginTop="39px" marginBottom="10px">
              <Text fontSize="20px" fontWeight="bold">
                Single Tournament
              </Text>
            </Container>
            <Container width="400px">
              <Text fontSize="14px" textAlign="center">
                Create a single stage tournament, like a leaderboard, a round robin or bracket tournament.
              </Text>
            </Container>
          </S.SectionContainer>
          <S.SectionContainer active={tournamentType === "multistage"} onClick={() => setTournamentType("multistage")}>
            <Flex columnGap="10px">
              <TournamentCardIcon />
              <TournamentCardIcon />
              <TournamentCardIcon />
            </Flex>
            <Container marginTop="39px" marginBottom="10px">
              <Text fontSize="20px" fontWeight="bold">
                Multistages
              </Text>
            </Container>
            <Container width="400px">
              <Text fontSize="14px" textAlign="center">
                Create a tournament with multiple stages, like a group stage qualifier followed by a bracket stage
                seeded with the top 2 teams from every group.
              </Text>
            </Container>

            <Container marginTop="20px">
              <Dropdown
                options={[
                  {
                    name: 2,
                    value: 2,
                  },
                  {
                    name: 3,
                    value: 3,
                  },
                  {
                    name: 4,
                    value: 4,
                  },
                ]}
                value={stageCount}
                onChange={setStageCount}
                containerStyles={{ background: "#4E3CCA", width: "99px" }}
                dropdownStyles={{ background: "#4E3CCA" }}
                dropdownOptionStyles={{ background: "transparent" }}
              />
            </Container>
          </S.SectionContainer>{" "}
        </S.Sections>

        <Container marginTop="49px" flex justifyContent="center">
          <Tooltip
            title={!tournamentType ? "Select a tournament type to continue" : ""}
            placement="bottom"
            color="#7265C8"
          >
            <Button borderRadius="30px" width="76px" height="42px" disabled={!tournamentType} onClick={goToFormPage}>
              Next
            </Button>{" "}
          </Tooltip>
        </Container>
      </S.MainContainer>
    </>
  );
};

export default TournamentTypeSelect;
