import React, { useEffect, useRef, useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { AccordionArrow, WarningOutlineRed } from "../../../../../assets/svg";
import * as S from "../../styles";
import Container from "../../../../common/Container/Container";
import { Dropdown } from "../../../../common/Dropdown";
import ErrorTooltip from "../../../../common/ErrorTooltip";
import Counter from "../../../../common/Counter";
import { Camera, MaticWhite, ProfileIcon, TeamIcon, USDTWhite } from "../../../../../assets/svg";
import Text from "../../../../common/Text/Text";
import PoolThumbnail from "../../subcomponents/PoolThumbnail";
import PoolBanner from "../../subcomponents/PoolBanner";
import { assetUpload, getOrganizationGameAssets } from "../../../../../api/files";
import { useGetSecHeaders } from "../../../../../hooks/useHeaders";
import { useLogin } from "../../../../../providers/Loginprovider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { notifyError } from "../../../../common/ToastComponent";
import LoadingSkeleton from "../../../../common/LoadingSkeleton";
import { SpinnerLoader } from "../../../../common/SpinningLoader";
import { formatNumberWithLetters, isNonEmptyObject } from "../../../../../utilities";
import { POOL_MODES, STAGE_MODES } from "../../../../../constants";

const WinnersModeSection = props => {
  //  type: TOP_X | FROM_X_TO_END | FROM_X_TO_Y | ROUND_X
  const {
    register,
    errors = {},
    setValue,
    currentInputValues = {},
    stageCount,
    clearErrors,
    watch,
    stageIndex,
  } = props;

  const [currentSelectedType, setCurrentSelectedType] = useState(watch(`winnersPolicy.${stageIndex}.type`));
  const stageMode = watch(`stages.${stageIndex}.stageMode`);
  const disableSelection = type => {
    if (stageMode === POOL_MODES.SINGLE_ELIMINATION) {
      return !(type === "TOP_X" || type === "ROUND_X");
    } else {
      return type === "ROUND_X";
    }
  };
  function handleSelect(type) {
    if (currentSelectedType === type) {
      return; // Do not do anything if user clicks on already selected row
    }

    if (disableSelection(type)) return;

    setValue(`winnersPolicy.${stageIndex}.value`, []);
    setValue(`winnersPolicy.${stageIndex}.type`, type);
    setCurrentSelectedType(type);
  }
  function handleInput(e, index = 0) {
    const value = e?.target?.value;
    setValue(`winnersPolicy.${stageIndex}.value.${index}`, value);
  }

  const val = watch(`winnersPolicy.${stageIndex}.value`);
  return (
    <>
      <S.RowContainer>
        <label style={{ width: "95px" }}>Winners Mode</label>

        <S.WinnersModeContainer>
          <S.WinnersModeRow
            disabled={disableSelection("TOP_X")}
            isSelected={currentSelectedType === "TOP_X"}
            onClick={() => handleSelect("TOP_X")}
          >
            Top
            <input
              onChange={handleInput}
              disabled={disableSelection("TOP_X")}
              value={currentSelectedType === "TOP_X" ? val?.[0] : ""}
            />
          </S.WinnersModeRow>
          <S.WinnersModeRow
            disabled={disableSelection("FROM_X_TO_END")}
            isSelected={currentSelectedType === "FROM_X_TO_END"}
            onClick={() => handleSelect("FROM_X_TO_END")}
          >
            From
            <input
              onChange={handleInput}
              disabled={disableSelection("FROM_X_TO_END")}
              value={currentSelectedType === "FROM_X_TO_END" ? val?.[0] : ""}
            />
            to end
          </S.WinnersModeRow>
          <S.WinnersModeRow
            disabled={disableSelection("FROM_X_TO_Y")}
            isSelected={currentSelectedType === "FROM_X_TO_Y"}
            onClick={() => handleSelect("FROM_X_TO_Y")}
          >
            From
            <input
              disabled={disableSelection("FROM_X_TO_Y")}
              onChange={e => handleInput(e, 0)}
              value={currentSelectedType === "FROM_X_TO_Y" ? val?.[0] : ""}
            />
            to
            <input
              disabled={disableSelection("FROM_X_TO_Y")}
              onChange={e => handleInput(e, 1)}
              value={currentSelectedType === "FROM_X_TO_Y" ? val?.[1] : ""}
            />
          </S.WinnersModeRow>
          <S.WinnersModeRow
            disabled={disableSelection("ROUND_X")}
            isSelected={currentSelectedType === "ROUND_X"}
            onClick={() => handleSelect("ROUND_X")}
          >
            Round
            <input
              disabled={disableSelection("ROUND_X")}
              onChange={handleInput}
              value={currentSelectedType === "ROUND_X" ? val?.[0] : ""}
            />
          </S.WinnersModeRow>
        </S.WinnersModeContainer>

        {/* <Dropdown
          options={[
            { name: "1", value: 1 },
            { name: "2", value: 2 },
            { name: "3", value: 3 },
            { name: "4", value: 4 },
            { name: "5", value: 5 },
          ]}
          onChange={e => {
            clearErrors("winnerCount");
            clearErrors("percentageList");
            clearErrors("percentageTotal");
            setValue("winnerCount", e);
            setValue("percentageList", new Array(e).fill(undefined));
          }}
          value={watch("winnerCount") || currentInputValues.winnerCount || 0}
          hasError={!!errors.winnerCount}
        />
        {errors.winnerCount && <ErrorTooltip message={errors.winnerCount?.message} right="30px" />} */}
      </S.RowContainer>
    </>
  );
};

const FourthStep = props => {
  const { register, errors = {}, setValue, currentInputValues = {}, stageCount, clearErrors, watch } = props;
  return (
    <Accordion>
      <Container flex flexDirection="column" rowGap="27px">
        {[...Array(stageCount - 1).keys()].map((_, i) => (
          <AccordionItem>
            <AccordionItemHeading
              style={{
                borderRadius: "6px",
                border:
                  errors?.winnersPolicy?.[i] || isNonEmptyObject(errors?.winnersPolicy) ? "1px solid #ed6140" : "",
              }}
            >
              <AccordionItemButton>
                <span>Stage {i + 1}</span>
                <span>
                  <AccordionArrow />
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Container flex flexDirection="column" rowGap="27px" marginLeft="35px" marginRight="35px">
                <WinnersModeSection {...props} stageIndex={i} />
              </Container>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
        {/* Final Stage Accordion */}
        <AccordionItem>
          <AccordionItemHeading
            style={{
              borderRadius: "6px",
              border: errors?.winnerCount ? "1px solid #ed6140" : "",
            }}
          >
            <AccordionItemButton>
              <span>Stage {stageCount}</span>
              <span>
                <AccordionArrow />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Container flex flexDirection="column" rowGap="23px">
              <S.RowContainer>
                <label>Winners</label>
                <Dropdown
                  options={[
                    { name: "1", value: 1 },
                    { name: "2", value: 2 },
                    { name: "3", value: 3 },
                    { name: "4", value: 4 },
                    { name: "5", value: 5 },
                  ]}
                  onChange={e => {
                    clearErrors("winnerCount");
                    clearErrors("percentageList");
                    clearErrors("percentageTotal");
                    setValue("winnerCount", e);
                    setValue("percentageList", new Array(e).fill(undefined));
                  }}
                  value={watch("winnerCount") || currentInputValues.winnerCount || 0}
                  hasError={!!errors.winnerCount}
                />
                {errors.winnerCount && <ErrorTooltip message={errors.winnerCount?.message} right="30px" />}
              </S.RowContainer>
              <S.RowContainer alignItems="flex-start">
                <label>Percentage</label>
                <S.FieldsContainer
                  hidden={!currentInputValues?.percentageList}
                  hasError={errors.percentageList?.message}
                >
                  {errors.percentageList && (
                    <Container flex columnGap="8px" alignItems="center" marginBottom="10px">
                      <WarningOutlineRed style={{ width: "14px" }} />
                      <Text color="#ED6140" fontSize="12px">
                        {errors.percentageList?.message
                          ? errors.percentageList?.message
                          : " Valid input is required for each row"}
                      </Text>
                    </Container>
                  )}
                  {errors.percentageTotal && (
                    <Container flex columnGap="8px" alignItems="center" marginBottom="10px">
                      <WarningOutlineRed style={{ width: "14px" }} />
                      <Text color="#ED6140" fontSize="12px">
                        {errors.percentageTotal?.message}
                      </Text>
                    </Container>
                  )}
                  {currentInputValues.winnerCount && (
                    <Container flex flexDirection="column" rowGap="7px">
                      {[...Array(Number(currentInputValues.winnerCount)).keys()].map((item, index) => (
                        <S.PercentageInputRow key={item}>
                          <Text fontSize="11px">{formatNumberWithLetters(item + 1)} Position</Text>
                          <Container flex columnGap="6px" alignItems="center">
                            <S.PercentageInput
                              type="number"
                              onChange={e => {
                                let temp = currentInputValues.percentageList || [];
                                temp[index] = e.target.value;

                                setValue("percentageList", temp);
                                clearErrors("percentageList");
                                clearErrors("percentageTotal");
                              }}
                              value={currentInputValues?.percentageList?.[index] || ""}
                              hasError={!!errors?.percentageList?.[index]}
                            />
                            <Text fontSize="11px">%</Text>
                          </Container>
                        </S.PercentageInputRow>
                      ))}
                      <S.PercentageInputRow>
                        <Text fontSize="11px">Total</Text>
                        <Container flex columnGap="6px" alignItems="center">
                          <S.PercentageInput
                            type="number"
                            disabled
                            value={
                              currentInputValues.percentageList
                                ? currentInputValues.percentageList.reduce((acc, curr) => {
                                    let current = Number(curr) || 0;
                                    return acc + current;
                                  }, 0)
                                : 0
                            }
                            hasError={!!errors?.percentageTotal}
                          />
                          <Text fontSize="11px">%</Text>
                        </Container>
                      </S.PercentageInputRow>
                    </Container>
                  )}
                </S.FieldsContainer>
              </S.RowContainer>
            </Container>
          </AccordionItemPanel>
        </AccordionItem>
      </Container>
    </Accordion>
  );
};

export default FourthStep;
