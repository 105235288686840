import React, { useState } from "react";
import * as S from "./style";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Tooltip } from "antd";
import {
  BlastLogo,
  BurgerIcon,
  Calendar,
  CalendarSelected,
  Controller,
  ControllerActive,
  LeftArrow,
  OrganizationActiveIcon,
  OrganizationIcon,
  ProfileActiveIcon,
  ProfileIcon,
  TeamActiveIcon,
  TeamIcon,
  Tournament,
  TournamentSelected,
} from "../../../assets/svg";
import { useQuery } from "@tanstack/react-query";
import { getGamesList } from "../../../api/game";
import { useSession } from "../../../providers/SessionProvider";

export const Sidebar = ({ collapseSidebar, setCollapseSidebar, userType }) => {
  const [hoverItem, setHoverItem] = useState("");
  const [navElementActive, setNavElementActive] = useState("");

  // React Router Hooks
  const location = useLocation();
  const history = useHistory();

  const {
    data: activeGames,
    isLoading,
    isError,
  } = useQuery({ queryKey: ["games"], queryFn: getGamesList, refetchOnWindowFocus: false });

  // Sets the active nav element based on the current route
  const navClick = (title, route) => {
    setNavElementActive(title);
    if (route !== undefined) {
      history.push(route);
    }
  };

  // misc functions
  const onMouseOver = item => {
    setHoverItem(item);
  };

  const onMouseOut = () => {
    setHoverItem("");
  };

  // Icons
  const navItems = [
    {
      icon: <Tournament />,
      activeIcon: <TournamentSelected />,
      title: "Tournaments",
      public: true,
      route: "/",
      visible: true,
    },
    {
      icon: <Calendar />,
      activeIcon: <CalendarSelected />,
      title: "Event Calendar",
      public: true,
      route: "/calendar",
      visible: false,
    },
    {
      icon: <ProfileIcon />,
      activeIcon: <ProfileActiveIcon />,
      public: true,
      route: "/users",
      title: "Users",
      visible: userType === "SUPERUSER" ? true : false,
    },
    {
      icon: <TeamIcon />,
      activeIcon: <TeamActiveIcon />,
      public: true,
      route: "/teams",
      title: "Teams",
      visible: true,
    },
    {
      icon: <OrganizationIcon />,
      activeIcon: <OrganizationActiveIcon />,
      public: true,
      route: "/organizations",
      title: "Organizations",
      visible: userType === "SUPERUSER" ? true : false
    },
    {
      icon: <Controller />,
      activeIcon: <ControllerActive />,
      public: true,
      route: "/games",
      title: "Games",
      visible: userType === "SUPERUSER" ? true : false
    },
  ];

  return (
    <>
      <S.SidebarWrapper
        collapseSidebar={collapseSidebar}
        onClick={e => {
          setCollapseSidebar(!collapseSidebar);
        }}
      >
        <S.NavTopWrapper>
          <S.NavBar>
            <div style={{ marginBottom: "80px" }}>
              <Link to="/">
                <S.BlastLogoWrapper>
                  <BlastLogo />
                </S.BlastLogoWrapper>
              </Link>
            </div>
            {navItems
              .filter(item => item.visible)
              .map((item, index) => (
                <Tooltip title={item?.title} placement="right" color="#7265C8">
                  <S.NavItem
                    key={item.title}
                    active={navElementActive === item.title}
                    onClick={e => {
                      e.stopPropagation();
                      navClick(item.title, item.route);
                    }}
                    onMouseEnter={() => onMouseOver(item.title)}
                    onMouseLeave={() => onMouseOut()}
                  >
                    <S.IconWrapper style={{ position: "relative" }} key={index}>
                      {hoverItem === item.title || item.route === location.pathname ? item.activeIcon : item.icon}
                    </S.IconWrapper>
                    <S.TitleWrapper>{item.title}</S.TitleWrapper>
                  </S.NavItem>
                </Tooltip>
              ))}
          </S.NavBar>
        </S.NavTopWrapper>
      </S.SidebarWrapper>
    </>
  );
};
