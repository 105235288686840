import React from "react";

import { POOL_MODES } from "../../../../constants";
import Brackets from "../../../../components/TournamentDetail/Brackets/Brackets";

const BracketsSection = props => {
  const { isFetchingPoolData, poolData = {}, selectedStage = {}, joinMode } = props;
  // const { gameDetails, isFetchingPoolData = false, poolStatus } = props;
  // const { isFetchingPoolData, poolData, selectedStage } = props;

  const isMultiStagePool = poolData.poolMode === POOL_MODES.MULTI_STAGE;

  const gameDetails =
    isMultiStagePool && selectedStage?.extraConfigData?.bracketsConfig
      ? selectedStage?.extraConfigData?.bracketsConfig
      : poolData.gameDetails;

  const poolStatus = poolData?.poolStatus;
  const poolMode = poolData?.poolMode;
  return (
    <Brackets
      {...{
        gameDetails,
        isFetchingPoolData,
        poolStatus,
        isMultiStagePool,
        stageId: selectedStage?.stageId,
        joinMode,
        poolMode,
      }}
    />
  );
};

export default BracketsSection;
