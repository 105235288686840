import styled from "styled-components";
import { Button } from "../../../common/Button";

export const InvitationalCodeRowWrapper = styled.div`
  min-width: 404px;
  height: 49px;
  border-radius: 6px;
  padding: 0px 18px;
  background-color: #3f2faa;
  display: flex;

  &:hover{
    background: #3f2faab3;
  }
`;

export const DataText = styled.span`
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  margin: 0;
`;

export const StatusWrapper = styled.div`
  width: fit-content;
  display: flex;
  padding: 7px 11px;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  background-color: #4e3cca;
`;

export const StatusDot = styled.span`
  background: ${props => (props.isActive ? "#b2fa4f" : "#ed6140")};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

export const StatusText = styled.span`
  font-size: 11px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  color: #a498ed;
  margin: 0;
  text-transform: capitalize;
`;

export const DeleteCodeButton = styled(Button)`
  background: transparent;
  padding-right: 0;
`;

export const ActionButton = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;
