import styled from "styled-components";

export const RowSpacing = styled.div`
  display: grid;
  grid-template-columns: 1.25fr repeat(4, 1fr) 0.2fr 0.45fr;
  padding: 10px 21px;
`;
export const ContactRow = styled(RowSpacing)`
  column-gap: 14px;
  width: 100%;
  height: 50px;
  background-color: #3f2faa;
  border-radius: 6px;
  padding: 10px 21px;
  color: #fff;
  min-width: 1000px;
  align-items: center;
  a {
    text-decoration: underline;
  }
`;

export const ContactRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  overflow-x: auto;
`;

export const NameContainer = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Input = styled.input`
  height: 41px;
  border-radius: 6px;
  background-color: #4e3cca;
  border: none;
  width: 100%;
  /* color: #a498ed; */
  color: #fff;
  margin-top: 8px;
  font-size: 14px;
  padding-left: 8px;
  border: ${props => (props.hasError ? "2px solid #ED6140" : "unset")};
  &::placeholder {
    color: #a498ed;
  }
  &:focus {
    outline: 2px solid #6c47ff;
  }
`;

export const TextArea = styled.textarea`
  height: auto;
  border-radius: 6px;
  background-color: #4e3cca;
  border: none;
  width: 100%;
  /* color: #a498ed; */
  color: #fff;
  margin-top: 8px;
  font-size: 14px;
  padding-left: 8px;
  padding-top: 8px;
  font-family: 'Gotham';
  border: ${props => (props.hasError ? "2px solid #ED6140" : "unset")};
  &::placeholder {
    color: #a498ed;
  }
  &:focus {
    outline: 2px solid #6c47ff;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 325;
  margin-bottom: 50px;
`;

export const Form = styled.form`
  display: flex;
  row-gap: 17px;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  position: relative;
`
