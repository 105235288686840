import Skeleton from "react-loading-skeleton";

import React from "react";

const LoadingSkeleton = ({ baseColor, highlightColor, height, width, ...rest }) => {
  return (
    <Skeleton
      baseColor={baseColor || "#362890"}
      highlightColor={highlightColor || "#00000033"}
      height={height || "100%"}
      width={width || "100%"}
      {...rest}
    />
  );
};

export default LoadingSkeleton;
