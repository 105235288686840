import React from "react";
import styled from "styled-components";
import { Maintenance } from "../../assets/svg";
import { Button } from "../../components/common/Button";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background: #201366;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-style: normal;
  font-weight: 350;
  font-size: 60px;
  line-height: 72px;
  text-align: center;

  color: #ffffff;
  margin: 0;
  margin-top: 30px;
  position: absolute;
`;

const Subtitle = styled.h2`
  font-style: normal;
  font-weight: 350;
  font-size: 38px;
  line-height: 46px;
  text-align: center;

  /* Text color */
  color: #a498ed;
`;

export const NotFoundPage = () => {
  return (
    <Container>
      <Maintenance />
      <Title>404</Title>
      <Subtitle>Oops! Page not found</Subtitle>
      <Button
        borderRadius="30px"
        width="176px"
        height="47px"
        fontSize="16px"
        onClick={() => (window.location.href = "/")}
      >
        Back To Home
      </Button>
    </Container>
  );
};
