import { WEBAPP_BE_API_BASE_URL } from "./env";

export const ENDPOINT = {
  BASE_URL: WEBAPP_BE_API_BASE_URL,
  USER: "user",
  JOIN_POOL: "joinPool",
  VALIDATE_ID: "validateActivisionId",
  SIGNIN: "signIn",
  CLOSE_SESSION: "removeSession",
  LEAVE_POOL: "leavePool",
  CONVERSION: "/utils/conversion",
  APP_STATE: "appStats",
  ADVERTISEMENT_DASHBOARD: "/advertisements/dashboard",
  ADVERTISEMENT_GAME: "/advertisements/game",
  POOL_LIST_BY_GAME: "/poolList/game",
  GAME: "/game",
  TOP_WINNERS: "/gamesStatistics",
  CREATE_TEAM: "/team", // POST request
  DISPLAY_USER_TEAMS: "/team/user",
  DISPLAY_ALL_TEAMS: "/team", // GET request
  JOIN_TEAM: "/team/requestJoin",
  TEAM_NOTIFICATIONS: "/team/notifications",
  IP_CONFIG: "https://ifconfig.co/ip",
  ADMIN_POOL: "/admPanel/pool"
};
