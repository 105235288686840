import styled from "styled-components";
import { breakpoints } from "../../themes";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  padding: 0px 76px 10px 81px;
  ${breakpoints.down(breakpoints.laptopL)} {
    padding: 0px 38px 10px 47px;
  }
  ${breakpoints.down(breakpoints.laptop)} {
    padding: 0px 16px 10px 16px;
  }
`;

export const TableContainer = styled.div`
  &::-webkit-scrollbar {
    width: 2px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #3f2faa;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #342788f0;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #342788;
  }

  margin-top: 56px;
  overflow-x: auto;
`;

export const TableHeader = styled.div`
  display: grid;
  color: #a498ed;
  padding-left: 21px;
  margin-bottom: 42px;
  min-width: 700px;
  grid-template-columns: 2fr 1fr 2fr;
`;

export const OrgRowWrapper = styled.div`
  cursor: pointer;
  &:hover {
    div {
      background-color: #3F2FAAb3;
    }
  }
`;
