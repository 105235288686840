import React, { useState } from "react";
import { createOrganization, getOrganizations } from "../../api/organizations";
import { useGetSecHeaders } from "../../hooks/useHeaders";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import * as S from "./styles";
import { Add } from "../../assets/svg";
import { Button } from "../../components/common/Button";
import { notifyError, notifySuccess } from "../../components/common/ToastComponent";
import Container from "../../components/common/Container/Container";
import CreateOrganizationModal from "../../components/views/players/organizations/CreateOrganizationModal";
import LoadingSkeleton from "../../components/common/LoadingSkeleton";
import OrganizationDetailModal from "../../components/views/players/organizations/OrganizationDetailModal";
import OrganizationRow from "../../components/views/players/organizations/OrganizationRow";
import SearchBar from "../../components/views/players/shared/SearchBar";
import Text from "../../components/common/Text/Text";

const Organizations = () => {
  const [showOrgInfoModal, setShowOrgInfoModal] = useState(false);
  const [showCreateOrgModal, setShowCreateOrgModal] = useState(false);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const queryClient = useQueryClient();
  const headers = useGetSecHeaders();

  const toggleShowOrgInfoModal = () => {
    setShowOrgInfoModal(!showOrgInfoModal);
  };

  const toggleShowCreateOrgModal = () => {
    setShowCreateOrgModal(!showCreateOrgModal);
  };

  const switchModal = (id) => {
    setSelectedOrganizationId(id);
    toggleShowCreateOrgModal();
    toggleShowOrgInfoModal();
  }

  const {
    data: organizations,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["organizations", searchQuery],
    queryFn: () => getOrganizations({ searchQuery }, headers),
    refetchOnWindowFocus: false,
    enabled: !!headers?.encryptedHeader,
  });

  const { mutate: mutateCreateOrganization, isLoading: mutateCreateOrganizationLoading } = useMutation({
    mutationFn: variables => {
      return createOrganization(
        {
          organizationName: variables.organizationName,
          referralCode: variables.referralCode,
          urlParam: variables.urlParam,
          selectedPermissions: variables.permissions,
          selectedOwner: variables.owner,
          language: variables.language
        },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.message || e?.data);
      if (variables?.errorCb) variables?.errorCb();
    },
    onSuccess: (e) => {
      notifySuccess(e?.data || "Organization created successfully");
      queryClient.invalidateQueries({ queryKey: ["organizations", searchQuery] });
      switchModal(e?.id);
    },
  });

  function handleClickOrgInfoModal(id) {
    setSelectedOrganizationId(id);
    setShowOrgInfoModal(true);
  }

  function handleClickOrgModal() {
    setShowCreateOrgModal(true);
  }

  const organizationNotFound = organizations && organizations.length < 1;

  return (
    <>
      <S.MainContainer>
        <Container flex justifyContent="space-between" alignItems="center">
          <Container flex columnGap="17px" alignItems="center">
            <Text fontSize="38px">Organizations</Text>
            <SearchBar setSearchQuery={setSearchQuery} />
          </Container>
          <Button borderRadius="30px" gap="10px" onClick={() => handleClickOrgModal()}>
            <Add /> New Organization
          </Button>
        </Container>

        <S.TableContainer>
          <S.TableHeader>
            <Text fontSize="13px">ID</Text>
            <Text fontSize="13px">Name</Text>
            <Text fontSize="13px">Status</Text>
          </S.TableHeader>
          <Container flex flexDirection="column" rowGap="12px">
            {isLoading ? (
              <>
                <LoadingSkeleton height="50px" />
                <LoadingSkeleton height="50px" />
                <LoadingSkeleton height="50px" />
              </>
            ) : organizationNotFound ? (
              <Container flex justifyContent="center" alignItems="center" height="200px">
                Organizations Not Found
              </Container>
            ) : (
              organizations?.map(organization => (
                <S.OrgRowWrapper onClick={() => handleClickOrgInfoModal(organization.id)}>
                  <OrganizationRow data={organization} />
                </S.OrgRowWrapper>
              ))
            )}
          </Container>
        </S.TableContainer>
      </S.MainContainer>

      <OrganizationDetailModal
        showModal={showOrgInfoModal}
        toggleModal={toggleShowOrgInfoModal}
        organizationId={selectedOrganizationId}
        setOrganizationId={setSelectedOrganizationId}
      />
      <CreateOrganizationModal
        showModal={showCreateOrgModal}
        toggleModal={toggleShowCreateOrgModal}
        mutateCreateOrg={mutateCreateOrganization}
        mutateCreateOrgLoading={mutateCreateOrganizationLoading}
      />
    </>
  );
};

export default Organizations;
