import { Camera } from "../../../../assets/svg";
import { SpinnerLoader } from "../../../common/SpinningLoader";
import * as S from "../styles";

const ImagePicker = ({ handleClick, loading = false }) => {
  return (
    <S.ImagePicker loading={loading} onClick={handleClick}>
      {loading ? <SpinnerLoader /> : <Camera />}
    </S.ImagePicker>
  );
};

export default ImagePicker;
