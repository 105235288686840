import React, { useState } from "react";
import { truncateWithMiddleDots } from "../../../../../utilities";

import * as S from "./styles";
import { Popover } from "antd";
import Container from "../../../../common/Container/Container";
import Avatar from "../../../../common/Avatar";
import { Button, IconButton } from "../../../../common/Button";
import { Crown, ProfileIcon, Trash } from "../../../../../assets/svg";
import { CheckBox } from "../../../../common/Checkbox";

const OrganizationMemberRow = ({ data = {}, ownerId, organizationId, mutatePermission, mutateRemovePlayerFromOrg }) => {
  const options = [
    {
      name: "Create",
      value: "CREATE",
    },
    {
      name: "Read",
      value: "READ",
    },
    {
      name: "Update",
      value: "UPDATE",
    },
    {
      name: "Delete",
      value: "DELETE",
    },
  ];

  // EXPLANATION: The pervPermission and currentPermission state are used to manage the state of the permissions dropdown
  // The dropdown value is controlled via the currentPermission state
  // The currentPermission state is updated with the new dropdown value as soon as the onChange effect of the dropdown is called (when the user uses the dropdown)
  // The onChange also calls the POST request to the update permissions API call.
  // If the API request fails, the currentPermission state is reverted to the old stateusing the prevPermission state.
  // If the API request is successful, the prevPermission state is also updated with the new value
  const [currentPermissions, setCurrentPermissions] = useState(data.userPermissions || []);
  const [prevPermission, setPrevPermission] = useState(currentPermissions);
  function permissionUpdateErrorCb() {
    setCurrentPermissions(prevPermission);
  }
  function permissionUpdateSuccessCb(val) {
    setPrevPermission(val);
  }

  const [openDelPopover, setOpenDelPopover] = useState(false);

  const hideDelPopover = () => {
    setOpenDelPopover(false);
  };

  const handleOpenChange = newOpen => {
    setOpenDelPopover(newOpen);
  };

  return (
    <S.Container>
      <Container flex alignItems="center" columnGap="8px">
        <Avatar src={data.avatarUrl} />
        <S.CustomText>{data.userNick}</S.CustomText>
      </Container>
      <Container flex alignItems="center">
        <S.CustomText>{truncateWithMiddleDots(data.userId)}</S.CustomText>
      </Container>
      <Container flex alignItems="center" marginLeft="10px">
        {ownerId === data.userId ? <Crown /> : <ProfileIcon />}
      </Container>
      <Container flex alignItems="center">
        <S.StatusContainer>
          <S.StatusText>{data.status === "ACTIVE" ? "Active" : data.status}</S.StatusText>
        </S.StatusContainer>
      </Container>
      <Container flex alignItems="center">
        <CheckBox
          options={options}
          onChange={vals => {
            setCurrentPermissions(vals);
            mutatePermission({
              selectedOrganizationId: organizationId,
              permissions: vals,
              userId: data.userId,
              errorCb: permissionUpdateErrorCb,
              successCb: () => permissionUpdateSuccessCb(vals),
            }); // data.id will hold the id of this row's organization
          }}
          value={currentPermissions}
        />
      </Container>
      <Container flex alignItems="center">
        <Popover
          content={
            <Container marginTop="22px">
              <Button
                width="100%"
                background="#3F2FAA"
                height="50.885px"
                borderRadius="30px"
                onClick={() => {
                  hideDelPopover();
                  mutateRemovePlayerFromOrg({
                    selectedOrganizationId: organizationId,
                    userId: data.userId,
                  });
                }}
              >
                Confirm
              </Button>
            </Container>
          }
          zIndex="9999"
          title="Are you sure you want to delete this User from this organization?"
          trigger="click"
          open={openDelPopover}
          onOpenChange={handleOpenChange}
          color="#7265C8"
          overlayInnerStyle={{
            color: "brown !important",
            width: "220px",
            padding: "19px",
            textAlign: "center",
          }}
          overlayStyle={{
            color: "green !important",
          }}
          overlayClassName="match-report-popover"
        >
          <IconButton onClick={() => setOpenDelPopover(true)}>
            <Trash />
          </IconButton>
        </Popover>
      </Container>
    </S.Container>
  );
};

export default OrganizationMemberRow;
