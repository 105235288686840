import React, { useContext, useState } from "react";
import * as S from "../styles";
import Avatar from "../../../components/common/Avatar";
import Text from "../../../components/common/Text/Text";
import Container from "../../../components/common/Container/Container";
import { capitalizeFirstLetter, formatNumberWithLetters } from "../../../utilities";
import { Edit, GroupMoveIcon, SwapPurple, Trash } from "../../../assets/svg";
import { TournamentContext } from "../../../providers/TournamentContext";
import { TournamentTeamDetailModal } from "../../../components/views/tournamentDetail/TeamDetailModal";
import { Tooltip } from "antd";
import { IconButton } from "../../../components/common/Button";
import { STAGE_TYPES } from "../../../constants";

const LeaderboardRow = props => {
  const {
    avatarURL,
    id,
    nick,
    bestMatch = {},
    displayData = [],
    rank,
    userId,
    playerData = [], // TODO: Confirm what this prop is used for
    joinMode,
    isRoundRobin,
    setPlayersToMoveGroup = [],
    setPlayerToSwapGroup = null,
    rowData = {}, // Data of each leaderboard row player
  } = props;
  const { setShowReportModal, setExtraReportData, stageInfo } = useContext(TournamentContext);
  const [showTeamDetailModal, setShowTeamDetailModal] = useState(false);

  const { stageType, groups } = stageInfo || {};

  function handleOpenReportModal(id, userId) {
    setExtraReportData({
      leaderboardRowId: id,
      userId: userId,
    });
    setShowReportModal(true);
  }

  const toggleTeamDetailsModal = () => {
    if (joinMode === "TEAM") {
      setShowTeamDetailModal(!showTeamDetailModal);
    } else {
      return;
    }
  };

  return (
    <>
      <S.LeaderboardRow
        dynamicColumnsCount={displayData.length}
        onClick={() => toggleTeamDetailsModal()}
        joinMode={joinMode}
      >
        <Container flex alignItems="center" columnGap="15px">
          <Text>{formatNumberWithLetters(rank)}</Text>
        </Container>
        <Container flex alignItems="center" columnGap="15px">
          <Avatar src={avatarURL} />
          <Text>{nick}</Text>
        </Container>

        <>
          {isRoundRobin ? (
            <div>{bestMatch?.totalPoints}</div>
          ) : (
            <div>
              {displayData.map(dataItem => (
                <div>{bestMatch[dataItem?.dataKey] || "-"}</div>
              ))}
            </div>
          )}
        </>

        <Container flex columnGap="29px">
          <Edit
            style={{ cursor: "pointer" }}
            onClick={e => {
              e.stopPropagation();
              handleOpenReportModal(id, userId);
            }}
          />
          {/* <Trash /> */}
          {stageType === STAGE_TYPES.LEADERBOARD && groups?.length && (
            <>
              <Tooltip title="Move player to other group" placement="top" color="#7265C8">
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    setPlayersToMoveGroup(prev => [...prev, rowData]);
                  }}
                  style={{ padding: 0, margin: 0 }}
                >
                  <GroupMoveIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Swap groups" placement="top" color="#7265C8">
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    setPlayerToSwapGroup(rowData);
                  }}
                  style={{ padding: 0, margin: 0 }}
                >
                  <SwapPurple />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Container>
      </S.LeaderboardRow>
      <TournamentTeamDetailModal
        showModal={showTeamDetailModal}
        toggleModal={toggleTeamDetailsModal}
        data={playerData.players}
      />
    </>
  );
};

export default LeaderboardRow;
