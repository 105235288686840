import styled from "styled-components";

export const InputWrapper = styled.div`
  height: 38px;
  width: 100%;
  padding: 10px;
  background-color: #3f2faa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border-radius: 6px;
`;
export const Input = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  color: #ffffff;

  &::placeholder {
    color: #a498ed;
  }
`;

export const OrganizationOwnerSelector = styled.button`
  background-color: #3f2faa;
  border: none;
  height: 38px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  padding: 10px;
  color: #a498ed;
  font-size: 14px;
  font-weight: 325;
  line-height: 16.8px;
  position: relative;

  &::after {
    content: "\\25BC";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%) rotate(270deg);
    transition: transform 0.3s;
  }
`;

export const Image = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
`;