import styled from "styled-components";

export const Container = styled.div`
  padding-top: 31px;
  padding-left: 72px;
  padding-right: 72px;
`;

export const GameListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  row-gap: 32px;
`;
export const GameImg = styled.img`
  width: 230px;
  height: 90%;
  border-radius: 6px;
  margin-bottom: 6px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 42px;
  align-items: center;
  justify-content: space-between;
`;

export const AddLink = styled.div`
  display: flex;
  column-gap: 7px;
  font-size: 13px;
  width: 230px;
  height: 258px;
  border-radius: 6px;
  margin-bottom: 6px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%);

  svg {
    width: 12px;
    height: 12px;
  }
`;
